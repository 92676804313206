import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./modalDeepDiveView.css";
import DeepDiveAddEditAction from "./deepDiveAddEditAction";
import DeepDiveActionCard from "./deepDiveActionCard";
import ActivityIndicator from "../../../../components/Loader/loader";
import plusIcon from "../../../../assets/images/PlusNew.svg";
import { fetchReviewAction } from "../../../../service/projectDetails";

const DeepDiveAction = (props) => {
  const { arrItem, isEdit } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [requiredActionData, setRequiredActionData] = useState([]);
  const [requiredActionDataGrouped, setRequiredActionDataGrouped] = useState(
    {}
  );
  const [showAddEditActionModal, setShowAddEditActionModal] = useState(false);
  const [newRowData, setNewRowData] = useState({});

  useEffect(() => {
    getRequiredActionList();
  }, []);

  const getRequiredActionList = async () => {
    const res = await fetchReviewAction(arrItem.projectId);
    if (res.status === 200) {
      console.log("res.data", res.data);
      const arr = res.data.map((item, index) => {
        return item;
      });
      setRequiredActionData([...arr]);
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (requiredActionData?.length > 0) {
      let obj = Object.assign({});
      requiredActionData.forEach((item) => {
        let key = "";
        if (item.materialType.length > 0) {
          key = `${item.materialType} ${item.materialNumber} - ${item.materialDescription}`;
        } else {
          key = "User Defined Actions";
        }
        if (obj[key]) {
          obj[key].push(item);
        } else {
          obj[key] = [item];
        }
      });
      setRequiredActionDataGrouped(obj);
    }
  }, [requiredActionData]);

  const handleAddActionManual = () => {
    let objPayload = {};
    objPayload.actionId = 0;
    objPayload.projectId = arrItem.projectId;
    objPayload.projectName = arrItem.projectName;
    objPayload.functionName = "";
    objPayload.roleName = "";
    objPayload.actionName = "";
    objPayload.actionDetails = "";
    objPayload.materialNumber = "";
    objPayload.materialDescription = "";
    objPayload.materialType = "";
    objPayload.contact = "";
    objPayload.deadline = "";
    objPayload.deadlineStatus = "";
    objPayload.status = false;
    objPayload.isAutomated = false;
    objPayload.isMailSent = false;
    setNewRowData(objPayload);
  };

  useEffect(() => {
    if (Object.keys(newRowData).length > 0) {
      setShowAddEditActionModal(true);
    }
  }, [newRowData]);

  const handleDataChange = (data) => {
    const arr = data.map((item, index) => {
      return item;
    });
    setRequiredActionData([...arr]);
  };

  return (
    <>
      <div className="dsf-deepdive-action-parent-div">
        <div className={`dsf-deepdive-action-title-div`}>
          <div className="dsf-deepdive-action-title-span">
            SUGGESTED ACTIONS
          </div>
          <div className="dsf-deepdive-action-details-div">
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <div className="dsf-deepdive-action-details-child-div">
                {Object.keys(requiredActionDataGrouped).map((key, index) => {
                  return (
                    <div
                      key={index}
                      className="dsf-deepdive-action-material-div"
                    >
                      <div className="dsf-deepdive-action-material-label">
                        {key}
                      </div>
                      <div className="dsf-deepdive-action-list-div">
                        {requiredActionDataGrouped[key].map((item, index) => {
                          return (
                            <DeepDiveActionCard
                              item={item}
                              key={index}
                              arrItem={arrItem}
                              onChange={handleDataChange}
                              isEdit={isEdit}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className="dsf-deepdive-action-button-div dsf-deepdive-action-button-footer-div">
          <Button
            variant="secondary"
            className="dsf-deepdive-action-button dsf-deepdive-action-button-footer"
            onClick={handleAddActionManual}
            disabled={isLoading || !isEdit}
          >
            {" "}
            <img
              src={plusIcon}
              alt="plus-icon"
              style={{ marginBottom: "3px", marginRight: "2px" }}
            />{" "}
            Add Actions Manually
          </Button>
        </div>
      </div>
      {showAddEditActionModal && (
        <DeepDiveAddEditAction
          showModal={showAddEditActionModal}
          setShowModal={setShowAddEditActionModal}
          arrItem={arrItem}
          onChange={handleDataChange}
          isAutomated={false}
          isEdit={isEdit}
          item={newRowData}
        />
      )}
    </>
  );
};

export default DeepDiveAction;
