import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import downloadIcon from "../../../../assets/images/download.svg";
import iconEdit from "../../../../assets/images/edit-pencil-icon.svg";
import "./modalP&ITaskDetails.css";
import {
  fetchDepartmentMappingDetails,
  saveDepartmentMappingDetails,
  UploadAdminLWDDetails,
  fetchLWDFileDownload,
  ResetLWDFile,
} from "../../../../service/projectDetails";
import ActivityIndicator from "../../../../components/Loader/loader";
import FileUploader from "./fileUploaderLWDAdmin";
import { isTaskInfoNull, validateUnileverMailId } from "../../../../utils";
import UserDetailsService from "../../../../service/userDetailsService";
import template from "../../../../assets/Templates/LWDTemplate.xlsx";

const ModalDepartmentDetails = (props) => {
  const [showModal, setShowModal] = useState(props.isShow);
  const [taskInfo, setTaskInfo] = useState([]);
  const [isOkEnabled, setIsOkEnabled] = useState(true);
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputRefs = useRef([]);

  const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;

  const getProjectInfo = async () => {
    const projLists = await fetchDepartmentMappingDetails();
    if (projLists.status === 200) {
      setTaskInfo(projLists.data);
      setIsOkEnabled(!isTaskInfoNull(projLists.data));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setMember(taskInfo.map((item) => item.departmentAdminEmail));
    // setFormDataList(Array(taskInfo.length).fill(new FormData()));
  }, [taskInfo]);

  useEffect(() => {
    setIsLoading(true);
    getProjectInfo();
  }, []);

  const handleChange = (event, i) => {
    if (validateUnileverMailId(event.target.value)) {
      getUserDetails(event.target.value, i);
    } else {
      taskInfo[i].isError = true;
    }
    member[i] = event.target.value;
    setMember([...member]);
    member.forEach((val, i) => {
      return (taskInfo[i].departmentAdminEmail = val);
    });
    const errorInputFromTask = taskInfo.filter((item) => item.isError === true);
    if (errorInputFromTask.length >= 1) {
      setIsOkEnabled(false);
    } else {
      setIsOkEnabled(true);
    }
  };
  const handleValueOnEnter = (event, i) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        let updatetaskInfo = [...taskInfo];
        updatetaskInfo[i].isValidateMessage = "";
        updatetaskInfo[i].isError = false;
        setTaskInfo(updatetaskInfo);
        getUserDetails(event.target.value, i);
      } else {
        let updatetaskInfo = [...taskInfo];
        updatetaskInfo[i].isValidateMessage =
          "Please provide valid Unilever Mail id";
        updatetaskInfo[i].isError = true;
        setTaskInfo(updatetaskInfo);
      }
    }
  };

  const getUserDetails = async (recepientMailId, i) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      let updatetaskInfo = [...taskInfo];
      updatetaskInfo[i].isError = false;
      updatetaskInfo[i].isValidateMessage = "";
      updatetaskInfo[i].departmentAdminFirstName =
        userDetails.data.userFirstName;
      updatetaskInfo[i].departmentAdminLastName = userDetails.data.userLastName;
      setTaskInfo(updatetaskInfo);
      setIsOkEnabled(true);
    } else {
      let updatetaskInfo = [...taskInfo];
      updatetaskInfo[i].isError = true;
      updatetaskInfo[
        i
      ].isValidateMessage = `${recepientMailId} id doesn't exist`;
      setTaskInfo(updatetaskInfo);
      setIsOkEnabled(false);
    }
  };

  const onLWDSampleDownloadClicked = () => {
    const link = document.createElement("a");
    link.href = template;
    link.download = "LWDTemplate";
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const focusInputField = (index) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index].focus();
    }
  };

  const onOkBtnClicked = async () => {
    const arr = taskInfo.map((item) => {
      return {
        departmentName: item.departmentName,
        departmentAdminEmail: item.departmentAdminEmail,
        uploadedFileName: item.uploadedFileName,
        departmentAdminFirstName: item.departmentAdminFirstName,
        departmentAdminLastName: item.departmentAdminLastName,
      };
    });
    const res = await saveDepartmentMappingDetails(arr);
    if (res.status === 200) {
      setShowModal(false);
      props.onComplete(false);
    } else {
      console.log("status", res.data);
    }
  };
  const closeModal = () => {
    props.onComplete(false);
    setShowModal(false);
  };

  return (
    <>
      <Modal
        centered
        contentClassName="bwi-modal-admin-department-details"
        dialogClassName="bwi-dialog-admin-department-details"
        show={showModal}
      >
        <Modal.Header className="bwi-modal-admin-task-header bwi-modal-admin-department-header">
          <Modal.Title className="">
            <div className="bwi-modal-admin-task-header-title-div">
              <p className="bwi-modal-admin-task-title dsf-span-ml">
                {" "}
                Assign P&I Admin owner per Business Group & Upload Master Data
              </p>
              <p className="bwi-modal-admin-task-subtitle dsf-span-s">
                In case Supply Chain team member is not entered in InnoFLEX for
                a project,
                <br />
                new projects will go to P&I admin to assign it to a P&I member
                and upload the master data. <br />
                The master data specifies the number of weeks between 'Last week
                of delivery' and 'Launch date'.
              </p>
            </div>
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close-Image"
            className="dsf-close-img"
            onClick={() => closeModal()}
          />
        </Modal.Header>
        <Modal.Body className="bwi-modal-admin-task-body">
          <Row className="pb-3 bwi-modal-admin-task-subtitle">
            <Col xs={2} className="dsf-text-left">
              <p className="dsf-span-sl">Business Group</p>
            </Col>
            <Col xs={4} className="dsf-text-left">
              <p className="dsf-span-sl">P&I Administration Owner</p>
            </Col>
            <Col xs={6} className="bwi-display-flex-between">
              <div className="dsf-text-left bwi-admin-lwdupload-col1">
                <p className="dsf-span-sl">Master Data</p>
                <p className="dsf-span-x">(Only xlsx file, size upto 2 MB)</p>
              </div>
              <div className="dsf-text-right bwi-admin-lwdupload-col2">
                <p className="dsf-span-x">Don’t have template?</p>
                <div
                  className="bwi-admin-lwdu-download-row bwi-landing-cursor-pointer"
                  onClick={() => onLWDSampleDownloadClicked()}
                >
                  <img
                    src={downloadIcon}
                    alt="download-Image"
                    className="lwd-file-download-Img"
                  />
                  <span className="bwi-admin-lwd-download-text">
                    Download Master Template
                  </span>
                </div>
              </div>
            </Col>
          </Row>

          {isLoading ? (
            <ActivityIndicator />
          ) : (
            taskInfo
              ?.sort((a, b) => a.departmentName.localeCompare(b.departmentName))
              .map((item, i) => {
                return (
                  <Row className="pb-2" Key={item.departmentName}>
                    <Col
                      xs={2}
                      className="dsf-text-left bwi-modal-admin-task-subtitle"
                    >
                      <p className="bw-label dsf-span-s">
                        {item.departmentName}
                      </p>
                    </Col>
                    <Col
                      xs={4}
                      className="dsf-text-left bwi-modal-admin-task-subtitle"
                    >
                      <input
                        type="text"
                        value={member[i]}
                        ref={(input) => (inputRefs.current[i] = input)}
                        className={`dsf-textField bwi-admin-textField  ${
                          item.isError ? "errorBorder" : "successBorder"
                        }`}
                        onChange={(e) => handleChange(e, i)}
                        required
                        onBlur={(e) => handleValueOnEnter(e, i)}
                        onKeyDown={(e) => handleValueOnEnter(e, i)}
                      />
                      <img
                        src={iconEdit}
                        alt="edit-icon"
                        className="bwi-admin-owner-edit-img"
                        onClick={() => focusInputField(i)}
                      />
                      <p className="dsf-size10 dsf-font-weight-400 errorMsg bwi-admin-owner-err-msg">
                        {item.isValidateMessage}
                      </p>
                    </Col>
                    <Col
                      xs={6}
                      className="dsf-text-left bwi-admin-lwd-upload-col"
                    >
                      <FileUploader
                        fileName={item.uploadedFileName}
                        departmentName={item.departmentName}
                        setIsLoading={setIsLoading}
                      />
                    </Col>
                  </Row>
                );
              })
          )}
        </Modal.Body>
        <Modal.Footer className="bwi-modal-admin-task-footer">
          <Button
            className="dsf-footer-okbutton-PI bwi-footer-admin-task-button"
            onClick={() => onOkBtnClicked()}
            disabled={!isOkEnabled}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalDepartmentDetails;
