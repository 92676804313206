import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import vectorGreaterSymbol from "../../../../assets/images/landingPage/vectorGreaterSymbol.svg";
import vectorLesserSymbol from "../../../../assets/images/landingPage/vectorLesserSymbol.svg";
import "./emailE2E.css";
import ModalWarning from "../modalWarning";
import E2EPlannerDatePicker from "../../../../components/DatePicker/datePicker";
import { ConstraintTooltip } from "../../../../components/Tooltip/tooltip";
import { validateUnileverMailId } from "../../../../utils";
import {
  fetchE2EDetails,
  saveE2EDetails,
  fetchProjectWorkFlowStatus,
  resetWorkflowDetails,
} from "../../../../service/projectDetails";
import { dateTimeFormat } from "../../../../utils";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import UserDetailsService from "../../../../service/userDetailsService";
import ActivityIndicator from "../../../../components/Loader/loader";
import { configByEnv } from "../../../../service/APIHelper";

const ModalE2EPlanner = (props) => {
  const [showModal, setShowModal] = useState(props.isShow);
  // const [projectStage, setProjectStage] = useState("");
  const [deadLine, setDeadLine] = useState("");
  const [sendto, setSendto] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [objE2E, setObjE2E] = useState(undefined);
  const [constraints, setConstraints] = useState("");
  const [isOkEnabled, setIsOkEnabled] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { isEdit, stepStatus } = props;
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);
  const [warnWindowButton, setWarnWindowButton] = useState("");

  const userDetails = useSelector((state) => state.userDetails);
  const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;

  const dispatch = useDispatch();

  const isNullvalue = (value) => {
    if (value === null || value === "") {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getE2EDetails();
  }, []);

  useEffect(() => {
    dataCheck();
  }, [sendto, deadLine, validationMessage]);

  const dataCheck = () => {
    setIsOkEnabled(
      isNullvalue(sendto) ||
        isNullvalue(deadLine) ||
        !isNullvalue(validationMessage)
    );
  };

  const getE2EDetails = async () => {
    const res = await fetchE2EDetails(props.arrItem.projectId);
    if (res.status === 200) {
      setObjE2E(Object.assign({}, res.data));
      setSendto(res.data.emailIdE2E);
      setDeadLine(res.data.projectDeadline);
      // setProjectStage(res.data.lockedDecisions)
      setConstraints(res.data.constraints);
      const mailUserName = await UserDetailsService.fetchUsersByMailId(
        res.data.emailIdE2E
      );
      if (mailUserName.status === 200) {
        setRecipientName(
          `${mailUserName.data.userFirstName} ${mailUserName.data.userLastName}`
        );
      }
    }
    setIsLoading(false);
  };
  const closeModal = () => {
    setShowModal(false);
    props.onComplete(false);
  };

  const tradeWindow = objE2E
    ? `${dateTimeFormat(
        objE2E?.tradeWindowStartDate,
        "DD/MM/YYYY"
      )}-${dateTimeFormat(objE2E?.tradeWindowEndDate, "DD/MM/YYYY")}`
    : "";

  const handleSendtoChange = (event) => {
    setSendto(event.target.value);
  };

  const handleSendtoValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        setValidationMessage("");
        getUserDetails(event.target.value);
      } else {
        setValidationMessage("Please provide valid Unilever Mail id");
        setRecipientName("");
      }
    }
  };

  const handleConstraintsChange = (event) => {
    setConstraints(event.target.value);
  };

  const handleConstraintsValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      setConstraints(event.target.value);
    }
  };

  // const handleDropdownChange = (value) => {
  //   setProjectStage(value);
  // };

  const handleDatePickerChange = (value, isNull, date) => {
    setDeadLine(dateTimeFormat(date, "MM/DD/YYYY"));
  };

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setValidationMessage("");
      setSendto(recepientMailId);
      setRecipientName(
        `${userDetails.data.userFirstName} ${userDetails.data.userLastName}`
      );
    } else {
      setValidationMessage("The Unilever mail id does not exist");
      setRecipientName("");
    }
  };

  const onWarnModalOpen = (modalOpen, button) => {
    if (stepStatus !== "4") {
      setIsWarnWindowOpen(modalOpen);
      setWarnWindowButton(button);
    } else {
      if (button === "okButton") {
        onOkBtnClicked();
      } else {
        resetE2EUIData();
      }
    }
  };

  const handleWarnModalOk = () => {
    if (warnWindowButton === "okButton") {
      onOkBtnClicked();
    } else {
      resetE2EData();
    }
  };

  const resetE2EUIData = () => {
    setSendto("");
    setDeadLine("");
    setConstraints("");
    setValidationMessage("");
  };

  const resetE2EData = async () => {
    setIsLoading(true);
    const res = await resetWorkflowDetails(
      props.arrItem.projectId,
      props.arrItem.workflow[7].workflowId
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        getE2EDetails();
      }
      // props.onComplete(false);
    } else {
      console.log(res.status);
    }
    setIsLoading(false);
  };

  const onOkBtnClicked = async () => {
    setIsLoading(true);
    let previewMail = document.getElementById("myP").innerHTML;
    let emailSubject = `Request for scenario planning of BW in RR for ${
      objE2E.projectName
    } - deadline: ${dateTimeFormat(deadLine, "DD/MM/YYYY")}`;
    objE2E.emailIdE2E = sendto;
    objE2E.emailPreview = previewMail;
    objE2E.constraints = constraints;
    objE2E.projectDeadline = deadLine;
    objE2E.emailSubject = emailSubject;
    const res = await saveE2EDetails(objE2E);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
      setShowModal(false);
      props.onComplete(false);
    } else {
      console.log(res.status);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={handleWarnModalOk}
          onWarnModal={onWarnModalOpen}
        />
      )}
      <div className="bwi-modal bwi-modal-emailE2E">
        <div className="bwi-landing-modal-header bwi-modal-emailE2E-header">
          <div className="bwi-modal-emailE2E-header-title-div">
            <span className="bwi-modal-title dsf-span-ml">
              Email E2E planner on Scenarios
            </span>
          </div>
          <div className="bwi-modal-emailE2E-body">
            {isLoading ? (
              <ActivityIndicator />
            ) : (
              <>
                <div className="row-spacing-5 ">
                  <Col className="bwi-emailE2E-col bwi-emailE2E-col-xl">
                    <label className="form-label">Send To</label>
                    <input
                      readOnly={!isEdit}
                      type="text"
                      name="Sendto"
                      value={sendto}
                      className={`bwi-emailE2E-textField  ${
                        validationMessage.length > 0
                          ? "errorBorder"
                          : "successBorder"
                      }`}
                      onChange={(e) => handleSendtoChange(e)}
                      onBlur={(e) => handleSendtoValueOnEnter(e)}
                      onKeyDown={(e) => handleSendtoValueOnEnter(e)}
                      required
                      autoComplete="off"
                    />
                  </Col>
                </div>
                <Row className="row-spacing-5">
                  <Col className="bwi-emailE2E-col bwi-emailE2E-col-xl2">
                    <label className="form-label">Project</label>
                    <input
                      type="text"
                      name="ProjectName"
                      value={objE2E ? objE2E.projectName : ""}
                      className="bwi-emailE2E-textField"
                      readonly
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="bwi-emailE2E-col">
                    <label className="form-label">Project ID</label>
                    <input
                      type="text"
                      name="ProjectID"
                      value={objE2E ? objE2E.projectIdDisplay : ""}
                      className="bwi-emailE2E-textField"
                      readonly
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="bwi-emailE2E-col">
                    <label className="form-label">Project Family</label>
                    <input
                      type="text"
                      name="ProjectFamily"
                      value={objE2E ? objE2E.projectFamily : ""}
                      className="bwi-emailE2E-textField"
                      readonly
                      autoComplete="off"
                    />
                  </Col>
                  <Col className="bwi-emailE2E-col bwi-emailE2E-datepicker">
                    <label className="form-label">Deadline for response</label>
                    <div className="bwi-emailE2E-datepicker-wrap-field">
                      <E2EPlannerDatePicker
                        launchDate={deadLine}
                        isValueNull={isEdit}
                        onDatePickerChange={(value, isNull, date) =>
                          handleDatePickerChange(value, isNull, date)
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <div className="row-spacing-5">
                  <Col className="bwi-emailE2E-col bwi-emailE2E-col-xxl">
                    <label className="form-label">Project Description</label>
                    <input
                      type="text"
                      name="ProjectDescription"
                      value={objE2E ? objE2E.projectDescription : ""}
                      className="bwi-emailE2E-textField"
                      readonly
                      autoComplete="off"
                    />
                  </Col>
                </div>
                <Row className="row-spacing-5">
                  <Col className="bwi-emailE2E-col">
                    <label className="form-label form-label-constraint">
                      Constraints
                      <ConstraintTooltip
                        name="info"
                        positionClass={"tooltip-position-top"}
                      />
                    </label>
                    <input
                      readOnly={!isEdit}
                      type="text"
                      name="Constraints"
                      value={constraints}
                      className="bwi-emailE2E-textField"
                      onChange={(e) => handleConstraintsChange(e)}
                      onBlur={(e) => handleConstraintsValueOnEnter(e)}
                      onKeyDown={(e) => handleConstraintsValueOnEnter(e)}
                      maxlength="256"
                      autoComplete="off"
                    />
                  </Col>
                </Row>
                <Row className="row-spacing-5 row-spacing-mail-attachment">
                  <Col className="bwi-emailE2E-col">
                    <label className="form-label">Preview Email</label>
                    <div className="bwi-E2E-preview-div" id="myP">
                      <p className="bwi-E2E-preview-text1">
                        Hi {recipientName} ,
                      </p>
                      <div className="bwi-E2E-preview-project-info">
                        <Row>
                          <Col>
                            <label className="bwi-E2E-preview-text2">
                              Project ID:{" "}
                            </label>
                            <label className="bwi-E2E-preview-text1">
                              {objE2E ? objE2E.projectIdDisplay : ""}
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label className="bwi-E2E-preview-text2">
                              Project Family:{" "}
                            </label>
                            <label className="bwi-E2E-preview-text1">
                              {" "}
                              {objE2E ? objE2E.projectFamily : ""}
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <label className="bwi-E2E-preview-text2">
                              Project Description:{" "}
                            </label>
                            <label className="bwi-E2E-preview-text1">
                              {objE2E ? objE2E.projectDescription : ""}
                            </label>
                          </Col>
                        </Row>
                      </div>
                      <div className="bwi-E2E-preview-project-graph-details pb-4">
                        <p className="bwi-E2E-preview-text1">
                          There is a new Innovation Project -{" "}
                          {objE2E ? objE2E.projectName : ""}. Kindly review the
                          Project info, discuss with P&I PM and other project
                          stakeholders and plan the ideal scenario(s) in RR to
                          minimise BW.
                        </p>
                        <p className="bwi-E2E-preview-text1 pb-4"></p>
                        <p className="bwi-E2E-preview-text1 pb-4">
                          Deadline due {dateTimeFormat(deadLine, "DD/MM/YYYY")}.
                        </p>
                        <p className="bwi-E2E-preview-text1">
                          Once completed, please upload the scenarios to the BW
                          Web Application :
                          <a target="_blank" href={configByEnv().appLink}>
                            {" "}
                            Application
                          </a>
                        </p>
                      </div>
                      <div className="bwi-E2E-preview-project-Constarint pb-4">
                        <lable className="bwi-E2E-preview-text3 font-weight-bold">
                          Constraints:
                        </lable>
                        <p className="bwi-E2E-preview-text1 pt-3">
                          {constraints}
                        </p>
                      </div>
                      <div className="bwi-E2E-preview-project-Attachments pb-4">
                        <lable className="bwi-E2E-preview-text3 font-weight-bold">
                          Attached files:
                        </lable>
                        <ul>
                          <li className="bwi-E2E-preview-text1">
                            Check Innoflex – List of countries in scope for the
                            project, launch dates and trade windows.
                          </li>
                          <li className="bwi-E2E-preview-text1">
                            Delisted FGs – List of FGs to be delisted in each
                            country, and consequently in which Plants (SUs) the
                            production will be impacted. Also provide the Action
                            (Delist/ BOM change) and Launch type (Hard/ soft).
                          </li>
                          <li className="bwi-E2E-preview-text1">
                            Unique RPMs – The Unique RPMs for each FG in the
                            Plant (SUs) where the production is impacted
                          </li>
                          <li className="bwi-E2E-preview-text1">
                            Scenario template – The file format to upload
                            scenario to BW webapp. Please ensure you manually
                            convert the RR scenario output file to this format
                            before uploading.
                          </li>
                          <li>
                            Last week of delivery - The launch date for the FG
                            and the last week of delivery for production in SU.
                          </li>
                        </ul>
                      </div>
                      <div className="bwi-E2E-preview-text1">
                        Kind Regards,
                        <br />
                        {userDetails.userFirstName}&nbsp;{" "}
                        {userDetails.userLastName}
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </div>
          <div className="bwi-modal-emailE2E-footer">
            <div className="bwi-footer-prevbtn-div">
              {/* <Button
                className="bwi-footer-button-movement bwi-footer-button-prev bwi-emailE2E-footer-btn"
                variant="secondary"
                onClick={() => {
                  props.onComplete(true, "Previous");
                }}
              >
                <img
                  src={vectorLesserSymbol}
                  alt="Next"
                  style={{ marginRight: "8px", paddingTop: "2px" }}
                />{" "}
                Previous
              </Button> */}
            </div>
            <div className="bwi-modal-footer">
              <Button
                className="bwi-emailE2E-footer-btn bwi-reset-button"
                variant="secondary"
                disabled={!isEdit || isOkEnabled}
                onClick={() => onWarnModalOpen(true, "resetButton")}
              >
                Reset
              </Button>
              <Button
                className="bwi-emailE2E-footer-btn bwi-submit-button"
                variant="secondary"
                disabled={!isEdit || isOkEnabled}
                onClick={() => onWarnModalOpen(true, "okButton")}
              >
                Submit
              </Button>
              {/* <Button
                className={`bwi-footer-button-movement bwi-footer-button-next bwi-emailE2E-footer-btn ${
                  props.arrItem.workflow[8].status === "1" &&
                  "dsf-cursor-disabled"
                }`}
                variant="secondary"
                disabled={props.arrItem.workflow[8].status === "1"}
                onClick={() => {
                  props.onComplete(true, "Next");
                }}
              >
                Next{" "}
                <img
                  src={vectorGreaterSymbol}
                  alt="Next"
                  style={{ marginLeft: "8px", paddingTop: "2px" }}
                />
              </Button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalE2EPlanner;
