import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import icon from "../../../../assets/images/Vector-cross.svg";
import "./modalP&ITaskDetails.css";
import {
  fetchProjectMappingDetails,
  saveProjectMappingDetails,
} from "../../../../service/projectDetails";
import { validateUnileverMailId } from "../../../../utils";
import ActivityIndicator from "../../../../components/Loader/loader";
import UserDetailsService from "../../../../service/userDetailsService";

const ModalProjectDetails = (props) => {
  const [showModal, setShowModal] = useState(props.isShow);
  const [taskInfo, setTaskInfo] = useState([]);
  const [isOkEnabled, setIsOkEnabled] = useState(true);
  const [member, setMember] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;

  const isTaskInfoNull = (arr) => {
    let blankValueCount = 0;
    arr.forEach((item) => {
      if (item.member === null || item.member === "") {
        blankValueCount = blankValueCount + 1;
      }
    });
    return blankValueCount >= 1 ? true : false;
  };

  const getProjectInfo = async () => {
    const projLists = await fetchProjectMappingDetails();
    if (projLists.status === 200) {
      setTaskInfo(projLists.data);
      setIsOkEnabled(!isTaskInfoNull(projLists.data));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setMember(taskInfo.map((item) => item.member));
  }, [taskInfo]);

  useEffect(() => {
    setIsLoading(true);
    getProjectInfo();
  }, []);

  const handleChange = (event, i) => {
    if (validateUnileverMailId(event.target.value)) {
      taskInfo[i].isError = false;
    } else {
      taskInfo[i].isError = true;
    }
    member[i] = event.target.value;
    setMember([...member]);
    member.forEach((val, i) => {
      return (taskInfo[i].member = val);
    });
    const errorInputFromTask = taskInfo.filter((item) => item.isError === true);
    if (errorInputFromTask.length >= 1) {
      setIsOkEnabled(false);
    } else {
      setIsOkEnabled(true);
    }
  };

  const handleValueOnEnter = (event, i) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        let updatetaskInfo = [...taskInfo];
        console.log("blur if");
        updatetaskInfo[i].isValidateMessage = "";
        updatetaskInfo[i].isError = false;
        setTaskInfo(updatetaskInfo);
        getUserDetails(event.target.value, i);
      } else {
        let updatetaskInfo = [...taskInfo];
        updatetaskInfo[i].isValidateMessage =
          "Please provide valid Unilever Mail id";
        updatetaskInfo[i].isError = true;
        setTaskInfo(updatetaskInfo);
      }
    }
  };

  const getUserDetails = async (recepientMailId, i) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      let updatetaskInfo = [...taskInfo];
      updatetaskInfo[i].isError = false;
      updatetaskInfo[i].isValidateMessage = "";
      setTaskInfo(updatetaskInfo);
    } else {
      let updatetaskInfo = [...taskInfo];
      updatetaskInfo[i].isError = true;
      updatetaskInfo[
        i
      ].isValidateMessage = `${recepientMailId} id doesn't exist`;
      setTaskInfo(updatetaskInfo);
    }
  };
  const onOkBtnClicked = async () => {
    const arr = taskInfo.map((item) => {
      return {
        member: item.member,
        projectName: item.projectName,
        projectId: item.projectId,
      };
    });
    const res = await saveProjectMappingDetails(arr);
    if (res.status === 200) {
      setShowModal(false);
      props.onComplete(false);
    } else {
      console.log("status", res ? res.status : "unknown");
    }
  };

  const closeModal = () => {
    props.onComplete(false);
    setShowModal(false);
  };

  return (
    <>
      <Modal
        centered
        contentClassName="dsf-modal-workflowModal"
        dialogClassName="dsf-dialog-task-details"
        show={showModal}
      >
        <Modal.Header className="bw-modal-header">
          <Modal.Title className="">
            <div className="dsf-task-modal-title">
              <p className="bwi-modal-admin-task-title dsf-span-m">
                {" "}
                Assign PELE LUXE project to P&I member
              </p>
            </div>
          </Modal.Title>
          <img
            src={icon}
            alt="Close-Image"
            className="dsf-close-img"
            onClick={() => closeModal()}
          />
        </Modal.Header>
        <Modal.Body className="bw-modal-body">
          <Row className="pb-3">
            <Col xs={4} className="dsf-text-left">
              <p className="bw-proj-value dsf-span-sx">Project</p>
            </Col>
            <Col className="dsf-text-left dsf-pleft0">
              <p className="bw-proj-value dsf-span-sx">
                Assigned to P&I member
              </p>
            </Col>
          </Row>
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            taskInfo?.map((item, i) => {
              return (
                <Row className="pb-2">
                  <Col xs={4} className="dsf-text-left">
                    <p className="bw-label dsf-span-s">{item.projectName}</p>
                  </Col>
                  <Col className="dsf-text-left dsf-pleft0">
                    <input
                      type="text"
                      value={member[i]}
                      className={`dsf-textField  ${
                        item.isError ? "errorBorder" : "successBorder"
                      }`}
                      placeholder="Assign P&I Member"
                      onChange={(e) => handleChange(e, i)}
                      required
                      onBlur={(e) => handleValueOnEnter(e, i)}
                      onKeyDown={(e) => handleValueOnEnter(e, i)}
                    />
                    <p className="dsf-size8 dsf-font-weight-400 errorMsg  pl-2">
                      {item.isValidateMessage}
                    </p>
                  </Col>
                </Row>
              );
            })
          )}
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer">
          {isOkEnabled ? (
            <Button
              className="dsf-footer-okbutton-PI"
              onClick={() => onOkBtnClicked()}
            >
              OK
            </Button>
          ) : (
            <Button
              className="dsf-footer-okbutton-PI"
              disabled
              onClick={() => onOkBtnClicked()}
            >
              OK
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalProjectDetails;
