import React, { useState, useRef, useEffect } from "react";
import polygon from "../../assets/images/Polygon1.svg";
import polygonClose from "../../assets/images/PolygonClose1.svg";
import polygonDisable from "../../assets/images/Polygon-disable.svg";

const TooltipDropdown = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [displayTooltip, setDisplayTooltip] = useState(-1);
  const [isOpenFirstTime, setIsOpenFirstTime] = useState(true);
  const {
    options,
    isOpen,
    setIsOpen,
    disable,
    listClassName,
    onSelectionChange,
  } = props;

  const toggleDropdown = () => {
    {
      !disable && setIsOpen(!isOpen);
    }
  };

  const handleSelection = (value) => {
    setSelectedValues(value);
    onSelectionChange(value);
    setIsOpen(!isOpen);
    setDisplayTooltip(-1);
  };

  const handleMouseHover = (id) => {
    setDisplayTooltip(id);
  };

  return (
    <div className={`bwi-custom-tooltip-dropdown ${listClassName}`}>
      <div
        className="input-container"
        onClick={() => {
          toggleDropdown();
        }}
      >
        <input
          type="text"
          value={selectedValues?.length > 0 ? selectedValues : ""}
          disabled={disable}
          readOnly
          placeholder="Select an action to proceed"
        />
        <img
          src={disable ? polygonDisable : isOpen ? polygon : polygonClose}
          disabled={disable}
          alt="autocomplete-dropdown-icon"
          className={`autocomplete-dropdown-icon  ${
            disable
              ? "dropdownDisable"
              : isOpen
              ? "dropdownEnable"
              : "dropdownClose"
          }`}
        />
      </div>
      {isOpen && (
        <div className="options-container">
          <div
            className="options-list"
          >
            {options.map((option, index) => (
              <>
                <div
                  key={option.value}
                  className={`option-row`}
                  onClick={() => handleSelection(option.label)}
                  onMouseEnter={() => handleMouseHover(index)}
                  onMouseLeave={()=> setDisplayTooltip(-1)}
                >
                  {option.label}
                </div>
                {index === displayTooltip && (
                  <div className="menu-tooltip">
                    {option.toolTip}
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TooltipDropdown;
