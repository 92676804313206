import axios from "axios";
import configDev from '../config/config_dev.json';
import configNonProd from '../config/config_nonprod.json';
import configPreProd from '../config/config_preprod.json';
import configProd from '../config/config_prod.json';
import { msalInstance } from '../index';
const msalScope = ["user.read"];

export const generateHeaders = (token) => {
  return {
    "Content-type": "application/json;",
    // "Access-Control-Allow-Origin": "*",
    // "Authorization" :`Bearer ${localStorage.getItem('AccessToken')}`
    "Authorization": `Bearer ${localStorage.getItem('IDToken')}`
  }
}

export const generateMultiPartHeaders = (token) => {
  return {
    "Content-type": "multipart/form-data;",
    // "Access-Control-Allow-Origin": "*",
    // "Authorization" :`Bearer ${localStorage.getItem('AccessToken')}`
    "Authorization": `Bearer ${localStorage.getItem('IDToken')}`
  }
}

export const configByEnv = () => {
  let config;
  if (typeof document !== 'undefined') {
    var host = window.location.host;
    if (host.match('localhost') || host.includes('innowaste.dev.unilever') || host.match('innowaste.dev.unilever') ) {
      config = configDev;
    } else if (host.match('bieno-da11-q-930073') || host.includes('bieno-da11-q-930073') || host.includes('innowaste.uat.unilever') || host.match('innowaste.uat.unilever') ){
      config = configNonProd;
    } else if (host.match('bieno-da10-u-930071') || host.includes('bieno-da10-u-930071') || host.includes('innowaste.ppd.unilever') || host.match('innowaste.ppd.unilever') ){
      config = configPreProd;
    }else if (host.match('bieno-da09-p-930070') || host.includes('bieno-da09-p-930070') || host.includes('innowaste.unilever') || host.match('innowaste.unilever') ){
      config = configProd;
    }else {
      config = configDev; 
    }
  }
  return config;
}

const refreshToken = async () => {
  const tokenExpireInMilliSecond = parseInt(localStorage.getItem('Token_Expires_in'))*1000; //in millisecond
  let tokenExpireInDate = new Date(tokenExpireInMilliSecond);
  const currentDateTime = new Date();
  const currentDateTimeInMilliSecond = Date.now();
  // console.log('currentDateTimeInMilliSecond',currentDateTimeInMilliSecond);
  // console.log('tokenExpireInMilliSecond',tokenExpireInMilliSecond);
  if (tokenExpireInMilliSecond < currentDateTimeInMilliSecond)  {
    const tokenResponse = await msalInstance.acquireTokenSilent(msalScope);
    if (tokenResponse) {
      localStorage.setItem('userDetails', JSON.stringify(tokenResponse.account));
      localStorage.setItem('IDToken', tokenResponse.idToken);
      localStorage.setItem('AccessToken', tokenResponse.accessToken);
      localStorage.setItem('Token_Expires_in', tokenResponse.idTokenClaims.exp);
    }
  }
}


async function exchange(url, headers, method, data) {
  await refreshToken();
  const response = await axios({
    method,
    url,
    data,
    headers: generateHeaders(headers),
  });
  return response;

}

async function exchangeFileType(url, headers, method, data, responseType) {
  await refreshToken();
  const response = await axios({
    method,
    url,
    data,
    headers: generateMultiPartHeaders(headers),
    responseType,
  });
  return response;
}

export const ApiHelper = {
  get: async (url, headers) => {
    return await exchange(url, headers, 'get');
  },

  post: async (url, payload, headers) => {
    return await exchange(url, headers, 'post', payload);
  },

  postFileType: async (url, payload, headers, responseType ="") => {
    return await exchangeFileType(url, headers, 'post', payload, responseType);
  },
  putFileType: async (url, payload, headers) => {
    return await exchangeFileType(url, headers, 'put', payload);
  },

  put: async (url, payload, headers) => {
    return await exchange(url, headers, 'put', payload);
  },

  patch: async (url, payload, headers) => {
    return await exchange(url, headers, 'patch', payload);
  },

  delete: async (url, headers) => {
    return await exchange(url, headers, 'delete');
  },

};