import React, { useState, useEffect } from "react";
import PendingTask from "./pendingTask";
import Carousel from "../../../../components/Carousel/carousel";
import "./pendingTaskBar.css";

const PendingTaskBar = (props) => {
  const { pendingTasks, role } = props;

  return (
    <>
      {(props.role === "BWInnovationP&IAdmin" ||
        props.role === "BWInnovationR&D") && (
        <div className="bwi-dashbaord-summary-div">
          <div className="bwi-widgets-div">
            <span className="dsf-sortby-title dsf-color-EDF1F3">
              Your Tasks
            </span>
          </div>
          {pendingTasks && pendingTasks.length <= 3 ? (
            <>
              <div className="bwi-pending-task-list">
                {pendingTasks.map((item, i) => {
                  return (
                    <PendingTask
                      task={item}
                      key={i}
                      role={role}
                      onComplete={props.onComplete}
                    />
                  );
                })}
              </div>
            </>
          ) : (
            <div className="bwi-pending-task-carousel-list">
              <Carousel
                items={pendingTasks}
                visibleItems={3}
                autoScroll={false}
                role={role}
                onComplete={props.onComplete}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PendingTaskBar;
