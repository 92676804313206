import React from "react";
import { Button, Modal } from "react-bootstrap";
import closeIcon from "../../../assets/images/Vector-cross.svg";
import error from "../../../assets/images/error.svg";

const ModalWarning = (props) => {
  const [showModal, setShowModal] = React.useState(props.show);

  const onComplete = () => {
    setShowModal(false);
    props.onOk();
  };

  return (
    <Modal
      centered
      dialogClassName="dsf-modal-warning"
      contentClassName="dsf-modal-workflowModal"
      show={showModal}
    >
      <Modal.Header className="bw-modal-messagebox-header">
        <Modal.Title className="bw-warnmodal-header-title bw-messagebox-header-title">
          <img src={error} alt="error-Image" />
          <span>Are you sure ? </span>
        </Modal.Title>
        <img
          src={closeIcon}
          alt="Close-Image"
          className="img.dsf-close-messagebox-img"
          onClick={() => {
            setShowModal(false);
            props.onWarnModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="bw-modal-messagebox-body">
        <p className="bw-modal-messagebox-msg" style={{ textAlign: "left" }}>
          You are about to delete the project history.
          <br />
          Continuing will result in the loss of data entered in subsequent
          steps.
        </p>
        <p
          className="bw-modal-messagebox-msg"
          style={{ textAlign: "left", marginTop: "10px" }}
        >
          This action cannot be undone. Do you still wish to proceed?
        </p>
      </Modal.Body>
      <Modal.Footer className="bw-modal-footer">
        <Button
          className="bw-footer-cancle-btn"
          variant="secondary"
          onClick={() => {
            setShowModal(false);
            props.onWarnModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="dsf-footer-okbutton-PI"
          variant="secondary"
          onClick={() => onComplete()}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalWarning;
