import { useEffect, useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreIcon from "../../assets/images/moreIcon.svg";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 0,
    border: "1px solid #797775",
    marginTop: theme.spacing(1),
    minWidth: 120,
    backgroundColor: "#1E232F",
    color: "#EDF1F3",
    "& .MuiMenu-list": {
      padding: "2px 0",
    },
    "& .MuiMenuItem-root": {
      fontSize: "12px",
      fontWeight: 400,
      fontFamily: "Unilever Shilling",
      padding: "5px 10px",
      "&:hover": {
        backgroundColor: "#340D71",
      },
    },
  },
}));

export default function CustomizedMenus(props) {
  const { listClassName, variant, menuItems } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={listClassName}>
      <IconButton
        id="bwi-demo-customized-button"
        aria-controls={open ? "bwi-demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="bwi-menu-customized-button"
        disableRipple
      >
        <img
          src={variant === "MoreButton" ? MoreIcon : MoreIcon}
          alt={variant}
        />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "bwi-demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="bwi-workflow-selection-non-click"
      >
        {menuItems.map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              props.onMenuItemClick(item.label);
            }}
            disableRipple
            className="bwi-workflow-selection-non-click"
          >
            <img
              src={require(`../../assets/images/menu-${item.icon}.svg`)}
              alt={item.label}
              style={{
                marginRight: "10px",
                paddingTop: "2px",
                width: "15px",
                height: "15px",
              }}
            />
            {item.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}
