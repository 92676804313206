import { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";

const DonutChartDesign = (props) => {
  const [donutChartData, setDonutChartData] = useState([]);

  useEffect(() => {
    let arrdonutchartdata = [];
    arrdonutchartdata.push(["FG", Math.round(props.chartData.fgbw)]);
    arrdonutchartdata.push(["RM", Math.round(props.chartData.rmbw)]);
    arrdonutchartdata.push(["PM", Math.round(props.chartData.pmbw)]);
    arrdonutchartdata.push(["SFG", Math.round(props.chartData.sfgbw)]);
    setDonutChartData(arrdonutchartdata);
  }, []);

  useEffect(() => {
    GoogleCharts.load(drawChart, { packages: ["corechart"] });
  }, [donutChartData]);

  const drawChart = () => {
    const chartDiv = document.getElementById(props.container);
    let data = new GoogleCharts.api.visualization.DataTable();
    data.addColumn("string", "Material");
    data.addColumn("number", "Value");
    data.addRows(donutChartData);

    var options = {
      pieHole: 0.6,
      backgroundColor: "transparent",
      legend: "none",
      chartArea: { left: "20%", bottom: "32%", width: "56%", height: "80%" },
      pieSliceText: "none",
      pieSliceBorderColor: "none",
      tooltip: {
        // ignoreBounds : true,
      },
      slices: {
        0: { color: "#8FCAEB" },
        1: { color: "#FCEDA4" },
        2: { color: "#C79AD5" },
        3: { color: "#CFCFCF" },
      },
    };

    const chart = new GoogleCharts.api.visualization.PieChart(chartDiv);
    new GoogleCharts.api.visualization.events.addListener(
      chart,
      "select",
      function () {
        var selection = chart.getSelection();
        let selectedIndex;
        selection.map((item) => {
          selectedIndex = item.row;
        });
        props.onChange(selectedIndex);
        if (selection.length === 1) {
          var item = selection[0];
          if (item.row != null && item.column != null) {
            var str = data.getFormattedValue(item.row, item.column);
          }
        }
      }
    );
    chart.draw(data, options);
  };
};
export default DonutChartDesign;
