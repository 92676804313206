import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { uploadDelistFile } from "../../service/projectDetails";

const FileUploaderInventory = (props) => {
  const [fileName, setFileName] = useState("");
  const hiddenFileInput = React.useRef(null);
  const [isUploadStatus, setUploadStatus] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [formData, setFormData] = useState(new FormData());

  useEffect(() => {
    if (props.supplierCode) {
      setFileName("");
      setUploadStatus(false);
      setUploadMessage("");
    }
  }, [props.supplierCode]);

  const handleClick = (event) => {
    setUploadMessage("");
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const file = new FormData();
    setFileName(event.target.files[0].name);
    file.append("file", event.target.files[0]);
    setFormData(file);
    hiddenFileInput.current.value = null;
  };

  const handleUploadBtnChange = async () => {
    props.onStartFileUpload();
    const res = await uploadDelistFile(
      props.supplierCode,
      props.projectId,
      formData,
      props.isRPMSupplier
    );
    if (res.status === 200) {
      props.handlefileUpload(res);
      setUploadStatus(true);
      setUploadMessage("Upload Successful !!!");
    } else {
      props.handlefileUpload(res);
      setUploadStatus(true);
      setUploadMessage("Upload Failed !!!");
    }
  };
  return (
    <>
      <div className="dsf-delist-form-upload-section">
        <div className={`dsf-delist-form-upload-browse`}>
          <InputGroup className="mb-3 dsf-delist-form-upload-form-ctrl">
            <Form.Control
              aria-describedby="browse-button"
              name="upload-delist_bom"
              className="dsf-delist-form-upload-type"
              value={fileName}
              //   disabled={props.disabled}
            />
            <Button
              variant="outline-secondary"
              disabled={props.BrowseDisabled}
              onClick={handleClick}
              className="dsf-delist-form-browse-button dsf-Inventory-upload-btn"
            >
              Browse
            </Button>
          </InputGroup>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
          />
        </div>
        <div className="dsf-delist-form-upload-btn">
          <Button
            variant="secondary"
            id="upload-button"
            disabled={fileName.length == 0}
            onClick={handleUploadBtnChange}
            className="dsf-delist-form-upload-btn-css"
          >
            Upload
          </Button>
        </div>
      </div>
      {isUploadStatus && (
        <span
          className={`dsf-size12 dsf-font-weight-400`}
          style={{
            color:
              uploadMessage === "Upload Successful !!!" ? "#00A889" : "#FF544F",
            marginBottom: "-15px",
          }}
        >
          {uploadMessage}
        </span>
      )}
    </>
  );
};

export default FileUploaderInventory;
