import React, { useEffect, useState, useRef } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import "./modalDeepDiveView.css";
import DonutChart from "../../../../helpers/donutChart";
import ComboChart from "../../../../helpers/comboChart";
import LineChart from "../../../../helpers/lineChartDD";
import { formatNumber } from "../../../../utils";
import TableArrowPointer from "../../../../assets/images/TableArrowPointer.svg";
import ModalDetailedTable from "./modalDetailedTable";

const DeepDiveValue = (props) => {
  const {
    deepDiveDefaultValueData,
    deepDiveActualValueData,
    deepDiveValueTableData,
    selectedProjectId,
  } = props;
  const [activeTab, setActiveTab] = useState("Value");
  const [deepDiveViewData, setDeepDiveViewData] = useState({});
  const [defaultDeepDiveViewData, setDefaultDeepDiveViewData] = useState({});
  const [fgChartData, setFgChartData] = useState([]);
  const [rmChartData, setRMChartData] = useState([]);
  const [pmChartData, setPMChartData] = useState([]);
  const [CountryChartData, setCountryChartData] = useState([]);
  const [countryValue, setCountryValue] = useState(0);
  const [fgBwValue, setFgBwValue] = useState(0);
  const [rmBwValue, setRmBwValue] = useState(0);
  const [pmBwValue, setPmBwValue] = useState(0);
  const [isModalTableOpen, setIsModalTableOpen] = useState(false);
  const [countrySelected, setCountrySelected] = useState("");
  const [functionSelected, setFuntionSelected] = useState("");
  const [funcValueSelected, setFuncValueSelected] = useState("");
  const [isSelectedDonutSlice, setIsSelectedDonutSlice] = useState(-1);
  // const [detailedTableData, setDetailedTableData] = useState({});
  const [LineChartData, setLineChartData] = useState();
  const [filteredTableData, setFilteredTableData] = useState({});

  useEffect(() => {
    setDefaultDeepDiveViewData(Object.assign({}, deepDiveDefaultValueData));
    setDeepDiveViewData(Object.assign({}, deepDiveActualValueData));
    // setDetailedTableData(Object.assign({}, deepDiveValueTableData));
  }, []);

  useEffect(() => {
    if (Object.keys(defaultDeepDiveViewData).length > 0) defaultBarchartData();
  }, [defaultDeepDiveViewData]);

  useEffect(() => {
    if (LineChartData?.length > 0) {
      let filteredData = {};
      if (fgChartData?.length > 0) {
        if (functionSelected === "FG") {
          filteredData["fgList"] = deepDiveValueTableData.fgList.filter(
            (item) => {
              return item.fgCode === funcValueSelected;
            }
          );
        } else {
          filteredData["fgList"] = deepDiveValueTableData.fgList.filter(
            (item) => {
              return fgChartData.some((ele) => ele.code === item.fgCode);
            }
          );
        }
      }
      if (rmChartData?.length > 0) {
        if (functionSelected === "RM") {
          filteredData["rmList"] = deepDiveValueTableData.rmList.filter(
            (item) => {
              return item.rmCode === funcValueSelected;
            }
          );
        } else {
          filteredData["rmList"] = deepDiveValueTableData.rmList.filter(
            (item) => {
              return rmChartData.some((ele) => ele.code === item.rmCode);
            }
          );
        }
      }
      if (pmChartData?.length > 0) {
        if (functionSelected === "PM") {
          filteredData["pmList"] = deepDiveValueTableData.pmList.filter(
            (item) => {
              return item.pmCode === funcValueSelected;
            }
          );
        } else {
          filteredData["pmList"] = deepDiveValueTableData.pmList.filter(
            (item) => {
              return pmChartData.some((ele) => ele.code === item.pmCode);
            }
          );
        }
      }
      setFilteredTableData(Object.assign({}, filteredData));
    } else {
      setFilteredTableData({});
    }
  }, [LineChartData]);

  const defaultBarchartData = () => {
    const objFG = [],
      objRM = [],
      objPM = [],
      objCountry = [];
    defaultDeepDiveViewData?.value?.countryView?.map((ele) => {
      if (ele.countryBW !== "0") {
        objCountry.push({
          code: ele.country,
          value: Math.round(parseFloat(ele.countryBW)),
          color: "#61D4C4",
        });
      }
    });
    defaultDeepDiveViewData?.value?.fgView?.map((data) => {
      if (data.fgbw !== "0") {
        objFG.push({
          code: data.fgCode,
          value: Math.round(parseFloat(data.fgbw)),
          country: data.country,
          color: "#8FCAEB",
        });
      }
    });
    defaultDeepDiveViewData?.value?.pmView?.map((item) => {
      if (item.pmbw !== "0") {
        objPM.push({
          code: item.pmCode,
          value: Math.round(parseFloat(item.pmbw)),
          fgCode: item.fgCode,
          country: item.country,
          color: "#C79AD5",
        });
      }
    });
    defaultDeepDiveViewData?.value?.rmView?.map((item) => {
      if (item.rmbw !== "0") {
        objRM.push({
          code: item.rmCode,
          value: Math.round(parseFloat(item.rmbw)),
          fgCode: item.fgCode,
          country: item.country,
          color: "#FCEDA4",
          materialType: item.materialType,
        });
      }
    });
    objCountry.sort((a, b) => b.value - a.value);
    objFG.sort((a, b) => b.value - a.value);
    objRM.sort((a, b) => b.value - a.value);
    objPM.sort((a, b) => b.value - a.value);
    setFgChartData(objFG);
    setRMChartData(objRM);
    setPMChartData(objPM);
    setCountryChartData(objCountry);
    setCountryValue(
      formatNumber(defaultDeepDiveViewData?.value?.overview?.totalBW)
    );
    setFgBwValue(formatNumber(defaultDeepDiveViewData?.value?.overview?.fgbw));
    setRmBwValue(formatNumber(defaultDeepDiveViewData?.value?.overview?.rmbw));
    setPmBwValue(formatNumber(defaultDeepDiveViewData?.value?.overview?.pmbw));
  };

  const onChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
    props.onTabChange(selectedTab);
  };

  const handleChange = (value) => {
    if (value === undefined) setIsSelectedDonutSlice(-1);
    else setIsSelectedDonutSlice(value === 3 ? 1 : value);
  };

  const handleCountryBarSelect = (value) => {
    console.log("value", value);
    if (deepDiveViewData?.value?.countryView?.length > 0) {
      const objFG = [],
        objRM = [],
        objPM = [],
        objCountry = [],
        fgArray = [],
        rmArray = [],
        pmArray = [];
      let FGbwsum = "",
        RMbwsum = "",
        PMbwsum = "";
      if (value === undefined) {
        setLineChartData([]);
        setCountrySelected("");
        defaultBarchartData();
        setFuntionSelected("");
        setFuncValueSelected("");
      } else {
        setCountrySelected(value);
        setFuntionSelected("Country");
        setFuncValueSelected(CountryChartData[value].code);

        deepDiveViewData?.value?.countryView?.map((ele, index) => {
          if (
            CountryChartData[value].code === ele.country &&
            ele.countryBW !== "0"
          ) {
            FGbwsum = ele.countryFGBW;
            RMbwsum = ele.countryRMBW;
            PMbwsum = ele.countryPMBW;
            objCountry.push({
              code: ele.country,
              value: Math.round(parseFloat(ele.countryBW)),
              color: value === index ? "#61D4C4" : "#36716A",
              country: ele.country,
            });
          }
          if (CountryChartData[value].code === ele.country) {
            ele.fgView.map((data) => {
              const fgCode = objFG.every((ele) => ele.code !== data.fgCode);
              if (fgCode && data.fgbw !== "0") {
                objFG.push({
                  code: data.fgCode,
                  value: Math.round(parseFloat(data.fgbw)),
                  color: "#8FCAEB",
                  country: ele.country,
                  fgCode: data.fgCode,
                });
                fgArray.push(data.fgCode);
              }
              data.pmView.map((item) => {
                const pmCode = objPM.every((ele) => ele.code !== item.pmCode);
                if (pmCode && item.pmbw !== "0") {
                  objPM.push({
                    code: item.pmCode,
                    value: Math.round(parseFloat(item.pmbw)),
                    color: "#C79AD5",
                    country: ele.country,
                    fgCode: data.fgCode,
                  });
                  pmArray.push(item.pmCode);
                }
              });
              data.rmView.map((item) => {
                const rmCode = objRM.every((ele) => ele.code !== item.rmCode);
                if (rmCode && item.rmbw !== "0") {
                  objRM.push({
                    code: item.rmCode,
                    value: Math.round(parseFloat(item.rmbw)),
                    color: "#FCEDA4",
                    country: ele.country,
                    fgCode: data.fgCode,
                    materialType: item.materialType,
                  });
                  rmArray.push(item.rmCode);
                }
              });
            });
          }
        });
        objCountry.sort((a, b) => b.value - a.value);
        objFG.sort((a, b) => b.value - a.value);
        objRM.sort((a, b) => b.value - a.value);
        objPM.sort((a, b) => b.value - a.value);
        // setCountryChartData(objCountry);
        setFgChartData(objFG);
        setRMChartData(objRM);
        setPMChartData(objPM);
        const countrybwsum = objCountry.reduce((acc, item) => {
          return acc + item.value;
        }, 0);
        setCountryValue(formatNumber(countrybwsum));
        setFgBwValue(formatNumber(FGbwsum));
        setRmBwValue(formatNumber(RMbwsum));
        setPmBwValue(formatNumber(PMbwsum));
        let FGlinechartdata = [];
        let sumFG = 0;
        console.log("fgArray", fgArray, deepDiveValueTableData?.fgList);
        if (fgArray.length > 0) {
          deepDiveValueTableData?.fgList?.map((item) => {
            if (
              fgArray.includes(item.fgCode) &&
              item.plantCountry === CountryChartData[value].code
            ) {
              item.weekDetails.map((ele) => {
                const weekIndex = FGlinechartdata.findIndex(
                  (ele1) => ele1.weekname === ele.weekNumber
                );
                if (weekIndex !== -1) {
                  let inventory = parseFloat(
                    FGlinechartdata[weekIndex].inventory
                  );
                  inventory += parseFloat(ele.inventory);
                  FGlinechartdata[weekIndex].inventory = inventory;
                } else {
                  FGlinechartdata.push({
                    weekname: ele.weekNumber,
                    inventory: ele.inventory,
                  });
                }
              });
            }
          });
        }
        if (pmArray.length > 0) {
          let sumPM = 0;
          deepDiveValueTableData?.pmList?.map((item) => {
            if (
              pmArray.includes(item.pmCode) &&
              item.plantCountry === CountryChartData[value].code
            ) {
              item.weekDetails.map((ele) => {
                const weekIndex = FGlinechartdata.findIndex(
                  (ele1) => ele1.weekname === ele.weekNumber
                );
                if (weekIndex !== -1) {
                  let inventory = parseFloat(
                    FGlinechartdata[weekIndex].inventory
                  );
                  inventory += parseFloat(ele.inventory);
                  FGlinechartdata[weekIndex].inventory = inventory;
                } else {
                  FGlinechartdata.push({
                    weekname: ele.weekNumber,
                    inventory: ele.inventory,
                  });
                }
              });
            }
          });
        }
        if (rmArray.length > 0) {
          let sumRM = 0;
          deepDiveValueTableData?.rmList?.map((item) => {
            if (
              rmArray.includes(item.rmCode) &&
              item.plantCountry === CountryChartData[value].code
            ) {
              item.weekDetails.map((ele) => {
                const weekIndex = FGlinechartdata.findIndex(
                  (ele1) => ele1.weekname === ele.weekNumber
                );
                if (weekIndex !== -1) {
                  let inventory = parseFloat(
                    FGlinechartdata[weekIndex].inventory
                  );
                  inventory += parseFloat(ele.inventory);
                  FGlinechartdata[weekIndex].inventory = inventory;
                } else {
                  FGlinechartdata.push({
                    weekname: ele.weekNumber,
                    inventory: ele.inventory,
                  });
                }
              });
            }
          });
        }

        console.log("FGlinechartdata", FGlinechartdata);

        setLineChartData(FGlinechartdata);
      }
    }
  };

  const handleFGBarSelect = (index) => {
    if (deepDiveViewData?.value?.countryView?.length > 0) {
      if (index === undefined) {
        setLineChartData([]);
        setFuntionSelected("");
        setFuncValueSelected("");
        if (countrySelected === "") {
          defaultBarchartData();
        } else {
          handleCountryBarSelect(countrySelected);
        }
      } else {
        setFuntionSelected("FG");
        let countryArray = [],
          fgCodeArray = [],
          filterCountyArray,
          objFG = [],
          objCountry = [],
          objRM = [],
          objPM = [];
        countryArray = fgChartData[index].country.split(",");
        fgCodeArray = fgChartData[index].code.split(",");
        setFuncValueSelected(fgChartData[index].code);

        filterCountyArray = deepDiveViewData.value.countryView.filter((item) =>
          countryArray.includes(item.country)
        );
        filterCountyArray.map((item) => {
          item.fgView.map((data) => {
            if (fgCodeArray.includes(data.fgCode)) {
              objFG.push({
                code: data.fgCode,
                value: Math.round(parseFloat(data.fgbw)),
                color: "#8FCAEB",
                country: item.country,
                fgCode: data.fgCode,
              });
              data.rmView.map((item) => {
                const rmCode = objRM.every((ele) => ele.code !== item.rmCode);
                if (rmCode && item.rmbw !== "0") {
                  objRM.push({
                    code: item.rmCode,
                    value: Math.round(parseFloat(item.rmbw)),
                    color: "#FCEDA4",
                    country: item.country,
                    fgCode: data.fgCode,
                    materialType: item.materialType,
                  });
                }
              });
              data.pmView.map((item) => {
                const pmCode = objPM.every((ele) => ele.code !== item.pmCode);
                if (pmCode && item.pmbw !== "0") {
                  objPM.push({
                    code: item.pmCode,
                    value: Math.round(parseFloat(item.pmbw)),
                    color: "#C79AD5",
                    country: item.country,
                    fgCode: data.fgCode,
                  });
                }
              });
              const cntry = objCountry.every(
                (ele) => ele.code !== item.country
              );
              if (cntry) {
                objCountry.push({
                  code: item.country,
                  value: Math.round(parseFloat(data.fgbw)),
                  color: "#61D4C4",
                  country: item.country,
                });
              }
            }
          });
        });
        const countrybwsum = objCountry.reduce((acc, item) => {
          return acc + item.value;
        }, 0);
        const FGbwsum = objFG.reduce((acc, item) => {
          return acc + item.value;
        }, 0);
        const RMbwsum = objRM.reduce((acc, item) => {
          return acc + item.value;
        }, 0);
        const PMbwsum = objPM.reduce((acc, item) => {
          return acc + item.value;
        }, 0);

        objRM.sort((a, b) => b.value - a.value);
        objPM.sort((a, b) => b.value - a.value);
        objCountry.sort((a, b) => b.value - a.value);

        setRMChartData(objRM);
        setPMChartData(objPM);
        {
          countrySelected.length === 0 && setCountryChartData(objCountry);
        }
        setCountryValue(formatNumber(fgChartData[index].value));
        setFgBwValue(formatNumber(fgChartData[index].value));
        setRmBwValue(formatNumber(RMbwsum));
        setPmBwValue(formatNumber(PMbwsum));

        let FGlinechartdata = [];
        deepDiveValueTableData?.fgList?.map((item) => {
          if (fgCodeArray[0] === item.fgCode) {
            item.weekDetails.map((ele) => {
              const weekIndex = FGlinechartdata.findIndex(
                (ele1) => ele1.weekname === ele.weekNumber
              );
              if (weekIndex !== -1) {
                let inventory = parseFloat(
                  FGlinechartdata[weekIndex].inventory
                );
                inventory += parseFloat(ele.inventory);
                FGlinechartdata[weekIndex].inventory = inventory;
              } else {
                FGlinechartdata.push({
                  weekname: ele.weekNumber,
                  inventory: ele.inventory,
                });
              }
            });
          }
        });
        setLineChartData(FGlinechartdata);
      }
    }
  };

  const handlePMBarSelect = (index) => {
    if (index === undefined) {
      setFuntionSelected("");
      setFuncValueSelected("");
      if (countrySelected === "") {
        defaultBarchartData();
      } else {
        handleCountryBarSelect(countrySelected);
      }
    } else {
      setFuntionSelected("PM");
      let countryArray = [],
        fgCodeArray = [],
        filterCountyArray,
        objCountry = [],
        objFG = [],
        objRM = [],
        objPM = [],
        pmcode;
      countryArray = pmChartData[index].country.split(",");
      fgCodeArray = pmChartData[index].fgCode.split(",");
      pmcode = pmChartData[index].code;
      setFuncValueSelected(pmChartData[index].code);

      filterCountyArray = deepDiveViewData?.value?.countryView?.filter((item) =>
        countryArray.includes(item.country)
      );
      filterCountyArray.map((item) => {
        item.fgView.map((data) => {
          if (fgCodeArray.includes(data.fgCode)) {
            const fgCode = objFG.every((ele) => ele.code !== data.fgCode);
            if (fgCode && data.fgbw !== "0") {
              objFG.push({
                code: data.fgCode,
                value: Math.round(parseFloat(data.fgbw)),
                color: "#8FCAEB",
                country: item.country,
                fgCode: data.fgCode,
              });
            }
            data.pmView.map((item) => {
              if (pmcode === item.pmCode) {
                objPM.push({
                  code: item.pmCode,
                  value: Math.round(parseFloat(item.pmbw)),
                  color: "#C79AD5",
                  country: item.country,
                  fgCode: data.fgCode,
                });
                const cntry = objCountry.every(
                  (ele) => ele.code !== item.country
                );
                if (cntry) {
                  objCountry.push({
                    code: item.country,
                    value: Math.round(parseFloat(item.pmbw)),
                    color: "#61D4C4",
                    country: item.country,
                  });
                }
              }
            });
            data.rmView.map((item) => {
              const rmCode = objRM.every((ele) => ele.code !== item.rmCode);
              if (rmCode && item.rmbw !== "0") {
                objRM.push({
                  code: item.rmCode,
                  value: Math.round(parseFloat(item.rmbw)),
                  color: "#FCEDA4",
                  country: item.country,
                  fgCode: data.fgCode,
                  materialType: item.materialType,
                });
              }
            });
          }
        });
      });
      const countrybwsum = objCountry.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const FGbwsum = objFG.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const PMbwsum = objPM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const RMbwsum = objRM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      objRM.sort((a, b) => b.value - a.value);
      objFG.sort((a, b) => b.value - a.value);
      objCountry.sort((a, b) => b.value - a.value);
      setFgChartData(objFG);
      setRMChartData(objRM);
      {
        countrySelected.length === 0 && setCountryChartData(objCountry);
      }
      setCountryValue(formatNumber(pmChartData[index].value));
      setFgBwValue(formatNumber(FGbwsum));
      setPmBwValue(formatNumber(pmChartData[index].value));
      setRmBwValue(formatNumber(RMbwsum));
      let PMlinechartdata = [];
      deepDiveValueTableData?.pmList?.map((item) => {
        if (pmChartData[index].code === item.pmCode) {
          item.weekDetails.map((ele) => {
            const weekIndex = PMlinechartdata.findIndex(
              (ele1) => ele1.weekname === ele.weekNumber
            );
            if (weekIndex !== -1) {
              let inventory = parseFloat(PMlinechartdata[weekIndex].inventory);
              inventory += parseFloat(ele.inventory);
              PMlinechartdata[weekIndex].inventory = inventory;
            } else {
              PMlinechartdata.push({
                weekname: ele.weekNumber,
                inventory: ele.inventory,
              });
            }
          });
        }
      });
      setLineChartData(PMlinechartdata);
    }
  };

  const handleRMBarSelect = (index) => {
    if (index === undefined) {
      setFuntionSelected("");
      setFuncValueSelected("");
      if (countrySelected === "") {
        defaultBarchartData();
      } else {
        handleCountryBarSelect(countrySelected);
      }
    } else {
      setFuntionSelected("RM");
      let countryArray = [],
        fgCodeArray = [],
        filterCountyArray,
        objCountry = [],
        objFG = [],
        objPM = [],
        objRM = [],
        rmcode;
      countryArray = rmChartData[index].country.split(",");
      fgCodeArray = rmChartData[index].fgCode.split(",");
      rmcode = rmChartData[index].code;
      setFuncValueSelected(rmChartData[index].code);
      filterCountyArray = deepDiveViewData.value.countryView.filter((item) =>
        countryArray.includes(item.country)
      );
      filterCountyArray.map((item) => {
        item.fgView.map((data) => {
          if (fgCodeArray.includes(data.fgCode)) {
            const fgCode = objFG.every((ele) => ele.code !== data.fgCode);
            if (fgCode && data.fgbw !== "0") {
              objFG.push({
                code: data.fgCode,
                value: Math.round(parseFloat(data.fgbw)),
                color: "#8FCAEB",
              });
            }
            data.pmView.map((item) => {
              const pmCode = objPM.every((ele) => ele.code !== item.pmCode);
              if (pmCode && item.pmbw !== "0") {
                objPM.push({
                  code: item.pmCode,
                  value: Math.round(parseFloat(item.pmbw)),
                  color: "#C79AD5",
                  country: item.country,
                  fgCode: data.fgCode,
                });
              }
            });
            data.rmView.map((item) => {
              if (rmcode === item.rmCode) {
                objRM.push({
                  code: item.rmCode,
                  value: Math.round(parseFloat(item.rmbw)),
                  color: "#FCEDA4",
                  country: item.country,
                  fgCode: data.fgCode,
                });
                const cntry = objCountry.every(
                  (ele) => ele.code !== item.country
                );
                if (cntry) {
                  objCountry.push({
                    code: item.country,
                    value: Math.round(parseFloat(item.rmbw)),
                    color: "#61D4C4",
                    country: item.country,
                  });
                }
              }
           
            });
          }
        });
      });
      const countrybwsum = objCountry.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const FGbwsum = objFG.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const PMbwsum = objPM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const RMbwsum = objRM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      objFG.sort((a, b) => b.value - a.value);
      objPM.sort((a, b) => b.value - a.value);
      objCountry.sort((a, b) => b.value - a.value);
      setFgChartData(objFG);
      setPMChartData(objPM);
      setCountryChartData(objCountry);
      {
        countrySelected.length === 0 && setCountryChartData(objCountry);
      }
      setCountryValue(formatNumber(rmChartData[index].value));
      setFgBwValue(formatNumber(FGbwsum));
      setPmBwValue(formatNumber(PMbwsum));
      setRmBwValue(formatNumber(rmChartData[index].value));
      let RMlinechartdata = [];
      deepDiveValueTableData?.rmList?.map((item) => {
        if (rmChartData[index].code === item.rmCode) {
          item.weekDetails.map((ele) => {
            const weekIndex = RMlinechartdata.findIndex(
              (ele1) => ele1.weekname === ele.weekNumber
            );
            if (weekIndex !== -1) {
              let inventory = parseFloat(RMlinechartdata[weekIndex].inventory);
              inventory += parseFloat(ele.inventory);
              RMlinechartdata[weekIndex].inventory = inventory;
            } else {
              RMlinechartdata.push({
                weekname: ele.weekNumber,
                inventory: ele.inventory,
              });
            }
          });
        }
      });
      setLineChartData(RMlinechartdata);
    }
  };

  return (
    <>
      {isModalTableOpen && (
        <ModalDetailedTable
          tableData={deepDiveValueTableData}
          filteredData={filteredTableData}
          showModal={true}
          setShowModal={() => setIsModalTableOpen(false)}
          selectedProjectId={selectedProjectId}
          tabselected={"Value"}
          countrySelected={
            functionSelected === "Country" ? funcValueSelected : ""
          }
        />
      )}
      <div className="dsf-deepdive-parent-div">
        <Row className="dsf-deepdive-child-row1">
          <Col xs={4} className="dsf-deepdive-child-row1-col1">
            <div className="dsf-deepdive-child-row1-col1-div">
              <ul className="nav dsp-deepdive-toggle-ul">
                <li
                  className={`dsp-deepdive-toggle-li 
                      ${activeTab === "Value" ? "active" : ""}`}
                  onClick={() => onChangeTab("Value")}
                >
                  Value (€)
                </li>
                <li
                  className={`dsp-deepdive-toggle-li ${
                    activeTab === "Volume" ? "active" : ""
                  }`}
                  onClick={() => onChangeTab("Volume")}
                >
                  Volume
                </li>
              </ul>
              <Row className="dsf-deepdive-donut-chart-row">
                <Col xs={5} className="dsf-deepdive-donut-chart-row-col1">
                  <div className="">
                    <p className="dsf-bw-title">Total BW Risk</p>
                    <p className="dsf-bw-value">
                      {formatNumber(
                        defaultDeepDiveViewData?.value?.overview?.totalBW
                      )}
                      €
                    </p>
                  </div>
                  <div className="donut-legends-main-div">
                    <div className="donut-legends-div">
                      <span className="dsf-legen-style span-gap">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#8FCAEB" }}
                        ></span>
                        FG
                      </span>
                      <span className="dsf-legen-style">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#FCEDA4" }}
                        ></span>
                        RM
                      </span>
                    </div>
                    <div className="donut-legends-div">
                      <span className="dsf-legen-style span-gap">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#C79AD5" }}
                        ></span>
                        PM
                      </span>
                      <span className="dsf-legen-style">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#CFCFCF" }}
                        ></span>
                        SFG
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={7} className="dsf-deepdive-donut-chart-row-col2">
                  {Object.keys(defaultDeepDiveViewData).length > 0 && (
                    <div id="donut_Chart" className="donut-chart-div">
                      <DonutChart
                        container={"donut_Chart"}
                        chartData={defaultDeepDiveViewData?.value?.overview}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            xs={8}
            className={`dsf-deepdive-child-row2-col1`}
            key={CountryChartData}
          >
            {CountryChartData?.length > 0 ? (
              <div className="dsf-deepdive-child-row1-col2-div">
                <div className="dsf-deepdive-barchart-info">
                  <p className="dsf-deepdive-barchart-name">DC-country view</p>
                  <p className="dsf-deepdive-barchart-value">
                    Total BW:
                    <span className="dsf-deepdive-barchart-value-FG">
                      {""} {countryValue} €
                    </span>
                  </p>
                </div>
                {CountryChartData && CountryChartData.length > 0 && (
                  <div className="fg-combo-chart-parent-div">
                    <div
                      id="country_view_chart"
                      className={`country_combo_chart_div ${
                        functionSelected.length > 0
                          ? functionSelected !== "Country"
                            ? "dsf-deepdive-col-disable"
                            : ""
                          : ""
                      }`}
                    >
                      <ComboChart
                        container={"country_view_chart"}
                        chartData={CountryChartData}
                        onChange={handleCountryBarSelect}
                        chartAreaLeft={"10%"}
                        chartAreaBottom={"20%"}
                        chartAreaWidth={"80%"}
                        chartAreaHeight={"70%"}
                        chartWidth={"70%"}
                        chartHeight={"140"}
                        barColor={"#61D4C4"}
                        barCount={"14"}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>No country data available</span>
              </div>
            )}
          </Col>
        </Row>
        <Row className="dsf-deepdive-child-row2" key={isSelectedDonutSlice}>
          <Col
            xs={4}
            className={`dsf-deepdive-child-row2-col1`}
            style={{
              opacity:
                isSelectedDonutSlice === 0 || isSelectedDonutSlice === -1
                  ? "none"
                  : "0.5",
            }}
          >
            {fgChartData?.length > 0 ? (
              <div
                className="dsf-deepdive-child-row2-col1-div"
                key={fgChartData}
              >
                <div className="dsf-deepdive-barchart-info">
                  <p className="dsf-deepdive-barchart-name">Finished Goods</p>
                  <p className="dsf-deepdive-barchart-value">
                    Total BW:
                    <span className="dsf-deepdive-barchart-value-FG">
                     {""} {fgBwValue} €
                    </span>
                  </p>
                </div>
                {fgChartData && fgChartData.length > 0 && (
                  <div className="fg-combo-chart-parent-div">
                    <div
                      id="fg_combo_chart"
                      className={`fg_combo_chart_div ${
                        functionSelected.length > 0
                          ? functionSelected !== "FG"
                            ? "dsf-deepdive-col-disable"
                            : ""
                          : ""
                      }`}
                    >
                      <ComboChart
                        container={"fg_combo_chart"}
                        chartData={fgChartData}
                        onChange={handleFGBarSelect}
                        chartAreaLeft={"20%"}
                        chartAreaBottom={"20%"}
                        chartAreaWidth={"70%"}
                        chartAreaHeight={"70%"}
                        chartWidth={"70%"}
                        chartHeight={"140"}
                        barColor={"#8FCAEB"}
                        barCount={"8"}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>No FG data available</span>
              </div>
            )}
          </Col>
          <Col
            xs={4}
            className={`dsf-deepdive-child-row2-col2`}
            style={{
              opacity:
                isSelectedDonutSlice === 1 || isSelectedDonutSlice === -1
                  ? "none"
                  : "0.5",
            }}
          >
            {rmChartData?.length > 0 ? (
              <div
                className="dsf-deepdive-child-row2-col2-div"
                key={rmChartData}
              >
                <div className="dsf-deepdive-barchart-info">
                  <p className="dsf-deepdive-barchart-name">Raw Materials</p>
                  <p className="dsf-deepdive-barchart-value">
                    Total BW:
                    <span className="dsf-deepdive-barchart-value-RM">
                     {" "} {rmBwValue} €
                    </span>
                  </p>
                </div>
                {rmChartData && rmChartData.length > 0 && (
                  <div className="fg-combo-chart-parent-div">
                    <div
                      id="rm_combo_chart"
                      className={`rm_combo_chart_div ${
                        functionSelected.length > 0
                          ? functionSelected !== "RM"
                            ? "dsf-deepdive-col-disable"
                            : ""
                          : ""
                      }`}
                    >
                      <ComboChart
                        container={"rm_combo_chart"}
                        chartData={rmChartData}
                        onChange={handleRMBarSelect}
                        chartAreaLeft={"20%"}
                        chartAreaBottom={"20%"}
                        chartAreaWidth={"70%"}
                        chartAreaHeight={"70%"}
                        chartWidth={"70%"}
                        chartHeight={"140"}
                        barColor={"#FCEDA4"}
                        barCount={"8"}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>No RM data available</span>
              </div>
            )}
          </Col>
          <Col
            xs={4}
            className={`dsf-deepdive-child-row2-col3`}
            style={{
              opacity:
                isSelectedDonutSlice === 2 || isSelectedDonutSlice === -1
                  ? "none"
                  : "0.5",
            }}
          >
            {pmChartData?.length > 0 ? (
              <div
                className="dsf-deepdive-child-row2-col3-div"
                key={pmChartData}
              >
                <div className="dsf-deepdive-barchart-info">
                  <p className="dsf-deepdive-barchart-name">Pack Materials</p>
                  <p className="dsf-deepdive-barchart-value">
                    Total BW:
                    <span className="dsf-deepdive-barchart-value-PM">
                     {" "} {pmBwValue} €
                    </span>
                  </p>
                </div>
                {pmChartData && pmChartData.length > 0 && (
                  <div className="fg-combo-chart-parent-div">
                    <div
                      id="pm_combo_chart"
                      className={`pm_combo_chart_div ${
                        functionSelected.length > 0
                          ? functionSelected !== "PM"
                            ? "dsf-deepdive-col-disable"
                            : ""
                          : ""
                      }`}
                    >
                      <ComboChart
                        container={"pm_combo_chart"}
                        chartData={pmChartData}
                        onChange={handlePMBarSelect}
                        chartAreaLeft={"20%"}
                        chartAreaBottom={"20%"}
                        chartAreaWidth={"70%"}
                        chartAreaHeight={"70%"}
                        chartWidth={"70%"}
                        chartHeight={"140"}
                        barColor={"#C79AD5"}
                        barCount={"8"}
                      />
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>No PM data available</span>
              </div>
            )}
          </Col>
        </Row>
        <Row className="dsf-deepdive-child-row3">
          <Col xs={12} className="dsf-deepdive-child-row3-col1">
            <div className="dsf-deepdive-child-row3-col1-div">
              <div className="dsf-deepdive-barchart-info">
                <p className="dsf-deepdive-barchart-name">
                  Inventory Development
                </p>
                <div
                  className="dsf-deepdive-detaile-table"
                  onClick={() => setIsModalTableOpen(true)}
                >
                  Detailed Table
                  <img src={TableArrowPointer} alt="arrowpointer" />
                </div>
              </div>
              <div className="donut-legends-div align-data-div">
                <span className="span-gap dsf-legen-style">
                  <span
                    className="color-dot"
                    style={{ backgroundColor: "#F1A33A" }}
                  ></span>
                  Business Waste
                </span>
                <span className="dsf-legen-style">
                  <span
                    className="color-dot"
                    style={{ backgroundColor: "#00D7C4" }}
                  ></span>
                  Delist Date
                </span>
              </div>
              {LineChartData && (
                <div id="line_chart_div">
                  {LineChartData.length > 0 ? (
                    <LineChart chartData={LineChartData} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DeepDiveValue;
