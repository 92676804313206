import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProjectDetailsService from "../service/projectDetailsService";

const initialState = [];

export const retrieveProjectLists = createAsyncThunk(
  "projectDetails/retrieve",
  async (data) => {
    const res = await ProjectDetailsService.fetchProjectLists(data);
    return res.data;
  }
);

export const deleteDelistingProject = createAsyncThunk(
  "projectDetails/delete",
  async (data) => {
    const res = await ProjectDetailsService.deleteDelistingProjectDetails(data);
    return res;
  }
);

const projectDetailsSlice = createSlice({
  name: "projectDetails",
  initialState,
  reducers: {
    postUpdatedWorkFlowStatus(state, action) {
      console.log(action);
      let existingPost = state.find(item => item.projectName === action.payload.projectName)
      if (existingPost) {
         existingPost.workflow = [... action.payload.workflow];
      }
      console.log(state);
    },
    sortProjectList(state,action){
      console.log(action);
      let arrProjList = [];
      action.payload.forEach((item) => {
        arrProjList.push(item);
      });
      return arrProjList;
    }
  },
  extraReducers: {
  [retrieveProjectLists.fulfilled]: (state, action) => {
    let arrProjList = [];
    action.payload.forEach((item,index) => {
      arrProjList.push(item);
    });
    return arrProjList;
  },
  [deleteDelistingProject.fulfilled]: (state, action) => {
    console.log("deleteDelistingProject",action);
  },
},
});
export const { postUpdatedWorkFlowStatus,sortProjectList } = projectDetailsSlice.actions;
const { reducer } = projectDetailsSlice;
export default reducer;
