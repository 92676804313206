import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import InnoflexDatePicker from "../../../../components/DatePicker/datePicker";
import UserDetailsService from "../../../../service/userDetailsService";
import { saveScenarioAction } from "../../../../service/scenarioPlannerService";
import {
  dateTimeFormat,
  validateUnileverMailId,
  checkNullDataByObjInArray,
} from "../../../../utils";

const ScenaioAddEdit = (props) => {
  const {
    selectedMaterial,
    selectedCountry,
    selectedProject,
    requiredActionData,
  } = props;
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [activeTab, setActiveTab] = useState("Procurement");
  const [isOkBtnDisable, setIsOkBtnDisable] = useState(true);
  const [isValidationSuccessful, setIsValidationSuccessful] = useState(true);
  const [recipient, setRecipient] = useState("");
  const [allActionList, setAllActionList] = useState([]);
  const [actionListForSelectedUser, setActionListForSelectedUser] = useState(
    []
  );
  const [validationMessage, setValidationMessage] = useState("");

  const userDetails = useSelector((state) => state.userDetails);

  useEffect(() => {
    const arrActionList = requiredActionData.map((item) => ({ ...item })); // Copy by value not reference
    setAllActionList(arrActionList);
  }, []);

  useEffect(() => {
    const filteredObj = allActionList.filter((item) => item.isEdited === true);
    if (filteredObj.length >= 1) {
      setIsOkBtnDisable(false);
    }
    else {
      setIsOkBtnDisable(true);
    }
  }, [allActionList]);

  const closeModal = () => {
    setShowModal(false);
    props.onComplete(false, []);
  };

  const handleOkActionBtnOnClick = async () => {
    let arrPayload = [];
    allActionList.forEach((element) => {
      let objPayload = {};
      if (element.isEdited) {
        if (element.isNew) {
          objPayload.actionId = 0;
        } else {
          objPayload.actionId = element.actionId;
        }
        objPayload.projectId = element.projectId;
        objPayload.projectName = element.projectName;
        objPayload.functionName = element.functionName;
        objPayload.roleName = element.roleName;
        objPayload.actionDetails = element.actionDetails;
        objPayload.contact = element.contact;
        objPayload.deadline = element.deadline;
        objPayload.status = element.status;
        arrPayload.push(objPayload);
      }
    });
    const pass = checkNullDataByObjInArray(arrPayload, [
      "actionDetails",
      "deadline",
    ]);
    if (pass) {
      const usermailId = JSON.parse(
        localStorage.getItem("userDetails")
      ).username;
      const res = await saveScenarioAction(arrPayload);
      if (res.status === 200) {
        setShowModal(false);
        props.onComplete(true, res.data);
      } else {
        console.log("error in Add action", res.data);
      }
    } else {
      setIsValidationSuccessful(false);
    }
  };

  const onChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
    let arr = [...allActionList];
    let filteredArr = arr.filter(
      (obj) => obj.actionDetails !== "" || obj.deadline !== ""
    );
    setAllActionList(filteredArr);
    let filteredArrByUser = allActionList.filter(
      (item) => item.contact === recipient && item.functionName === selectedTab
    );
    setActionListForSelectedUser(filteredArrByUser);
  };
  const handleDatePickerChange = (value, isNull, date, item) => {
    let arr = [...allActionList];
    let filteredArrByAction = arr.filter(
      (obj) => obj.actionId === item.actionId
    );
    filteredArrByAction.forEach((item) => {
      item.deadline = dateTimeFormat(date, "MM/DD/YYYY");
      item.isEdited = true;
    });
    setAllActionList(arr);
    arr = [];
    let arr1 = [...actionListForSelectedUser];
    let filteredArrByAction1 = arr1.filter(
      (obj) => obj.actionId === item.actionId
    );
    filteredArrByAction1.forEach((item) => {
      item.deadline = dateTimeFormat(date, "MM/DD/YYYY");
      item.isEdited = true;
    });
    setActionListForSelectedUser(arr1);
  };
  const handleRecipientValueOnEnter = (event) => {
    if (validateUnileverMailId(event.target.value)) {
      setValidationMessage("");
      getUserDetails(event.target.value);
    } else {
      setRecipient("");
      setActionListForSelectedUser([]);
      setValidationMessage("Please provide valid Unilever Mail id");
    }
  };
  const handleActionValueOnEnter = (event, item) => {
    let arr = [...allActionList];
    let filteredArr = arr.filter((obj) => obj.actionId === item.actionId);
    if (filteredArr.length === 1) {
      filteredArr[0].actionDetails = event.target.value;
      filteredArr[0].isEdited = true;
    }
    setAllActionList(arr);
    arr = [];
    let arr1 = [...actionListForSelectedUser];
    let filteredArr1 = arr1.filter((obj) => obj.actionId === item.actionId);
    if (filteredArr1.length === 1) {
      filteredArr1[0].actionDetails = event.target.value;
      filteredArr1[0].isEdited = true;
    }
    setActionListForSelectedUser(arr1);
    arr1 = [];
    if (event.type === "blur") {
    }
  };
  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setValidationMessage("");
      setRecipient(recepientMailId);
      let arr = [...allActionList];
      let filteredArr = arr.filter(
        (obj) => obj.actionDetails !== "" || obj.deadline !== ""
      );
      setAllActionList(filteredArr);
      let filteredArrByUser = allActionList.filter(
        (item) =>
          item.contact === recepientMailId && item.functionName === activeTab
      );
      setActionListForSelectedUser(filteredArrByUser);
    } else {
      setRecipient("");
      setActionListForSelectedUser([]);
      setValidationMessage("The Unilever mail id does not exist");
    }
  };
  const onClickAddActionDetailsForUser = () => {
    if (recipient !== "") {
      let arr = [...allActionList];
      let selectedUserArr = [...actionListForSelectedUser];
      let objPayload = {};
      objPayload.actionId = "new" + Math.random();
      objPayload.projectId = selectedProject.projectId;
      objPayload.projectName = selectedProject.projectName;
      objPayload.functionName = activeTab;
      objPayload.roleName = userDetails.roleName;
      objPayload.actionDetails = "";
      objPayload.contact = recipient;
      objPayload.deadline = "";
      objPayload.status = false;
      objPayload.isEdited = true;
      objPayload.isNew = true;
      arr.push(objPayload);
      selectedUserArr.push(objPayload);
      setActionListForSelectedUser(selectedUserArr);
      setAllActionList(arr);
    }
  };
  const onDeleteClicked = (item) => {
    let arr = [...allActionList];
    let filteredArr = allActionList.filter(
      (obj) => obj.actionId !== item.actionId
    );
    setAllActionList(filteredArr);
    arr = [];
    let arr1 = [...actionListForSelectedUser];
    let filteredArr1 = actionListForSelectedUser.filter(
      (obj) => obj.actionId !== item.actionId
    );
    setActionListForSelectedUser(filteredArr1);
    arr1 = [];
  };

  return (
    <>
      <Modal
        centered
        dialogClassName="dsf-dialog-UniqueRPMModal"
        contentClassName="dsf-modal-workflowModal scenario-add-action-modal"
        show={showModal}
      >
        <Modal.Header className="bw-modal-checkDelist-header scenario-upload-header-div">
          <Modal.Title className=" bw-checkDeslist-header-title-div">
            <div className="checkDelist-modal-header-div">
              <span className="dsf-size16 dsf-font-weight-600">
                {" "}
                Add Action and Recipient for {activeTab}
              </span>
              <span
                className="dsf-size12 dsf-font-weight-400"
                style={{ color: "#CFCFCF" }}
              >
                Select the recipient and define the task to be actioned by{" "}
                {activeTab} for this scenario.
              </span>
              {!isValidationSuccessful && (
                <span
                  className={`dsf-size12 dsf-font-weight-400 errorMsg`}
                  style={{ marginBottom: "-15px" }}
                >
                  Please fill in all actions & its deadline
                </span>
              )}
            </div>
            <img
              src={closeIcon}
              alt="Close-Image"
              onClick={() => closeModal()}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scenario-upload-body">
          <Row>
            <ul className=" nav review_action">
              <li
                className={activeTab === "Marketing" ? "active" : ""}
                onClick={() => onChangeTab("Marketing")}
              >
                Marketing
              </li>
              <li
                className={activeTab === "Procurement" ? "active" : ""}
                onClick={() => onChangeTab("Procurement")}
              >
                Procurement
              </li>
              <li
                className={activeTab === "Material Planning" ? "active" : ""}
                onClick={() => onChangeTab("Material Planning")}
              >
                Material Planning
              </li>
              <li
                className={activeTab === "Demand Planning" ? "active" : ""}
                onClick={() => onChangeTab("Demand Planning")}
              >
                Demand Planning
              </li>
              <li
                className={activeTab === "E2E Planning" ? "active" : ""}
                onClick={() => onChangeTab("E2E Planning")}
              >
                E2E Planning
              </li>
              <li
                className={activeTab === "P&I" ? "active" : ""}
                onClick={() => onChangeTab("P&I")}
              >
                P&I
              </li>
            </ul>
          </Row>
          <Row className="scenario-action-div" style={{ marginTop: "20px" }}>
            <Col>
              <span className="dsf-size12 dsf-font-weight-400">Send to</span>
            </Col>
            <Col>
              {/* <span className="dsf-size12 dsf-font-weight-400">Deadline</span> */}
            </Col>
          </Row>
          <Row
            className="scenario-action-div "
            style={{ marginTop: "5px", marginLeft: "0px" }}
          >
            <Col>
              <Row>
                <input
                  type="text"
                  name="recipient"
                  // value={props.currentWorkflowStage === '5' ? props.rdApproverName : recipient}
                  className={`dsf-RPM-textField input-sendTo ${
                    validationMessage.length > 0
                      ? "errorBorder"
                      : "successBorder"
                  }`}
                  // disabled={!isReceipientEnable}
                  // onChange={(e) => handleRecipientChange(e)}
                  onBlur={(e) => handleRecipientValueOnEnter(e)}
                  // onKeyDown={(e) => handleRecipientValueOnEnter(e)}
                />
              </Row>
              <Row>
                <span className="dsf-size11 dsf-font-weight-400 errorMsg">
                  {validationMessage}
                </span>
              </Row>
            </Col>
            <Col className="bw-checkinnoflex-datepicker review-action-datepicker"></Col>
          </Row>
          <Row
            className="scenario-action-div"
            style={{ marginTop: "15px", marginLeft: "1px", cursor: "pointer" }}
          >
            <span className="dsf-size12 dsf-font-weight-400">Action</span>
            {/* {recipient !== '' &&  */}
            <AddIcon
              style={{ color: recipient !== "" ? "#EDF1F3" : "#797775" }}
              onClick={() => onClickAddActionDetailsForUser()}
            />
            {/* } */}
          </Row>
          <Row
            className="scenario-action-div actionList "
            style={{ marginTop: "5px" }}
          >
            <ul className="actionList-ul">
              {actionListForSelectedUser.map((item, index) => {
                return (
                  <li key={index} className="actionList-li">
                    <Row className="actionListRow">
                      <Col className="col-basis-60">
                        <input
                          type="text"
                          name="action"
                          onChange={(e) => handleActionValueOnEnter(e, item)}
                          className="dsf-RPM-textField input-sendTo input-action dsf-size12 dsf-font-weight-400"
                          onBlur={(e) => handleActionValueOnEnter(e, item)}
                          value={item.actionDetails}
                        />
                      </Col>
                      <Col className="col-basis-15 picker-Position">
                        <InnoflexDatePicker
                          className="dsf-RPM-textField input-deadline"
                          launchDate={item.deadline}
                          isValueNull={true}
                          onDatePickerChange={(value, isNull, date) =>
                            handleDatePickerChange(value, isNull, date, item)
                          }
                        />
                      </Col>
                      <Col className="col-basis-5">
                        {item.isNew && (
                          <DeleteOutlineIcon
                            style={{ marginLeft: "8px", color: "#EC6157" }}
                            onClick={() => onDeleteClicked(item)}
                          />
                        )}
                      </Col>
                    </Row>
                  </li>
                );
              })}
            </ul>
          </Row>
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer scenario-action-footer-div ">
          <Button
            className="scenario-upload-footer-btn"
            variant="secondary"
            onClick={() => closeModal()}
            disabled={false}
          >
            Cancel
          </Button>
          <Button
            className="scenario-upload-footer-btn"
            variant="secondary"
            onClick={() => handleOkActionBtnOnClick()}
            disabled={isOkBtnDisable}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ScenaioAddEdit;
