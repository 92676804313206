import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

import "./menu.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import SortIcon from "@mui/icons-material/Sort";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";

import { sortNotificationEnum } from "../../globalConstants";
import TickMark from "../../assets/images/tick.svg";
import closeIcon from "../../assets/images/Vector-cross.svg";

export default function MenuBar(props) {
  const [sortWidgetOpen, setSortWidgetOpen] = useState(false);
  const [activeSortFunc, setActiveSortFunc] = useState("");
  const [sortData, setSortData] = useState("desc");
  const sortDivRef = useRef(null);
  // let rowData = props.rowData;

  const onSortBtnClicked = () => {
    setSortWidgetOpen(!sortWidgetOpen);
  };
  const onCloseBtnClicked = () => {
    props.toggleDrawer(false);
  };

  useEffect(() => {
    changeActiveSort("Time - newest first");

    function handleClickOutside(event) {
      if (sortDivRef.current && !sortDivRef.current.contains(event.target)) {
        setSortWidgetOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sortDivRef]);

  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: "#FF544F",
      color: "#FF544F",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const prepareSortWindow = () => {
    return (
      <>
        <div
          ref={sortDivRef}
          className={"modal-sort-window"}
          style={{
            display: sortWidgetOpen ? "block" : "none",
            marginLeft: "20px",
            marginTop: "-29px",
          }}
        >
          <span
            className="dsf-size8 dsf-font-weight-400"
            style={{ color: "#cfcfcf" }}
          >
            Sort by
          </span>
          <ul>
            {Object.values(sortNotificationEnum).map((item, index) => {
              return (
                <li key={index} onClick={() => changeActiveSort(item)}>
                  <span className="dsf-size12 dsf-font-weight-600">
                    {" "}
                    {item}{" "}
                  </span>
                  {item === activeSortFunc && (
                    <img
                      src={TickMark}
                      style={{
                        marginLeft:
                          index === 0 || index === 1 ? "2rem" : "6rem",
                      }}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      </>
    );
  };

  // const sortTime = (sort)=>{

  //   if(sort=="asc"){
  //     let asc = props.rowData.sort((a,b)=> new Date(a.notificationCreatedOn) - new Date(b.notificationCreatedOn))
  //    setRowDatas(asc)

  //   } else if (sort=="desc"){

  //     let desc = props.rowData.sort((a,b)=> new Date(b.notificationCreatedOn) - new Date(a.notificationCreatedOn))
  //     setRowDatas(desc)

  //   } else if (sort=="proj"){

  //    let proj = props.rowData.sort((a,b)=> a.notificationProjectName.localeCompare(b.notificationProjectName))
  //    setRowDatas(proj)
  //   } else {
  //     setRowDatas([])
  //   }

  // }

  const changeActiveSort = async (item) => {
    setActiveSortFunc(item);
    setSortWidgetOpen(false);
    switch (item) {
      case "Time - newest first":
        setSortData("desc");
        props.getNotificationData("desc");
        break;
      case "Time - oldest first":
        setSortData("asc");
        props.getNotificationData("asc");
        break;
      case "Group by - project":
        setSortData("proj");
        props.getNotificationData("proj");
        // sortTime("proj");
        break;
    }
  };

  const list = () => (
    <Box
      sx={{ width: 302 }}
      role="presentation"
      className="notification-menu-container"
    >
      <Grid
        container
        spacing={2}
        className="notification-menu-heading-container"
      >
        <Grid item xs={10}>
          <span className="notifications-title-span">Notifications</span>
          <SortIcon
            style={{
              color: "#EDF1F3",
              width: "14px",
              height: "14px",
              marginLeft: "5px",
            }}
            onClick={() => onSortBtnClicked()}
          />
        </Grid>
        <Grid item xs={2}>
          <img
            src={closeIcon}
            alt="Close-Image"
            onClick={props.toggleDrawer(false)}
            style={{ cursor: "pointer" }}
          />
        </Grid>
      </Grid>
      {sortWidgetOpen && prepareSortWindow()}

      <Divider sx={{ bgcolor: "#EDF1F3" }} />
      <List>
        {props.rowData?.map((item) => (
          <>
            {item.isSeen !== 1 ? (
              <ListItem key={item.notificationID} disablePadding>
                <ListItemButton
                  onClick={() => {
                    props.updateStatus(item, sortData);
                  }}
                >
                  <ListItemIcon>
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "top", horizontal: "left" }}
                      variant="dot"
                    >
                      <Avatar sx={{ width: 25, height: 25 }}>
                        {item.notificationFromByUserInitial}
                      </Avatar>
                    </StyledBadge>
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        color: "#CFCFCF",
                        fontSize: "12px",
                        fontFamily: "Unilever Shilling",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      },
                    }}
                    primary={
                      <React.Fragment>
                        <Typography className="notifications-highlight-span">
                          {item.notificationProjectName}
                        </Typography>{" "}
                        {item.notificationMessage} is created by{" "}
                        <Typography className="notifications-highlight-span">
                          {item.notificationFrom}
                        </Typography>
                      </React.Fragment>
                    }
                    secondaryTypographyProps={{
                      style: {
                        color: "#CFCFCF",
                        fontSize: "10px",
                        fontFamily: "Unilever Shilling",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      },
                    }}
                    secondary={
                      new Date(
                        item.notificationCreatedOn
                      ).toLocaleDateString() +
                      " . " +
                      new Date(item.notificationCreatedOn).toLocaleTimeString()
                    }
                  />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem key={item.notificationID} disablePadding>
                <ListItemButton
                  onClick={() => {
                    props.updateStatus(item, sortData);
                  }}
                >
                  <ListItemIcon>
                    <Avatar sx={{ width: 25, height: 25 }}>
                      {item.notificationFromByUserInitial}
                    </Avatar>
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{
                      style: {
                        color: "#CFCFCF",
                        fontSize: "12px",
                        fontFamily: "Unilever Shilling",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      },
                    }}
                    primary={
                      <React.Fragment>
                        <Typography className="notifications-highlight-span">
                          {item.notificationProjectName}
                        </Typography>{" "}
                        {item.notificationMessage} is created by{" "}
                        <Typography className="notifications-highlight-span">
                          {item.notificationFrom}
                        </Typography>
                      </React.Fragment>
                    }
                    secondaryTypographyProps={{
                      style: {
                        color: "#CFCFCF",
                        fontSize: "10px",
                        fontFamily: "Unilever Shilling",
                        fontWeight: "400",
                        lineHeight: "16px",
                        letterSpacing: "0em",
                        textAlign: "left",
                      },
                    }}
                    secondary={
                      new Date(
                        item.notificationCreatedOn
                      ).toLocaleDateString() +
                      " . " +
                      new Date(item.notificationCreatedOn).toLocaleTimeString()
                    }
                  />
                </ListItemButton>
              </ListItem>
            )}
            <Divider sx={{ bgcolor: "#EDF1F3" }} />
          </>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        <SwipeableDrawer
          anchor="right"
          open={props.openMenu}
          // onClose={props.toggleDrawer(false)}
          onOpen={props.toggleDrawer(true)}
          PaperProps={{
            sx: {
              backgroundColor: "#1E232F",
            },
          }}
        >
          {list()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
