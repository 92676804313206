import { ApiHelper, configByEnv } from "./APIHelper";

const config = configByEnv();

export const fetchProjectInfo = async (projectId) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetProjectsummary?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const fetchProjectWorkFlowStatus = async (projectId) => {
  try {
    // https://bieno-da12-d-930072-webapi-02.azurewebsites.net/ProjectDetails/GetWorkfowStatusForProject?strProjectName=BRAMASTRA%202_ID
    const url =
      config.baseURL +
      "/ProjectDetails/GetWorkflowStatusForProject?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

//Innoflex api call start
export const fetchInnoflex = async (projectId) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetCheckInnoflexDetails?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveInnoflexData = async (payload) => {
  try {
    const url = config.baseURL + "/ProjectDetails/UpdateCheckInnoflexDetails";
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
//Innoflex api call end

//DelistBOM api call start
export const fetchDelistBOMCountry = async (projectId) => {
  try {
    const url =
      config.baseURL + "/DelistBOM/GetCountryDetails?projectId=" + projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDelistBOMData = async (
  projectId,
  country,
  searchText,
  launchType
) => {
  try {
    const url =
      config.baseURL +
      `/DelistBOM/GetDelistbomForCountry?projectId=${projectId}&Country=${country}&search=${searchText}&launchType=${launchType}`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const saveDelistBOMData = async (projectId, payload) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/UpdateDelistbomDetails?projectId=${projectId}`;
    const response = await ApiHelper.post(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const fetchDelistBOMFGData = async (projectId, payload) => {
  try {
    const url =
      config.baseURL +
      `/DelistBOM/GetFinishedGoodsForSKU?projectId=${projectId}`;
    const response = await ApiHelper.post(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

//DelistBOM api call end

//Check Delist api call start
export const fetchCheckDelists = async (projectId) => {
  try {
    const url =
      config.baseURL + "/ProjectDetails/GetCheckDelist?projectId=" + projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const saveCheckDelistData = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/UpdateCheckDelist?projectId=${projectId}`;
    const response = await ApiHelper.post(url, "");
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
//Check Delist api call end

//Unique RPM start
export const fetchUniqueRPMData = async (projectId) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetUniqueRMPMDetails?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const saveUniqueRPMData = async (
  payload,
  isReceipientEnable,
  isInventoryCMSkipped
) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/UpdateUniqueRMPMDetails?isRndRequired=${isReceipientEnable}&isInventorySkip=${isInventoryCMSkipped}`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const saveUniqueRPMRecipients = async (payload) => {
  try {
    const url = config.baseURL + `/ProjectDetails/UpdateUniqueRMPMRecipients`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
//Unique RPM end

//MDM Ops start
export const fetchMDMOpsData = async (projectId, isRnD) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetSendToCG?projectId=" +
      projectId +
      "&isRnD=" +
      isRnD;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const saveMDMOpsData = async (payload) => {
  try {
    const url = config.baseURL + `/ProjectDetails/UpdateSendToCG`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveRNDSignoffData = async (projectId, payload) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/UpdateRNDSignOff?projectId=${projectId}`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDepartmentMappingDetails = async () => {
  try {
    const url = config.baseURL + `/ProjectDetails/GetDepartmentMappingDetails`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveDepartmentMappingDetails = async (payload) => {
  try {
    const url =
      config.baseURL + `/ProjectDetails/UpdateDepartmentMappingDetails`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const UploadAdminLWDDetails = async (payload, departmentName) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/UploadAdminLWDDetails?departmentName=${departmentName}`;
    const response = await ApiHelper.postFileType(url, payload);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchLWDFileDownload = async (departmentName) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/DownloadLWDFile?departmentName=${departmentName}`;
    const response = await ApiHelper.postFileType(url, {}, {}, "blob");
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const ResetLWDFile = async (departmentName) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/ResetLWDFile?departmentName=${departmentName}`;
    const response = await ApiHelper.postFileType(url);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchProjectMappingDetails = async () => {
  try {
    const url = config.baseURL + `/ProjectDetails/GetProjectMember`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveProjectMappingDetails = async (payload) => {
  try {
    const url =
      config.baseURL + `/ProjectDetails/UpdateCheckProjectDetailsForAdmin`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchE2EDetails = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/GetE2EProjectDetails?projectId=${projectId}`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveE2EDetails = async (payload) => {
  try {
    const url = config.baseURL + `/ProjectDetails/UpdateE2EPlanner`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchInventoryCMDetails = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/InventoryAtCM/GetInventoryAtCMDetails?projectId=${projectId}`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const sendToCMOps = async (payload, validateFGDetails) => {
  try {
    const url =
      config.baseURL +
      `/InventoryAtCM/UpdateSendToCMOps?validateFGDetails=${validateFGDetails}`;
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveInventoryCMDetails = async (projectId, isInventorySkip) => {
  try {
    const url =
      config.baseURL +
      `/InventoryAtCM/UpdateInventoryCMDetails?projectId=${projectId}&isInventorySkip=${isInventorySkip}`;
    const response = await ApiHelper.put(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const resetInventoryCMDetails = async (supplierCode, projectId) => {
  try {
    const url =
      config.baseURL +
      `/InventoryAtCM/ResetInventoryDetails?supplierCode=${supplierCode}&projectId=${projectId}`;
    const response = await ApiHelper.put(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const uploadDelistFile = async (
  supplierCode,
  projectId,
  payload,
  isRPMSupplier
) => {
  try {
    const url =
      config.baseURL +
      `/InventoryAtCM/UploadInventoryFile?supplierCode=${supplierCode}&projectId=${projectId}&isRPMSupplier=${isRPMSupplier}`;
    const response = await ApiHelper.postFileType(url, payload);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchSnapshotDetails = async (projectId) => {
  try {
    const url =
      config.baseURL + `/Scenario/GetSnapshotDetails?projectId=${projectId}`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchReviewAction = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/GetActionsTracker?projectId=${projectId}`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDelistingProjectDetails = async () => {
  try {
    const url = config.baseURL + `/ProjectDetails/GetClassificationDetails`;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const UpdateActionStatus = async (payload) => {
  try {
    const url = config.baseURL + "/ProjectDetails/UpdateActionStatus";
    const response = await ApiHelper.put(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveDelistingProjectDetails = async (payload) => {
  try {
    const url = config.baseURL + `/ProjectDetails/AddNewProject`;
    const response = await ApiHelper.post(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const updateDelistingProjectDetails = async (payload) => {
  try {
    const url = config.baseURL + `/ProjectDetails/UpdateProjectSummaryDetails`;
    const response = await ApiHelper.post(url, payload);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const resetWorkflowDetails = async (projectId, workflowId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/ResetWorkflow?projectId=${projectId}&resetWorkflowId=${workflowId}`;
    const response = await ApiHelper.post(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDeepDiveValueDefault = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/GetSystemBWDetailsDefault?projectId=` +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDeepDiveValue = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/GetSystemBWDetails?projectId=` +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDeepDiveVolumeDefault = async (projectId) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetSystemBWDetailsVolumeDefault?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDeepDiveVolume = async (projectId) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetSystemBWDetailsVolume?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDeepDiveDetailedTable = async (projectId) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetSystemBWDetailedView?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchDeepDiveDetailedTableVolume = async (projectId) => {
  try {
    const url =
      config.baseURL +
      "/ProjectDetails/GetSystemBWDetailedViewVolume?projectId=" +
      projectId;
    const response = await ApiHelper.get(url);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
