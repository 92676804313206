import { ApiHelper ,configByEnv} from "./APIHelper";
import axios from "axios";

const config = configByEnv();

export const uploadSKUFile = async (loggedInUser, projectName, payload) => {
  try {
    
    const url = config.baseURL + `/ProjectDetails/UploadFile?loggedInUser=${loggedInUser}&projectName=${projectName}`;
    const response = await ApiHelper.postFileType(url,payload)
    return {
      "status":response.status,
      "data":response.data
    };
  } catch (e) {
    console.log("error", e);
  }
}


export const fetchSKUCodeAndNames = async (projectName, searchText) => {
  try {
    
    const url = config.baseURL + `/ProjectDetails/GetDelistbom?strProjectName=${projectName}&search=${searchText}`;
    const response = await ApiHelper.get(url)
    return {
      "status":response.status,
      "data":response.data
    };
  } catch (e) {
    console.log("error", e);
  }
}

export const updateSKUCodeAndNames = async (projectName, loggedInUser, payload) => {
  try {
    
    const url = config.baseURL + `/ProjectDetails/UpdateDelistbomDetails?projectName=${projectName}&loggedInUser=${loggedInUser}`;
    const response = await ApiHelper.post(url, payload);
    return {
      "status":response.status,
      "data":response.data
    };
  } catch (e) {
    console.log("error", e);
  }
}


const SKUCodeAndNameService = {
  uploadSKUFile,
  fetchSKUCodeAndNames,
  updateSKUCodeAndNames
};

export default SKUCodeAndNameService;
