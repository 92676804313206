import logo from './logo.svg';
import './App.css';
import "./assets/fonts/UnileverShilling.ttf";
import "./assets/fonts/UnileverShillingBold.ttf";
import "./assets/fonts/UnileverShillingItalic.ttf";
import "./assets/fonts/UnileverShillingMedium.ttf";
import { AuthenticatedTemplate, UnauthenticatedTemplate,MsalProvider } from '@azure/msal-react';


import Routes from './routes/routes';

function App({pca}) {
  return (
    <MsalProvider instance={pca}>
      <Routes />
    </MsalProvider>
  );
}
export default App;