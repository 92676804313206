import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Row, Col } from "react-bootstrap";
import ModalReviewSendMail from "./modalReviewSendMail";
import ActivityIndicator from "../../../../components/Loader/loader";
import CustomDropdown from "../../../../components/Dropdown/dropdown";
import CustomDropdownAdd from "../../../../components/Dropdown/customDropdown";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import mailSent from "../../../../assets/images/actionCompleted.svg";
import actionEdit from "../../../../assets/images/actionEdit.svg";
import actionMinus from "../../../../assets/images/actionMinus.svg";
import mailNotSent from "../../../../assets/images/actionPending.svg";
import actionPlus from "../../../../assets/images/actionPlus.svg";
import InnoflexDatePicker from "../../../../components/DatePicker/datePicker";
import {
  dateTimeFormat,
  checkNullDataByObjInArray,
  validateUnileverMailId,
} from "../../../../utils";
import { functionRoleType } from "../../../../globalConstants";
import UserDetailsService from "../../../../service/userDetailsService";
import { saveScenarioAction } from "../../../../service/scenarioPlannerService";
import "./modalAddSendAction.css";

const ModalAddSendAction = (props) => {
  const {
    showModal,
    setShowModal,
    requiredActionData,
    selectedProject,
    e2ePlannerName,
  } = props;
  const [isLoadingDatagrid, setIsLoadingDatagrid] = useState(true);
  const [rowInitialData, setRowInitialData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [rowModifiedData, setRowModifiedData] = useState([]);
  const [newRowData, setNewRowData] = useState({});
  const [addNewRow, setAddNewRow] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [showReviewSendMailModal, setShowReviewSendMailModal] = useState(false);
  const [currentItem, setCurrentItem] = useState("");
  const [currentItemIndex, setCurrentItemIndex] = useState("");
  const [changedData, setChangedData] = useState([]);
  const [suggestedActionData, setSuggestedActionData] = useState([]);
  const [okBtnEnable, setOkBtnEnable] = useState(false);
  const [addUserName, setAddUserName] = useState("");
  const [addDeadline, setAddDeadline] = useState("");
  const [dataCheck, setDataCheck] = useState("");

  const userDetails = useSelector((state) => state.userDetails);

  useEffect(() => {
    if (requiredActionData && requiredActionData.length > 0) {
      const updatedData = requiredActionData.map((item, index) => {
        item.isEditable = false;
        item.isModified = false;
        return item;
      });
      setRowData(updatedData);
      setRowInitialData(updatedData);
      setRowModifiedData(updatedData);
      setIsLoadingDatagrid(false);
    } else {
      setRowData([]);
      setRowModifiedData([]);
      setRowInitialData([]);
    }
    setIsLoadingDatagrid(false);
  }, []);

  useEffect(() => {
    const checkData = [...rowData];
    if (checkData && checkData.length > 0) {
      const filteredData = checkData.filter(
        (item) =>
          item.functionName === "" &&
          item.contact === "" &&
          item.deadline === "" &&
          item.isAutomated
      );
      let dropdownData = [];

      if (filteredData.length > 0) {
        filteredData.map((item) => {
          dropdownData.push({
            label: item.actionDetails,
            value: item.actionDetails,
          });
        });
      }
      setSuggestedActionData(dropdownData);
      const updatedData = checkData.filter(
        (item) =>
          item.functionName.length > 0 &&
          item.actionDetails.length > 0 &&
          item.contact.length > 0 &&
          item.deadline.length > 0
      );
      setDataCheck(updatedData);
      if (updatedData.length > 0) {
        setOkBtnEnable(true);
      } else {
        setOkBtnEnable(false);
      }
    } else {
      setOkBtnEnable(false);
    }
  }, [rowData]);

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setValidationMessage("");
      setAddUserName(recepientMailId);
    } else {
      setValidationMessage("The Unilever mail id does not exist");
    }
  };

  const closeModal = () => {
    setShowModal(false);
    // props.onComplete(false);
  };

  const onHandleSuggestedActions = () => {
    setAddDeadline("");
    setAddUserName("");
    let objPayload = {};
    objPayload.actionId = 0;
    objPayload.projectId = selectedProject.projectId;
    objPayload.projectName = selectedProject.projectName;
    objPayload.functionName = "";
    objPayload.roleName = userDetails.roleName;
    objPayload.actionName = "";
    objPayload.actionDetails = "";
    objPayload.materialNumber = "";
    objPayload.materialDescription = "";
    objPayload.materialType = "";
    objPayload.contact = "";
    objPayload.deadline = "";
    objPayload.deadlineStatus = "";
    objPayload.status = false;
    objPayload.isAutomated = false;
    objPayload.isMailSent = false;
    objPayload.isEditable = false;
    objPayload.isModified = false;
    setNewRowData(objPayload);
    setAddNewRow(!addNewRow);
  };

  const handleDatePickerChange = (
    value,
    isNull,
    objInnoflexCountryItem,
    date,
    index
  ) => {
    const updatedData = rowModifiedData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          deadline: dateTimeFormat(date, "MM/DD/YYYY"),
          isModified: true,
        };
      } else {
        return item;
      }
    });
    setRowModifiedData(updatedData);
  };

  const handleActionChange = (e, index) => {
    const updatedData = rowModifiedData.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          actionDetails: e.target.value,
          isModified: true,
        };
      } else {
        return item;
      }
    });
    setRowModifiedData(updatedData);
  };

  const handleEdit = (index) => {
    const updatedRowData = rowData.map((item, i) => {
      if (i === index) {
        return { ...item, isEditable: true };
      } else {
        return item;
      }
    });
    setRowData(updatedRowData);
  };

  const handleSave = (index) => {
    const updatedRowData = rowData.map((item, i) => {
      if (i === index) {
        let isDataSame = false;
        if (rowModifiedData[index].actionId !== 0) {
          isDataSame =
            rowModifiedData[index].actionDetails ===
              rowInitialData[index].actionDetails &&
            dateTimeFormat(rowModifiedData[index].deadline, "MM/DD/YYYY") ===
              dateTimeFormat(rowInitialData[index].deadline, "MM/DD/YYYY");
        }

        if (!isDataSame) {
          return {
            ...item,
            isEditable: false,
            deadline: rowModifiedData[index].deadline,
            actionDetails: !item.isAutomated
              ? rowModifiedData[index].actionDetails
              : item.actionDetails,
            isModified: rowModifiedData[index].isModified,
            isMailSent: false,
          };
        } else {
          return {
            ...item,
            isEditable: false,
            isModified: false,
          };
        }
      } else {
        return item;
      }
    });
    setRowData(updatedRowData);
  };

  const handleCancel = (index) => {
    const updatedInitialData = rowData
      .map((item, i) => {
        if (i === index) {
          if (item.actionId === 0) {
            return null;
          } else {
            if (item.isAutomated && !item.isEditable) {
              return {
                ...item,
                isEditable: false,
                isModified: false,
                contact: "",
                deadline: "",
                functionName: "",
              };
            }
            return { ...item, isEditable: false };
          }
        } else {
          return item;
        }
      })
      .filter((item) => item !== null);
    setRowData(updatedInitialData);

    const updatedRowModifiedData = rowModifiedData
      .map((item, i) => {
        if (i === index) {
          if (item.actionId === 0) {
            return null;
          } else {
            return {
              ...item,
              deadline: dateTimeFormat(rowData[index].deadline),
              actionDetails: !item.isAutomated
                ? rowData[index].actionDetails
                : item.actionDetails,
            };
          }
        } else {
          return item;
        }
      })
      .filter((item) => item !== null);

    setRowModifiedData(updatedRowModifiedData);
  };

  const handleDropdownActionChange = (value, action) => {
    let updatedData = { ...newRowData };
    updatedData.actionDetails = value;
    if (action === "dropdown") {
      updatedData.isAutomated = true;
    } else {
      updatedData.isAutomated = false;
    }
    setNewRowData(updatedData);
  };

  const handleDropdownFunctionChange = (value) => {
    let updatedData = { ...newRowData };
    updatedData.functionName = value;
    setNewRowData(updatedData);
  };

  const handleRecipientValueOnEnter = (event) => {
    if (validateUnileverMailId(event.target.value)) {
      setValidationMessage("");
      setAddUserName(event.target.value);
      getUserDetails(event.target.value);
    } else {
      setValidationMessage("Please provide valid Unilever Mail id");
    }
  };

  const handleActionInputChange = (event) => {
    let updatedData = { ...newRowData };
    updatedData.actionDetails = event.target.value;
    setNewRowData(updatedData);
  };

  const handleAddDatePickerChange = (
    value,
    isNull,
    objInnoflexCountryItem,
    date,
    index
  ) => {
    setAddDeadline(dateTimeFormat(date, "MM/DD/YYYY"));
  };

  const handleAddRowSave = () => {
    let updatedData = [...rowData];
    let updatedRowData = { ...newRowData };
    updatedRowData.contact = addUserName;
    updatedRowData.deadline = addDeadline;
    if (newRowData.isAutomated) {
      const index = updatedData.findIndex(
        (item) => item.actionDetails === newRowData.actionDetails
      );
      updatedRowData.actionId = updatedData[index].actionId;
      updatedRowData.isModified = true;
      updatedRowData.isEditable = false;
      updatedData[index] = updatedRowData;
    } else {
      updatedRowData.isModified = true;
      updatedRowData.isEditable = false;
      updatedData.push(updatedRowData);
    }
    setRowData(updatedData);
    setRowModifiedData(updatedData);
    setAddNewRow(false);
  };

  const handleAddRowCancel = () => {
    setAddNewRow(false);
  };

  const handleReviewMailSend = async (response) => {
    const checkData = [...response];

    if (currentItem.actionId !== 0) {
      const updatedData = rowData.map((item) => {
        const found = checkData.find(
          (element) =>
            element.actionId === item.actionId &&
            element.actionId === currentItem.actionId
        );
        if (found) {
          return {
            ...item,
            isMailSent: true,
          };
        } else {
          return item;
        }
      });
      setRowData(updatedData);
      setRowModifiedData(updatedData);
    } else {
      const selectedItem = rowData[currentItemIndex];
      let updatedData = [...rowData];

      const responseIndex = checkData.findIndex(
        (item) =>
          item.actionDetails === selectedItem.actionDetails &&
          item.functionName === selectedItem.functionName &&
          item.contact === selectedItem.contact &&
          dateTimeFormat(item.deadline, "MM/DD/YYYY") === selectedItem.deadline
      );

      updatedData[currentItemIndex] = checkData[responseIndex];

      setRowData(updatedData);
      setRowModifiedData(updatedData);
    }
    setChangedData(response);
  };

  const handleOkButton = async () => {
    let updatedData = [...rowData];

    updatedData = updatedData.map((item) => {
      const { isModified, isEditable, deadlineStatus, ...rest } = item;
      return rest;
    });

    updatedData = updatedData.filter(
      (item) =>
        item.functionName.length > 0 &&
        item.actionDetails.length > 0 &&
        item.contact.length > 0 &&
        item.deadline.length > 0
    );
    const response = await saveScenarioAction(
      updatedData,
      selectedProject.projectId
    );
    if (response.status === 200) {
      props.onUpdate(true, response.data);
    } else {
      console.log("error", response);
    }
    closeModal();
  };

  return (
    <>
      <Modal
        centered
        contentClassName="bwi-modal-add-review-action"
        dialogClassName="bwi-dialog-add-review-action"
        show={showModal}
      >
        <Modal.Header className="bwi-modal-add-review-action-header">
          <div className="bwi-modal-unique-rpm-header-title">
            <span className="bwi-modal-title dsf-span-ml">
              Add Actions, Assign Owners and Send
            </span>
            <br />
            <span className="dsf-span-s">
              For this scenario, assign the task to action owners with the
              deadlines, review and send.
              <br />
              You can also update any action and resend to the same or different
              action owner.
            </span>
          </div>
          <img
            src={closeIcon}
            alt="Close-Image"
            onClick={() => {
              closeModal();
              props.onUpdate(
                true,
                changedData.length > 0 ? changedData : rowInitialData
              );
            }}
          />
        </Modal.Header>
        <Modal.Body className="bwi-modal-add-review-action-body">
          <div className="bwi-unique-rpm-table">
            <div className="bwi-add-review-action-content">
              {isLoadingDatagrid ? (
                <ActivityIndicator />
              ) : (
                <>
                  <div className="bwi-add-review-action-data">
                    <div className="bwi-add-review-action-table">
                      <Row className="bwi-add-review-action-table-header-row">
                        <Col
                          xs={2}
                          className="bwi-add-review-action-header-col"
                        >
                          Function
                        </Col>
                        <Col
                          xs={3}
                          className="bwi-add-review-action-header-col"
                        >
                          Action
                        </Col>
                        <Col
                          xs={2}
                          className="bwi-add-review-action-header-col"
                        >
                          Assigned to
                        </Col>
                        <Col
                          xs={2}
                          className="bwi-add-review-action-header-col"
                          style={{ paddingLeft: "38px" }}
                        >
                          Deadline
                        </Col>
                        <Col
                          xs={1}
                          className="bwi-add-review-action-header-col"
                        >
                          Mail Status
                        </Col>
                        <Col
                          xs={1}
                          className="bwi-add-review-action-header-col"
                        ></Col>
                        <Col
                          xs={1}
                          className="bwi-add-review-action-header-col"
                        ></Col>
                      </Row>
                      <div className="bwi-add-review-action-table-row-data">
                        {rowData &&
                        rowData.length > 0 &&
                        dataCheck &&
                        dataCheck.length > 0
                          ? rowData.map((action, index) => {
                              return (
                                (!action.isAutomated ||
                                  action.functionName.length > 0) && (
                                  <Row className="bwi-add-review-action-table-row">
                                    <Col
                                      xs={2}
                                      className="bwi-add-review-action-table-col"
                                    >
                                      {action.functionName}
                                    </Col>
                                    <Col
                                      xs={3}
                                      className="bwi-add-review-action-table-col"
                                    >
                                      {action.isEditable ? (
                                        action.isAutomated ? (
                                          action.actionDetails
                                        ) : (
                                          <input
                                            type="text"
                                            value={
                                              rowModifiedData[index]
                                                .actionDetails
                                            }
                                            className="bwi-add-review-action-input"
                                            onChange={(e) => {
                                              handleActionChange(e, index);
                                            }}
                                          />
                                        )
                                      ) : (
                                        action.actionDetails
                                      )}
                                    </Col>
                                    <Col
                                      xs={2}
                                      className="bwi-add-review-action-table-col"
                                    >
                                      {action.contact}
                                    </Col>
                                    <Col
                                      xs={2}
                                      className="bwi-add-review-action-table-col-date"
                                      //apply style if action is editable
                                      style={
                                        !action.isEditable
                                          ? { Opacity: "0.8" }
                                          : {}
                                      }
                                    >
                                      <InnoflexDatePicker
                                        key={`${rowModifiedData[index].actionId}-date-picker-${rowModifiedData[index].deadline}`}
                                        launchDate={
                                          action.isEditable
                                            ? rowModifiedData[index].deadline
                                            : action.deadline
                                        }
                                        isValueNull={
                                          action.isEditable ? true : false
                                        }
                                        onDatePickerChange={(
                                          value,
                                          isNull,
                                          date
                                        ) =>
                                          handleDatePickerChange(
                                            value,
                                            isNull,
                                            action,
                                            date,
                                            index
                                          )
                                        }
                                      />
                                    </Col>
                                    <Col
                                      xs={1}
                                      className="bwi-add-review-action-table-col"
                                    >
                                      {action.isEditable ? (
                                        "-"
                                      ) : action.isMailSent &&
                                        !action.isModified ? (
                                        <div>
                                          <img
                                            src={mailSent}
                                            alt="Close-Image"
                                            className="bwi-action-mail-status-img"
                                          />{" "}
                                          Sent{" "}
                                        </div>
                                      ) : (
                                        <div>
                                          <img
                                            src={mailNotSent}
                                            alt="Close-Image"
                                            className="bwi-action-mail-status-img"
                                          />{" "}
                                          Not Sent{" "}
                                        </div>
                                      )}
                                    </Col>
                                    <Col
                                      xs={1}
                                      className="bwi-add-review-action-table-col bw-modal-footer bwi-add-review-action-select-col"
                                    >
                                      <div>
                                        {action.isEditable ? (
                                          <img
                                            src={actionPlus}
                                            alt="Action-Add-Image"
                                            className="bwi-action-add-img"
                                            onClick={() => handleSave(index)}
                                          />
                                        ) : (
                                          <img
                                            src={actionEdit}
                                            alt="Action-Edit-Image"
                                            className="bwi-action-edit-img"
                                            onClick={() => handleEdit(index)}
                                          />
                                        )}
                                      </div>
                                      <div>
                                        {(action.isEditable ||
                                          action.actionId === 0 ||
                                          (action.isAutomated &&
                                            action.isModified)) && (
                                          <img
                                            src={actionMinus}
                                            alt="Action-Minus-Image"
                                            className="bwi-action-minus-img"
                                            onClick={() => handleCancel(index)}
                                          />
                                        )}
                                      </div>
                                    </Col>
                                    <Col
                                      xs={1}
                                      className="bwi-add-review-action-table-col bw-modal-footer"
                                    >
                                      {(!action.isMailSent ||
                                        action.isModified) && (
                                        <Button
                                          className="bw-delist-footer-btn dsf-footer-okbutton-PI"
                                          variant="secondary"
                                          onClick={() => {
                                            setShowReviewSendMailModal(true);
                                            setCurrentItem(action);
                                            setCurrentItemIndex(index);
                                          }}
                                          disabled={action.isEditable}
                                        >
                                          Send Email
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                )
                              );
                            })
                          : !addNewRow && (
                              <div className="no-data-found">
                                No Actions Assigned
                              </div>
                            )}
                        {addNewRow && (
                          <Row className="bwi-add-review-action-table-row">
                            <Col
                              xs={2}
                              className="bwi-add-review-action-table-col"
                            >
                              <CustomDropdown
                                data={functionRoleType}
                                styleClass="dsf-checkinnoflex-type"
                                value={""}
                                width="170%"
                                onChange={(value) =>
                                  handleDropdownFunctionChange(value)
                                }
                              />
                            </Col>
                            <Col
                              xs={3}
                              className="bwi-add-review-action-table-col"
                            >
                              {suggestedActionData.length > 0 ? (
                                <CustomDropdownAdd
                                  data={suggestedActionData}
                                  styleClass="dsf-checkinnoflex-type"
                                  value={""}
                                  width="260%"
                                  onChange={(value, action) =>
                                    handleDropdownActionChange(value, action)
                                  }
                                />
                              ) : (
                                <input
                                  type="text"
                                  style={{ width: "280px" }}
                                  className={`bwi-add-review-action-input`}
                                  onChange={(e) => {
                                    handleActionInputChange(e);
                                  }}
                                />
                              )}
                            </Col>
                            <Col
                              xs={2}
                              className="bwi-add-review-action-table-col"
                            >
                              <input
                                type="text"
                                style={{ width: "220px" }}
                                className={`bwi-add-review-action-input ${
                                  validationMessage.length > 0
                                    ? "errorBorder"
                                    : "successBorder"
                                }`}
                                onBlur={(e) => {
                                  handleRecipientValueOnEnter(e);
                                }}
                              />
                              <span className="dsf-size10 dsf-font-weight-400 errorMsg">
                                {validationMessage}
                              </span>
                            </Col>
                            <Col
                              xs={2}
                              className="bwi-add-review-action-table-col-date"
                            >
                              <InnoflexDatePicker
                                key={`add-date-picker-new-row-0`}
                                launchDate={""}
                                isValueNull={true}
                                onDatePickerChange={(value, isNull, date) =>
                                  handleAddDatePickerChange(
                                    value,
                                    isNull,
                                    [],
                                    date,
                                    0
                                  )
                                }
                              />
                            </Col>
                            <Col
                              xs={1}
                              className="bwi-add-review-action-table-col"
                            >
                              -
                            </Col>
                            <Col
                              xs={1}
                              className="bwi-add-review-action-table-col bwi-add-review-action-select-col"
                            >
                              <div>
                                <img
                                  src={actionPlus}
                                  alt="Action-Add-Image"
                                  className={`bwi-action-add-img ${
                                    addUserName.length === 0 ||
                                    addDeadline.length === 0 ||
                                    newRowData.actionDetails.length === 0 ||
                                    newRowData.functionName.length === 0 ||
                                    validationMessage.length > 0
                                      ? "bwi-action-add-img-disabled"
                                      : ""
                                  }`}
                                  onClick={() => {
                                    handleAddRowSave();
                                  }}
                                />
                              </div>
                              <div>
                                <img
                                  src={actionMinus}
                                  alt="Action-Minus-Image"
                                  className="bwi-action-minus-img"
                                  onClick={() => {
                                    handleAddRowCancel();
                                  }}
                                />
                              </div>
                            </Col>
                            <Col
                              xs={1}
                              className="bwi-add-review-action-table-col bw-modal-footer"
                            >
                              <Button
                                className="bw-delist-footer-btn dsf-footer-okbutton-PI"
                                variant="secondary"
                                disabled={true}
                              >
                                Send Email
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="bwi-add-review-action-Assign-btn-div">
                <div className="bwi-add-review-action-button bw-modal-footer">
                  <Button
                    variant="secondary"
                    className="bw-delist-footer-btn dsf-footer-okbutton-PI"
                    onClick={onHandleSuggestedActions}
                    disabled={addNewRow}
                  >
                    Add New Action
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer">
          <Button
            className="bw-footer-cancle-btn"
            variant="secondary"
            onClick={() => {
              closeModal();
              props.onUpdate(
                true,
                changedData.length > 0 ? changedData : rowInitialData
              );
            }}
          >
            Cancel
          </Button>
          <Button
            className="dsf-footer-okbutton-PI"
            variant="secondary"
            onClick={handleOkButton}
            disabled={!okBtnEnable}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      {showReviewSendMailModal && (
        <ModalReviewSendMail
          showModal={showReviewSendMailModal}
          setShowModal={setShowReviewSendMailModal}
          currentItem={currentItem}
          selectedProject={selectedProject}
          e2ePlannerName={e2ePlannerName}
          onChange={handleReviewMailSend}
        />
      )}
    </>
  );
};

export default ModalAddSendAction;
