import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row, Container } from "react-bootstrap";
import icon from "../../../../assets/images/Vector-cross.svg";
import "./modalCountryLaunchDetails.css";

const ModalCountryLaunchDetails = (props) => {
  const { showModal, setShowModal, data } = props;
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const columns = 2;
    const chunkSize = Math.ceil(data.length / columns);
    const chunkedData = [];
    // Split the data into chunks
    for (let i = 0; i < data.length; i += chunkSize) {
      chunkedData.push(data.slice(i, i + chunkSize));
    }
    setCountryData(chunkedData);
  }, [data]);

  return (
    <>
      <Modal
        centered
        dialogClassName="dsf-modal-Country-info"
        contentClassName="dsf-modal-workflowModal"
        show={showModal}
      >
        <Modal.Header className="bw-modal-header">
          <Modal.Title className="dsf-size16">
            Countries & Launch Dates
          </Modal.Title>
          <img
            src={icon}
            alt="Close-Image"
            className="dsf-close-img"
            onClick={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Container className="country-container">
            {countryData.map((chunk, index) => {
              return (
                <Col xs={6} className="country-col space">
                  {chunk.map((item, index) => {
                    return (
                      <Row className="country-row">
                        <Col xs={6} className="country-details">
                          <span>{item.countryName}</span>
                        </Col>
                        <Col xs={6} className="country-details">
                          <span>{item.launchDate}</span>
                        </Col>
                      </Row>
                    );
                  })}
                </Col>
              );
            })}
          </Container>
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer">
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCountryLaunchDetails;
