import { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";

const HorizontalBarChartDesign = (props) => {
    const [barChartData, setBarChartData] = useState([]);
    useEffect(() => {
        let arrBarChartData = [];
        props.chartData && props.chartData.map((item, index) => {
            arrBarChartData.push([
                item.parameterName,
                Math.round(parseFloat(item.value)),
                props.colorCode[index],
                Math.round(parseFloat(item.value)),
            ]);
        });
        setBarChartData(arrBarChartData);
    }, [props.chartData]);

    useEffect(() => {
        GoogleCharts.load(drawMultSeries, {
            packages: ['corechart', 'bar'],
        });
    },[barChartData]);

    const drawMultSeries = () => {
        // const data = new GoogleCharts.api.visualization.arrayToDataTable([
        //     ['Asset name', 'Availability', { role: 'style' }, { role: 'annotation' }],
        //     ...barChartData,
        // ]);

        let data_n = new GoogleCharts.api.visualization.DataTable();
    data_n.addColumn('string', 'Asset name');
    
    // data_n.addColumn({ type: 'string', role: 'annotationText' });
    data_n.addColumn('number', "Availability");
    data_n.addColumn({ type: 'string', role: 'style' });
    data_n.addColumn({ type: 'number', role: 'annotation' });
    data_n.addRows(barChartData);


        // var view = new GoogleCharts.api.visualization.DataView(data);
        // view.setColumns([0, 1,
        //     {
        //         calc: "stringify",
        //         sourceColumn: 1,
        //         type: "string",
        //         role: "annotation"
        //     },
        //     2]);
        /* var data = google.visualization.arrayToDataTable([
           ['Element', 'Density', { role: 'style' }, { role: 'annotation' } ],
           ['Copper', 8.94, '#b87333', 'Cu' ],
           ['Silver', 10.49, 'silver', 'Ag' ],
           ['Gold', 19.30, 'gold', 'Au' ],
           ['Platinum', 21.45, 'color: #e5e4e2', 'Pt' ]
        ]); */
        var options1 = {
            // width: '100%',
            // height: 355, //510 12694
            width: 700,
            height: 232,
            backgroundColor: '#FFF',
            hAxis: {
                viewWindowMode: 'maximized',
                title: 'Time',
                textStyle: { color: '#95A5A6' },
                titleTextStyle: { italic: false },
            },
            // legend: 'none',
            titleTextStyle: {
                color: '#95A5A6',
            },
            enableInteractivity: true,
            chartArea: {
                width: '83%',
                // width: `${this.state.chartWidth}%`,
            },
            animation: {
                startup: false,
                easing: 'linear',
                duration: 5000,
            },
            annotations: {
                /* colors: ['#f07f09'], */
                textStyle: {
                    fontSize: 16,
                    bold: true,
                    // The color of the text.
                    color: '#846CE2',
                    // The color of the text outline.
                    // The transparency of the text.
                    opacity: 1
                },
                stemColor: 'transparent',
                displayDateBarSeparator: false,
                displayExactValues: true,
                // alwaysOutside: true
            },
            interpolateNulls: true,
            hAxis: {
                gridlines: { count: 0 },
                // titleTextStyle:{color: '#FFF'},
                // textStyle:{color: '#FFF'},
                // textPosition: 'none' 
                /* title: 'Total Population', */
                /* minValue: 0 */
            },
            baselineColor: "transparent",
            vAxis: {
                //   textPosition: 'left' ,
                gridlines: { count: 0 },
                //   titleTextStyle:{color: '#FFF'},
                //     textStyle:{color: '#FFF'},
                /* title: 'City' */
            },
        };

        const options = {
          height: "200",
            legend: { position: "none" },
            bar: { groupWidth: "70%" ,}, 
            backgroundColor: props.chartAreaBackgroundColor ?? '#353535',
            chartArea: { width: '100%', height: '50%' },
            // chartArea: {
            //     // top:55,
            //     width: (props.vAxisTextReq) ? '50%' : '50%',
            //     height: '70%'
            // },
            hAxis: {
                gridlines: { count: 0 },
                titleTextStyle: { color: '#FFF' },
                textStyle: { color: '#FFF', fontSize: 12, },
                textPosition: 'none'
                /* title: 'Total Population', */
                /* minValue: 0 */
            },
            vAxis: {
                gridlines: { count: 10 },
                titleTextStyle: { color: '#FFF' },
                textStyle: { color: '#FFF', fontSize: 12, bold: true,align:'left' },
                /* title: 'City' */
                textPosition:"none"
                // textPosition: (props.vAxisTextReq) ? "left" : "left"
            },
            baselineColor: "transparent",
            'tooltip': {
                trigger: 'none'
            },
            annotations: {
                // textPosition: "left",
                /* colors: ['#f07f09'], */
                textStyle: {
                    // position:'left',
                    fontSize: 10,
                    bold: true,
                    // The color of the text.
                    // color: '#846CE2',
                },
                stemColor: 'transparent',
                displayDateBarSeparator: false,
                displayExactValues: true,
                // alwaysOutside: true
            }
        };

        // var chart = new GoogleCharts.api.visualization.BarChart(document.getElementById(divId ??'chart_div'));
        // const container = document.getElementById(props.container);
        // container.innerHTML='';
        // if (container && container.innerHTML.length>0) {
        //     while (container.firstChild) {
        //         container.removeChild(container.firstChild);
        //     }
        // } 
        
        let chart = new GoogleCharts.api.visualization.BarChart(document.getElementById(props.container));
        // chart.draw(view, options);
        
        // GoogleCharts.api.visualization.events.addListener(chart, 'onmouseover', () => {
        //     changeFillColor('#1F2123',props.container); 
        // });
        // GoogleCharts.api.visualization.events.addListener(chart, 'onmouseout', () => {
        //    changeFillColor('#353535',props.container); 
        // });
        GoogleCharts.api.visualization.events.addListener(chart, 'onmousedown', () => {
            
         });
         GoogleCharts.api.visualization.events.addListener(chart, 'onmouseup', () => {
         });
         GoogleCharts.api.visualization.events.addListener(chart, 'ready', () => {
            // changeFillColor('#353535'); 
            // setLastElement(props.container);
         });
        chart.draw(data_n, options);
    }
    const changeFillColor = (colorCode ,container) => {
        const parentDiv = document.getElementById(container);
        // var x = document.getElementById(props.container).getElementsByTagName('svg')[0];
        let svg = parentDiv.getElementsByTagName('svg')[0];
        let rect = svg.getElementsByTagName('rect')[1];
        // console.log(rect.getAttribute('style'));
        if (rect.hasAttributes()) {
            rect.setAttribute(
              'style',
              `fill:${colorCode};`
            );
        }
        // console.log(rect.getAttribute('style'));
        
    }
}
export default HorizontalBarChartDesign;