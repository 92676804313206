// appInsights.js

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { withAITracking,ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { configByEnv } from './service/APIHelper';

// const config = configByEnv();
const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const ai = new ApplicationInsights({
  config: {
    // instrumentationKey: process.env.REACT_APP_APPINSIGHTS_KEY,
    instrumentationKey: configByEnv().instrumentationKey, //'63f889c5-ea74-472b-b939-8b59877d4d20',
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

ai.loadAppInsights();
const setAppInsightAuthenticatedUserId = () =>{
  ai.setAuthenticatedUserContext(JSON.parse(localStorage.getItem("userDetails")).username);
}

// export { reactPlugin, appInsights };
export default (Component) => withAITracking(reactPlugin, Component);
// export const appInsights = ai.appInsights;
const appInsights = ai.appInsights;

export const appInsightsService = {
  appInsights,
  setAppInsightAuthenticatedUserId
};