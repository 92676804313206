import React, { useState, useEffect, useMemo } from "react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./../../dashboardGridTable.css";
import fileDownload from "../../../../assets/images/deepDive_file_download.svg";
import "./detailedAgGridTable.css";
import * as XLSX from "xlsx";

const ModalDetailedTable = (props) => {
  const {
    showModal,
    setShowModal,
    filteredData,
    selectedUom,
    tabselected,
    countrySelected = "",
    selectedProjectId,
  } = props;

  const [activeTab, setActiveTab] = useState("FG");
  const [rowData, setRowData] = useState([]);
  const [resData, setResdata] = useState(props.tableData);
  const [columnDefs, setColumnDefs] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [pinnedBottomRowDataCount, setPinnedBottomRowDataCount] = useState(0);
  const [fileName, setFileName] = useState();
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    getDetaileViewTemplate();
  }, [activeTab]);

  const getDetaileViewTemplate = async () => {
    let fileName;
    if (activeTab === "FG") {
      fileName = "FG.xlsx";
    } else if (activeTab === "RM") {
      fileName = "RM.xlsx";
    } else {
      fileName = "PM.xlsx";
    }
    setFileName(fileName);
  };

  const onDownloadBtnClicked = () => {
    const ws = XLSX.utils.json_to_sheet(rowData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, fileName);
  };

  const FGvariableList = [
    "inventory",
    "demand",
    "plannedProduction",
    "confirmedProduction",
  ];

  const RPMvariableList = [
    "inventory",
    "plannedProduction",
    "confirmedProduction",
    "confirmedPO",
    "plannedPO",
    "extrapolatedPO",
  ];

  const fgTableData = (List, variableList) => {
    const arr = [];
    let filteredUomArr;
    List.forEach((item) => {
      item.weekDetails.sort(
        (a, b) =>
          a.weekNumber.substring(3, 7) - parseInt(b.weekNumber.substring(3, 7))
      );
    });
    for (let i = 0; i < List.length; i++) {
      for (let j = 0; j < variableList.length; j++) {
        {
          const data = {
            "FG Code": List[i].fgCode,
            "FG Name": List[i].fgDescription,
            "DC/SU Code": List[i].plantCode,
            "DC/SU Name": List[i].plantName,
            UoM: List[i].uom === "Euro" ? "€" : List[i].uom,
          };
          for (let k = 0; k < List[i].weekDetails.length; k++) {
            if (Object.keys(List[i].weekDetails[k]).includes(variableList[j]))
              if (j === 0) {
                data["Figure Name"] = "Inventory";
                data[`${List[i].weekDetails[k].weekNumber}`] =
                List[i].weekDetails[k].inventory !== null ? List[i].weekDetails[k].inventory : 0;
              } else if (j === 1) {
                data["Figure Name"] = "Demand";
                data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].demand !== null ? List[i].weekDetails[k].demand : 0;
              } else if (j === 2) {
                data["Figure Name"] = "Planned Production";
                data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].plannedProduction !== null ? List[i].weekDetails[k].plannedProduction : 0;
              } else {
                data["Figure Name"] = "Confirmed Production";
                data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].confirmedProduction !== null ? List[i].weekDetails[k].confirmedProduction : 0;
              }
          }
          arr.push(data);
        }
      }
    }
    if (tabselected === "Volume" && selectedUom !== "") {
      filteredUomArr = arr.filter((item) => item.UoM === selectedUom);
      return filteredUomArr;
    } else {
      return arr;
    }
    // return arr;
  };

  const RPMTableData = (List, variableList, type) => {
    const arr = [];
    let filteredUomArr;
    List.forEach((item) => {
      item.weekDetails.sort(
        (a, b) =>
          a.weekNumber.substring(3, 7) - parseInt(b.weekNumber.substring(3, 7))
      );
    });
    for (let i = 0; i < List.length; i++) {
      for (let j = 0; j < variableList.length; j++) {
        {
          let data;
          if (type === "RM") {
            data = {
              "RM Code": List[i].rmCode,
              "RM Name": List[i].rmDescription,
              "SU Code": List[i].plantCode,
              "SU Name": List[i].plantName,
              "Material Type": List[i].materialType,
              UoM: List[i].uom === "Euro" ? "€" : List[i].uom,
            };
          } else {
            data = {
              "PM Code": List[i].pmCode,
              "PM Name": List[i].pmDescription,
              "SU Code": List[i].plantCode,
              "SU Name": List[i].plantName,
              UoM: List[i].uom === "Euro" ? "€" : List[i].uom,
            };
          }
          for (let k = 0; k < List[i].weekDetails.length; k++) {
            if (Object.keys(List[i].weekDetails[k]).includes(variableList[j]))
              if (j === 0) {
                if (type === "RM") {
                  data["Figure Name"] = "Inventory";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].inventory !== null ? List[i].weekDetails[k].inventory : 0;
                } else {
                  data["Figure Name"] = "Inventory";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].inventory !== null ? List[i].weekDetails[k].inventory : 0;
                }
              } else if (j === 1) {
                if (type === "RM") {
                  data["Figure Name"] = "Planned Production";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].plannedProduction !== null ? List[i].weekDetails[k].plannedProduction : 0;
                } else {
                  data["Figure Name"] = "Planned Production";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].plannedProduction !== null ? List[i].weekDetails[k].plannedProduction : 0;
                }
              } else if (j === 2) {
                if (type === "RM") {
                  data["Figure Name"] = "Confirmed Production";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].confirmedProduction !== null ? List[i].weekDetails[k].confirmedProduction : 0;
                } else {
                  data["Figure Name"] = "Confirmed Production";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].confirmedProduction !== null ? List[i].weekDetails[k].confirmedProduction : 0;
                }
              } else if (j === 3) {
                if (type === "RM") {
                  data["Figure Name"] = "Confirmed PO";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].confirmedPO !== null ? List[i].weekDetails[k].confirmedPO : 0;
                } else {
                  data["Figure Name"] = "Confirmed PO";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].confirmedPO !== null ? List[i].weekDetails[k].confirmedPO : 0;
                }
              } else if (j === 4) {
                if (type === "RM") {
                  data["Figure Name"] = "Planned PO";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].plannedPO !== null ? List[i].weekDetails[k].plannedPO : 0;
                } else {
                  data["Figure Name"] = "Planned PO";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].plannedPO !== null ? List[i].weekDetails[k].plannedPO : 0;
                }
              } else {
                if (type === "RM") {
                  data["Figure Name"] = "Extrapolated PO";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                    List[i].weekDetails[k].extrapolatedPO !== null ? List[i].weekDetails[k].extrapolatedPO : 0;
                } else {
                  data["Figure Name"] = "Extrapolated PO";
                  data[`${List[i].weekDetails[k].weekNumber}`] =
                  List[i].weekDetails[k].extrapolatedPO !== null ? List[i].weekDetails[k].extrapolatedPO : 0;
                }
              }
          }
          arr.push(data);
        }
      }
    }
    if (tabselected === "Volume" && selectedUom !== "") {
      filteredUomArr = arr.filter((item) => item.UoM === selectedUom);
      return filteredUomArr;
    } else {
      return arr;
    }
  };

  const calculateFGTotals = (data, typeKey) => {
    if (!data || data.length === 0) return [];

    const inventoryData = data.filter((row) => row[typeKey] === "Inventory");
    const demandData = data.filter((row) => row[typeKey] === "Demand");
    const plannedProductionData = data.filter(
      (row) => row[typeKey] === "Planned Production"
    );
    const confirmedProductionData = data.filter(
      (row) => row[typeKey] === "Confirmed Production"
    );

    if (
      inventoryData.length === 0 &&
      demandData.length === 0 &&
      plannedProductionData.length === 0 &&
      confirmedProductionData.length === 0
    ) {
      return data;
    }

    const totals = [];
    const weekNumbers = Object.keys(data[0]).filter((key) =>
      key.startsWith("W")
    );

    if (inventoryData.length > 0) {
      const inventoryTotals = { [typeKey]: "Total Inventory" };
      weekNumbers.forEach((weekNumber) => {
        inventoryTotals[weekNumber] = inventoryData.reduce(
          (sum, row) => sum + (parseFloat(row[weekNumber]) || 0),
          0
        );
      });
      totals.push(inventoryTotals);
    }

    if (demandData.length > 0) {
      const demandTotals = { [typeKey]: "Total Demand" };
      weekNumbers.forEach((weekNumber) => {
        demandTotals[weekNumber] = demandData.reduce(
          (sum, row) => sum + (parseFloat(row[weekNumber]) || 0),
          0
        );
      });
      totals.push(demandTotals);
    }

    if (
      plannedProductionData.length > 0 ||
      confirmedProductionData.length > 0
    ) {
      const productionTotals = { [typeKey]: "Total Production" };
      weekNumbers.forEach((weekNumber) => {
        productionTotals[weekNumber] = [
          ...plannedProductionData,
          ...confirmedProductionData,
        ].reduce((sum, row) => sum + (parseFloat(row[weekNumber]) || 0), 0);
      });
      totals.push(productionTotals);
    }

    return [...data, ...totals];
  };

  const calculateRPMTotals = (data, typeKey) => {
    if (!data || data.length === 0) return [];

    const inventoryData = data.filter((row) => row[typeKey] === "Inventory");
    const confirmedPOData = data.filter(
      (row) => row[typeKey] === "Confirmed PO"
    );
    const plannedPOData = data.filter((row) => row[typeKey] === "Planned PO");
    const extrapolatedPOData = data.filter(
      (row) => row[typeKey] === "Extrapolated PO"
    );
    const plannedProductionData = data.filter(
      (row) => row[typeKey] === "Planned Production"
    );
    const confirmedProductionData = data.filter(
      (row) => row[typeKey] === "Confirmed Production"
    );

    if (
      inventoryData.length === 0 &&
      plannedPOData.length === 0 &&
      confirmedPOData.length === 0 &&
      extrapolatedPOData.length === 0 &&
      plannedProductionData.length === 0 &&
      confirmedProductionData.length === 0
    ) {
      return data;
    }

    const totals = [];
    const weekNumbers = Object.keys(data[0]).filter((key) =>
      key.startsWith("W")
    );

    if (inventoryData.length > 0) {
      const inventoryTotals = { [typeKey]: "Total Inventory" };
      weekNumbers.forEach((weekNumber) => {
        inventoryTotals[weekNumber] = inventoryData.reduce(
          (sum, row) => sum + (parseFloat(row[weekNumber]) || 0),
          0
        );
      });
      totals.push(inventoryTotals);
    }

    if (
      plannedProductionData.length > 0 ||
      confirmedProductionData.length > 0
    ) {
      const productionTotals = { [typeKey]: "Total Production" };
      weekNumbers.forEach((weekNumber) => {
        productionTotals[weekNumber] = [
          ...plannedProductionData,
          ...confirmedProductionData,
        ].reduce((sum, row) => sum + (parseFloat(row[weekNumber]) || 0), 0);
      });
      totals.push(productionTotals);
    }

    if (
      plannedPOData.length > 0 ||
      confirmedPOData.length > 0 ||
      extrapolatedPOData.length > 0
    ) {
      const poTotals = { [typeKey]: "Total Purchase Orders" };
      weekNumbers.forEach((weekNumber) => {
        poTotals[weekNumber] = [
          ...plannedPOData,
          ...confirmedPOData,
          ...extrapolatedPOData,
        ].reduce((sum, row) => sum + (parseFloat(row[weekNumber]) || 0), 0);
      });
      totals.push(poTotals);
    }

    return [...data, ...totals];
  };

  useEffect(() => {
    if (Object.keys(resData).length > 0) {
      let tableArray;
      let typeKey;
      if (activeTab === "FG") {
        let FgList = [...resData.fgList];
        typeKey = "FGType";
        if (filteredData && Object.keys(filteredData).length > 0) {
          if (filteredData.fgList?.length > 0) {
            FgList = FgList.filter((item) => {
              if (countrySelected.length > 0) {
                return filteredData.fgList.some(
                  (filterItem) =>
                    filterItem.fgCode === item.fgCode &&
                    item.plantCountry === countrySelected
                );
              } else {
                return filteredData.fgList.some(
                  (filterItem) => filterItem.fgCode === item.fgCode
                );
              }
            });
          } else {
            FgList = [];
          }
        }
        tableArray = fgTableData(FgList, FGvariableList);
      } else if (activeTab === "RM") {
        let RmList = [...resData.rmList];
        typeKey = "RMType";
        if (filteredData && Object.keys(filteredData).length > 0) {
          if (filteredData.rmList?.length > 0) {
            RmList = RmList.filter((item) => {
              if (countrySelected.length > 0) {
                return filteredData.rmList.some(
                  (filterItem) =>
                    filterItem.rmCode === item.rmCode &&
                    item.plantCountry === countrySelected
                );
              } else {
                return filteredData.rmList.some(
                  (filterItem) => filterItem.rmCode === item.rmCode
                );
              }
            });
          } else {
            RmList = [];
          }
        }
        tableArray = RPMTableData(RmList, RPMvariableList, "RM");
      } else {
        let PmList = [...resData.pmList];
        typeKey = "PMType";
        if (filteredData && Object.keys(filteredData).length > 0) {
          if (filteredData.pmList?.length > 0) {
            PmList = PmList.filter((item) => {
              if (countrySelected.length > 0) {
                return filteredData.pmList.some(
                  (filterItem) =>
                    filterItem.pmCode === item.pmCode &&
                    item.plantCountry === countrySelected
                );
              } else {
                return filteredData.pmList.some(
                  (filterItem) => filterItem.pmCode === item.pmCode
                );
              }
            });
          } else {
            PmList = [];
          }
        }
        tableArray = RPMTableData(PmList, RPMvariableList, "PM");
      }
      setSelectedKey(typeKey);
      if (typeKey === "FGType") {
        tableArray = calculateFGTotals(tableArray, "Figure Name");
      } else {
        tableArray = calculateRPMTotals(tableArray, "Figure Name");
      }
      setRowData(tableArray);
      if (tableArray.length > 0) {
        setColumnDefs(
          Object.keys(tableArray[0]).map((key) => {
            let width = undefined;
            if (typeKey === "FGType") {
              if (key === "Figure Name") width = 220;
              else if (key === "FG Name") width = 220;
              else if (key === "DC/SU Code") width = 100;
              else if (key === "DC/SU Name") width = 220;
            } else if (typeKey === "RMType") {
              if (key === "Figure Name") width = 220;
              else if (key === "RM Name") width = 220;
              else if (key === "SU Name") width = 220;
            } else if (typeKey === "PMType") {
              if (key === "Figure Name") width = 220;
              else if (key === "PM Name") width = 220;
              else if (key === "SU Name") width = 220;
            }
            return {
              headerName: key,
              field: key,
              cellClass: "bwi-gird-cell",
              width: width,
              minWidth: width,
              maxWidth: width,
            };
          })
        );
      }
    }
  }, [resData, activeTab]);

  const getPinnedBottomRowData = () => {
    if (!rowData || rowData.length === 0) return [];
    const totalRows = rowData.filter((row) => {
      return row["Figure Name"] && row["Figure Name"].includes("Total");
    });
    setPinnedBottomRowDataCount(totalRows.length);
    return totalRows.slice(-3);
  };

  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return {
        backgroundColor: "#8FCAEB",
        color: "#2A3548",
      };
    }
    return null;
  };

  const pinnedBottomRowData = useMemo(
    () => getPinnedBottomRowData(),
    [rowData, selectedKey, pinnedBottomRowDataCount]
  );

  const filteredRowData = useMemo(() => {
    if (pinnedBottomRowDataCount === 0) return rowData;
    return rowData.slice(0, rowData.length - pinnedBottomRowDataCount);
  }, [rowData, pinnedBottomRowDataCount]);

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      resizable: true,
    };
  }, []);

  return (
    <Modal
      centered
      contentClassName="bwi-modal-deepdive-table"
      dialogClassName="bwi-dialog-deepdive-table"
      show={showModal}
    >
      <Modal.Header className="bwi-modal-deepdive-table-header">
        <div className="bwi-modal-deepdive-table-header-title">
          <span className="bwi-modal-title">Detailed Summary</span>
        </div>
        <img
          src={closeIcon}
          alt="Close-Image"
          onClick={() => setShowModal(false)}
          className="bwi-landing-cursor-pointer"
        />
      </Modal.Header>

      <Modal.Body className="bwi-modal-deepdive-table-body">
        <div className="bwi-deepdive-table-tabrow">
          <ul className="nav dsp-deepdive-toggle-ul">
            <li
              className={`dsp-deepdive-toggle-li 
                      ${activeTab === "FG" ? "active" : "deactive"}`}
              onClick={() => setActiveTab("FG")}
            >
              Finished Goods
            </li>
            <li
              className={`dsp-deepdive-toggle-li 
                      ${activeTab === "RM" ? "active" : "deactive"}`}
              onClick={() => setActiveTab("RM")}
            >
              Raw Materials
            </li>
            <li
              className={`dsp-deepdive-toggle-li 
                      ${activeTab === "PM" ? "active" : "deactive"}`}
              onClick={() => setActiveTab("PM")}
            >
              Pack Materials
            </li>
          </ul>
          <div
            className="bwi-deepdive-table-template"
            onClick={() => onDownloadBtnClicked()}
            style={{
              cursor: isWaiting
                ? "wait"
                : rowData.length === 0
                ? "unset"
                : "pointer",
              opacity: rowData.length === 0 ? 0.5 : 1,
              pointerEvents: rowData.length === 0 ? "none" : "unset",
            }}
          >
            <img src={fileDownload} alt="download-Icon" />
            <span className="dsf-deepdive-barchart-name">Download Excel</span>
          </div>
        </div>
        <div className="bwi-deepdive-table-data">
          <div
            id="detailed-table"
            className="ag-theme-quartz"
            style={{ height: "60vh" }}
          >
            <AgGridReact
              rowData={filteredRowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              headerHeight={50}
              getRowStyle={getRowStyle}
              pinnedBottomRowData={pinnedBottomRowData}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDetailedTable;
