import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Checkbox from "@mui/material/Checkbox";
import "./dropdown.css";
import polygon from "../../assets/images/Polygon1.svg";
import polygonClose from "../../assets/images/PolygonClose1.svg";
import polygonDisable from "../../assets/images/Polygon-disable.svg";

const CustomOptionsDropdown = (props) => {
  const [selectedValues, setSelectedValues] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const {
    options,
    width,
    disable,
    maxDropdownHeight,
    onSelectionChange,
    listClassName,
  } = props;

  useEffect(() => {
    if (props.selectedValues.length > 0) {
      setSelectedValues(props.selectedValues);
    }
    else {
      setSelectedValues("");
    }
  }, [props.selectedValues]);

  const toggleDropdown = () => {
    {
      !disable && setIsOpen(!isOpen);
    }
  };

  const toggleValue = (value) => {
    if (value.length > 0) {
      setSelectedValues(value);
      onSelectionChange(value);
      toggleDropdown();
    }
  };

  return (
    <div className={`bwi-custom-options-dropdown  ${listClassName}`}>
      <div
        className="bwi-options-input-container"
        style={{ height: "18px"}}
        onClick={() => {
          toggleDropdown();
        }}
      >
        {selectedValues.length > 0 && (
          <span className="bwi-options-dropdown-input">{selectedValues}</span>
        )}
        <img
          src={isOpen ? polygon : polygonClose}
          disabled={disable}
          alt="autocomplete-dropdown-icon"
          className={`bwi-dropdown-icon  ${
            disable
              ? "bwi-dropdown-icon-disable"
              : isOpen
              ? "bwi-dropdown-icon-enable"
              : "bwi-dropdown-icon-close"
          }`}
        />
      </div>
      {isOpen && (
        <div className="bwi-options-container"
        style={{
            maxHeight: maxDropdownHeight,
            maxWidth: width,
          }}>
          <div
            className="bwi-options-list"
            style={{
              maxHeight: maxDropdownHeight,
              overflowX: "hidden",
              minWidth: width,
            }}
          >
            {options?.length > 0 &&
              options.map((option) => (
                <div
                  key={option.value}
                  className={`bwi-options-row ${
                    selectedValues === option.label ? "selected" : ""
                  }`}
                  onClick={() => {
                    toggleValue(option.label);
                  }}
                >
                  {option.label}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomOptionsDropdown;
