import React, { useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import icon from "../../../../assets/images/Vector-cross.svg";
import { rndsignoff_text } from "../../../../globalConstants";
import "./modalRNDSignOff.css";

const ModalRNDSignOff = (props) => {
  const { showModal, setShowModal } = props;

  const handleOkButton = () => {
    setShowModal(false);
    props.onComplete(true);
  };

  return (
    <>
      <Modal
        centered
        dialogClassName="bwi-dialog-rnd-signoff"
        contentClassName="bwi-modal-rnd-signoff"
        show={showModal}
      >
        <Modal.Header className="bwi-modal-header-rnd-signoff">
          <Modal.Title className="dsf-font-weight-600 dsf-size16 dsf-title-color">
            R&D Sign Off
          </Modal.Title>
          <img
            src={icon}
            alt="Close-Image"
            className="bwi-rnd-close-img"
            onClick={handleOkButton}
          />
        </Modal.Header>
        <Modal.Body className="bwi-modal-body-rnd-signoff">
          <div>
            <p className="dsf-span-s">{rndsignoff_text.split("\n")[0]}</p>
            <br />
            <p className="dsf-span-s">{rndsignoff_text.split("\n")[1]}</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="bwi-modal-footer-rnd-signoff justify-content-center bw-modal-footer">
          <Button
            className="dsf-footer-okbutton-PI"
            variant="secondary"
            onClick={handleOkButton}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalRNDSignOff;
