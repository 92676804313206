import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "./stepper.css";

import {
  workflowStageSteps,
  workflowStatusEnum_New,
  cgAwaitingText,
} from "../../globalConstants";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 11,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#61D4C4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#61D4C4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 1,
    backgroundColor: "#4B4B4B",
    borderRadius: 3,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  zIndex: 1,
  color: "#fff",
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 1s ease-in-out",
}));

const ColorlibStepIcon = React.memo(
  (props) => {
    const { className, workflowData, showCGTooltip } = props;
    const [hover, setHover] = useState(false);

    return (
      <ColorlibStepIconRoot
        className={className}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img
          className="bwi-stepper-icon-img"
          src={require(`../../assets/images/landingPage/${workflowStatusEnum_New[workflowData]}.svg`)}
          alt={`Step ${props.icon}`}
        />
        {showCGTooltip && hover && (
          <div className="bwi-stepper-tooltip-cg">
            <p>{cgAwaitingText}</p>
          </div>
        )}
      </ColorlibStepIconRoot>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.workflowData === nextProps.workflowData;
  }
);

ColorlibStepIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  workflowData: PropTypes.string.isRequired,
  showCGTooltip: PropTypes.bool,
};

export default function CustomizedSteppers(props) {
  const { stepData, activeStep, listClassName, arrItem } = props;
  const [iconSelected, setIconSelected] = useState(props.iconSelected);

  useEffect(() => {
    // console.log("iconSelected", props.iconSelected, activeStep);
    setIconSelected(props.iconSelected);
  }, [props.iconSelected]);

  return (
    <Stack
      sx={{ width: "100%", paddingTop: "20px" }}
      spacing={1}
      className={`bwi-stepper ${listClassName}`}
    >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {workflowStageSteps.map((label, index) => (
          <Step
            key={label}
            className={`bwi-stepper-step ${
              index === 4 &&
              stepData[4] === "1" &&
              arrItem.workflow[6].status === "4"
                ? "bwi-stepper-invcm-disable"
                : ""
            }
          ${
            index === 5 && arrItem.workflow[6].status === "4"
              ? "bwi-stepper-cg-waiting"
              : ""
          }`}
          >
            <StepButton
              onClick={() => {
                setIconSelected(index);
                props.onStepSelection(index);
              }}
              disabled={stepData[index] === "1" ? true : false}
              disableRipple
            >
              <StepLabel
                className={`bwi-stepper-label ${
                  index === iconSelected ? "bwi-stepper-label-active" : ""
                }`}
                StepIconComponent={(props) => (
                  <ColorlibStepIcon
                    {...props}
                    iconIndex={index}
                    workflowData={stepData[index]}
                    showCGTooltip={
                      index === 5 && arrItem.workflow[6].status === "4"
                        ? true
                        : false
                    }
                    // className={`bwi-stepper-icon ${index === activeStep ? "bwi-stepper-icon-active" : ""}`}
                  />
                )}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: "#EDF1F3 !important",
                  },
                }}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
