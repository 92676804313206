import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import MDMOps from "../../ui-components/MDMOps/modalMDMOps";
import ModalDepartmentDetails from "../MTB/modalP&IDepartmentDetails";
import ModalProjectDetails from "../../ui-components/MTB/modalP&IProjectDetails";
import "./pendingTaskBar.css";

const PendingTaskBar = (props) => {
  const { task } = props;
  const [showModal, setShowModal] = useState(false);

  const onTaskCompletion = async () => {
    setShowModal(false);
    props.onComplete();
  };

  const rolebasedTaskBar = () => {
    switch (props.role) {
      case "BWInnovationR&D":
        return (
          <MDMOps
            isShow={showModal}
            selectedProjectId={task.projectId}
            onComplete={onTaskCompletion}
            isFromRnDUser={true}
            isEdit={true}
          />
        );
        break;
      case "BWInnovationP&IAdmin":
        return modalEnabled();
        break;
      default:
        break;
    }
  };

  const modalEnabled = () => {
    switch (task.taskID) {
      case "PIABG":
        return (
          <ModalDepartmentDetails
            isShow={showModal}
            onComplete={onTaskCompletion}
          />
        );
        break;
      case "PIAP":
        return (
          <ModalProjectDetails
            isShow={showModal}
            onComplete={onTaskCompletion}
          />
        );
        break;
      default:
        break;
    }
  };

  return (
    <>
      {showModal && rolebasedTaskBar()}
      {
        <div className="bwi-pending-task">
          <div className="bwi-project-info">
            <span className="bwi-dashbaord-project-title-span dsf-span-m">
              {task.taskdesc}
            </span>
            <span className="bwi-dashbaord-project-desc-span">
              {" "}
              {task.taskdetail} for {task.projectName}
            </span>
          </div>
          <div className="bwi-BW-info">
            <div className={`dsf-col-12`}>
              <Button
                className="bwi-BW-info-btn"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              >
                {task.taskName}
              </Button>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PendingTaskBar;
