import { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";
import { formatNumber } from "../utils";
import "./tableChart.css";
import { arrCombochart } from "../pages/Dashboard/dashboardFixture";

const textStyle = {
  color: "#EDF1F3",
  fontSize: 8,
  fontFamily: "Unilever Shilling",
  fontWeight: 400,
};
const hAxisTextStyle = {
  color: "#797775",
  fontSize: 13,
  fontFamily: "Unilever Shilling",
  fontWeight: 400,
};
const VerticalBarChartDesign = (props) => {
  const [comboChartData, setComboChartData] = useState([]);
  let annotationTextForScenario = "";
  let annotation = "";
  let annotationBWRisk = "BW Risk";
  useEffect(() => {
    let arrcomboChartData = [];
    props.arrComboChartData.data &&
      props.arrComboChartData.data.map((item, index) => {
        const barAnnotationText = `€ ${formatNumber(
          parseFloat(item.scenarioBWRisk)
        )}`;
        annotationTextForScenario = `Target = € ${formatNumber(
          parseFloat(item.scenarioBWRisk)
        )}`;
        let colorCode = "#F1A33A";
        if (index === 0) {
          colorCode = "#e5e4e2";
        }
        arrcomboChartData.push([
          item.name,
          parseFloat(item.bwRisk),
          formatNumber(parseFloat(item.bwRisk)) !== "NaN"
            ? formatNumber(parseFloat(item.bwRisk))
            : "-",
          colorCode,
          parseFloat(item.scenarioBWRisk),
          formatNumber(parseFloat(item.scenarioBWRisk)),
          annotation,
        ]);
      });

    // console.log(
    //   "arrcomboChartData",
    //   arrcomboChartData.length,
    //   arrcomboChartData
    // );
    setComboChartData(arrcomboChartData);
  }, [props.arrComboChartData.data && props.arrComboChartData.data.length > 0]);

  useEffect(() => {
    GoogleCharts.load(drawChart, {
      packages: ["corechart", "bar"],
    });
  }, [comboChartData]);

  const drawChart = () => {
    let data = new GoogleCharts.api.visualization.arrayToDataTable([
      [
        "Month",
        { role: "annotation" },
        "BW Risk",
        { role: "style" },
        "ScenarioA",
        { role: "annotation" },
      ],
      ["Week1", null, 100111, "#e5e4e2", 1501111, null],
      ["Week2", null, 211011, "#b87333", 1501111, null],
      ["Week3", "Lanucn", 51011, "#b87333", 1501111, null],
    ]);

    let data_n = new GoogleCharts.api.visualization.DataTable();
    data_n.addColumn("string", "Week");
    data_n.addColumn("number", "BW Risk");
    data_n.addColumn({ type: "string", role: "tooltip" });
    data_n.addColumn({ role: "style" });
    data_n.addColumn(
      "number",
      `${props.arrComboChartData.scenarioName ?? "Scenario"}`
    );
    data_n.addColumn({ type: "string", role: "tooltip" });
    data_n.addColumn({ type: "string", role: "annotation" });
    data_n.addRows(comboChartData);

    let options = {
      title: "",
      backgroundColor: "#0D1116",
      chartArea: { width: "87%" },
      // bar: { groupWidth: "3%" },
      legend: { position: "top", textStyle: textStyle, maxLines: 1 },
      vAxis: {
        title: "euro(€)",
        titleTextStyle: {
          color: "white",
          fontSize: 13,
          fontFamily: "Unilever Shilling",
          fontWeight: 400,
        },
        textStyle: hAxisTextStyle,
        gridlines: { count: 1 },
        baselineColor: "#797775",
        format: "short",
      },
      focusTarget: "category",
      hAxis: {
        titleTextStyle: { color: "#FFF" },
        textStyle: hAxisTextStyle,
        slantedText: true,
        slantedTextAngle: 34,
      },
      annotations: {
        stemColor: "none",
        textStyle: {},
      },
      bar: {
        groupWidth: "50%",
      },
      lineWidth: 2,
      seriesType: "bars",
      series: {
        0: {
          color: "#F1A33A",
        },
        1: {
          type: "line",
          lineDashStyle: [5, 5],
          tooltip: {
            trigger: "none",
          },
          color: "#00B2FF",
        },
      },
    };

    let container = document.getElementById(props.container);
    let chart = new GoogleCharts.api.visualization.ColumnChart(container);

    new GoogleCharts.api.visualization.events.addListener(
      chart,
      "ready",
      function () {
        let observer = new MutationObserver(function () {
          const labels = container.getElementsByTagName("text");
          let annotationLabel = labels[labels.length - 1];
          Array.prototype.forEach.call(labels, function (label) {
            if (
              label.getAttribute("text-anchor") === "middle" &&
              label.innerHTML === annotation
            ) {
              const yAxisValue = parseFloat(label.getAttribute("y")) - 32;
              const xAxisValue = parseFloat(label.getAttribute("x")) - 32;
              label.setAttribute(
                "transform",
                "rotate(-90, " +
                  xAxisValue.toString() +
                  " " +
                  yAxisValue.toString() +
                  ")"
              );
              label.setAttribute("color", "white");
              label.setAttribute("font-size", 35);
            }
            label.setAttribute("font-family", "Unilever Shilling");
            if (
              label.getAttribute("text-anchor") === "middle" &&
              label.innerHTML === annotationTextForScenario
            ) {
              const yAxisValue = parseFloat(label.getAttribute("y")) + 115;
              const xAxisValue = parseFloat(label.getAttribute("x")) + 12;
              label.setAttribute(
                "transform",
                "rotate(360, " +
                  xAxisValue.toString() +
                  " " +
                  yAxisValue.toString() +
                  ")"
              );
              label.setAttribute("font-size", 14);
              label.setAttribute("font", "#EC6157");
            }
            if (
              label.getAttribute("text-anchor") === "start" &&
              label.innerHTML === props.arrComboChartData.scenarioName
            ) {
              label.innerHTML += ` ${annotationTextForScenario}`;
              label.setAttribute("fill", "#EDF1F3");
              label.setAttribute("font-size", 10);
              label.setAttribute("font-weight", 400);
            }
            if (
              label.getAttribute("text-anchor") === "start" &&
              label.innerHTML === annotationBWRisk
            ) {
              // label.innerHTML += ` ${annotationTextForScenario}`;
              label.setAttribute("fill", "#EDF1F3");
              label.setAttribute("font-size", 10);
              label.setAttribute("font-weight", 400);
            }
          });
        });
        observer.observe(container, {
          childList: true,
          subtree: true,
        });
      }
    );
    GoogleCharts.api.visualization.events.addListener(
      chart,
      "onmouseover",
      () => {
        const tooltip = container.getElementsByClassName(
          "google-visualization-tooltip"
        );
        if (tooltip.length > 0) {
          const labels = tooltip[0].getElementsByTagName("text");
          const rects = tooltip[0].getElementsByTagName("rect");
          console.log(rects.length);
          if (rects.length >= 2) {
            rects[1].setAttribute("fill", "white");
          }
          Array.prototype.forEach.call(labels, function (label, index) {
            if (index > 2) {
              labels[index].setAttribute("fill", "white");
            }
          });
        }
      }
    );

    chart.draw(data_n, options);
  };
};
export default VerticalBarChartDesign;
