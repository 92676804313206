import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


export default forwardRef((props, ref) => {
  const [filterText, setFilterText] = useState(null); 
  


  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        // make sure each word passes separately, ie search for firstname, lastname
        let passed = true;
        filterText
          .toLowerCase()
          .split(' ')
          .forEach((filterWord) => {
            const value = props.valueGetter({
              api,
              colDef,
              column,
              columnApi,
              context,
              data: node.data,
              getValue: (field) => node.data[field],
              node,
            });

            if (value.toString().toLowerCase().indexOf(filterWord) < 0) {
              passed = false;
            }
          });

        return passed;
      },

      isFilterActive() {
        return filterText != null && filterText !== '';
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return { value: filterText };
      },

      setModel(model) {
        setFilterText(model == null ? null : model.value);
      },
    };
  });

  const onChange = (event) => {
    setFilterText(event.target.value);
  };

  useEffect(() => {
    props.filterChangedCallback();
  }, [filterText]);

  return (
    <div
      style={{ display: 'inline-block' , height:50,width:120}}
    >
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-simple-select-autowidth-label">Status</InputLabel>
      <Select
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={filterText}
        onChange={onChange}
        autoWidth
        label="Function"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value={"Completed"}>Completed</MenuItem>
        <MenuItem value={"Less than 3 days to deadline"}>Less than 3 days to deadline</MenuItem>
        <MenuItem value={"Past Deadline"}>Past Deadline</MenuItem>
      </Select>
    </FormControl>

    </div>
  );
});