import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import DeepDiveAddEditAction from "./deepDiveAddEditAction";
import ArrowDown from "../../../../assets/images/arrowDownWhite.svg";
import ArrowUp from "../../../../assets/images/arrowUpWhite.svg";
import CompletedIcon from "../../../../assets/images/circleCompleted.svg";
import plusIcon from "../../../../assets/images/PlusNew.svg";
import editIcon from "../../../../assets/images/pencilBlack.svg";
import { dateTimeFormat } from "../../../../utils";

const DeepDiveActionCard = (props) => {
  const { item, key, arrItem, isEdit } = props;
  const [expanded, setExpanded] = useState(false);
  const [showAddEditActionModal, setShowAddEditActionModal] = useState(false);
  const [functionSelected, setFunctionSelected] = useState("");

  const handleAddActionManual = (type) => {
    setShowAddEditActionModal(true);
    setFunctionSelected(type);
  };

  return (
    <>
      <div className="bwi-deepdive-action-card">
        <Row>
          <Row
            className={`${
              item.contact.length === 0 && !expanded
                ? "bwi-deepdive-action-card-title-row"
                : ""
            }`}
          >
            <Col xs={10}>
              <div className="bwi-deepdive-action-card-title">
                {item.actionName}
              </div>
            </Col>
            <Col xs={2}>
              <div
                className="bwi-deepdive-action-card-toggle"
                onClick={() => setExpanded(!expanded)}
              >
                {expanded ? (
                  <img src={ArrowUp} alt="ArrowUp" />
                ) : (
                  <img src={ArrowDown} alt="ArrowDown" />
                )}
              </div>
            </Col>
          </Row>
          {expanded && (
            <Row
              className={`${
                item.contact.length === 0 && expanded
                  ? "bwi-deepdive-action-card-title-row"
                  : ""
              }`}
            >
              <Col xs={12}>
                <div className="bwi-deepdive-action-card-description">
                  {item.actionDetails}
                </div>
              </Col>
            </Row>
          )}
          {item.contact.length > 0 && (
            <>
              <Row>
                <Col xs={7}>
                  <div className="bwi-deepdive-action-card-label">
                    Action Owner
                  </div>
                </Col>
                <Col xs={5}>
                  <div className="bwi-deepdive-action-card-label">Deadline</div>
                </Col>
              </Row>
              <Row
                className={`${
                  !item.status ? "bwi-deepdive-action-card-contact-row" : ""
                }`}
              >
                <Col xs={7}>
                  <div className="bwi-deepdive-action-card-value">
                    {item.contact}
                  </div>
                </Col>
                <Col xs={5}>
                  <div className="bwi-deepdive-action-card-value">
                    {dateTimeFormat(item.deadline, "DD/MM/YYYY")}
                  </div>
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col xs={7}>
              <div className="bwi-deepdive-action-card-value"></div>
            </Col>
            <Col
              xs={5}
              className={`bwi-deepdive-action-card-status-col ${
                item.status ? "bwi-deepdive-action-card-completed-col" : ""
              }`}
            >
              {item.status ? (
                <div className="bwi-display-flex bwi-deepdive-action-card-completed-div">
                  <img src={CompletedIcon} alt="CompletedIcon" />
                  <div className="bwi-deepdive-action-card-label">
                    Completed
                  </div>
                </div>
              ) : item.contact.length === 0 ? (
                <div className="dsf-deepdive-action-button-div">
                  <Button
                    variant="secondary"
                    className="dsf-deepdive-action-button"
                    onClick={() => handleAddActionManual("Add")}
                    style={{ padding: "0px" }}
                    disabled={!isEdit}
                  >
                    {" "}
                    <img
                      src={plusIcon}
                      alt="plus-icon"
                      style={{ marginBottom: "3px", marginRight: "2px" }}
                    />{" "}
                    Add Action
                  </Button>
                </div>
              ) : (
                <div className="dsf-deepdive-action-button-div">
                  <Button
                    variant="secondary"
                    className="dsf-deepdive-action-button"
                    onClick={() => handleAddActionManual("Edit")}
                    style={{ padding: "0px" }}
                    disabled={!isEdit}
                  >
                    {" "}
                    <img
                      src={editIcon}
                      alt="plus-icon"
                      style={{ marginBottom: "3px", marginRight: "2px" }}
                    />{" "}
                    Edit
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Row>
      </div>
      {showAddEditActionModal && (
        <DeepDiveAddEditAction
          showModal={showAddEditActionModal}
          setShowModal={setShowAddEditActionModal}
          arrItem={arrItem}
          onChange={props.onChange}
          isAutomated={item.isAutomated}
          isEdit={functionSelected === "Edit"}
          item={item}
        />
      )}
    </>
  );
};

export default DeepDiveActionCard;
