import React, { useState, useEffect } from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import "./dropdown.css";

const CustomDropdown = (props) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const { data, onChange, disabled, readOnly, popperClass } = props;
    const additionalClass = 'bw-innoflex-popper-class';

    useEffect(() => {
        // Check if data contains only one item and set it as the default value
        const defaultValue = data.length === 1 ? data[0].label : props.value;

        // Update the selected value when data changes
        setSelectedOptions(defaultValue);
        if (data.length === 1) {
            onChange(data[0].label);
        }   
    }, [props.data,props.value]);

    const handleChange = (event) => {
        setSelectedOptions(event.target.value);
        onChange(event.target.value);
    };

    const handleClick = () => {
        if (!readOnly) {
            setOpen(!open);
        }
    }

    const handleOpen = () => {
        if (!readOnly) {
            setOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    return (
        <FormControl>
            <Select
                value={selectedOptions}
                onChange={handleChange}
                onClick={handleClick}
                open={open}
                onOpen={handleOpen}
                onClose={handleClose}
                className='custom-select'
                disabled={disabled}
                sx={{
                    "& .MuiSelect-icon": {
                        color: '#EDF1F3'
                    },
                    // need to add width and height if given in props
                    minWidth: props.width ? props.width : {},
                    minHeight: props.height ? props.height : {},
                }}

                MenuProps={{
                    className: popperClass ? `${additionalClass} ${popperClass}` : additionalClass,
                }}
            >
                {data.map((item) => (
                    <MenuItem key={item.value} value={props.isFromScenarioPlanner ? item.value : item.label}>{item.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default CustomDropdown;
