import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import DelistBomService from "../service/delistBomService";

const initialState = [];

export const retrieveCountriesByProject = createAsyncThunk(
  "DelistBOM/retrieveCountriesByProject",
  async ({projectName}) => {
    const res = await DelistBomService.fetchCountriesByProject(projectName);
    return res.data;
  }
);

export const retrieveDelistBomListByCountry = createAsyncThunk(
  "DelistBOM/retrieveDelistBomListByCountry",
  async ({projectName, country}) => {
    const res = await DelistBomService.fetchDelistBomListByCountry(projectName, country);
    return res.data;
  }
);

const delistBomSlice = createSlice({
  name: "delistBom",
  initialState,
  reducers: {
  },
  extraReducers: {  
  [retrieveCountriesByProject.fulfilled]: (state, action) => {
    return action.payload;
  },
  [retrieveDelistBomListByCountry.fulfilled]: (state, action) => {
    return action.payload;
  }    
},
});

const { reducer } = delistBomSlice;
export default reducer;