import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  CustomTooltip,
  InfoTooltip,
  HelpTooltip,
} from "../../components/Tooltip/tooltip";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

import {
  getNotification,
  updateNotification,
} from "../../service/headerService";

import "./header.css";
import "../../styles/global.css";
import { helpIconText_part1, helpIconText_part2 } from "../../globalConstants";
import MenuBar from "../../components/Menu/menu";

const ITEM_HEIGHT = 48;

function Header(props) {
  const [user, setUser] = useState([]);
  const [userName, setUserName] = useState();
  const [showToolTip, setShowToolTip] = useState(false);

  const [rowData, setRowData] = useState([]);
  const [rowCount, setRowCount] = useState(0);

  const [openMenu, setOpenMenu] = React.useState(false);
  const [notificationList, setNotificationList] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const userDetails = useSelector((state) => state.userDetails);
  useEffect(() => {
    setUserName(
      (userDetails.userFirstName ?? "") + " " + (userDetails.userLastName ?? "")
    );
    if (userDetails.email) {
      getNotificationList(userDetails.email);
    }
  }, [userDetails]);

  const getNotificationList = async () => {
    const res = await getNotification(userDetails.email);
    if (res.status === 200) {
      setNotificationList(res.data);
      sortNotificationData("desc", res.data);
      setNotificationCount(res.data);
    } else {
      console.log("failed to fetch data", res.data);
    }
  };

  const setNotificationCount = async (notificationList) => {
    let counter = 0;
    for (const obj of notificationList) {
      if (obj.isSeen === 0) counter++;
    }

    setRowCount(counter);
  };
  const sortNotificationData = async (
    sort,
    notificationListData = notificationList
  ) => {
    if (sort == "asc") {
      let unseenNotification = notificationListData.filter(
        (item) => item.isSeen === 0
      );
      let ascUnseenNotification = unseenNotification.sort(
        (a, b) =>
          new Date(a.notificationCreatedOn) - new Date(b.notificationCreatedOn)
      );
      let seenNotification = notificationListData.filter(
        (item) => item.isSeen === 1
      );
      let ascSeenNotification = seenNotification.sort(
        (a, b) =>
          new Date(a.notificationCreatedOn) - new Date(b.notificationCreatedOn)
      );
      setRowData(ascUnseenNotification.concat(ascSeenNotification));
    } else if (sort == "desc") {
      let unseenNotification = notificationListData.filter(
        (item) => item.isSeen === 0
      );
      let seenNotification = notificationListData.filter(
        (item) => item.isSeen === 1
      );
      let descUnseenNotification = unseenNotification.sort(
        (a, b) =>
          new Date(b.notificationCreatedOn) - new Date(a.notificationCreatedOn)
      );
      let descSeenNotification = seenNotification.sort(
        (a, b) =>
          new Date(b.notificationCreatedOn) - new Date(a.notificationCreatedOn)
      );
      setRowData(descUnseenNotification.concat(descSeenNotification));
    } else if (sort == "proj") {
      let unseenNotification = notificationListData.filter(
        (item) => item.isSeen === 0
      );
      let seenNotification = notificationListData.filter(
        (item) => item.isSeen === 1
      );

      let projUnseenNotification = unseenNotification.sort((a, b) =>
        a.notificationProjectName.localeCompare(b.notificationProjectName)
      );
      let projSeenNotification = seenNotification.sort((a, b) =>
        a.notificationProjectName.localeCompare(b.notificationProjectName)
      );
      setRowData(projUnseenNotification.concat(projSeenNotification));
    } else {
      setRowData(notificationList);
    }
  };

  const updateStatus = async (selectedNotification, sort) => {
    if (selectedNotification.isSeen === 0) {
      const res = await updateNotification(selectedNotification.notificationID);
      if (res.status === 200) {
        getNotificationList();
        setOpenMenu(false);
      } else {
        console.log("failed to fetch data", res.data);
      }
    } else {
      setOpenMenu(false);
    }
  };

  const toggleDrawer = (open) => (event) => {
    setOpenMenu(open);
  };

  return (
    <>
      <div className={`dsf-header dsf-header-color-PI`}>
        <div className="dsf-header-title-div">
          <img
            src={require("../../assets/images/NewLogo.svg").default}
            className="dsf-brand"
            alt="logo"
            onClick={() => (window.location.href = "/")}
          />
        </div>
        <div className="dsf-header-icon-div">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={toggleDrawer(true)}
          >
            <Badge badgeContent={rowCount} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            onMouseEnter={() => setShowToolTip(true)}
            onMouseLeave={() => setShowToolTip(false)}
          >
            <ContactSupportIcon style={{ color: "#EDF1F3" }} />
          </IconButton>
          {showToolTip && (
            <HelpTooltip
              helpText_part1={helpIconText_part1}
              helpText_part2={helpIconText_part2}
            />
          )}
        </div>
        <div className="dsf-header-user-div">
          <div className=" dsf-user-icon">
            <img
              src={require("../../assets/images/user.svg").default}
              alt="user"
            />
          </div>
          <span className="dsf-header-span">{userName}</span>
        </div>
        <MenuBar
          openMenu={openMenu}
          toggleDrawer={toggleDrawer}
          rowData={rowData}
          updateStatus={updateStatus}
          getNotificationData={sortNotificationData}
        />
      </div>
    </>
  );
}
export default Header;
