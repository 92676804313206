import { ApiHelper ,configByEnv} from "./APIHelper";

const config = configByEnv();
const fetchProjectSummary = async () => {
  try {
    // const method = "get";
    // const url =
    //   "https://bieno-da12-d-930072-webapi-02.azurewebsites.net/ProjectDetails/GetProjectsummary?strProjectName=EPKE_NL_BNL_DUP";
    // const data = "";
    // const response = await axios({
    //   method,
    //   url,
    //   data,
    //   headers: generateHeaders(localStorage.getItem("IDToken")),
    // });
    // return response.data;

    
  } catch (e) {
    console.log("error", e);
  }
};

const fetchProjectLists = async (mailId) => {
  try {
    const url = config.baseURL + '/ProjectDetails/GetProjectList';
    const response = await ApiHelper.get(url);
    return response;
    
  } catch (e) {
    console.log("error", e);
  }
};

 const deleteDelistingProjectDetails = async (projectId) => {
  try {
    const url = config.baseURL + `/ProjectDetails/DeleteProject?projectId=${projectId}`;
    const response = await ApiHelper.put(url)
    return {
      "status":response.status,
      "data":response.data
    };
  } catch (e) {
    console.log("error", e);
    return {
      "status":e.response.status,
      "data":e.response.data
    };
  }
}

const ProjectDetailsService = {
  fetchProjectLists,
  deleteDelistingProjectDetails
};

export default ProjectDetailsService;
