import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import info from "../../assets/images/info.svg";
import "./tooltip.css";

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    classes={{ popper: `${className} ${props.additionalClassName}` }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontFamily: "Unilever Shilling",
    fontSize: "12px",
    fontWeight: 400,
    color: "#FFFFFF",
    backgroundColor: "#4B4B4B",
    borderRadius: 0,
    boxShadow: "0px 3.01449px 3.01449px rgba(0, 0, 0, 0.25)",
    boxSizing: "border-box",
    padding: "12px 18px 12px 18px",
    maxWidth: "500px",
  },
});

export const ConstraintTooltip = ({
  name = "info",
  positionClass,
  width = "424px",
  height = "108px",
}) => {
  const [hover, setHover] = useState(false);
  return (
    <div className="bw-info-tool-tip-div">
      <img
        src={name === "info" ? info : ""}
        style={{
          zIndex: 999,
          width: "9px",
          height: "9px",
          marginLeft: "5px",
          opacity: hover ? 0.5 : 1,
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      {hover && (
        <div
          className={`info-tooltip-div ${
            positionClass ?? "tooltip-position-default"
          }`}
          style={{
            width: `${width}`,
            height: `${height}`,
            padding: "5px 3px",
          }}
        >
          <p
            className="bw-help-tool-tip-text "
            style={{ padding: "15px 66px 15px 15px" }}
          >
            Add here any information that can help E2E planner understand the
            restrictions they have on the scenario modelling
          </p>
          <ul>
            <li className={`bw-help-tool-tip-text`}>
              Factory restrictions for production eg. dependencies of line
              decommissions
            </li>
            <li className={`bw-help-tool-tip-text`}>
              Customer restrictions for demand planning
            </li>
            <li className={`bw-help-tool-tip-text`}>Supplier restrictions</li>
          </ul>
        </div>
      )}
    </div>
  );
};

export const HelpTooltip = ({ helpText_part1, helpText_part2 }) => {
  return (
    <div className="bw-help-tooltip">
      <span className="bw-tooltip-header">InnoWaste Help</span>
      <p className={` bw-help-tool-tip-text`}>{helpText_part1}</p>
      <p className={` bw-help-tool-tip-text`}>{helpText_part2}</p>
    </div>
  );
};

export const InfoTooltip = ({
  name = "info",
  Tooltiptext,
  positionClass,
  width = "244px",
  height = "66px",
}) => {
  const [hover, setHover] = useState(false);
  const breakText = Tooltiptext.split("<br>");
  return (
    <div className="bw-info-tool-tip-div">
      <img
        src={name === "info" ? info : ""}
        style={{
          zIndex: 999,
          width: "9px",
          height: "9px",
          marginLeft: "5px",
          marginBottom: "6px",
          opacity: hover ? 0.5 : 1,
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
      {hover && (
        <div
          className={`info-tooltip-div ${
            positionClass ?? "tooltip-position-default"
          }`}
          style={{
            maxWidth: `${width}`,
            maxHeight: `${height}`,
            width: `${width}`,
            height: `${height}`,
            position: "fixed",
            zIndex: 999,
          }}
        >
          {breakText.length > 1 && (
            <div style={{ margin: "12px 18px 12px 18px" }}></div>
          )}
          {breakText.map((item) => {
            return (
              <>
                <p className={`bw-info-tool-tip-text `}>{item}</p>
                {breakText.length > 1 && <br />}
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export const ProjectInfoTooltip = ({
  item,
  isShowDesc = true,
  projNameLength,
  projDescLength,
}) => {
  const [hover, setHover] = useState(false);
  const HtmlTooltip = styled((props) => (
    <Tooltip
      {...props}
      classes={{ popper: props.className }}
      placement="left"
      // open={hover}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#1E232F",
      maxWidth: 580,
      marginLeft: "2px",
      border: "0.5px solid #797775",
      borderRadius: "0px",
      padding: "12px 18px",
      color: "#edf1f3",
    },
  }));
  return (
    <div className="project-info-tooltip">
      <HtmlTooltip
        title={
          <React.Fragment>
            {isShowDesc && (
              <Typography className="bw-proj-info-tooltip-header">
                {item.projectName}
              </Typography>
            )}
            <p className={`bw-proj-info-tooltip-text`}>{item.projectDesc}</p>
          </React.Fragment>
        }
      >
        <div className="project-info-tooltip-label">
          {isShowDesc && (
            <div className="dsf-dashbaord-project-detail-div">
              <div className="dsf-dashbaord-project-title-span dsf-span-m">
                {item.projectName.length > projNameLength
                  ? `${item.projectName.substring(0, projNameLength)}...`
                  : item.projectName}
              </div>
            </div>
          )}
          <div className="dsf-dashbaord-project-desc-span dsf-span-s">
            {" "}
            {item.projectDesc.length > projDescLength
              ? `${item.projectDesc.substring(0, projDescLength)}...`
              : item.projectDesc}
          </div>
        </div>
      </HtmlTooltip>
    </div>
  );
};
