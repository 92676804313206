import { ApiHelper,configByEnv } from './APIHelper';

const fetchUsers = async () => {
    const url = configByEnv().baseURL + '/User/GetUserDetails';
    try {
        const response = await ApiHelper.get(url);
        return response;
    }
    catch (e) {
        console.log('error', e)
        return e.response;
    }
}

const fetchUsersByMailId = async (userMailId) => {
    const url = configByEnv().baseURL + '/User/GetUserDetails?UserEmail='+userMailId;
    try {
        const response = await ApiHelper.get(url);
        return response;
    }
    catch (e) {
        console.log('error', e)
        return e.response;
    }
}

const UserDetailsService = {
    fetchUsers,
    fetchUsersByMailId
};
  
export default UserDetailsService;