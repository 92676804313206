import React from "react";
import { Button, Modal } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import error from "../../../../assets/images/error.svg";

const ModalDeleteWarning = (props) => {
  const [showModal, setShowModal] = React.useState(props.show);
  const {displayText} = props; 

  const onComplete = () => {
    setShowModal(false);
    props.onOk();
  };

  return (
    <Modal
      centered
      dialogClassName="dsf-modal-warning-lwd"
      contentClassName="dsf-modal-workflowModal"
      show={showModal}
    >
      <Modal.Header className="bw-modal-messagebox-header">
        <Modal.Title className="bw-warnmodal-header-title bw-messagebox-header-title">
          <img src={error} alt="error-Image" />
          <span>Are you sure ? </span>
        </Modal.Title>
        <img
          src={closeIcon}
          alt="Close-Image"
          className="img.dsf-close-messagebox-img"
          onClick={() => {
            setShowModal(false);
            props.onWarnModal(false);
          }}
        />
      </Modal.Header>
      <Modal.Body className="bw-modal-messagebox-body">
        <p className="bw-modal-messagebox-msg" style={{ textAlign: "left" }}>
            {displayText}  
        </p>
      </Modal.Body>
      <Modal.Footer className="bw-modal-footer">
        <Button
          className="bw-delist-footer-btn"
          variant="secondary"
          onClick={() => {
            setShowModal(false);
            props.onWarnModal(false);
          }}
        >
          Cancel
        </Button>
        <Button
          className="bw-delist-footer-btn"
          variant="secondary"
          onClick={() => onComplete()}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteWarning;
