import React, { PureComponent } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard/dashboard";
import Login from "../pages/Login/login";
// import appInsights from "../appInsights";
// import  {appInsightsService}  from "../appInsights";

class Routes extends PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <Switch>
            {/* <Route exact path="/" component={Login} /> */}
            <Route exact path="/" component={Dashboard} />
          </Switch>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

// function projectRoutes(props) {
//   return (
//     <React.Fragment>
//       <BrowserRouter>
//         <Switch>
//           <Route exact path="/" component={Login} />
//         </Switch>
//       </BrowserRouter>
//     </React.Fragment>
//   );
// }

// export default projectRoutes;

export default Routes;
// export default appInsightsService.appInsights(Routes);  
