import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";

import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";
import Header from "../../../../layouts/Header/header";
import Spinner from "react-bootstrap/Spinner";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import ActivityIndicator from "../../../../components/Loader/loader";
import pastDeadlineIcon from "../../../../assets/images/pastDeadlineIcon.svg";
import lessThan3dayIcon from "../../../../assets/images/pauseIcon.svg";
import completedDeadlineIcon from "../../../../assets/images/completedDeadlineIcon.svg";
import openIcon from "../../../../assets/images/openIcon.svg";
import statusComplete from "../../../../assets/images/stateComplete.svg";
import { arrCombochart } from "../../dashboardFixture";
import VerticalBarChartDesign from "../../../../helpers/VerticalBarChart";
import ModalCountryLaunchDetails from "./modalCountryLaunchDetails";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./../../dashboardGridTable.css";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import {
  fetchSnapshotDetails,
  fetchReviewAction,
} from "../../../../service/projectDetails";

import {
  statusfilterParams,
  formatNumber,
  datefilterParams,
  functionfilterParams,
} from "../../../../utils";

import "./modalSnapshot.css";
import { innoflexStatusEnum } from "../../../../globalConstants";

import statusCellRender from "./statusCellRender";
import statusFilter from "../../../../components/AgGridFilter/statusFilter";
import functionFilter from "../../../../components/AgGridFilter/functionFilter";

const colorCode = ["#EF5891", "#846CE2", "#846CE2", "#846CE2"];

const ModalSnapshot = (props) => {
  const [showModal, setShowModal] = React.useState(props.showModal);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDatagrid, setIsLoadingDatagrid] = useState(true);
  const [arrComboChartData, setArrComboChartData] = useState(null);
  const [isModalCountry, setIsModalCountry] = useState(false);
  let imgSrc = "";
  const [rowData, setRowData] = useState(null);

  const userDetails = useSelector((state) => state.userDetails);

  //Redux Start
  const dispatch = useDispatch();
  //Redux End

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingDatagrid(true);
    getSnapshotGraphData();
    getreviwActionDataGridData();
  }, []);

  const getreviwActionDataGridData = async () => {
    const res = await fetchReviewAction(props.selectedProjectId);
    console.log(res);
    if (res.status === 200) {
      res.data = res.data.filter((item) => item.contact.length > 0);
      setRowData(res.data);
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsLoadingDatagrid(false);
  };

  const getSnapshotGraphData = async () => {
    const res = await fetchSnapshotDetails(props.selectedProjectId);
    console.log(res);
    if (res.status === 200) {
      // let arr = res.data.map((item) => { return item; })
      // setArrComboChartData([...arr]);
      setArrComboChartData(Object.assign({}, res.data));
      console.log("scenarioList", arrComboChartData);
      imgSrc = res.data?.currentProjectPhase
        ? innoflexStatusEnum[res.data?.currentProjectPhase]
        : "Execute";
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsLoading(false);
  };
  const closeModal = () => {
    setShowModal(false);
    props.onComplete(false);
  };

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  // DefaultColDef sets props common to all Columns

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      floatingFilter: true,
      resizable: true,
    };
  }, []);
  const gridRef = useRef();

  const cellClickedListener = useCallback((event) => {
    console.log("cellClicked", event);
  }, []);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Function",
      field: "functionName",
      width: 150,
      filter: true,
      filterParams: functionfilterParams,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Action",
      field: "actionDetails",
      width: 150,
      filter: true,
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["contains"],
        buttons: ["clear"],
      },
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Contact",
      field: "contact",
      width: 300,
      filter: true,
      suppressMenu: true,
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["contains"],
        buttons: ["clear"],
      },
      floatingFilter: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Deadline",
      field: "deadline",
      width: 150,
      filter: "agDateColumnFilter",
      suppressMenu: true,
      floatingFilter: true,
      resizable: true,
      filterParams: datefilterParams,
      valueFormatter: (params) => {
        var date = new Date(params.value);
        var day = date.getDate().toString().padStart(2, "0");
        var month = (date.getMonth() + 1).toString().padStart(2, "0");
        var year = date.getFullYear().toString();
        return day + "/" + month + "/" + year;
      },
      sortable: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      field: "deadlineStatus",
      width: 120,
      suppressMenu: true,
      filter: true,
      filterParams: statusfilterParams,
      resizable: true,
      cellRenderer: statusCellRender,
      sortable: true,
      cellStyle: { textAlign: "left" },
    },
  ]);

  return (
    <>
      <Modal
        id="fullScreenModalId"
        dialogClassName="fullscreen-modal"
        contentClassName="snapshot-modal-content"
        show={showModal}
      >
        <div className="dsf-header-div">
          <Header />
        </div>
        {/* <div className="bw-snapshot-body"> */}

        <Row className="bw-snapshot-chart">
          <Col className="graph-col" xs={8}>
            {isLoading ? (
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  position: "relative",
                  top: "36%",
                }}
              >
                <ActivityIndicator />
              </div>
            ) : (
              <>
                <Row className=" snapshot-chart-parent-row">
                  <span className="dsf-size16 dsf-font-weight-700">
                    Weekly snapshot of predicted Business Waste
                  </span>
                </Row>
                <Row className=" snapshot-chart-parent-row">
                  <span
                    className="dsf-size12 dsf-font-weight-400"
                    style={{ color: "#CFCFCF" }}
                  >
                    Waste at launch date based on current data available in the
                    system. Showing if corrective actions are leading to
                    Business Waste reduction.
                  </span>
                </Row>
                <Row className="snapshot-chart-parent-row chart-info-div">
                  <div className="">
                    <span className="dsf-size10 dsf-font-weight-400">
                      Target
                    </span>
                    <span
                      className="dsf-size20 dsf-font-weight-700"
                      style={{ color: "#00B2FF" }}
                    >{`€ ${
                      formatNumber(arrComboChartData.data[0].scenarioBWRisk) ??
                      "-"
                    }`}</span>
                  </div>
                  <div className="">
                    <span className="dsf-size10 dsf-font-weight-400">
                      Change from Baseline
                    </span>
                    <span
                      className="dsf-size20 dsf-font-weight-700"
                      style={{ color: "#37BBA9" }}
                    >{`€ ${
                      formatNumber(arrComboChartData.changeFromBaseline) ?? "-"
                    }`}</span>
                  </div>
                  <div className="">
                    <span className="dsf-size10 dsf-font-weight-400">
                      Difference from Target
                    </span>
                    <span
                      className="dsf-size20 dsf-font-weight-700"
                      style={{ color: "#EC6157" }}
                    >{`€ ${
                      formatNumber(arrComboChartData.changeFromTarget) ?? "-"
                    }`}</span>
                  </div>
                </Row>
                <Row
                  className="combo_chart_div snapshot-chart-parent-row"
                  id="combo_chart_div"
                >
                  <VerticalBarChartDesign
                    container={"combo_chart_div"}
                    arrComboChartData={arrComboChartData}
                  />
                </Row>
              </>
            )}
          </Col>
          <Col className="snapshot-details-col" xs={3}>
            <div className="snapshot-details-parent-div">
              {!isLoading && (
                <>
                  <span className="dsf-size12 dsf-font-weight-700">
                    Snapshot Details
                  </span>
                  <br />
                  <Row className="details-row" style={{ marginTop: "10px" }}>
                    <Col className="col-2 snapshot-details-col">
                      <Row className="icon-parent-div">
                        <img
                          src={require(`../../../../assets/images/${
                            arrComboChartData.currentProjectPhase
                              ? innoflexStatusEnum[
                                  arrComboChartData.currentProjectPhase
                                ]
                              : "Execute"
                          }Phase.svg`)}
                          // src={require(`../../../../assets/images/ExecutePhase.svg`)}
                          alt="sort"
                          className={`${
                            arrComboChartData.currentProjectPhase === "2"
                              ? "dsf-status-execute-icon"
                              : "dsf-status-validate-icon"
                          }`}
                        />
                      </Row>
                      {/* <Row>
                        <span className="dsf-size10 dsf-font-weight-600">
                          {innoflexStatusEnum[
                            arrComboChartData.currentProjectPhase
                          ] ?? "Execute"}
                        </span>
                      </Row> */}
                    </Col>
                    <Col>
                      <span className="dsf-size10 dsf-font-weight-400">
                        {`Simulation locked during ${
                          innoflexStatusEnum[
                            arrComboChartData.currentProjectPhase
                          ]
                        }`}{" "}
                        <br /> project phase
                      </span>
                    </Col>
                  </Row>
                  <Row className="details-row">
                    <Col className="col-2 snapshot-details-col">
                      <Row className="icon-parent-div">
                        <img
                          src={require(`../../../../assets/images/${
                            arrComboChartData.currentProjectPhase
                              ? innoflexStatusEnum[
                                  arrComboChartData.currentProjectPhase
                                ]
                              : "Execute"
                          }Phase.svg`)}
                          alt="sort"
                          className={`${
                            arrComboChartData.currentProjectPhase === "2"
                              ? "dsf-status-execute-icon"
                              : "dsf-status-validate-icon"
                          }`}
                        />
                      </Row>
                      {/* <Row>
                        <span className="dsf-size10 dsf-font-weight-600">
                          {innoflexStatusEnum[
                            arrComboChartData.currentProjectPhase
                          ] ?? "Execute"}
                        </span>
                      </Row> */}
                    </Col>
                    <Col>
                      <span className="dsf-size10 dsf-font-weight-400">
                        Current project phase
                      </span>
                    </Col>
                  </Row>
                  <Row className="details-row">
                    <Col className="col-2 snapshot-details-col">
                      <Row className="icon-parent-div">
                        <img
                          src={statusComplete}
                          alt="sort"
                          className="dsf-workflow-icon hide"
                        />
                      </Row>
                    </Col>
                    <Col>
                      <span className="dsf-size10 dsf-font-weight-400">
                        Validated by R&D
                      </span>
                      <br />
                      <div className="rnd-list">
                        {arrComboChartData?.validatedByRnD?.map((item) => {
                          return (
                            <>
                              <span className="dsf-size10 dsf-font-weight-400">
                                {item}
                              </span>
                              <br />
                            </>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                  <Row className="details-row">
                    <Col className="col-2 snapshot-details-col">
                      <Row className="icon-parent-div">
                        <img
                          src={statusComplete}
                          alt="sort"
                          className="dsf-workflow-icon hide"
                        />
                      </Row>
                    </Col>
                    <Col>
                      <span className="dsf-size10 dsf-font-weight-400">
                        Validated by CM Ops
                      </span>
                      <br />
                      <span className="dsf-size10 dsf-font-weight-400">
                        {arrComboChartData.validatedByCMOps}
                      </span>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </Col>
          <Col className="country-details-col" xs={1}>
            <div className="snapshot-details-parent-div">
              {!isLoading && (
                <>
                  <span
                    className="dsf-size10 dsf-font-weight-400"
                    style={{ color: "#CFCFCF" }}
                  >
                    Countries & <br /> Launch Dates
                  </span>
                  {arrComboChartData.launchDatas.slice(0, 4).map((item) => {
                    return (
                      <div
                        className="details-row"
                        style={{ marginTop: "10px" }}
                      >
                        <p>{item.countryName}</p>
                        <p>{item.launchDate}</p>
                      </div>
                    );
                  })}
                  {arrComboChartData?.launchDatas?.length > 4 && (
                    <div
                      className="dsf-size10 dsf-font-weight-600"
                      style={{
                        color: "#CFCFCF",
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                      onClick={() => {
                        setIsModalCountry(!isModalCountry);
                      }}
                    >
                      Show All
                    </div>
                  )}
                </>
              )}
            </div>
            <img
              src={closeIcon}
              alt="Close-Image"
              className=" dsf-close-checkinnoflex-img bw-snapshot-close-img"
              onClick={() => closeModal()}
            />
          </Col>
          {/* </>} */}
        </Row>
        <div className="bw-snapshot-actionTracker">
          <Row>
            <Col md={2}>
              <div style={{ textAlign: "start" }}>
                <span className="dsf-size16 dsf-font-weight-400 dsf-title-color">
                  Review Actions
                  {/* <span className="dsf-size10 dsf-font-weight-400">
                    {" "}
                    (click to follow up)
                  </span> */}
                </span>
              </div>
            </Col>
            <Col md={4}>
              <Button
                className="buttonWidth"
                variant="secondary"
                onClick={() => clearFilters()}
              >
                Clear Filters
              </Button>
            </Col>
            <Col md={6}>
              <span className="dsf-size10 dsf-font-weight-400">
                <img src={completedDeadlineIcon} alt="Completed Deadline" />{" "}
                Completed{" "}
              </span>
              <span className="dsf-size10 dsf-font-weight-400">
                <img
                  src={lessThan3dayIcon}
                  alt="Less than 3 days to deadline"
                />{" "}
                Less than 3 days to deadline{" "}
              </span>
              <span className="dsf-size10 dsf-font-weight-400">
                <img src={pastDeadlineIcon} alt="Past deadline" /> Past deadline{" "}
              </span>
              <span className="dsf-size10 dsf-font-weight-400">
                <img src={openIcon} alt="Open" /> Open{" "}
              </span>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div
                className="ag-theme-alpine"
                style={{ height: 180, width: 1280 }}
              >
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData ? rowData : []}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  headerHeight={24}
                  floatingFiltersHeight={24}
                  rowHeight={24}
                  animateRows={true}
                  rowSelection="multiple"
                  onCellClicked={cellClickedListener}
                />
              </div>
            </Col>
          </Row>
          {isModalCountry && (
            <ModalCountryLaunchDetails
              showModal={isModalCountry}
              setShowModal={setIsModalCountry}
              data={arrComboChartData.launchDatas}
            />
          )}
        </div>
      </Modal>
    </>
  );
};
export default ModalSnapshot;
