import React from 'react';
import pastDeadlineIcon from "../../../../assets/images/pastDeadlineIcon.svg";
import lessThan3dayIcon from "../../../../assets/images/pauseIcon.svg";
import completedDeadlineIcon from "../../../../assets/images/completedDeadlineIcon.svg";
import openIcon from "../../../../assets/images/openIcon.svg";

  export default function statusCellRender(props) {
    let icon='';
    if(props.value=='Completed'){
        icon=completedDeadlineIcon;
    } else if(props.value == 'Past deadline'){
        icon=pastDeadlineIcon
    } else if(props.value == 'Less than 3 days to deadline'){
        icon=lessThan3dayIcon
    } else if(props.value == 'Open'){
      icon=openIcon
    }
    return <img src={icon}/>
  }
  