import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "react-bootstrap";
import "./autocomplete.css";
import addList from "../../assets/images/addList.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  //   width: "100%",
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: "0px 5px 0px 16px",
  height: "100%",
  color: "#EDF1F3",
  opacity: 0.8,
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-root": {
    alignItems: "unset",
  },
  "& .MuiInputBase-input": {
    color: "#EDF1F3",
    fontWeight: "400",
    maxWidth: "unset",
    justifyContent: "center",
    opacity: 0.8,
  },
}));

const CustomAutoCompleteDropdown = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    options,
    width,
    disable,
    limitTags,
    onSelectionChange,
    listClassName,
  } = props;

  const chipLabelLength = 20;

  useEffect(() => {
    setSelectedValues([]);
  }, [props.options]);

  const filteredOptions =
    Array.isArray(options) && options.length > 0
      ? options.filter((option) =>
          option?.label?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      : [];

  const toggleValue = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const handleDataAdd = () => {
    if (selectedValues.length > 0) {
      let selectedItem = [];
      selectedValues.map((value) => {
        let item = options.find((option) => option.label === value);
        selectedItem.push(item);
      });
      onSelectionChange(selectedItem);
    }
    setSearchTerm("");
    setSelectedValues([]);
  };

  return (
    <div className={`bwi-custom-autocomplete-list ${listClassName}`}>
      <div className="options-container">
        <div className="options-container-input-div">
          <Search className="dsf-delistingProj-search">
            <SearchIconWrapper className="dsf-delistingProj-search-icon">
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={"Search for available FGs and add"}
              value={searchTerm}
              className="dsf-delistingProj-search-text"
              onChange={(e) => setSearchTerm(e.target.value)}
              inputProps={{ "aria-label": "search" }}
              disabled={options.length === 0}
            />
          </Search>
          <div className="bwi-autocomplete-select-all">
            <Checkbox
              checked={
                selectedValues.length > 0 &&
                selectedValues.length ===
                  filteredOptions.filter((option) => !option.isDisable).length
              }
              disabled={!props.disable}
              onChange={() => {
                if (
                  selectedValues.length ===
                  filteredOptions.filter((option) => !option.isDisable).length
                ) {
                  setSelectedValues([]);
                } else {
                  // i need to select the values which are not disabled
                  setSelectedValues(
                    filteredOptions
                      .filter((option) => !option.isDisable)
                      .map((option) => option.label)
                  );
                }
              }}
              className="bwi-checkbox"
              disableRipple
            />
            <span>
              Select All ({options.filter((option) => !option.isDisable).length}
              )
            </span>
          </div>
        </div>
        <div
          className={`${
            options.length === 0 ? "bwi-no-options-list" : "options-list"
          }`}
          style={{
            maxWidth: width,
          }}
        >
          {options.length === 0 && (
            <div className="bwi-no-options">No options available to select</div>
          )}

          {filteredOptions.map((option) => (
            <div
              key={option.value}
              className={`option-row ${
                selectedValues.includes(option.label) ? "selected" : ""
              } ${
                option.isDisable
                  ? "dsf-option-row-txt-disable"
                  : !selectedValues.includes(option.label)
                  ? "bwi-option-row-not-selected"
                  : ""
              }  ${!props.disable ? "bwi-pointer-none" : ""}`}
              onClick={() => {
                toggleValue(option.label);
              }}
            >
              <Checkbox
                checked={selectedValues.includes(option.label)}
                onChange={() => toggleValue(option.label)}
                className="bwi-checkbox"
                disabled={!props.disable}
                disableRipple
              />
              {option.label}
            </div>
          ))}
        </div>
      </div>
      <Button
        variant="secondary"
        className="bw-delistBOMfg-btn bw-delistBOMfg-right-btn"
        onClick={handleDataAdd}
        disabled={selectedValues.length === 0}
      >
        {" "}
        <img
          src={addList}
          alt="buttonSelected"
          style={{ marginRight: "8px" }}
        />{" "}
        Add Selected
      </Button>
    </div>
  );
};

export default CustomAutoCompleteDropdown;
