export const checkInnoflexlaunchType=[
    {value: 1, label: 'Soft'},
    {value: 2, label: 'Hard'}
]
export const arrWorkFlow=[
    {
        projectID: '123',
        projectName: "Pele Luxe",
        projectShortDesc: "Short Desc",
        projectDesc: "Core Re-Stager of Magnum Double & Launch of Limited Edition Range",
        projectLeader:"Jennifer Omahan",
        brand:"Magnum",
        region:"Netherlands, UK, France, Germany",
        channel:"Hypers, Supers",
        projectStatus:"Launch",
        Category:"Ice Cream",
        innoflexStatus: "1",
        systemBW: '3.2m',
        scenarioBW: '',
        arrworkflow: [
            { workflowId: "1", status: "2" },
            { workflowId: "2", status: "2" },
            { workflowId: "3", status: "2" },
            { workflowId: "4", status: "5" },
            { workflowId: "5", status: "4" },
            { workflowId: "6", status: "1" },
            { workflowId: "7", status: "1" },
            { workflowId: "8", status: "1" },
            { workflowId: "9", status: "1" },
        ],
    },
    {
        projectID: '1234',
        projectName: "Project Name 1",
        projectShortDesc: "Short Desc",
        projectDesc: "Short Desc",
        projectLeader:"Test2",
        brand:"Magnum",
        region:"Region",
        channel:"Channel",
        projectStatus:"Launch",
        Category:"Ice Cream",
        innoflexStatus: "2",
        systemBW: '3.2m',
        scenarioBW: '2.7m',
        arrworkflow: [
            { workflowId: "1", status: "2" },
            { workflowId: "2", status: "2" },
            { workflowId: "3", status: "2" },
            { workflowId: "4", status: "2" },
            { workflowId: "5", status: "2" },
            { workflowId: "6", status: "2" },
            { workflowId: "7", status: "2" },
            { workflowId: "8", status: "2" },
            { workflowId: "9", status: "2" },
        ],
    },
];

export const arrCheckDelist = {
    finishedGoods: [
        {
            projectID: '1234',
            projectName: "Project Name 1", 
            FGImpacted:'SKU1',
            actionType:'Delist',
            delistedLocation:'DC2',
            locationName: 'DC2',
            soldAt:'MCO Germany'
        },
        {
            projectID: '1234',
            projectName: "Project Name 1", 
            FGImpacted:'SKU1',
            actionType:'BOM',
            delistedLocation:'SU1',
            locationName: 'SU1',
            soldAt:'MCO Germany'
        },
    ],
    possibleFinishedGoods: [
        {
            projectID: '1234',
            projectName: "Project Name 1",
            possibleFGImpacted:'SKU1',
            actionType:'BOM',
            possibleDelistedLocation:'SU1',
            locationName: 'SU1',
            soldAt:'MCO Germany'
        },
        {
            projectID: '1234',
            projectName: "Project Name 1",
            possibleFGImpacted:'SKU1',
            actionType:'Delist',
            possibleDelistedLocation:'DC1',
            locationName: 'DC1',
            soldAt:'MCO Germany'
        }

    ]
}

export const arrRPMList = [
    {
        projectID: '1234',
        projectName: "Project Name 1", 
        country:'Germay',
        material:'100000064690',
        MaterialDesc:'Californian Almond splits 2-15m',
        plantCode: 'A571',
        plantName:'Heppenheim',
        materialType: 'RM',
        uniqueTag:'Unique',
        isSelected:false
    },
    {
        projectID: '1234',
        projectName: "Project Name 1", 
        country:'Germay',
        material:'100000064690',
        MaterialDesc:'Californian Almond splits 2-15m',
        plantCode: 'A571',
        plantName:'Heppenheim',
        materialType: 'RM',
        uniqueTag:'Unique',
        isSelected:false
    },
    {
        projectID: '1234',
        projectName: "Project Name 1", 
        country:'Germay',
        material:'100000064690',
        MaterialDesc:'Californian Almond splits 2-15m',
        plantCode: 'A571',
        plantName:'Heppenheim',
        materialType: 'RM',
        uniqueTag:'Unique',
        isSelected:false
    },
]

export const arrE2E={
    projectID:"001",
    projectName:"PELE LUXE",
    projectFamily:"Magnum",
    projectDescription:"Core Re-Stager of Magnum Double & Launch of Limited Edition Range",
    projectStartDate:"10/02/2023",
    projectLaunchDate:"10/03/2023",
    tradeWindowStartDate:"10/02/2023",
    tradeWindowEndDate:"10/05/2023",
    projectDeadline:"",
    projectStage:"",
    constraints:"",//max length 256
    emailIdE2E:"",
    emailPreview:"",
    attachementList:[]
}

export const taskDetails = [
    {projectName: "PELE LUXE", member: "lavanya" },
    { projectName: "Another Project #3", member: "data" },
    { projectName: "Another Project #4", member: "lava" },
    { projectName: "Another Project #5", member: "" },
    { projectName: "Another Project #6", member: "" },
]
const scenarioArr = [
    {
        scenarioName: "Scenario A",
        scenarioDesc: "Reduce/ Stop production 2 months earlier",
        phase: "Explore",
        LaunchDate: "06/31/2023",
        value: [
            {
                parameterName: "Total Business Waste Risk",
                value: 25.94,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 11.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 8.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 10.94,
                colorCode: '#846CE2'
            }
        ],
        volume: [
            {
                parameterName: "Total Business Waste Risk",
                value: 18.94,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 12.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 8.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 9.94,
                colorCode: '#846CE2'
            }
        ]
    },
    {
        scenarioName: "Scenario B",
        scenarioDesc: "Increase Demand",
        phase: "Explore",
        LaunchDate: "05/31/2023",
        value: [
            {
                parameterName: "Total Business Waste Risk",
                value: 8.94,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 21.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 18.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 10.94,
                colorCode: '#846CE2'
            }
        ],
        volume: [
            {
                parameterName: "Total Business Waste Risk",
                value: 18.94,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 12.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 8.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 9.94,
                colorCode: '#846CE2'
            }
        ]
    },
    {
        scenarioName: "Scenario C",
        scenarioDesc: "Increase Demand",
        phase: "Explore",
        LaunchDate: "05/31/2023",
        value: [
            {
                parameterName: "Total Business Waste Risk",
                value: 8.94,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 21.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 18.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 10.94,
                colorCode: '#846CE2'
            }
        ],
        volume: [
            {
                parameterName: "Total Business Waste Risk",
                value: 8.94,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 21.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 18.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 19.94,
                colorCode: '#846CE2'
            }
        ]
    },
    {
        scenarioName: "Scenario D",
        scenarioDesc: "Increase Demand",
        phase: "Explore",
        LaunchDate: "05/31/2023",
        value: [
            {
                parameterName: "Total Business Waste Risk",
                value: 8.94,
                colorCode:'#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 21.94,
                colorCode:'#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 18.94,
                colorCode:'#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 10.94,
                colorCode:'#846CE2'
            }
        ],
        volume: [
            {
                parameterName: "Total Business Waste Risk",
                value: 8.94,
                colorCode:'#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 21.94,
                colorCode:'#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 18.94,
                colorCode:'#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 19.94,
                colorCode:'#846CE2'
            }
        ]
    },

]

export const scenarioList = {
    projectID: 1,
    projectName: "Pele Luxe",
    currentPos: {
        scenarioName: "Current Position",
        scenarioDesc: " As is position of Goods",
        value: [
            {
                parameterName: "Total Business Waste Risk",
                value: 1000,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 600,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 900,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 500,
                colorCode: '#846CE2'
            }
        ],
        volume: [
            {
                parameterName: "Total Business Waste Risk",
                value: 18.94,
                colorCode: '#EF5891'
            },
            {
                parameterName: "Finished Goods",
                value: 2.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Raw Materials",
                value: 12.94,
                colorCode: '#846CE2'
            },
            {
                parameterName: "Pack Materials",
                value: 19.94,
                colorCode: '#846CE2'
            }
        ],
    },
    arrScenario: [],
    // arrScenario:[]
}

export const arrLinechart = {
    materialCode: 1,
    materialDesc: "test",
    data: [
        {
            name: "Week1",
            inventory: 100111,
            forecast: 1501111,
            production: 2011110,

        },
        {
            name: "Week2",
            inventory: 5111100,
            forecast: 1111110,
            production: 4111120,

        },
        {
            name: "Week3",
            inventory: 111150,
            forecast: 3111150,
            production: 2901111,

        }
    ],

    // {
    //     countryCOde: "german",
    //     materialCode: 2,
    //     materialDesc: "test1",
    //     data: [
    //         {
    //             name: "Week1",
    //             inventory: 100111,
    //             forecast: 1501111,
    //             production: 2011110,

    //         },
    //         {
    //             name: "Week2",
    //             inventory: 5111100,
    //             forecast: 1111110,
    //             production: 4111120,

    //         },
    //         {
    //             name: "Week3",
    //             inventory: 111150,
    //             forecast: 3111150,
    //             production: 2901111,

    //         }
    //     ],
    // }

};


export const arrMaterialList = [
    {
        Country: "Germany",
        materialList: [
            {
                materialCode:'1',
                materialDesc:'Test1'
            },
            {
                materialCode:'2',
                materialDesc:'Test2'
            },
            {
                materialCode:'3',
                materialDesc:'Test3'
            }
        ]
    }, 
    {
        Country: "France",
        materialList: [
            {
                materialCode:'1',
                materialDesc:'Test'
            },
            {
                materialCode:'2',
                materialDesc:'Test'
            },
            {
                materialCode:'3',
                materialDesc:'Test'
            }
        ]

    }
]


export const arrRequiredAction = [
    {
        functionName:"Marketing",
        actionDetails:'none'
    },
    {
        functionName:"Procurement",
        actionDetails:'(Snake Plissken) stop contract for 100000040607 Chocolate dark pieces 70pct with 0000380419 BARRY CALLEBAUT BELGIUM NV for 1st of April '
    },
    {
        functionName:"Material Planning",
        actionDetails:'none'
    },
    {
        functionName:"Demand Planning",
        actionDetails:'Decrease Forecast from WK 10 to Wk 13 to match worst case scenario E2E Planning - Inform mat'
    },
    {
        functionName:"E2E planning",
        actionDetails:'none'
    }

];

export const arrICMList ={
    supplierCode : "0050285888",
    supplier : "Multipack Holdings",
    fgCode : "68933560",
    fgName : "Magnum dairy free ...",
    rpmCode : "TBC",
    rpmName : "TBC",
    inventoryRPMtonnes : "TBC",
    inventoryRPMk : "TBC",
    weekOfRPM : "TBC",
    consumptionRPM : "TBC",
}

export const arrCombochart = {
    changeFromBaseline: 0.9,
    changeFromTarget: 0.01,
    currentProjectPhase: 'Validate',
    validationByRnD: 'Hans Giger 01-04-2023',
    validationByCMOps: 'Snake Plissken 09-04-2023',
    scenarioName:'Scenario A',
    data: [
        {
            name: "Initial",
            BWRisk: 2000,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week1",
            BWRisk: 1300,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week2",
            BWRisk: 400,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week3",
            BWRisk: 550,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week4",
            BWRisk: 950,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week5",
            BWRisk: 1350,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week4",
            BWRisk: 350,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week4",
            BWRisk: 350,
            scenarioBWRisk: 1050,

        },
        {
            name: "Week4",
            BWRisk: 350,
            scenarioBWRisk: 1050,

        },
    ],

};

export const delistingProjectInput1 = {
    "classification": [
      "Renovation"
    ],
    "projectStatus": [
      "Active"
    ],
    "projectPhaseType": [
      "Temporary"
    ],
    "leadChannels": [
      "Hypers & Supers",
      "Health & Beauty (incl. pharmacy)",
      "Value",
      "Proximity",
      "eCommerce",
      "Out of home",
      "Home Care Professional",
      "Travel Retail"
    ],
    "groupDetails": [
      {
        "groupCode": "CF1315",
        "groupName": "Nutrition",
        "categoryDetails": [
          {
            "categoryCode": "CF1070",
            "categoryName": "Scratch Cooking Aids",
            "brandDetails": [
              {
                "brandCode": "BF0020",
                "brandName": "Amino"
              },
              {
                "brandCode": "BF0644",
                "brandName": "Oswald"
              },
              {
                "brandCode": "BF0426",
                "brandName": "Knorr"
              }
            ]
          },
          {
            "categoryCode": "CF1069",
            "categoryName": "Beverages",
            "brandDetails": [
              {
                "brandCode": "BF0646",
                "brandName": "Other Brands (N)"
              },
              {
                "brandCode": "BF8391",
                "brandName": "Portugal (N)"
              },
              {
                "brandCode": "BF0529",
                "brandName": "Lipton Yellow Label"
              },
              {
                "brandCode": "BF1069",
                "brandName": "Lipton Fusion"
              }
            ]
          },
          {
            "categoryCode": "CF1062",
            "categoryName": "Dressings",
            "brandDetails": [
              {
                "brandCode": "BF0426",
                "brandName": "Knorr"
              },
              {
                "brandCode": "BF0929",
                "brandName": "Tortex"
              },
              {
                "brandCode": "BF0181",
                "brandName": "Chirat"
              },
              {
                "brandCode": "BF0199",
                "brandName": "Colman's"
              },
              {
                "brandCode": "BF0257",
                "brandName": "DOB (N)"
              },
              {
                "brandCode": "BF0759",
                "brandName": "Savora"
              },
              {
                "brandCode": "BF0406",
                "brandName": "Jif (N)"
              }
            ]
          }
        ]
      }
    ],
    "countryDetails": [
      {
        "countryCode": "AD",
        "geographyName": "Andorra"
      },
      {
        "countryCode": "AE",
        "geographyName": "United Arab Emirates"
      },
      {
        "countryCode": "AF",
        "geographyName": "Afghanistan"
      },
      {
        "countryCode": "AG",
        "geographyName": "Antigua and Barbuda"
      },
      {
        "countryCode": "AI",
        "geographyName": "Anguilla"
      },
      {
        "countryCode": "AL",
        "geographyName": "Albania"
      },
      {
        "countryCode": "AM",
        "geographyName": "Armenia"
      },
      {
        "countryCode": "AN",
        "geographyName": "Netherlands Antilles"
      },
      {
        "countryCode": "AO",
        "geographyName": "Angola"
      },
      {
        "countryCode": "AQ",
        "geographyName": "Antarctica"
      },
      {
        "countryCode": "AR",
        "geographyName": "Argentina"
      },
      {
        "countryCode": "AS",
        "geographyName": "American Samoa"
      },
      {
        "countryCode": "AT",
        "geographyName": "Austria"
      }
    ]
  };