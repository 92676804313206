import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ActivityIndicator from "../../../../components/Loader/loader";
import "./modalUniqueRPM.css";
import { handleSort } from "../../../../utils";
import AutoCompleteFilter from "../../../../components/Autocomplete/autocompleteFilter";
import ArrowUp from "../../../../assets/images/Arrow up sm.svg";
import ArrowDown from "../../../../assets/images/Arrow down sm.svg";
import polygon from "../../../../assets/images/Polygon-enable.svg";
import polygonClose from "../../../../assets/images/PolygonClose.svg";
import { Checkbox } from "@mui/material";
import { uniqueRPMFilterEnum } from "../../../../globalConstants";
import ModalRedundantDetails from "../../TaskflowScreens/UniqueRPM/modalRedundantError";

const UniqueRPM = (props) => {
  const [objUniqueRPM, setObjUniqueRPM] = useState(props.objUniqueRPM);
  const [objFilteredRPM, setObjFilteredRPM] = useState();
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const {
    isEdit,
    isReceipientEnable,
    isRNDPIUser,
    isCheckboxDisabled,
    selectionValueChange,
    isDataNotAvailable,
  } = props;
  const [activeSort, setActiveSort] = useState();
  const [isSortActive, setIsSortActive] = useState(false);
  const [countryIsOpen, setCountryIsOpen] = useState(false);
  const [skuCodeIsOpen, setSkuCodeIsOpen] = useState(false);
  const [skuNameIsOpen, setSkuNameIsOpen] = useState(false);
  const [plantCodeIsOpen, setPlantCodeIsOpen] = useState(false);
  const [plantNameIsOpen, setPlantNameIsOpen] = useState(false);
  const [materialTypeIsOpen, setMaterialTypeIsOpen] = useState(false);
  const [recipientIsOpen, setRecipientIsOpen] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [gridData, setGridData] = useState({});
  const [changeStatus, setChangeStatus] = useState({});
  const [rpmFilterEnum, setRPMFilterEnum] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [currentItem, setCurrentItem] = useState("");

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    setFilterData({});
    setGridData({});
    setIsLoading(props.isLoading);
    setObjUniqueRPM(props.objUniqueRPM);
    setObjFilteredRPM(props.objUniqueRPM);
    //handleInitialFilterData(props.objUniqueRPM);
    setSelectedAll(false);
  }, [props.isLoading, props.objUniqueRPM]);

  useEffect(() => {
    let updatedEnum = { ...uniqueRPMFilterEnum };
    if (isReceipientEnable) {
      updatedEnum = {
        ...updatedEnum,
        7: "recipientEmail",
      };
      setRPMFilterEnum(updatedEnum);
    }
    setRPMFilterEnum(updatedEnum);
    handleInitialFilterData(props.objUniqueRPM, updatedEnum);
  }, [isReceipientEnable, props.objUniqueRPM]);

  const handleInitialFilterData = (obj, updatedEnum) => {
    if (obj && obj.uniqueRMPMDetail && obj.uniqueRMPMDetail.length > 0) {
      let filterDataInitial = {};

      Object.values(updatedEnum).forEach((filterType) => {
        filterDataInitial[filterType] = {
          selectedData: [],
          checkData: [],
          optionData: [],
          modifiedFilter: false,
        };
      });

      obj.uniqueRMPMDetail.forEach((item) => {
        Object.values(updatedEnum).forEach((filterType) => {
          if (item[filterType] !== undefined) {
            filterDataInitial[filterType].selectedData = [
              ...new Set([
                ...filterDataInitial[filterType].selectedData,
                item[filterType],
              ]),
            ];
          }
        });
      });

      Object.values(updatedEnum).forEach((filterType) => {
        filterDataInitial[filterType].optionData =
          filterDataInitial[filterType].selectedData;
        filterDataInitial[filterType].checkData =
          filterDataInitial[filterType].selectedData;
      });
      setFilterData(filterDataInitial);
      setGridData(filterDataInitial);
      setChangeStatus(filterDataInitial);
    }
  };

  useEffect(() => {
    handleFilterChange();
    if (objFilteredRPM && objFilteredRPM.uniqueRMPMDetail && objFilteredRPM.uniqueRMPMDetail.length > 0) {
      objFilteredRPM.uniqueRMPMDetail.filter((item) => item.isRowSelected)
        .length < objFilteredRPM.uniqueRMPMDetail.length
        ? setSelectedAll(false)
        : setSelectedAll(true);
    } else {
      setSelectedAll(false);
    }
  }, [changeStatus]);

  useEffect(() => {
    if (objUniqueRPM) {
      const updateObjUniqueRPM = Object.assign({}, objFilteredRPM);
      if (!isReceipientEnable) {
        if (isSelectedAll) {
          updateObjUniqueRPM?.uniqueRMPMDetail.map(
            (item, i) => (item.isSelected = true)
          );
        } else {
          if (
            !updateObjUniqueRPM.uniqueRMPMDetail.filter(
              (item) => !item.isSelected
            ).length
          ) {
            updateObjUniqueRPM?.uniqueRMPMDetail.map(
              (item, i) => (item.isSelected = false)
            );
          }
        }
      } else {
        if (isSelectedAll) {
          updateObjUniqueRPM?.uniqueRMPMDetail.map(
            (item, i) => (item.isRowSelected = true)
          );
        } else {
          if (
            !updateObjUniqueRPM.uniqueRMPMDetail.filter(
              (item) => !item.isRowSelected
            ).length
          ) {
            updateObjUniqueRPM?.uniqueRMPMDetail.map(
              (item, i) => (item.isRowSelected = false)
            );
          }
        }
      }
      setObjFilteredRPM(updateObjUniqueRPM);
      selectionValueChange(updateObjUniqueRPM);
    }
  }, [isSelectedAll]);

  const handleSelectionChange = (event, item) => {
    if (filterData && gridData) {
      let filterDataInitial = { ...filterData };
      let gridDataInitial = { ...gridData };

      if (filterDataInitial[item] && event) {
        let data1 = gridDataInitial[item].optionData.filter((item) =>
          event.includes(item)
        );

        if (gridDataInitial[item].optionData.length === data1.length) {
          filterDataInitial[item].modifiedFilter = false;
        } else {
          filterDataInitial[item].modifiedFilter = true;
        }
        filterDataInitial[item].selectedData = event;
      }

      let filterObj = { ...objUniqueRPM };
      if (filterObj && filterObj.uniqueRMPMDetail) {
        Object.values(rpmFilterEnum).forEach((filterType) => {
          if (filterDataInitial && filterDataInitial[filterType]) {
            filterObj.uniqueRMPMDetail = filterObj.uniqueRMPMDetail.filter(
              (item) => {
                const matchesFilter =
                  item[filterType] !== undefined &&
                  filterDataInitial[filterType].selectedData.includes(
                    item[filterType]
                  );
                return matchesFilter;
              }
            );
          }
        });
        setObjFilteredRPM(filterObj);
        // update selectedData for all filter from filterObj if modifiedFilter is false
        if (filterObj.uniqueRMPMDetail.length > 0) {
          filterObj.uniqueRMPMDetail.filter((item) => item.isRowSelected)
            .length < filterObj.uniqueRMPMDetail.length
            ? setSelectedAll(false)
            : setSelectedAll(true);
        }
      }

      Object.values(rpmFilterEnum).forEach((filterType) => {
        if (
          filterDataInitial &&
          filterDataInitial[filterType] &&
          gridDataInitial &&
          gridDataInitial[filterType]
        ) {
          filterDataInitial[filterType].checkData = [];
          filterObj.uniqueRMPMDetail.forEach((item) => {
            if (item[filterType] !== undefined) {
              filterDataInitial[filterType].checkData = [
                ...new Set([
                  ...filterDataInitial[filterType].checkData,
                  item[filterType],
                ]),
              ];

              if (filterDataInitial[filterType].modifiedFilter) {
                gridDataInitial[filterType].optionData = [
                  ...new Set([
                    ...gridDataInitial[filterType].optionData,
                    item[filterType],
                  ]),
                ];
              }
            }
          });
        }
      });
      setFilterData(filterDataInitial);
      setChangeStatus(filterDataInitial);
      setGridData(gridDataInitial);
    }
  };

  const handleFilterChange = async () => {
    handleOptionChange(objFilteredRPM);
  };

  const handleOptionChange = (Obj) => {
    let filterDataInitial = { ...filterData };
    if (
      Obj &&
      Obj.uniqueRMPMDetail &&
      objUniqueRPM &&
      objUniqueRPM.uniqueRMPMDetail &&
      filterDataInitial
    ) {
      Object.values(rpmFilterEnum).forEach((filterType) => {
        let filteredData = [
          ...new Set(
            objUniqueRPM.uniqueRMPMDetail
              .filter((i) => !Obj.uniqueRMPMDetail.includes(i))
              .map((item) => item[filterType])
          ),
        ].filter((i) => !filterDataInitial[filterType].checkData.includes(i));
        let filteredObj = [
          ...new Set(
            objUniqueRPM.uniqueRMPMDetail
              .filter((i) => !Obj.uniqueRMPMDetail.includes(i))
              .map((item) => item[filterType])
          ),
        ].filter(
          (i) => !filterDataInitial[filterType].selectedData.includes(i)
        );

        if (
          filteredData.length > 0 &&
          filteredObj.length > 0 &&
          filterDataInitial[filterType].modifiedFilter === false &&
          filteredData.length !== filteredObj.length
        ) {
          filterDataInitial[filterType].optionData =
            filterDataInitial[filterType].checkData;
        }
      });

      Object.values(rpmFilterEnum).forEach((filterType) => {
        if (
          filterDataInitial &&
          filterDataInitial[filterType] &&
          filterDataInitial[filterType].modifiedFilter === false
        ) {
          filterDataInitial[filterType].optionData = [];
          Obj.uniqueRMPMDetail.forEach((item) => {
            if (item[filterType] !== undefined) {
              filterDataInitial[filterType].optionData = [
                ...new Set([
                  ...filterDataInitial[filterType].optionData,
                  item[filterType],
                ]),
              ];
            }
          });
        }
      });
      setFilterData(filterDataInitial);
    }
  };

  const handleSortChange = (event) => {
    const itemId = event.target.id.split("-")[1];
    if (objUniqueRPM && objUniqueRPM.uniqueRMPMDetail.length > 0) {
      setIsSortActive(true);
      changeActiveSort(itemId);
    }
  };

  const changeActiveSort = async (item) => {
    let sortedArray = [];
    setActiveSort(item);
    if (item === "skuCode" || item === "material") {
      if (!isSortActive) {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "ascending",
          item,
          false
        );
      } else {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "descending",
          item,
          false
        );
        setIsSortActive(!isSortActive);
      }
    } else {
      if (!isSortActive) {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "ascending",
          item,
          true
        );
      } else {
        sortedArray = await handleSort(
          objFilteredRPM.uniqueRMPMDetail,
          "descending",
          item,
          true
        );
        setIsSortActive(!isSortActive);
      }
    }
    setObjFilteredRPM((prevObj) => ({
      ...prevObj,
      uniqueRMPMDetail: [...sortedArray],
    }));
  };

  const onCheckBoxAllClicked = (e) => {
    setSelectedAll(!isSelectedAll);
  };

  const onCheckBoxClicked = async (e, index) => {
    if (isEdit) {
      const updateObjUniqueRPM = Object.assign({}, objFilteredRPM);
      const objRPM = updateObjUniqueRPM.uniqueRMPMDetail.filter(
        (item, i) => i === index
      )[0];
      if (isReceipientEnable) {
        objRPM.isRowSelected = !objRPM.isRowSelected;
        updateObjUniqueRPM.uniqueRMPMDetail.filter((item) => item.isRowSelected)
          .length < updateObjUniqueRPM.uniqueRMPMDetail.length
          ? setSelectedAll(false)
          : setSelectedAll(true);
      } else {
        objRPM.isSelected = !objRPM.isSelected;
        updateObjUniqueRPM.uniqueRMPMDetail.filter((item) => item.isSelected)
          .length < updateObjUniqueRPM.uniqueRMPMDetail.length
          ? setSelectedAll(false)
          : setSelectedAll(true);
      }
      setObjFilteredRPM(updateObjUniqueRPM);
      selectionValueChange(updateObjUniqueRPM);
    }
  };

  return (
    <>
      <div className="scroll-view-div">
        <Row
          className={`dsf-uniqueRPM-row dsf-uniqueRPM-header-row ${
            isRNDPIUser ? "dsf-rnd-piuser-row" : ""
          }`}
        >
          <Col
            className="checkboxDiv header-checkbox dsf-uniqueRPM-header-filter-col"
            xs={1}
          >
            <div className="header-label-country">
              <Checkbox
                checked={isSelectedAll}
                id={`default-checkbox`}
                hidden={isRNDPIUser ? true : (isCheckboxDisabled ? true : false)}
                disabled={
                  !isEdit ||
                  isDataNotAvailable ||
                  (objFilteredRPM &&
                    objFilteredRPM.uniqueRMPMDetail &&
                    objFilteredRPM.uniqueRMPMDetail.length === 0)
                }
                onClick={(e) => onCheckBoxAllClicked(e)}
                className="dsf-uniqueRPM-checkbox"
                disableRipple
              />
              {isRNDPIUser && <div className="gap-div"></div>}
              <div
                id="dsfrpmid-country"
                onClick={handleSortChange}
                className="dsf-rpm-country-selection"
              >
                <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                  Country
                </span>
                {activeSort === "country" && (
                  <img
                    src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                    className="dsf-rpm-filter-arrow"
                  />
                )}
              </div>
            </div>
            <div className="header-label-country-filter">
              <img
                className={`dsf-uniquerpm-country-dropdown-toggle ${
                  countryIsOpen
                    ? "dsf-rpm-dropdown-filter-open"
                    : "dsf-rpm-dropdown-filter-close"
                }`}
                src={countryIsOpen ? polygon : polygonClose}
                hidden={isDataNotAvailable}
                onClick={() => {
                  setCountryIsOpen(!countryIsOpen);
                }}
              />
              <AutoCompleteFilter
                options={
                  filterData &&
                  filterData.country &&
                  filterData.country.optionData
                    ? filterData.country.optionData
                    : []
                }
                selectedValues={filterData?.country?.selectedData}
                gridFilterData={filterData?.country?.checkData}
                //setSelectedValues={setCountryFilter}
                onSelectionChange={(e) => handleSelectionChange(e, "country")}
                maxLength="487px"
                maxDropdownHeight="150px"
                width="130px"
                isOpen={countryIsOpen}
                setIsOpen={setCountryIsOpen}
                disable={false}
                listClassName={`dsf-uniquerpm-country-dropdown dsf-uniquerpm-dropdown-filter ${
                  countryIsOpen
                    ? "dsf-rpm-dropdown-open"
                    : "dsf-rpm-dropdown-close"
                }`}
              />
            </div>
          </Col>
          <Col xs={1} className="dsf-col-rpm-material">
            <div
              className="header-label-material"
              onClick={handleSortChange}
              id="dsfrpmid-material"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                Material
              </span>
              {activeSort === "material" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
          </Col>
          <Col xs={2} className="dsf-col-rpm-material-description">
            <div
              className="header-label-material-description"
              onClick={handleSortChange}
              id="dsfrpmid-materialDescription"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                Material Description
              </span>
              {activeSort === "materialDescription" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
          </Col>
          <Col xs={1} className="dsf-uniqueRPM-header-filter-col">
            <div
              className="header-label-sku"
              onClick={handleSortChange}
              id="dsfrpmid-skuCode"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                FG Code
              </span>
              {activeSort === "skuCode" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
            <div className="header-label-sku-filter">
              <img
                className={`dsf-uniquerpm-skucode-dropdown-toggle ${
                  skuCodeIsOpen
                    ? "dsf-rpm-dropdown-filter-open"
                    : "dsf-rpm-dropdown-filter-close"
                }`}
                src={skuCodeIsOpen ? polygon : polygonClose}
                hidden={isDataNotAvailable}
                onClick={() => {
                  setSkuCodeIsOpen(!skuCodeIsOpen);
                }}
              />
              <AutoCompleteFilter
                options={
                  filterData &&
                  filterData.skuCode &&
                  filterData.skuCode.optionData
                    ? filterData.skuCode.optionData
                    : []
                }
                selectedValues={filterData?.skuCode?.selectedData}
                gridFilterData={filterData?.skuCode?.checkData}
                // setSelectedValues={setSkuCodeFilter}
                onSelectionChange={(e) => handleSelectionChange(e, "skuCode")}
                maxLength="487px"
                maxDropdownHeight="150px"
                width="130px"
                isOpen={skuCodeIsOpen}
                setIsOpen={setSkuCodeIsOpen}
                disable={false}
                listClassName={`dsf-uniquerpm-skucode-dropdown dsf-uniquerpm-dropdown-filter ${
                  skuCodeIsOpen
                    ? "dsf-rpm-dropdown-open"
                    : "dsf-rpm-dropdown-close"
                }`}
              />
            </div>
          </Col>
          <Col xs={2}>
            <div
              className="header-label-sku-name"
              onClick={handleSortChange}
              id="dsfrpmid-skuName"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                FG Name
              </span>
              {activeSort === "skuName" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
            <div className="header-label-sku-name-filter">
              <img
                className={`dsf-uniquerpm-skuname-dropdown-toggle ${
                  skuNameIsOpen
                    ? "dsf-rpm-dropdown-filter-open"
                    : "dsf-rpm-dropdown-filter-close"
                }`}
                src={skuNameIsOpen ? polygon : polygonClose}
                onClick={() => {
                  setSkuNameIsOpen(!skuNameIsOpen);
                }}
                hidden={isDataNotAvailable}
              />
              <AutoCompleteFilter
                options={
                  filterData &&
                  filterData.skuName &&
                  filterData.skuName.optionData
                    ? filterData.skuName.optionData
                    : []
                }
                selectedValues={filterData?.skuName?.selectedData}
                gridFilterData={filterData?.skuName?.checkData}
                // setSelectedValues={setSkuNameFilter}
                onSelectionChange={(e) => handleSelectionChange(e, "skuName")}
                maxLength="487px"
                maxDropdownHeight="180px"
                width="200px"
                isOpen={skuNameIsOpen}
                setIsOpen={setSkuNameIsOpen}
                disable={false}
                listClassName={`dsf-uniquerpm-skuname-dropdown dsf-uniquerpm-dropdown-filter ${
                  skuNameIsOpen
                    ? "dsf-rpm-dropdown-open"
                    : "dsf-rpm-dropdown-close"
                }`}
              />
            </div>
          </Col>
          <Col xs={1} className="dsf-uniqueRPM-header-filter-col">
            <div
              className="header-label-plant-code"
              onClick={handleSortChange}
              id="dsfrpmid-plantCode"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                Plant Code
              </span>
              {activeSort === "plantCode" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
            <div className="header-label-plant-code-filter">
              <img
                className={`dsf-uniquerpm-plantcode-dropdown-toggle ${
                  plantCodeIsOpen
                    ? "dsf-rpm-dropdown-filter-open"
                    : "dsf-rpm-dropdown-filter-close"
                }`}
                src={plantCodeIsOpen ? polygon : polygonClose}
                hidden={isDataNotAvailable}
                onClick={() => {
                  setPlantCodeIsOpen(!plantCodeIsOpen);
                }}
              />
              <AutoCompleteFilter
                options={
                  filterData &&
                  filterData.plantCode &&
                  filterData.plantCode.optionData
                    ? filterData.plantCode.optionData
                    : []
                }
                selectedValues={filterData?.plantCode?.selectedData}
                gridFilterData={filterData?.plantCode?.checkData}
                // setSelectedValues={setPlantCodeFilter}
                onSelectionChange={(e) => handleSelectionChange(e, "plantCode")}
                maxLength="487px"
                maxDropdownHeight="150px"
                width="130px"
                isOpen={plantCodeIsOpen}
                setIsOpen={setPlantCodeIsOpen}
                disable={false}
                listClassName={`dsf-uniquerpm-plantcode-dropdown dsf-uniquerpm-dropdown-filter ${
                  plantCodeIsOpen
                    ? "dsf-rpm-dropdown-open"
                    : "dsf-rpm-dropdown-close"
                }`}
              />
            </div>
          </Col>
          <Col xs={2} className="dsf-col-rpm-plant-name">
            <div
              className="header-label-plant-name"
              onClick={handleSortChange}
              id="dsfrpmid-plantName"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                Plant Name
              </span>
              {activeSort === "plantName" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
            <div className="header-label-plant-name-filter">
              <img
                className={`dsf-uniquerpm-plantname-dropdown-toggle ${
                  plantNameIsOpen
                    ? "dsf-rpm-dropdown-filter-open"
                    : "dsf-rpm-dropdown-filter-close"
                }`}
                src={plantNameIsOpen ? polygon : polygonClose}
                hidden={isDataNotAvailable}
                onClick={() => {
                  setPlantNameIsOpen(!plantNameIsOpen);
                }}
              />
              <AutoCompleteFilter
                options={
                  filterData &&
                  filterData.plantName &&
                  filterData.plantName.optionData
                    ? filterData.plantName.optionData
                    : []
                }
                selectedValues={filterData?.plantName?.selectedData}
                gridFilterData={filterData?.plantName?.checkData}
                // setSelectedValues={setPlantNameFilter}
                onSelectionChange={(e) => handleSelectionChange(e, "plantName")}
                maxLength="487px"
                maxDropdownHeight="180px"
                width="200px"
                isOpen={plantNameIsOpen}
                setIsOpen={setPlantNameIsOpen}
                disable={false}
                listClassName={`dsf-uniquerpm-plantname-dropdown dsf-uniquerpm-dropdown-filter ${
                  plantNameIsOpen
                    ? "dsf-rpm-dropdown-open"
                    : "dsf-rpm-dropdown-close"
                }`}
              />
            </div>
          </Col>
          <Col
            xs={1}
            className="dsf-col-rpm-material-type dsf-uniqueRPM-header-filter-col"
          >
            <div
              className="header-label-material-type"
              onClick={handleSortChange}
              id="dsfrpmid-materialType"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span dsf-desc-length-txt">
                Material Type
              </span>
              {activeSort === "materialType" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
            <div className="header-label-material-type-filter">
              <img
                className={`dsf-uniquerpm-materialtype-dropdown-toggle ${
                  materialTypeIsOpen
                    ? "dsf-rpm-dropdown-filter-open"
                    : "dsf-rpm-dropdown-filter-close"
                }`}
                src={materialTypeIsOpen ? polygon : polygonClose}
                hidden={isDataNotAvailable}
                onClick={() => {
                  setMaterialTypeIsOpen(!materialTypeIsOpen);
                }}
              />
              <AutoCompleteFilter
                options={
                  filterData &&
                  filterData.materialType &&
                  filterData.materialType.optionData
                    ? filterData.materialType.optionData
                    : []
                }
                selectedValues={filterData?.materialType?.selectedData}
                gridFilterData={filterData?.materialType?.checkData}
                // setSelectedValues={setMaterialTypeFilter}
                onSelectionChange={(e) =>
                  handleSelectionChange(e, "materialType")
                }
                maxLength="487px"
                maxDropdownHeight="150px"
                width="130px"
                isOpen={materialTypeIsOpen}
                setIsOpen={setMaterialTypeIsOpen}
                disable={false}
                listClassName={`dsf-uniquerpm-materialtype-dropdown dsf-uniquerpm-dropdown-filter ${
                  materialTypeIsOpen
                    ? "dsf-rpm-dropdown-open"
                    : "dsf-rpm-dropdown-close"
                }`}
              />
            </div>
          </Col>
          {isReceipientEnable && <Col xs={2} className="dsf-col-rpm-recipient">
            <div
              className="header-label-recipient"
              onClick={handleSortChange}
              id="dsfrpmid-recipientEmail"
            >
              <span className="dsf-size12 dsf-font-weight-600 bw-uniqueRPM-span">
                {isReceipientEnable ? "Recipient" : ""}
              </span>
              {isReceipientEnable && activeSort === "recipientEmail" && (
                <img
                  src={` ${isSortActive ? ArrowDown : ArrowUp}`}
                  className="dsf-rpm-filter-arrow"
                />
              )}
            </div>
            <div className="header-label-recipient-filter">
              {isReceipientEnable && (
                <img
                  className={`dsf-uniquerpm-recipient-dropdown-toggle ${
                    recipientIsOpen
                      ? "dsf-rpm-dropdown-filter-open"
                      : "dsf-rpm-dropdown-filter-close"
                  }`}
                  src={recipientIsOpen ? polygon : polygonClose}
                  hidden={isDataNotAvailable}
                  onClick={() => {
                    setRecipientIsOpen(!recipientIsOpen);
                  }}
                />
              )}
              {isReceipientEnable && (
                <AutoCompleteFilter
                  options={
                    filterData &&
                    filterData.recipientEmail &&
                    filterData.recipientEmail.optionData
                      ? filterData.recipientEmail.optionData
                      : []
                  }
                  selectedValues={filterData?.recipientEmail?.selectedData}
                  gridFilterData={filterData?.recipientEmail?.checkData}
                  // setSelectedValues={setRecipientFilter}
                  onSelectionChange={(e) =>
                    handleSelectionChange(e, "recipientEmail")
                  }
                  maxLength="487px"
                  maxDropdownHeight="180px"
                  width="200px"
                  isOpen={recipientIsOpen}
                  setIsOpen={setRecipientIsOpen}
                  disable={false}
                  listClassName={`dsf-uniquerpm-recipient-dropdown dsf-uniquerpm-dropdown-filter ${
                    recipientIsOpen
                      ? "dsf-rpm-dropdown-open"
                      : "dsf-rpm-dropdown-close"
                  }`}
                />
              )}
            </div>
          </Col>}
        </Row>
        {isLoading ? (
          <ActivityIndicator />
        ) : objFilteredRPM &&
          objFilteredRPM.uniqueRMPMDetail &&
          objFilteredRPM.uniqueRMPMDetail.length > 0 ? (
          objFilteredRPM.uniqueRMPMDetail.map((item, index) => {
            return (
              // <>
              <Row
                className={`dsf-uniqueRPM-row uniqueRPM-list ${
                  isRNDPIUser ? "dsf-rnd-piuser-row" : ""
                }`}
              >
                <Col className="checkboxDiv header-checkbox" xs={1}>
                  <Checkbox
                    checked={
                      isReceipientEnable ? item.isRowSelected : item.isSelected
                    }
                    id={`default-checkbox`}
                    hidden={isRNDPIUser ? true : (isCheckboxDisabled ? true : false)}
                    disabled={!isEdit}
                    onClick={(e) => onCheckBoxClicked(e, index)}
                    className="dsf-uniqueRPM-checkbox"
                    disableRipple
                  />
                  {isRNDPIUser && <div className="gap-div"></div>}
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span">
                    {item.country}
                  </span>
                </Col>
                <Col xs={1} className="dsf-col-rpm-material dsf-materialicon">
                {item.isMaterialShared &&
                  <img
                    src={require(`../../../../assets/images/Tooltip-new.svg`).default}
                    alt="sort"
                    className="dsf-rpm-info-icon"
                    onClick={()=> {
                      setShowModal(!showModal)
                      setCurrentItem(index)
                    }}
                  />}
                  <span
                    className={`dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span ${
                      isRNDPIUser
                        ? !item.isRnDApproved
                          ? "not-selected"
                          : item.isSelected
                          ? "non-unique-selected"
                          : "unique-selected"
                        : ""
                    }`}
                  >
                    {item.material}
                  </span>
                </Col>
                <Col xs={2} className="dsf-col-rpm-material-description">
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span dsf-desc-length-txt">
                    {item.materialDescription}
                  </span>
                </Col>
                <Col xs={1}>
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span">
                    {item.skuCode}
                  </span>
                </Col>
                <Col xs={2}>
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span dsf-desc-length-txt">
                    {item.skuName}
                  </span>
                </Col>
                <Col xs={1}>
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span">
                    {item.plantCode}
                  </span>
                </Col>
                <Col xs={2} className="dsf-col-rpm-plant-name">
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span">
                    {item.plantName}
                  </span>
                </Col>
                <Col xs={1}>
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span">
                    {item.materialType}
                  </span>
                </Col>
                {isReceipientEnable && <Col xs={2} className="dsf-col-rpm-recipient" style={{paddingRight:"42px"}}>
                  <span className="dsf-size12 dsf-font-weight-400 bw-uniqueRPM-span dsf-desc-length-txt">
                    {isReceipientEnable
                      ? item.recipientEmail.length > 0
                        ? item.recipientEmail
                        : "-"
                      : ""}
                  </span>
                </Col>}
              </Row>
              // </>
            );
          })
        ) : (
          <div className="no-data-found">No data found</div>
        )}
      </div>
      {showModal && (
        <ModalRedundantDetails
          showModal={showModal}
          setShowModal={setShowModal}
          material ={objFilteredRPM?.uniqueRMPMDetail[currentItem]?.material}
          redundantMaterial={objFilteredRPM?.uniqueRMPMDetail[currentItem]?.sharedMaterialDetails}
        />
      )}
    </>
  );
};
export default UniqueRPM;
