import React, { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";
import { formatNumber } from "../utils";

const StackedHorizontalBarChartDesign = (props) => {
    const [barChartData, setBarChartData] = useState([]);
    useEffect(() => {
        let arrBarChartData = [];
        let annotation ,finishedGood, rawMaterial,packMaterial;
        props.chartData && props.chartData.map((item, index) => {
            switch(props.selectedGoodValue){
               case "finishedGoodsValue" :
                annotation = formatNumber(item.finishedGoodsValue, 0);
                finishedGood = item.finishedGoodsValue;
                rawMaterial = "0";
                packMaterial = "0";
                break;
               case "rawMaterialsValue" :
                annotation = formatNumber(item.rawMaterialsValue, 0);
                finishedGood = "0";
                rawMaterial = item.rawMaterialsValue;
                packMaterial = "0";

                break;
               case "packMaterialsValue" :
                annotation = formatNumber(item.packMaterialsValue, 0);
                finishedGood = "0";
                rawMaterial = "0";
                packMaterial = item.packMaterialsValue;
                break;
               case "combinedGoodsValue" :
                annotation = formatNumber((parseFloat(item.finishedGoodsValue) +
                parseFloat(item.rawMaterialsValue) +
                parseFloat(item.packMaterialsValue)), 0);
                finishedGood = item.finishedGoodsValue;
                rawMaterial = item.rawMaterialsValue;
                packMaterial = item.packMaterialsValue;
                break;
            }
            arrBarChartData.push([
                item.parameterName,
                Math.round(parseFloat(finishedGood)),
                Math.round(parseFloat(rawMaterial)),
                Math.round(parseFloat(packMaterial)),
                annotation
            ]);
        });
        setBarChartData(arrBarChartData);
    }, [props.chartData,props.selectedGoodValue]);
 
    useEffect(() => {
        GoogleCharts.load(drawMultSeries, {
            packages: ['corechart', 'bar'],
        });
    }, [barChartData]);
 
    const drawMultSeries = () => {
        let data_n = new GoogleCharts.api.visualization.DataTable();
        data_n.addColumn('string', 'Asset name');
        data_n.addColumn('number', "Finished Goods");
        data_n.addColumn('number', "Raw Materials");
        data_n.addColumn('number', "Pack Materials");
        data_n.addColumn({ type: 'string', role: 'annotation' });
        data_n.addRows(barChartData);
        var chartHeight = data_n.getNumberOfRows() <= 3 ? data_n.getNumberOfRows() * 40 : ((data_n.getNumberOfRows() * 40) - (data_n.getNumberOfRows() * 10));
        var rowHeight = data_n.getNumberOfRows() * 30;
        const options = {
            height: chartHeight,
            legend: { position: "left" },
            bar: { groupWidth: "20" },
            backgroundColor: props.chartAreaBackgroundColor ?? '#353535',
            chartArea: { width: '50%', height: rowHeight },
            isStacked: true,
            hAxis: {
                gridlines: {
                    count: 0,
                    color: "#FFF",
                    type: 'dotted',
                },
                titleTextStyle: { color: '#FFF' },
                textStyle: { color: '#FFF', fontSize: 12 },
                textPosition: 'none'
            },
            vAxis: {
                titleTextStyle: { color: '#EDF1F3' },
                textStyle: { color: '#EDF1F3', fontSize: 12 },
            },
            series: {
                0: { color: '#EF5891' },
                1: { color: '#846CE2' },
                2: { color: '#0044AC' },
            },
            annotations: {
                stemColor: "none",
                alwaysOutside: true,
                textStyle: {
                    fontSize: 12,
                    bold: true,
                    color: "#797775",
                },
            },
        };
        let container = document.getElementById(props.container);
        let chart = new GoogleCharts.api.visualization.BarChart(container);
        // Draw the chart with options
        chart.draw(data_n, options);
        // Add annotations directly to the options
        const labels = container.getElementsByTagName('text');
        Array.prototype.forEach.call(labels, function (label, index) {
            if (label.getAttribute('text-anchor') === 'end') {
            }
        });
        chart.draw(data_n, options);
    };
};
 
export default StackedHorizontalBarChartDesign;