import React, { useEffect, useState } from "react";
import "./uniqueRPM.css";
import UniqueRPMSelection from "./uniqueRPMSelection";
import UniqueRPMStatus from "./uniqueRPMStatus";
import { fetchUniqueRPMData } from "../../../../service/projectDetails";

const UniqueRPM = (props) => {
  const [activeTab, setActiveTab] = useState("Select RPM");
  const [objUniqueRPM, setObjUniqueRPM] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isDataNotAvailable, setIsDataNotAvailable] = useState(true);
  const [isReceipientEnable, setIsReceipientEnable] = useState(false);
  const [showStatusTab, setShowStatusTab] = useState(false);
  const [isLoadedFirstTime, setIsLoadedFirstTime] = useState(false);
  const [isRPMSelectionLoaded, setIsRPMSelectionLoaded] = useState(false);
  const [isSaveCompleted, setIsSaveCompleted] = useState(false);
  const [saveStatusCheck, setSaveStatusCheck] = useState(false);

  useEffect(() => {
    if (
      props.arrItem.workflow[4].status !== "1" &&
      props.arrItem.workflow[4].status !== "6" &&
      !isLoadedFirstTime
    ) {
      setShowStatusTab(true);
      setActiveTab("Sign-off Status");
      setIsLoadedFirstTime(true);
      setIsLoading(true);
      getUniqueRPMData();
    } else {
      setIsLoading(true);
      getUniqueRPMData();
      setIsLoadedFirstTime(true);
    }
  }, []);

  useEffect(() => {
    if (
      // !showStatusTab &&
      props.currentWorkflowStage === 4 &&
      objUniqueRPM &&
      objUniqueRPM.uniqueRMPMDetail.filter((item) => item.recipientEmail)
        .length > 0 &&
      props.arrItem.workflow[4].status !== "1" &&
      props.arrItem.workflow[4].status !== "6" &&
      !isLoadedFirstTime
    ) {
      setShowStatusTab(true);
      // setActiveTab("Sign-off Status");
      // setIsLoadedFirstTime(true);
    }
  }, [props.arrItem]);

  useEffect(() => {
    if (
      isSaveCompleted &&
      (props.arrItem.workflow[3].status === "2" || props.arrItem.workflow[3].status === "4") &&
      props.arrItem.workflow[5].status === "1"
    ) {
      getUniqueRPMData();
      setIsSaveCompleted(false);
    }
  }, [isSaveCompleted]);

  useEffect(() => {
    if (isRPMSelectionLoaded) {
      setIsRPMSelectionLoaded(false);
      getUniqueRPMData();
    }
  }, [isRPMSelectionLoaded]);

  const handleGridDataUpdate = (data, action) => {
    if (action && action.length > 0) {
      if (action === "save") {
        setIsSaveCompleted(true);
      } else {
        setIsRPMSelectionLoaded(true);
      }
    }
  };

  const handleStatusGridUpdate = (data) => {
    setActiveTab("Select RPM");
    setIsLoadedFirstTime(false);
    setIsReceipientEnable(false);
    getUniqueRPMData();
  };

  const getUniqueRPMData = async () => {
    const response = await fetchUniqueRPMData(props.arrItem.projectId);
    if (response.status === 200) {
      let uniqueRPMData = {};
      uniqueRPMData.appLink = response.data.appLink;
      uniqueRPMData.approvalLink = response.data.approvalLink;
      uniqueRPMData.deadline = response.data.deadline;
      uniqueRPMData.piUser = response.data.piUser;
      uniqueRPMData.projectDescription = response.data.projectDescription;
      uniqueRPMData.projectFamily = response.data.projectFamily;
      uniqueRPMData.projectId = response.data.projectId;
      uniqueRPMData.projectName = response.data.projectName;
      uniqueRPMData.userName = response.data.userName;
      uniqueRPMData.userMailId = response.data.userMailId;
      uniqueRPMData.mailMessage = response.data.mailMessage;
      uniqueRPMData.projectIdDisplay = response.data.projectIdDisplay;
      uniqueRPMData.uniqueRMPMDetail = response.data.uniqueRMPMDetail.map(
        (item) => ({
          ...item,
          isRowSelected: false,
        })
      );
      setObjUniqueRPM(uniqueRPMData);
      if (uniqueRPMData.uniqueRMPMDetail.length > 0) {
        setIsDataNotAvailable(false);
      }
      if (
        uniqueRPMData.uniqueRMPMDetail.filter((item) => item.recipientEmail)
          .length > 0
      ) {
        setIsReceipientEnable(true);
        if (
          props.arrItem.workflow[4].status !== "1" &&
          props.arrItem.workflow[4].status !== "6"
        ) {
          // setActiveTab("Sign-off Status");
          setShowStatusTab(true);
          if (saveStatusCheck) {
            setActiveTab("Sign-off Status");
            setSaveStatusCheck(false);
          }
        }
      } else {
        setShowStatusTab(false);
      }
    } else {
      console.log("error occurred ->", response.status);
      setIsDataNotAvailable(true);
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (
  //     showStatusTab &&
  //     props.arrItem.workflow[4].status !== "1" &&
  //     !isLoadedFirstTime
  //   ) {
  //     setActiveTab("Sign-off Status");
  //     setIsLoadedFirstTime(true);
  //   }
  // }, [showStatusTab]);

  const onChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
    setIsLoading(true);
    getUniqueRPMData();
  };

  return (
    <>
      <div className="bwi-modal bwi-modal-unique-rpm">
        <div className="bwi-landing-modal-header bwi-modal-unique-rpm-header">
          <div className="bwi-modal-unique-rpm-header-title">
            <span className="bwi-modal-title dsf-span-ml">
              Unique Raw Pack Materials
            </span>
            <br />
            <span className="bwi-modal-sub-title dsf-span-s">
              The following materials have been identified as unique. Please
              select the materials that will be reused / kept for this project.
              <br /> They will be considered as NOT delisted.{" "}
              <span className="dsf-span-sl">
                So your selection will NOT be taken into the BW calculation.
              </span>
            </span>
          </div>
          <div className="bwi-modal-unique-rpm-header-nav">
            <ul className=" nav bwi-modal-checkDelist_nav bwi-modal-uniqueRPM_nav">
              <li
                className={activeTab === "Select RPM" ? "active" : ""}
                onClick={() => onChangeTab("Select RPM")}
              >
                Select RPM
              </li>
              {showStatusTab && (
                <li
                  className={activeTab === "Sign-off Status" ? "active" : ""}
                  onClick={() => onChangeTab("Sign-off Status")}
                >
                  Sign-off Status
                </li>
              )}
            </ul>
          </div>
        </div>
        {activeTab === "Select RPM" && (
          <UniqueRPMSelection
            isShow={props.isShow}
            isEdit={props.isEdit}
            currentWorkflowStage={props.currentWorkflowStage}
            arrItem={props.arrItem}
            selectedProjectName={props.selectedProjectName}
            onComplete={props.onComplete}
            objUniqueRPM={objUniqueRPM}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            updateGridData={handleGridDataUpdate}
            isDataNotAvailable={isDataNotAvailable}
            isReceipientEnable={isReceipientEnable}
            stepStatus={props.stepStatus}
          />
        )}
        {activeTab === "Sign-off Status" && (
          <UniqueRPMStatus
            isShow={props.isShow}
            isEdit={props.isEdit}
            currentWorkflowStage={props.currentWorkflowStage}
            arrItem={props.arrItem}
            selectedProjectName={props.selectedProjectName}
            onComplete={props.onComplete}
            objUniqueRPM={objUniqueRPM}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            stepStatus={props.stepStatus}
            updateGridData={handleStatusGridUpdate}
          />
        )}
      </div>
    </>
  );
};
export default UniqueRPM;
