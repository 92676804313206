import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import vectorGreaterSymbol from "../../../../assets/images/landingPage/vectorGreaterSymbol.svg";
import vectorLesserSymbol from "../../../../assets/images/landingPage/vectorLesserSymbol.svg";
import ActivityIndicator from "../../../../components/Loader/loader";
import { InfoTooltip } from "../../../../components/Tooltip/tooltip";
import ModalWarning from "../modalWarning";
import {
  fetchCheckDelists,
  saveCheckDelistData,
  fetchProjectWorkFlowStatus,
} from "../../../../service/projectDetails";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import "./checkDelists.css";

const CheckDelists = (props) => {
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [objCheckDelist, setObjCheckDelist] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("SU Impacted");
  const { isEdit, stepStatus } = props;
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setIsLoading(true);
    retrieveCheckDelists();
  }, []);

  const onChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const retrieveCheckDelists = async () => {
    const response = await fetchCheckDelists(props.arrItem.projectId);
    if (response.status === 200) {
      setObjCheckDelist(response.data);
    } else {
      console.log("some error happened", response.data);
    }
    setIsLoading(false);
  };

  const onWarnModalOpen = (modalOpen) => {
    if (stepStatus !== "4") {
      setIsWarnWindowOpen(modalOpen);
    } else {
      onOkBtnClicked();
    }
  };

  const onOkBtnClicked = async () => {
    setIsLoading(true);
    const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
    const res = await saveCheckDelistData(props.arrItem.projectId);
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
      setShowModal(false);
      props.onComplete(false);
    } else {
      console.log(res.status);
    }
    // setIsLoading(false);
  };

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={onOkBtnClicked}
          onWarnModal={onWarnModalOpen}
        />
      )}
      <div className="bwi-modal bwi-modal-checkdelists">
        <div className="bwi-landing-modal-header bwi-modal-checkdelists-header">
          <div className="bwi-modal-checkdelists-header-title-div">
            <span className="bwi-modal-title dsf-span-ml">
              Review the factories (SU's/ Suppliers) where Finished Goods (FG)
              will be delisted or will have a BOM change
            </span>
            <br />
            <span className="bwi-modal-sub-title dsf-span-s">
              Below list is auto generated based on the FG movements between
              SUs, DCs and Countries in the last 12 months. Please review this
              to understand which SUs are going to be impacted by the Delist/BOM
              changes of FGs and Countries selected in the previous screen
              (Delist/BOM)
            </span>
          </div>
        </div>
        <div className="bwi-modal-checkDelist-body">
          <Row>
            <ul className=" nav bwi-modal-checkDelist_nav">
              <li
                className={activeTab === "SU Impacted" ? "active" : ""}
                onClick={() => onChangeTab("SU Impacted")}
              >
                SU Impacted
              </li>
              <li
                className={activeTab === "Possibly SU Impacted" ? "active" : ""}
                onClick={() => onChangeTab("Possibly SU Impacted")}
              >
                Possible SU Impacted
              </li>
              <li
                className={activeTab === "SU Not Impacted" ? "active" : ""}
                onClick={() => onChangeTab("SU Not Impacted")}
              >
                SU Not Impacted
              </li>
            </ul>
          </Row>
          {isLoading && (
            <div className="bwi-loader-div">
              <ActivityIndicator />
            </div>
          )}
          {!isLoading && objCheckDelist && activeTab === "SU Impacted" && (
            <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
              <div
                style={{ width: "18%" }}
                className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col"
              >
                <span className="dsf-span-sl width">FG Impacted</span>
                <InfoTooltip
                  name="info"
                  Tooltiptext={"Will be considered in BW calculation"}
                  width={"226px"}
                  height={"38px"}
                  positionClass={"tooltip-position-bottom-right-delists"}
                />
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "27%" }}
              >
                <span className="dsf-span-sl width">FG Name</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "15%" }}
              >
                <span className="dsf-span-sl width">SU/ supplier Code</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "20%" }}
              >
                <span className="dsf-span-sl width">SU/ supplier name</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "20%" }}
              >
                <span className="dsf-span-sl width">FG Sold at</span>
              </div>
            </Row>
          )}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "SU Impacted" &&
            objCheckDelist.checkdelistdetails &&
            objCheckDelist.checkdelistdetails.map((item, index) => {
              return (
                <>
                  <Row
                    key={item.projectID + index}
                    className="bwi-checkdelists-row bwi-checkdelists-data-row"
                  >
                    <div
                      style={{ width: "18%" }}
                      className="flex-fill bwi-checkdelists-data-col"
                    >
                      <span className="dsf-span-s width">{item.skuCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "27%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.skuName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "15%" }}
                    >
                      <span className="dsf-span-s width">{item.plantCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "20%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.plantName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "20%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.country}
                      </p>
                    </div>
                  </Row>
                </>
              );
            })}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "Possibly SU Impacted" && (
              <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
                <div
                  style={{ width: "18%" }}
                  className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col"
                >
                  <span className="dsf-span-sl width">
                    Possible FG Impacted
                  </span>
                  <InfoTooltip
                    name="info"
                    width={"294px"}
                    height={"102px"}
                    Tooltiptext={
                      "These SUs supply FGs to countries in the selected list and also to other countries outside the selected list (in Delist/BOM screen). This means the production in these SUs might possibly be reduced, but might not be stopped."
                    }
                    positionClass={"tooltip-position-top-right-delists"}
                  />
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col"
                  style={{ width: "27%" }}
                >
                  <span className="dsf-span-sl width">FG Name</span>
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col"
                  style={{ width: "15%" }}
                >
                  <p className="dsf-span-sl width">SU/ supplier code</p>
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col"
                  style={{ width: "20%" }}
                >
                  <p className="dsf-span-sl width">SU/ supplier name</p>
                </div>
                <div
                  className="flex-fill bwi-checkdelists-header-col"
                  style={{ width: "20%" }}
                >
                  <p className="dsf-span-sl width">FG Sold at</p>
                </div>
              </Row>
            )}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "Possibly SU Impacted" &&
            objCheckDelist.impactedcheckdelistdetails &&
            objCheckDelist.impactedcheckdelistdetails.map((item, index) => {
              return (
                <>
                  <Row className="bwi-checkdelists-row bwi-checkdelists-data-row">
                    <div
                      style={{ width: "18%" }}
                      className="flex-fill bwi-checkdelists-data-col"
                    >
                      <span className="dsf-span-s width">{item.skuCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "27%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.skuName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "15%" }}
                    >
                      <p className="dsf-span-s width">{item.plantCode}</p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "20%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.plantName}
                      </p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "20%" }}
                    >
                      <p className="dsf-span-s width bw-country-value dsf-desc-length-txt">
                        {item.country}
                      </p>
                    </div>
                  </Row>
                </>
              );
            })}
          {!isLoading && objCheckDelist && activeTab === "SU Not Impacted" && (
            <Row className="bwi-checkdelists-header-row bwi-checkdelists-row">
              <div
                style={{ width: "18%" }}
                className="flex-fill delist-tooltip-header-div bwi-checkdelists-header-col"
              >
                <span className="dsf-span-sl width">Not Impacted</span>
                <InfoTooltip
                  name="info"
                  width={"294px"}
                  height={"82px"}
                  Tooltiptext={
                    "Based on material movement data for the past 12 months, production in these SUs will not be impacted by the renovation project."
                  }
                  positionClass={"tooltip-position-top-right-delists"}
                />
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "27%" }}
              >
                <span className="dsf-span-sl width">FG Name</span>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "15%" }}
              >
                <p className="dsf-span-sl width">SU/ supplier code</p>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "20%" }}
              >
                <p className="dsf-span-sl width">SU/ supplier name</p>
              </div>
              <div
                className="flex-fill bwi-checkdelists-header-col"
                style={{ width: "20%" }}
              >
                <p className="dsf-span-sl width">FG Sold at</p>
              </div>
            </Row>
          )}
          {!isLoading &&
            objCheckDelist &&
            activeTab === "SU Not Impacted" &&
            objCheckDelist.notimpactedcheckdelistdetails &&
            objCheckDelist.notimpactedcheckdelistdetails.map((item, index) => {
              return (
                <>
                  <Row className="bwi-checkdelists-row bwi-checkdelists-data-row">
                    <div
                      style={{ width: "18%" }}
                      className="flex-fill bwi-checkdelists-data-col"
                    >
                      <span className="dsf-span-s width">{item.skuCode}</span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "27%" }}
                    >
                      <span className="dsf-span-s width dsf-desc-length-txt">
                        {item.skuName}
                      </span>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "15%" }}
                    >
                      <p className="dsf-span-s width">{item.plantCode}</p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "20%" }}
                    >
                      <p className="dsf-span-s width dsf-desc-length-txt">
                        {item.plantName}
                      </p>
                    </div>
                    <div
                      className="flex-fill bwi-checkdelists-data-col"
                      style={{ width: "20%" }}
                    >
                      <p className="dsf-span-s width bw-country-value dsf-desc-length-txt">
                        {item.country}
                      </p>
                    </div>
                  </Row>
                </>
              );
            })}
        </div>
        <div className="bwi-checkdelists-footer">
          <div className="bwi-footer-prevbtn-div">
            {/* <Button
              className="bwi-footer-button-movement bwi-footer-button-prev bwi-checkdelists-footer-btn"
              variant="secondary"
              onClick={() => {
                props.onComplete(true, "Previous");
              }}
            >
              <img
                src={vectorLesserSymbol}
                alt="Next"
                style={{ marginRight: "8px", paddingTop: "2px" }}
              />{" "}
              Previous
            </Button> */}
          </div>
          <div className="bwi-modal-footer ">
            <Button
              disabled={!isEdit}
              className="bwi-checkdelists-footer-btn bwi-submit-button"
              variant="secondary"
              onClick={() => onWarnModalOpen(true)}
            >
              Reviewed
            </Button>
            {/* <Button
              className={`bwi-footer-button-movement bwi-footer-button-next bwi-checkdelists-footer-btn ${
                props.arrItem.workflow[3].status === "1" &&
                "dsf-cursor-disabled"
              }`}
              variant="secondary"
              disabled={props.arrItem.workflow[3].status === "1"}
              onClick={() => {
                props.onComplete(true, "Next");
              }}
            >
              Next{" "}
              <img
                src={vectorGreaterSymbol}
                alt="Next"
                style={{ marginLeft: "8px", paddingTop: "2px" }}
              />
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default CheckDelists;
