import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import {
  fetchReviewAction,
  UpdateActionStatus,
} from "../../../../service/projectDetails";
import "./../../dashboardGridTable.css";

import { datefilterParams, functionfilterParams } from "../../../../utils";

import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";

import "./tracker.css";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Tracker = (props) => {
  const gridRef = useRef();
  const [showModal, setShowModal] = useState(props.isShow);
  const [isLoadingDatagrid, setIsLoadingDatagrid] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [isOkBtnDisabled, setIsOkBtnDisabled] = useState(true);
  const [requestBody, setRequestBody] = useState([]);

  const closeModal = () => {
    setShowModal(false);
    props.onComplete(false);
  };

  const updateTracker = async () => {
    const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
    const res = await UpdateActionStatus(requestBody);

    if (res.status === 200) {
      closeModal();
    } else {
      console.log("failed to fetch data", res.data);
    }
  };

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, []);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Function",
      field: "functionName",
      width: 150,
      filter: true,
      filterParams: functionfilterParams,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Action",
      field: "actionDetails",
      width: 150,
      filter: true,
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["contains"],
        buttons: ["clear"],
      },
      suppressMenu: true,
      floatingFilter: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Contact",
      field: "contact",
      width: 300,
      filter: true,
      suppressMenu: true,
      filterParams: {
        suppressAndOrCondition: true,
        filterOptions: ["contains"],
        buttons: ["clear"],
      },
      floatingFilter: true,
      sortable: true,
      resizable: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Deadline",
      field: "deadline",
      width: 150,
      filter: "agDateColumnFilter",
      suppressMenu: true,
      floatingFilter: true,
      resizable: true,
      filterParams: datefilterParams,
      valueFormatter: (params) => {
        var date = new Date(params.value);
        var day = date.getDate().toString().padStart(2, "0");
        var month = (date.getMonth() + 1).toString().padStart(2, "0");
        var year = date.getFullYear().toString();
        return day + "/" + month + "/" + year;
      },
      sortable: false,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Status",
      field: "status",
      width: 50,
      filter: false,
      editable: (params) => {
        let functionName = [];
        if (props.userRole == "BWInnovationP&I") {
          functionName.concat([
            "E2E Planning",
            "Marketing",
            "Procurement",
            "Material Planning",
            "Demand Planning",
          ]);
        }

        if (props.userRole == "BWInnovationE2EPlanner") {
          functionName.push("E2E Planning");
        }

        if (props.userRole == "BWInnovationDemandPlanner") {
          functionName.push("Demand Planning");
        }

        if (props.userRole == "BWInnovationMaterialPlanner") {
          functionName.push("Material Planning");
        }

        if (props.userRole == "BWInnovationProcurement") {
          functionName.push("Procurement");
        }

        if (props.userRole == "BWInnovationMarketing") {
          functionName.push("Marketing");
        }

        return props.userRole != "BWInnovationP&IAdmin"
          ? params.data.functionName.includes(functionName)
          : false;
      },
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      floatingFilter: true,
      resizable: true,
    };
  }, []);

  const rowClassRules = useMemo(() => {
    return {
      // row style function
      "highlight-row": (params) => {
        let functionName = "";
        if (props.userRole == "BWInnovationE2EPlanner") {
          functionName = "E2E Planning";
        }
        return params.data.functionName == functionName;
      },
    };
  }, []);

  const onCellValueChanged = useCallback(
    (event) => {
      let reqdata = requestBody;

      const existingItem = reqdata.find(
        (item) => item.actionId === event.data.actionId
      );
      if (existingItem === undefined) {
        reqdata.push(event.data);
      } else {
        existingItem.status = event.data.status;

        reqdata = reqdata.filter((x) => {
          return x.actionId != existingItem.actionId;
        });
      }

      setRequestBody(reqdata);

      if (reqdata.length > 0) {
        setIsOkBtnDisabled(false);
      } else {
        setIsOkBtnDisabled(true);
      }
    },
    [requestBody]
  );

  // Example load data from server
  useEffect(() => {
    setIsLoadingDatagrid(true);
    getreviwActionDataGridData();
  }, []);

  const getreviwActionDataGridData = async () => {
    const res = await fetchReviewAction(props.selectedProjectId);
    console.log(res);
    if (res.status === 200) {
      res.data = res.data.filter((item) => item.contact.length > 0);
      setRowData(res.data);
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsLoadingDatagrid(false);
  };

  // Example using Grid's API
  const buttonListener = useCallback((e) => {
    gridRef.current.api.deselectAll();
  }, []);

  return (
    <Modal
      centered
      dialogClassName="dsf-dialog-InventoryCM"
      contentClassName="dsf-modal-workflowModal"
      show={showModal}
    >
      <Modal.Header className="bw-modal-checkDelist-header">
        <Modal.Title className=" bw-checkDeslist-header-title-div">
          <div className="checkDelist-modal-header-div">
            <span className="dsf-size16 dsf-font-weight-700">
              Action Tracker
            </span>
            <span className="dsf-span-s" style={{ color: "#CFCFCF" }}>
              Latest progress on required scenario actions
            </span>
          </div>
          <img src={closeIcon} alt="Close-Image" onClick={() => closeModal()} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bw-modal-uniqueRPM-body">
        <div
          id="tracker-table"
          className="ag-theme-alpine"
          style={{ width: 972, height: 400 }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            headerHeight={24}
            floatingFiltersHeight={24}
            rowHeight={24}
            animateRows={true}
            rowSelection="multiple"
            onCellValueChanged={onCellValueChanged}
            rowClassRules={rowClassRules}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="bw-modal-footer">
        <Button
          className="buttonWidth"
          variant="secondary"
          onClick={() => clearFilters()}
        >
          Clear Filters
        </Button>
        <Button
          className="buttonWidth"
          variant="secondary"
          onClick={() => closeModal()}
        >
          Cancel
        </Button>

        <Button
          className="buttonWidth dsf-footer-okbutton-PI"
          variant="secondary"
          onClick={() => updateTracker()}
          disabled={isOkBtnDisabled}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default Tracker;
