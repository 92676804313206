import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import CustomDatePicker from "../../../../components/DatePicker/datePickerNew";
import CustomDropdown from "../../../../components/Dropdown/dropdown";
import ActivityIndicator from "../../../../components/Loader/loader";
import closeIcon from "../../../../assets/images/crossWhite.svg";
import { configByEnv } from "../../../../service/APIHelper";
import { functionRoleType } from "../../../../globalConstants";
import "./modalDeepDiveView.css";
import UserDetailsService from "../../../../service/userDetailsService";
import { saveReviewAction } from "../../../../service/scenarioPlannerService";
import { validateUnileverMailId, dateTimeFormat } from "../../../../utils";

const DeepDiveAddEditAction = (props) => {
  const { showModal, setShowModal, arrItem, isAutomated, isEdit, item } = props;
  const [actionData, setActionData] = useState({});
  const [recipientName, setRecipientName] = useState("");
  const [contactValue, setContactValue] = useState(actionData.contact || "");
  const [enableOkButton, setEnableOkButton] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userDetails = useSelector((state) => state.userDetails);

  useEffect(() => {
    let updatedItem = Object.assign({}, item);
    if (updatedItem && updatedItem.roleName.length === 0) {
      updatedItem.roleName = userDetails.roleName;
    }
    if (updatedItem && updatedItem.contact.length > 0) {
      getUserDetails(updatedItem.contact);
    }

    setActionData(updatedItem);
    setContactValue(updatedItem.contact || "");
  }, []);

  useEffect(() => {
    let updatedItem = Object.assign({}, actionData);
    let isDataValid = true;
    if (validationMessage.length === 0 && updatedItem) {
      if (
        updatedItem?.actionName?.length === 0 ||
        updatedItem?.deadline?.length === 0 ||
        updatedItem?.contact?.length === 0 ||
        updatedItem?.functionName?.length === 0 ||
        updatedItem?.actionDetails?.length === 0
      ) {
        isDataValid = false;
      }
    } else {
      isDataValid = false;
    }
    setEnableOkButton(isDataValid);
  }, [actionData, validationMessage]);

  const handleDropdownFunctionChange = (value) => {
    let updatedItem = Object.assign({}, actionData);
    updatedItem.functionName = value;
    setActionData(updatedItem);
  };

  const handleActionTitleChange = (e) => {
    let updatedItem = Object.assign({}, actionData);
    updatedItem.actionName = e.target.value;
    setActionData(updatedItem);
  };

  const handleActionDetailsChange = (e) => {
    let updatedItem = Object.assign({}, actionData);
    updatedItem.actionDetails = e.target.value;
    setActionData(updatedItem);
  };

  const handleRecipientValueOnEnter = (event) => {
    const value = event.target.value;
    // setContactValue(value);
    if (validateUnileverMailId(value)) {
      setValidationMessage("");
      getUserDetails(value);
    } else {
      let updatedItem = Object.assign({}, actionData);
      updatedItem.contact = "";
      setActionData(updatedItem);
      setRecipientName("");
      setValidationMessage("Please provide valid Unilever Mail id");
    }
  };

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setValidationMessage("");
      setRecipientName(
        `${userDetails.data.userFirstName} ${userDetails.data.userLastName}`
      );
      if (Object.keys(actionData).length > 0) {
        let updatedItem = Object.assign({}, actionData);
        updatedItem.contact = recepientMailId;
        setActionData(updatedItem);
      }
    } else {
      setRecipientName("");
      setValidationMessage("The Unilever mail id does not exist");
    }
  };

  const handleDatePickerChange = (value, isNull, date) => {
    let updatedItem = Object.assign({}, actionData);
    updatedItem.deadline = dateTimeFormat(date, "MM/DD/YYYY");
    setActionData(updatedItem);
  };

  const handleOkClick = async () => {
    let doc = document.getElementById("review-action-preview-email");
    actionData.mailSubject = `Action for ${actionData.functionName}`;
    actionData.mailBody = doc.innerHTML;
    actionData.recepientMailId = actionData.contact;
    actionData.isMailSent = true;
    const response = await saveReviewAction(actionData);
    if (response.status === 200) {
      setErrorMessage("");
      props.onChange(response.data);
      setShowModal(false);
    } else {
      console.log("Mail not sent. Please try again.");
      setErrorMessage("Data Update Failed.");
    }
  };

  return (
    <>
      <Modal
        centered
        dialogClassName={` ${
          isAutomated
            ? "bwi-deepdive-add-edit-action-dialog"
            : "bwi-deepdive-add-edit-action-dialog-manual"
        }`}
        contentClassName={`bwi-deepdive-add-edit-action-modal ${
          isAutomated ? "bwi-deepdive-add-edit-action-content" : ""
        }`}
        show={showModal}
      >
        <Modal.Header className="bwi-deepdive-add-edit-action-header">
          <Modal.Title className="dsf-span-ml bwi-deepdive-add-edit-action-header-title bwi-display-flex-column">
            <span style={{ textAlign: "left" }}>
              {isEdit ? "Edit Action" : "Assign Action"}
            </span>
            {errorMessage.length > 0 && (
              <span className="dsf-size10 dsf-font-weight-400 errorMsg">
                {errorMessage}
              </span>
            )}
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close-Image"
            className="img.dsf-close-messagebox-img bwi-landing-cursor-pointer"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </Modal.Header>
        <Modal.Body className="bwi-deepdive-add-edit-action-body">
          <Row className="">
            <Col className="" xs={isAutomated ? 12 : 6}>
              <Row className="bwi-deepdive-add-edit-action-row">
                <span className="bwi-deepdive-add-edit-action-label">
                  Function <span className="dsf-label-mandatory-field">*</span>
                </span>
                <CustomDropdown
                  data={functionRoleType}
                  popperClass="bwi-deepdive-add-edit-action-popper"
                  value={
                    actionData.functionName?.length > 0
                      ? actionData.functionName
                      : ""
                  }
                  width="150px"
                  onChange={(value) => handleDropdownFunctionChange(value)}
                />
              </Row>
              {!isAutomated && (
                <Row className="bwi-deepdive-add-edit-action-row">
                  <span className="bwi-deepdive-add-edit-action-label">
                    Action Title{" "}
                    <span className="dsf-label-mandatory-field">*</span>
                  </span>
                  <input
                    type="text"
                    value={actionData.actionName}
                    maxLength="500"
                    className="bwi-deepdive-add-edit-action-input"
                    onChange={(e) => handleActionTitleChange(e)}
                    style={{ width: "458px" }}
                  />
                </Row>
              )}
              <Row className="bwi-deepdive-add-edit-action-row">
                <span className="bwi-deepdive-add-edit-action-label">
                  Action Owner{" "}
                  <span className="dsf-label-mandatory-field">*</span>
                </span>
                <input
                  type="text"
                  maxLength="500"
                  value={contactValue}
                  className={`bwi-deepdive-add-edit-action-input ${
                    isEdit ? "bwi-modal-title" : ""
                  } ${
                    validationMessage.length > 0
                      ? "errorBorder"
                      : "successBorder"
                  }`}
                  onChange={(e) => {
                    setContactValue(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleRecipientValueOnEnter(e);
                  }}
                  style={{ width: "458px" }}
                  disabled={isEdit}
                />
                <span className="dsf-size10 dsf-font-weight-400 errorMsg">
                  {validationMessage}
                </span>
              </Row>
              {!isAutomated && (
                <Row className="bwi-deepdive-add-edit-action-row">
                  <span className="bwi-deepdive-add-edit-action-label">
                    Action Details{" "}
                    <span className="dsf-label-mandatory-field">*</span>
                  </span>
                  <textarea
                    type="text"
                    name="projName"
                    value={actionData.actionDetails}
                    maxLength="500"
                    className="bwi-deepdive-add-edit-action-textarea"
                    onChange={(e) => handleActionDetailsChange(e)}
                    style={{ width: "458px" }}
                  />
                </Row>
              )}
              <Row className="bwi-deepdive-add-edit-action-row">
                <span className="bwi-deepdive-add-edit-action-label">
                  Deadline Date{" "}
                  <span className="dsf-label-mandatory-field">*</span>
                </span>
                <div className="bwi-deepdive-add-edit-action-date">
                  <CustomDatePicker
                    launchDate={actionData.deadline}
                    isValueNull="true"
                    disablePast={true}
                    disableFuture={false}
                    width="427px"
                    onDatePickerChange={(value, isNull, date) =>
                      handleDatePickerChange(value, isNull, date)
                    }
                  />
                </div>
              </Row>
              {isAutomated && (
                <Row className="bwi-deepdive-add-edit-action-row">
                  <span className="bwi-deepdive-add-edit-action-label">
                    Email Preview
                  </span>
                  <div
                    className="input-attachments"
                    id="review-action-preview-email"
                  >
                    <div className="E2E-preview-div">
                      <p className="E2E-preview-text1">Dear {recipientName},</p>{" "}
                      <br />
                      <p className="E2E-preview-text1">
                        As part of optimising the Business waste for project{" "}
                        {arrItem?.projectName}, we have agreed on the following
                        action. Please execute the action by the respective
                        deadline. Please confirm back when the action is
                        completed by using below buttons or going directly to
                        the
                        <a target="_blank" href={configByEnv().appLink}>
                          {" "}
                          Application
                        </a>
                        .
                      </p>
                      <br />
                      <ul>
                        {actionData?.actionDetails?.length > 0 &&
                          actionData?.deadline?.length > 0 && (
                            <li key={actionData.actionId}>
                              <span>
                                {actionData?.actionDetails} adjusted before{" "}
                                {dateTimeFormat(
                                  actionData?.deadline,
                                  "DD/MM/YYYY"
                                )}{" "}
                              </span>
                            </li>
                          )}
                      </ul>
                      <div>
                        Kind Regards,
                        <br />
                        {userDetails.userFirstName}&nbsp;{" "}
                        {userDetails.userLastName}
                      </div>
                    </div>
                  </div>
                </Row>
              )}
            </Col>
            {!isAutomated && (
              <>
                <Col className="" xs={6}>
                  <Row className="bwi-deepdive-add-edit-action-row-manual">
                    <span className="bwi-deepdive-add-edit-action-label-title">
                      Email Preview
                    </span>
                    <span className="bwi-deepdive-add-edit-action-label">
                      Action owner will receive the following mail upon
                      submission.
                    </span>
                  </Row>
                  <Row className="bwi-deepdive-add-edit-action-row">
                    <div
                      className="input-attachments"
                      id="review-action-preview-email"
                    >
                      <div className="E2E-preview-div">
                        <p className="E2E-preview-text1">
                          Dear {recipientName},
                        </p>{" "}
                        <br />
                        <p className="E2E-preview-text1">
                          As part of optimising the Business waste for project{" "}
                          {arrItem?.projectName}, we have agreed on the
                          following action. Please execute the action by the
                          respective deadline. Please confirm back when the
                          action is completed by using below buttons or going
                          directly to the
                          <a target="_blank" href={configByEnv().appLink}>
                            {" "}
                            Application
                          </a>
                          .
                        </p>
                        <br />
                        <ul>
                          {actionData?.actionDetails?.length > 0 &&
                            actionData?.deadline?.length > 0 && (
                              <li key={actionData.actionId}>
                                <span>
                                  {actionData?.actionDetails} adjusted before{" "}
                                  {dateTimeFormat(
                                    actionData?.deadline,
                                    "DD/MM/YYYY"
                                  )}{" "}
                                </span>
                              </li>
                            )}
                        </ul>
                        <div>
                          Kind Regards,
                          <br />
                          {userDetails.userFirstName}&nbsp;{" "}
                          {userDetails.userLastName}
                        </div>
                      </div>
                    </div>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer
          className={`bwi-deepdive-add-edit-action-footer ${
            !isAutomated ? "bwi-deepdive-add-edit-action-footer-manual" : ""
          }`}
        >
          <Button
            variant="secondary"
            className="dsf-deepdive-action-button"
            disabled={!enableOkButton}
            onClick={handleOkClick}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeepDiveAddEditAction;
