import React, { useEffect, useState } from "react";
import "./fgCheckModal.css";
import addList from "../../../../assets/images/addList.svg";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { fetchDelistBOMFGData } from "../../../../service/projectDetails";
import ActivityIndicator from "../../../../components/Loader/loader";
import { Checkbox } from "@mui/material";

const FGCheckModal = (props) => {
  const [showTable, setShowTable] = useState(false);
  const [copiedData, setCopiedData] = useState("");
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [fgDelistDBData, setFGDelistDBData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const {
    action,
    isEdit,
    handleSelectedFGData,
    isReset,
    handleResetSearchEnable,
  } = props;

  useEffect(() => {
    if (isReset) {
      setShowTable(false);
      setCopiedData("");
      setFGDelistDBData([]);
      setSelectedAll(false);
      handleResetSearchEnable(false);
    }
  }, [isReset]);

  const okButtonClicked = () => {
    const result = [];

    fgDelistDBData
      .filter((item) => item.isSelected)
      .forEach((item) => {
        result.push({
          skuCode: item.fgCode,
          skuName: item.fgName,
          country: item.country,
          action: action,
          isSelected: item.isSelected,
        });
      });

    handleSelectedFGData(result);
  };

  const getDelistBOMFGCheckData = async (data) => {
    let res1 = await fetchDelistBOMFGData(props.projectId, data);
    if (res1.status === 200) {
      setFGDelistDBData(res1.data);
    }
    setIsLoading(false);
    handleResetSearchEnable(true);
  };

  const handleDataCheck = () => {
    const parsedData = parseCopiedData(copiedData);
    setIsLoading(true);
    getDelistBOMFGCheckData(parsedData);
    setShowTable(true);
  };

  const parseCopiedData = (data) => {
    const rows = data.trim().split("\n");
    const firstColumnValues = rows.map((row) => {
      const columns = row.split("\t");
      const firstColumnValue = columns[0];
      return firstColumnValue;
    });
    return firstColumnValues;
  };
  //capture the first column value and store it in a list of array object

  const onCheckBoxAllClicked = (e) => {
    setSelectedAll(!isSelectedAll);
    const updatedData = [...fgDelistDBData];
    if (!isSelectedAll) {
      updatedData.forEach((item) => {
        if (item.fgValid.split("")[1] !== "N") {
          item.isSelected = true;
        }
      });
    } else {
      updatedData.forEach((item) => (item.isSelected = false));
    }
    setFGDelistDBData(updatedData);
  };

  const onCheckBoxClicked = (e, index) => {
    const updatedData = [...fgDelistDBData];
    const item = updatedData[index];
    if (item && item.fgValid.split("")[1] !== "N") {
      item.isSelected = !item.isSelected;
      setFGDelistDBData(updatedData);
    }
    if (
      updatedData.filter(
        (item) => !item.isSelected && item.fgValid.split("")[1] === "Y"
      ).length
    ) {
      setSelectedAll(false);
    } else {
      setSelectedAll(true);
    }
  };

  return (
    <>
      <div className="bwi-delistBOMfg-modal">
        <div className="bwi-delistBOMfg-table">
          {showTable ? (
            <div className="bwi-delistBOMfg-table-body">
              <Row className="bwi-delistBOMfg-table-header bwi-delistBOMfg-row-not-selected">
                <Col className="bwi-delistBOMfg-table-col" xs={1}>
                  <Checkbox
                    checked={isSelectedAll}
                    id={`default-checkbox`}
                    disabled={fgDelistDBData.every(
                      (item) => item.fgValid.split("")[1] === "N"
                    )}
                    onChange={(e) => onCheckBoxAllClicked(e)}
                    className="bwi-checkbox"
                    disableRipple
                  />
                </Col>
                <Col xs={2} className="bwi-delistBOMfg-table-col dsf-text-left">
                  <span className="dsf-desc-length-txt dsf-span-ss">
                    Country
                  </span>
                </Col>
                <Col xs={2} className="bwi-delistBOMfg-table-col dsf-text-left">
                  <span className="dsf-desc-length-txt dsf-span-ss">
                    FG Code
                  </span>
                </Col>
                <Col xs={3} className="bwi-delistBOMfg-table-col dsf-text-left">
                  <span className="dsf-desc-length-txt dsf-span-ss">
                    FG Name
                  </span>
                </Col>
                <Col
                  xs={4}
                  className="bwi-delistBOMfg-table-col bwi-display-flex-between dsf-text-center"
                >
                  <span
                    className="dsf-desc-length-txt dsf-span-ss"
                    style={{ width: "60px" }}
                  >
                    FG Status
                  </span>
                  <span
                    className="dsf-desc-length-txt dsf-span-ss"
                    style={{ width: "60px" }}
                  >
                    Sold Flag
                  </span>
                  <span
                    className="dsf-desc-length-txt dsf-span-ss"
                    style={{ width: "60px" }}
                  >
                    FG Valid
                  </span>
                </Col>
              </Row>
              <div className="bwi-delistBOMfg-table-data">
                {showTable && isLoading ? (
                  <ActivityIndicator />
                ) : (
                  fgDelistDBData.map((rowData, index) => (
                    <Row
                      className={`bwi-delistBOMfg-table-header ${
                        rowData.fgValid.split("")[1] === "N" || !isEdit
                          ? "dsf-delistBOMfg-txt-disable"
                          : !rowData.isSelected
                          ? "bwi-delistBOMfg-row-not-selected"
                          : "dsf-delistBOMfg-txt-enable"
                      }`}
                      key={index}
                    >
                      <Col
                        className={`bwi-delistBOMfg-table-col ${
                          rowData.fgValid.split("")[1] === "N"
                            ? "dsf-delistBOMfg-checkbox-disable"
                            : ""
                        }`}
                        xs={1}
                      >
                        <Checkbox
                          checked={rowData.isSelected ? true : false}
                          disabled={rowData.fgValid.split("")[1] === "N"}
                          id={`default-checkbox-${index}`}
                          onChange={(e) => onCheckBoxClicked(e, index)}
                          className="bwi-checkbox"
                          disableRipple
                        />
                      </Col>
                      <Col
                        xs={2}
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.country}
                        </span>
                      </Col>
                      <Col
                        xs={2}
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.fgCode}
                        </span>
                      </Col>
                      <Col
                        xs={3}
                        className="bwi-delistBOMfg-table-col dsf-text-left"
                      >
                        <span className="dsf-desc-length-txt dsf-span-s">
                          {rowData.fgName}
                        </span>
                      </Col>
                      <Col
                        xs={4}
                        className="bwi-delistBOMfg-table-col bwi-display-flex-between dsf-text-center"
                      >
                        <span
                          className="dsf-desc-length-txt dsf-span-s"
                          style={{ width: "60px" }}
                        >
                          {rowData.fgStatus}
                        </span>
                        <span
                          className="dsf-desc-length-txt dsf-span-s"
                          style={{ width: "60px" }}
                        >
                          {rowData.soldFlag}
                        </span>
                        <span
                          className="dsf-desc-length-txt dsf-span-s"
                          style={{ width: "60px" }}
                        >
                          {rowData.fgValid.split("")[0]}
                        </span>
                      </Col>
                    </Row>
                  ))
                )}
              </div>
            </div>
          ) : (
            <textarea
              className="dsf-delistBOMfg-body-input-textarea"
              rows="2"
              cols="50"
              placeholder="Paste the codes here"
              value={copiedData}
              onChange={(e) => setCopiedData(e.target.value)}
              disabled={!isEdit}
            ></textarea>
          )}
        </div>
        {!showTable ? (
          copiedData.length > 0 && (
            <Button
              variant="secondary"
              className="bw-delistBOMfg-btn bw-delistBOMfg-run-btn"
              disabled={(copiedData.length > 0 ? false : true) || !isEdit}
              onClick={handleDataCheck}
            >
              Run
            </Button>
          )
        ) : (
          <Button
            variant="secondary"
            className="bw-delistBOMfg-btn bw-delistBOMfg-run-btn"
            onClick={okButtonClicked}
            disabled={!isEdit}
          >
            {" "}
            <img
              src={addList}
              alt="addList"
              style={{ marginRight: "8px", paddingTop: "2px" }}
            />{" "}
            Add Selected
          </Button>
        )}
      </div>
    </>
  );
};

export default FGCheckModal;
