import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import vectorGreaterSymbol from "../../../../assets/images/landingPage/vectorGreaterSymbol.svg";
import vectorLesserSymbol from "../../../../assets/images/landingPage/vectorLesserSymbol.svg";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./uniqueRPM.css";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import UniqueRPMGrid from "./uniqueRPMGrid";
import ModalWarning from "../modalWarning";
import {
  saveUniqueRPMRecipients,
  fetchProjectWorkFlowStatus,
} from "../../../../service/projectDetails";

const ModalRNDSignOffPIUser = (props) => {
  const { isEdit, objUniqueRPM, isLoading, setIsLoading, stepStatus } = props;
  const [isOkBtnDisabled, setIsOkBtnDisabled] = useState(false);
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (objUniqueRPM) {
      setIsOkBtnDisabled(
        objUniqueRPM && objUniqueRPM.uniqueRMPMDetail ? false : true
      );
    }
  }, [objUniqueRPM && objUniqueRPM.uniqueRMPMDetail]);

  const onOkBtnClicked = async (e) => {
    // const res = {
    //   projectName: props.selectedProjectName,
    //   workflow: props.arrItem.workflow,
    // };
    // dispatch(postUpdatedWorkFlowStatus(res));
    props.onComplete(false, true);
  };

  const onWarnModalOpen = (modalOpen) => {
    if (stepStatus !== "4") {
      setIsWarnWindowOpen(modalOpen);
    } else {
      resetRecipientData();
    }
  };

  const resetRecipientData = async () => {
    setIsLoading(true);

    const objUniqueRPMToReset = {
      ...objUniqueRPM,
      uniqueRMPMDetail: objUniqueRPM.uniqueRMPMDetail.map((item) => {
        const { isRowSelected, ...updatedItem } = item;
        return {
          ...updatedItem,
          recipientEmail: "",
          mailMessage: "",
          isRnDApproved: false,
          isSelected: false,
        };
      }),
    };

    const res = await saveUniqueRPMRecipients(objUniqueRPMToReset);
    if (res.status === 200) {
      console.log("reset", res.data);
      const res1 = await fetchProjectWorkFlowStatus(props.arrItem.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: props.selectedProjectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
      }
    } else {
      console.log("error in reset Recipients", res.data);
    }
    props.updateGridData(true);
  };

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={resetRecipientData}
          onWarnModal={onWarnModalOpen}
        />
      )}
      <div className="bwi-modal-unique-rpm-body bwi-modal-unique-rpm-status-body">
        <div className="bwi-unique-rpm-table">
          {/* <div className="bwi-unique-rpm-status-wait-div">
            <span className="bwi-unique-rpm-status-wait-span">
              Select & Send RPM for R&D Sign-Off Status
            </span>
          </div> */}
          <div className="dsf-rnd-legend bwi-rnd-legend-info">
            <div
              className="legend-square"
              style={{ backgroundColor: "#61D4C4" }}
            ></div>
            <div className="legend-label-rpm">Unique</div>

            <div
              className="legend-square"
              style={{ backgroundColor: "#EC6157" }}
            ></div>
            <div className="legend-label-rpm">Non-Unique</div>

            <div
              className="legend-square"
              style={{ backgroundColor: "#F1A33A" }}
            ></div>
            <div className="legend-label-rpm">Sign-off Pending</div>
          </div>
          <UniqueRPMGrid
            objUniqueRPM={objUniqueRPM}
            isLoading={isLoading}
            isEdit={isEdit}
            isReceipientEnable={true}
            isRNDPIUser={true}
            isCheckboxDisabled={false}
          />
        </div>
      </div>
      <div className="bwi-rndsignoff-footer">
        <div className="bwi-footer-prevbtn-div">
          {/* <Button
            className="bwi-footer-button-movement bwi-footer-button-prev bwi-rndsignoff-footer-btn"
            variant="secondary"
            onClick={() => {
              props.onComplete(true, "Previous");
            }}
          >
            <img
              src={vectorLesserSymbol}
              alt="Next"
              style={{ marginRight: "8px", paddingTop: "2px" }}
            />{" "}
            Previous
          </Button> */}
        </div>
        <div className="bwi-modal-footer">
          <Button
            className="bwi-reset-button bwi-rndsignoff-footer-btn-ok"
            variant="secondary"
            disabled={!isEdit}
            onClick={() => onWarnModalOpen(true)}
          >
            Reset All
          </Button>
          {/* <Button
            className={`bwi-footer-button-movement bwi-footer-button-next bwi-rndsignoff-footer-btn ${
              props.arrItem.workflow[5].status === "1" &&
              props.arrItem.workflow[7].status === "1" &&
              "dsf-cursor-disabled"
            }`}
            variant="secondary"
            disabled={
              props.arrItem.workflow[5].status === "1" &&
              props.arrItem.workflow[7].status === "1"
            }
            onClick={() => {
              props.onComplete(true, "Next");
            }}
          >
            Next{" "}
            <img
              src={vectorGreaterSymbol}
              alt="Next"
              style={{ marginLeft: "8px", paddingTop: "2px" }}
            />
          </Button> */}
        </div>
      </div>
    </>
  );
};
export default ModalRNDSignOffPIUser;
