import { ApiHelper, configByEnv } from "./APIHelper";
import axios from "axios";

const config = configByEnv();

export const fetchCountriesByProject = async (projectName) => {
    try {

        const url = config.baseURL + `/DelistBOM/GetCountryDetails?strProjectName=${projectName}`;
        const response = await ApiHelper.get(url)
        return {
            "status": response.status,
            "data": response.data
        };
    } catch (e) {
        console.log("error", e);
    }
}

export const fetchDelistBomListByCountry = async (projectName, country) => {
    try {

        const url = config.baseURL + `/DelistBOM/GetDelistbomForCountry?strProjectName=${projectName}&Country=${country}&search=Van`;
        const response = await ApiHelper.get(url)
        return {
            "status": response.status,
            "data": response.data
        };
    } catch (e) {
        console.log("error", e);
    }
}


const DelistBomService = {
    fetchCountriesByProject,
    fetchDelistBomListByCountry
};

export default DelistBomService;
