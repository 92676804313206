import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";
import ActivityIndicator from "../../../../components/Loader/loader";
import CustomDropdown from "../../../../components/Dropdown/dropdown";
import { checkInnoflexlaunchType } from "../../../../globalConstants";
import ScenaioAddEdit from "./modalAddEditAction";
import ScenarioReview from "./modalReviewAction";
import LineTableChartDesign from "../../../../helpers/lineTableChart";
import LineChartDesign from "../../../../helpers/lineChart";
import ModalAddSendAction from "../../TaskflowScreens/ScenarioPlanner/modalAddSendAction";
import AutoCompleteNestedDropdown from "./nestedautocomplete";
import TableChartDesign from "../../../../helpers/tableChart";
import {
  arrMaterialList,
  arrLinechart,
  arrRequiredAction,
  scenarioData,
} from "../../dashboardFixture";
import {
  fetchLineChartData,
  saveLockUnlockScenario,
} from "../../../../service/scenarioPlannerService";
import { fetchProjectWorkFlowStatus } from "../../../../service/projectDetails";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";

const ScenarioAction = (props) => {
  const [isAddActionModalOpen, setIsAddActionModalOpen] = useState(false);
  const [isLock, setIsLock] = useState(false);
  const [isLockUnlockModalOpen, setIsLockUnlockModalOpen] = useState(false);
  const [isReviewActionModalOpen, setReviewActionModalOpen] = useState(false);
  const [isReviewActionBtnDisable, setIsReviewActionBtnDisable] =
    useState(true);
  const [islockUnlockBtnDisable, setIslockUnlockBtnDisable] = useState(true);
  const [isAddActionbtnDisable, setIsAddActionbtnDisable] = useState(false);
  const [countryMaterialList, setcountryMaterialList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const [rmpmList, setRmpmList] = useState([]);
  const [materialListByCountry, setMaterialListByCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [lineChartDataByMaterial, setLineChartDataByMaterial] = useState();
  const [activeTab, setActiveTab] = useState("");
  const [volFGList, setVolFGList] = useState([]);
  const [lineChartDataByGoods, setLineChartDataByGoods] = useState([]);
  const [selectedUomValue, setSelectedUomValue] = useState("");
  const [rmpmIsOpen, setRmpmIsOpen] = useState(false);
  const {
    arrCoutnryMaterialList,
    selectedProject,
    selectedScenario,
    requiredActionData,
    allScenario,
    parentTab,
  } = props;
  const dropdownRefRmpm = useRef(null);

  const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
  const userDetails = useSelector((state) => state.userDetails);
  const dispatch = useDispatch();

  // console.log("tab",activeTab,parentTab)

  useEffect(() => {
    enableDisableLockUnlockBtn();
  }, [selectedScenario]);
  useEffect(() => {
    enableDisableLockUnlockBtn();
  }, [requiredActionData]);

  const enableDisableLockUnlockBtn = () => {
    const lockedScenarioDetails =
      allScenario && allScenario.filter((item) => item.isScenarioLocked === 1);
    if (
      lockedScenarioDetails.length > 0 &&
      lockedScenarioDetails[0].scenarioId === selectedScenario.scenarioId &&
      lockedScenarioDetails[0].scenarioName === selectedScenario.scenarioName
    ) {
      setIslockUnlockBtnDisable(false);
      setIsAddActionbtnDisable(false);
      setIsLock(true);
      if (requiredActionData && requiredActionData.length > 0) {
        setIsReviewActionBtnDisable(false);
      } else {
        setIsReviewActionBtnDisable(true);
      }
    } else if (lockedScenarioDetails.length === 0) {
      setIsAddActionbtnDisable(false);
      if (requiredActionData && requiredActionData.length > 0) {
        setIslockUnlockBtnDisable(false);
      } else {
        setIslockUnlockBtnDisable(true);
      }
      setIsLock(false);
    } else {
      setIsAddActionbtnDisable(true);
      setIslockUnlockBtnDisable(true);
      setIsReviewActionBtnDisable(true);
      setIsLock(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefRmpm.current &&
        !dropdownRefRmpm.current.contains(event.target)
      ) {
        setRmpmIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRefRmpm]);

  useEffect(() => {
    prepareCountryList();
  }, [arrCoutnryMaterialList]);

  useEffect(() => {
    setLineChartDataByGoods([]);
    if (
      selectedMaterial.value &&
      selectedMaterial.value.length > 0 &&
      selectedScenario &&
      selectedScenario.scenarioName.length > 0
    ) {
      // console.log("material or scenario UE");
      setActiveTab("");
      getLineChartData();
    }
    // setTabFlag(false);
  }, [selectedMaterial, selectedScenario]);

  useEffect(() => {
    // console.log("AT", activeTab, parentTab);
    if (activeTab !== "") {
      // console.log("tab UE");
      setLineChartDataByGoods([]);
      const parentTabValue = parentTab.substring(0, 3);
      const tabValue = parentTabValue.concat(activeTab);
      switch (tabValue) {
        case "ValFG":
          handleValFGTab();
          break;
        case "ValRM/PM":
          handleValRMPMTab();
          break;
        case "VolFG":
          handleVolFGTab();
          break;
        case "VolRM/PM":
          handleVolRMPMTab();
          break;
      }
    }
  }, [activeTab, parentTab]);

  const handleValFGTab = () => {
    if (
      lineChartDataByMaterial &&
      lineChartDataByMaterial.materials.length > 0
    ) {
      const ValFGArray = lineChartDataByMaterial?.materials?.map((ele) => {
        const { weekName, value } = ele;
        return { weekName, ...value };
      });
      const LineChartDataValFG = ValFGArray?.map((item) => ({
        ...item,
        inventory: Math.round(parseFloat(item.inventory)),
        forecast: Math.round(parseFloat(item.forecast)),
        production: Math.round(parseFloat(item.production)),
      }));
      setLineChartDataByGoods(LineChartDataValFG);
    } else {
      setLineChartDataByGoods([]);
    }
  };

  const handleValRMPMTab = () => {
    setRmpmList([]);
    // console.log("original arraey", lineChartDataByMaterial);
    if (
      lineChartDataByMaterial &&
      lineChartDataByMaterial.materials.length > 0
    ) {
      // console.log(ValRMPMArray);
      const RMPMDataList = [];
      lineChartDataByMaterial?.materials[0].rmpm.value.map((item) => {
        if (item.type !== "FG") {
          RMPMDataList.push({
            type: item.type,
            desc: `${item.number}-${item.desc.substring(0, 21)}`,
          });
        }
      });
      // console.log("RMPMDataList", RMPMDataList);
      if (RMPMDataList.length > 0) {
        const RMPMDropdownList = mergedObjectsByRMPMData(RMPMDataList);
        setRmpmList(RMPMDropdownList);
      }

      // console.log("RMPMDropdownList", RMPMDropdownList);
    } else {
      setLineChartDataByGoods([]);
    }
  };

  const handleVolFGTab = () => {
    setUomList([]);
    if (
      lineChartDataByMaterial &&
      lineChartDataByMaterial.materials.length > 0
    ) {
      const VolFGArray = [];
      lineChartDataByMaterial?.materials?.map((ele) =>
        ele.volume.map((item) => {
          VolFGArray.push({
            ...item,
            weekName: ele.weekName,
            inventory_volume: Math.round(parseFloat(item.inventory_volume)),
            forecast_volume: Math.round(parseFloat(item.forecast_volume)),
            production_volume: Math.round(parseFloat(item.production_volume)),
          });
        })
      );
      setVolFGList(VolFGArray);
      const extractUomValues = [];
      lineChartDataByMaterial?.materials[0]?.volume?.map((item) => {
        extractUomValues.push({
          value: item.uom,
          label: item.uom,
        });
      });
      // const jsonObject = extractUomValues.map(JSON.stringify);
      // const uniqueSet = new Set(jsonObject);
      // const uniqueUomArray = Array.from(uniqueSet).map(JSON.parse);
      setUomList(extractUomValues);
    } else {
      setLineChartDataByGoods([]);
    }
  };

  const handleVolRMPMTab = () => {
    setUomList([]);
    setRmpmList([]);
    setRmpmIsOpen(false);
    if (
      lineChartDataByMaterial &&
      lineChartDataByMaterial.materials.length > 0
    ) {
      const extractUomValues =
        lineChartDataByMaterial.materials[0].rmpm.volume.map((item) => ({
          value: item.uom,
          label: item.uom,
        }));
      // console.log("extractUomValues", extractUomValues);
      setUomList(extractUomValues);
    } else {
      setLineChartDataByGoods([]);
    }
  };

  function mergedObjectsByRMPMData(object) {
    const result = [];

    // Organize the data into a temporary object
    const tempObj = {};
    object.forEach((item) => {
      if (!tempObj[item.type]) {
        tempObj[item.type] = {
          label: item.type,
          value: item.type,
          children: [],
        };
      }
      tempObj[item.type].children.push({
        label: item.desc,
        value: item.desc,
      });
    });

    // Convert the temporary object into the desired format
    for (const key in tempObj) {
      if (Object.hasOwnProperty.call(tempObj, key)) {
        result.push(tempObj[key]);
      }
    }
    return result;
  }

  function mergeObjectsByWeekName(objects) {
    // Create an object to store merged objects by weekName
    const mergedObjects = {};

    // Iterate through each object in the array
    objects.forEach((obj) => {
      const weekName = obj.weekName;

      // If the weekName doesn't exist in mergedObjects, create a new entry
      if (!mergedObjects[weekName]) {
        mergedObjects[weekName] = {};
      }

      // Merge the current object with the existing one for the same weekName
      Object.assign(mergedObjects[weekName], obj);
    });

    // Convert the mergedObjects back to an array of objects
    const result = Object.keys(mergedObjects).map((weekName) => {
      return { weekName, ...mergedObjects[weekName] };
    });

    return result;
  }

  const prepareCountryList = () => {
    let arrCountry = [];
    arrCoutnryMaterialList.map((item) => {
      arrCountry.push({ value: item.country, label: item.country });
    });
    setCountryList(arrCountry);
  };
  const onAddActionBtnClicked = () => {
    setIsAddActionModalOpen(true);
  };
  const onLockUnlockClicked = () => {
    setIsLockUnlockModalOpen(true);
  };
  const handleLockUnlockScenario = async () => {
    const res = await saveLockUnlockScenario(
      selectedScenario.scenarioId,
      selectedScenario.scenarioName,
      !isLock,
      selectedProject.projectId
    );
    if (res.status === 200) {
      const res1 = await fetchProjectWorkFlowStatus(selectedProject.projectId);
      if (res1.status === 200) {
        const res = {
          projectName: selectedProject.projectName,
          workflow: res1.data,
        };
        dispatch(postUpdatedWorkFlowStatus(res));
        const isLocked = !isLock;
        setIsLock(!isLock);
        if (isLock) {
          setIsReviewActionBtnDisable(true);
        } else {
          setIsReviewActionBtnDisable(false);
        }
        props.onLockUnlockScenario(isLocked);
      }
    }
    setIsLockUnlockModalOpen(false);
  };
  const onReviewActionBtnClicked = (isSuccess) => {
    setReviewActionModalOpen(true);
  };
  /*   succss Callback Method start*/
  const onSuccessfulAddEditAction = (isSuccess, data) => {
    setIsAddActionModalOpen(false);
    if (isSuccess && data) {
      props.onUpdateRequiredAction(data);
      enableDisableLockUnlockBtn();
    }
  };
  const onSuccessfulReviewAction = (isSuccess) => {
    if (isSuccess) {
      props.onSuccessfulReviewSend(true);
    }
    setReviewActionModalOpen(false);
  };
  /*   succss Callback Method end*/
  const handleUomDropdownChange = (value) => {
    setSelectedUomValue(value);
    if (`${parentTab}${activeTab}` === "VolumeFG") {
      const filteredUomChartData = volFGList
        .filter((item) => item.uom === value)
        .map((ele) => ({
          weekName: ele.weekName,
          inventory: ele.inventory_volume,
          forecast: ele.forecast_volume,
          production: ele.production_volume,
        }));
      setLineChartDataByGoods(filteredUomChartData);
    } else {
      const RMPMDataList = [];
      lineChartDataByMaterial.materials[0].rmpm.volume.map((item) => {
        if (value === item.uom) {
          item.volumeDetails.map((chunk) => {
            if (chunk.type !== "FG") {
              RMPMDataList.push({
                type: chunk.type,
                desc: `${chunk.number}-${chunk.desc.substring(0, 21)}`,
              });
            }
          });
        }
      });

      if (RMPMDataList.length > 0) {
        const RMPMDropdownList = mergedObjectsByRMPMData(RMPMDataList);
        setRmpmList(RMPMDropdownList);
      }
    }
  };

  const handleDropdownValRmpmChange = (value, key) => {
    const { selectedValues, selectedLabels } = value;
    // console.log("selectedValues",selectedValues)
    const valRmpmArray = [];
    lineChartDataByMaterial?.materials?.map((ele) => {
      ele.rmpm.value.map((chunk) => {
        valRmpmArray.push({
          type: chunk.type,
          materialDetails: `${chunk.number}-${chunk.desc.substring(0, 21)}`,
          valRmpmDetails: {
            weekName: ele.weekName,
            [`${chunk.type}-${chunk.number}`]: Math.round(
              parseFloat(chunk.value)
            ),
          },
        });
      });
    });
    const finalValRMPMArray = [];
    selectedValues?.map((ele) => {
      valRmpmArray?.map((item) => {
        if (item.materialDetails === ele) {
          finalValRMPMArray.push(item.valRmpmDetails);
        }
      });
    });
    // console.log("rmpmArray",finalValRMPMArray)

    const lineChartData = mergeObjectsByWeekName(finalValRMPMArray);
    setLineChartDataByGoods(lineChartData);
  };

  const handleDropdownVolRmpmChange = (value, key) => {
    const { selectedValues, selectedLabels } = value;
    const VolRmpmArray = [];
    lineChartDataByMaterial.materials.map((ele) => {
      ele.rmpm.volume.map((item) => {
        if (selectedUomValue === item.uom) {
          item.volumeDetails.map((chunk) => {
            VolRmpmArray.push({
              type: chunk.type,
              materialDetails: `${chunk.number}-${chunk.desc.substring(0, 21)}`,
              volRmpmDetails: {
                weekName: ele.weekName,
                [`${chunk.type}-${chunk.number}`]: Math.round(
                  parseFloat(chunk.volume)
                ),
              },
            });
          });
        }
      });
    });
    // console.log("VolRmpmArray", VolRmpmArray);

    const finalValRMPMArray = [];
    selectedValues?.map((ele) => {
      VolRmpmArray.map((item) => {
        if (item.materialDetails === ele) {
          finalValRMPMArray.push(item.volRmpmDetails);
        }
      });
    });
    // console.log("VOLRMPM", finalValRMPMArray);

    const lineChartData = mergeObjectsByWeekName(finalValRMPMArray);
    setLineChartDataByGoods(lineChartData);
  };

  const handleCountryDropdownChange = async (value) => {
    setSelectedCountry(value);
    const arrMaterial = [];
    const filterArrCountry = arrCoutnryMaterialList.filter(
      (item) => item.country === value
    );
    if (filterArrCountry && filterArrCountry.length === 1) {
      filterArrCountry[0].materialLists.map((item) => {
        arrMaterial.push({
          value: item.materialCode,
          label: item.materialDescription,
        });
      });
    }
    setMaterialListByCountry(arrMaterial);
  };
  const handleMaterialDropdownChange = async (value) => {
    const filterMaterial = materialListByCountry.filter(
      (item) => item.value === value
    );
    if (filterMaterial && filterMaterial.length === 1) {
      setSelectedMaterial(filterMaterial[0]);
    }
  };

  const onChangeTab = (selectedTab) => {
    // if(lineChartDataByMaterial.length > 0)
    setActiveTab(selectedTab);
  };

  const getLineChartData = async () => {
    setIsLoading(true);
    const res = await fetchLineChartData(
      selectedProject.projectId,
      selectedScenario.scenarioName,
      selectedMaterial.value
    );
    if (res.status === 200) {
      console.log("data", res.data);
      setLineChartDataByMaterial(res.data ?? []);
      if (res.data.materials.length > 0) {
        // console.log("hi");
        setActiveTab("FG");
      } else {
        // console.log("hello");
        setActiveTab("");
      }
    }
    setIsLoading(false);
  };
  // console.log(
  //   "lineChartDataByGoods",
  //   lineChartDataByMaterial,
  //   lineChartDataByGoods
  // );

  return (
    <>
      {isLockUnlockModalOpen && (
        <Modal
          centered
          dialogClassName=""
          contentClassName="lockUnlockModal"
          show={isLockUnlockModalOpen}
        >
          <Modal.Header className="bw-modal-checkDelist-header ">
            <Modal.Title className=" bw-checkDeslist-header-title-div lock-unlock-div">
              <span className="dsf-size16 dsf-font-weight-700">
                {isLock ? "Unlock Scenario" : "Lock Scenario"}
              </span>
              <img
                src={closeIcon}
                alt="Close-Image"
                onClick={() => setIsLockUnlockModalOpen(false)}
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="lock-unlock-div">
            {isLock ? (
              <span className="dsf-size12 dsf-font-weight-400">
                Are you sure you want to unlock this scenario?
              </span>
            ) : (
              <span className="dsf-size12 dsf-font-weight-400">
                Are you sure you want to lock this scenario?
                <br />
                Scenario will be used as benchmark to track BW against and
                notifications on actions will be sent.
              </span>
            )}
          </Modal.Body>
          <Modal.Footer className="bw-modal-footer scenario-action-footer-div ">
            <Button
              className="bw-footer-cancle-btn"
              variant="secondary"
              onClick={() => setIsLockUnlockModalOpen(false)}
              disabled={false}
            >
              Cancel
            </Button>
            <Button
              className="dsf-footer-okbutton-PI"
              variant="secondary"
              onClick={() => handleLockUnlockScenario()}
              disabled={false}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isAddActionModalOpen && (
        <ScenaioAddEdit
          isShow={isAddActionModalOpen}
          onComplete={onSuccessfulAddEditAction}
          selectedMaterial={selectedMaterial}
          selectedCountry={selectedCountry}
          selectedProject={selectedProject}
          requiredActionData={requiredActionData}
        />
      )}
      {isReviewActionModalOpen && (
        <ModalAddSendAction
          showModal={isReviewActionModalOpen}
          setShowModal={() => setReviewActionModalOpen(false)}
          selectedProject={selectedProject}
          requiredActionData={requiredActionData}
          e2ePlannerName={selectedScenario.uploadedBy}
          onUpdate={onSuccessfulAddEditAction}
          onComplete={onSuccessfulReviewAction}
        />
      )}
      {/* <div style={{ display: "contents", flexDirection: 'column' }}> */}
      <div className="scenario-line-chart-parent-div">
        <div className="Scenario-planner-header-selection-graph-row">
          <div className="scenario-planner-graph-row">
            <Col className=" col-basis-20 graph-header-selection-div">
              {/* Country */}
              <Row>
                <span className="dsf-size8 dsf-font-weight-400"> Country</span>
              </Row>
              <Row
                style={{ marginLeft: "-2px" }}
                className="scenario-country-dropdown-row"
              >
                <CustomDropdown
                  data={countryList}
                  styleClass="dsf-checkinnoflex-type"
                  // value={item.launchType}//{dropdownValue[i]}
                  onChange={(value) => handleCountryDropdownChange(value)}
                />
              </Row>
            </Col>
            {/* Material Description */}
            <Col className=" col-basis-60 graph-header-selection-div">
              <Row>
                <span className="dsf-size8 dsf-font-weight-400">
                  {" "}
                  Material Description
                </span>
              </Row>
              <Row
                style={{ marginLeft: "-2px" }}
                className="scenario-material-drop-down"
              >
                <CustomDropdown
                  data={materialListByCountry}
                  styleClass="dsf-checkinnoflex-type"
                  isFromScenarioPlanner={true}
                  // value={item.launchType}//{dropdownValue[i]}
                  onChange={(value) => handleMaterialDropdownChange(value)}
                />
              </Row>
            </Col>
            {/* Material Code */}
            <Col className=" col-basis-20 graph-header-selection-div">
              <Row>
                <span className="dsf-size8 dsf-font-weight-400"> Material</span>
              </Row>
              <Row>
                <span className="dsf-size12 dsf-font-weight-400">
                  {" "}
                  {selectedMaterial.value ?? ""}
                </span>
              </Row>
            </Col>
          </div>
        </div>
        {lineChartDataByMaterial &&
          lineChartDataByMaterial?.materials?.length > 0 && (
            <div className="material-selection-row">
              <ul className="nav bwi-modal-Scenario_nav">
                <li
                  className={activeTab === "FG" ? "active" : ""}
                  onClick={() => onChangeTab("FG")}
                >
                  Finished Goods
                </li>
                <li
                  className={activeTab === "RM/PM" ? "active" : ""}
                  onClick={() => onChangeTab("RM/PM")}
                >
                  RM/PM (Inventory)
                </li>
              </ul>
              {parentTab === "Volume" && (
                <div className="scenario-RPM-dropdown">
                  {/* Country */}
                  <Row className="label">
                    <span className="dsf-size8 dsf-font-weight-400"> UOM</span>
                  </Row>
                  <Row className="scenario-country-dropdown-row">
                    <CustomDropdown
                      data={uomList}
                      styleClass="dsf-checkinnoflex-type"
                      value={uomList}
                      disabled={uomList.length > 0 ? false : true}
                      onChange={(value) => handleUomDropdownChange(value)}
                    />
                  </Row>
                </div>
              )}
              {activeTab === "RM/PM" && (
                <div className="scenario-RPM-dropdown">
                  {/* Country */}
                  <Row className="label">
                    <span className="dsf-size8 dsf-font-weight-400">
                      {" "}
                      Select RM/PM(Max:5)
                    </span>
                  </Row>
                  <Row className="scenario-country-dropdown-row scenario-rmpm-dropdown-row">
                    <Col
                      className="dsf-delistProj-autocomplete dsf-col-textBox-div2 delistProj-dropdown"
                      ref={dropdownRefRmpm}
                    >
                      {parentTab === "Value" ? (
                        <AutoCompleteNestedDropdown
                          options={rmpmList ? rmpmList : []}
                          maxLength={60}
                          maxDropdownHeight="175px"
                          maxDropdownwidth="316px"
                          width="150px"
                          disable={rmpmList.length > 0 ? false : true}
                          isOpen={rmpmIsOpen}
                          setIsOpen={setRmpmIsOpen}
                          listClassName="dsf-delistingProj-geography-list"
                          onSelectionChange={(value, key) =>
                            handleDropdownValRmpmChange(value, key)
                          }
                        />
                      ) : (
                        <AutoCompleteNestedDropdown
                          options={rmpmList ? rmpmList : []}
                          maxLength={60}
                          maxDropdownHeight="175px"
                          maxDropdownwidth="316px"
                          width="150px"
                          disable={rmpmList.length > 0 ? false : true}
                          isOpen={rmpmIsOpen}
                          setIsOpen={setRmpmIsOpen}
                          listClassName="dsf-delistingProj-geography-list"
                          onSelectionChange={(value, key) =>
                            handleDropdownVolRmpmChange(value, key)
                          }
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </div>
          )}

        {isLoading ? (
          <ActivityIndicator />
        ) : /**** Graph added here ****/
        !isLoading && lineChartDataByGoods.length > 0 ? (
          <>
            <div
              id="line_chart_div"
              className="test_line_chart_1"
              style={{ marginBottom: "-11px" }}
            >
              <LineChartDesign arrLinechart={lineChartDataByGoods} />
            </div>

            <div id="table_chart_div" className="test_table_chart_1">
              <div className="test_table_chart_inside_1">
                <TableChartDesign
                  arrLinechart={lineChartDataByGoods}
                  parentTab={parentTab}
                  selectedUomValue={selectedUomValue}
                />
              </div>
            </div>
            {/* <span
              className={`legend-div ${
                parentTab === "Value" ? "legend-div-Value" : "legend-div-Volume"
              }`}
            >
              <span className="legend-span"></span>
              Launch
            </span> */}
          </>
        ) : (
          activeTab !== "" && (
            <div className="data-not-found">
              No data for the selected combination
            </div>
          )
        )}
      </div>

      <div className="scenario-lock-action-parent-div">
        {userDetails.roleName === "BWInnovationP&I" && (
          <>
            <Row>
              <span className="dsf-size16 dsf-font-weight-600">
                {" "}
                Actions to achieve scenario
              </span>
            </Row>
            {/* <Row>
              <span className="dsf-size12 dsf-font-weight-400"> Add/Edit actions to achieve scenario</span>
            </Row> */}
            {/* <Row style={{ marginTop: "15px" }} className={"addAction-btn-div"}>
              <Button
                className="scenario-planner-action-btn"
                variant="secondary"
                disabled={isAddActionbtnDisable}
                onClick={() => onAddActionBtnClicked()}
              >
                Add/Edit Actions
              </Button>
            </Row> */}
            <div className="scenario-requiredAction-parent-div">
              <Row style={{ marginTop: "10px", marginLeft: "0px" }}>
                <span className="dsf-size12 dsf-font-weight-600">
                  {" "}
                  System suggested Actions:
                </span>
              </Row>
              <Row className="scenario-requiredAction-div">
                <ul>
                  {requiredActionData.map((item) => {
                    const functionData =
                      item.functionName.length > 0
                        ? item.functionName
                        : "[Not Assigned]";
                    if (item.isAutomated) {
                      return (
                        <li>
                          <span className="dsf-size12 dsf-font-weight-400">
                            {functionData} -{" "}
                          </span>
                          <span className="dsf-size12 dsf-font-weight-400 grey-span">
                            {item.actionDetails}
                          </span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </Row>
              <Row style={{ marginTop: "10px", marginLeft: "0px" }}>
                <span className="dsf-size12 dsf-font-weight-600">
                  {" "}
                  User Defined Actions:
                </span>
              </Row>
              <Row className="scenario-requiredAction-div">
                <ul>
                  {requiredActionData.map((item) => {
                    const functionData =
                      item.functionName.length > 0
                        ? item.functionName
                        : "[Not Assigned]";
                    if (!item.isAutomated) {
                      return (
                        <li>
                          <span className="dsf-size12 dsf-font-weight-400">
                            {functionData} -{" "}
                          </span>
                          <span className="dsf-size12 dsf-font-weight-400 grey-span">
                            {item.actionDetails}
                          </span>
                        </li>
                      );
                    }
                  })}
                </ul>
              </Row>
            </div>
            <Row className="scenario-lock-scenario-div">
              <Button
                className="scenario-planner-action-btn"
                variant="secondary"
                onClick={() => onLockUnlockClicked()}
                disabled={islockUnlockBtnDisable}
              >
                {isLock ? "Unlock Scenario" : "Lock Scenario"}
              </Button>
              <Button
                className="scenario-planner-action-btn"
                variant="secondary"
                onClick={() => onReviewActionBtnClicked()}
                // disabled={isReviewActionBtnDisable}
              >
                Add Actions and Send
              </Button>
            </Row>
          </>
        )}
      </div>
    </>
  );
};
export default ScenarioAction;
