import { useEffect, useState } from "react";
import "./tableChart.css";
export default function TableChartDesign(props) {
  const [barChartData, setBarChartData] = useState([]);

  useEffect(() => {
    let newData = [];
    if (props.arrLinechart.length > 0) {
      props.arrLinechart.sort(
        (a, b) =>
          parseInt(a.weekName.substring(3, 7)) -
          parseInt(b.weekName.substring(3, 7))
      );
      const columnValues = Object.keys(props.arrLinechart[0]);
      if (props.parentTab === "Value") {
        columnValues[0] = "Euros (€)";
      } else {
        columnValues[0] = props.selectedUomValue;
      }
      newData.push(columnValues);
      for (var i = 0; i < props.arrLinechart.length; i++) {
        const rowValues = Object.values(props.arrLinechart[i]);
        newData.push(rowValues);
      }
    } else {
      newData.push([]);
    }
    setBarChartData(newData);
  }, [props.arrLinechart]);

  const tableGraph = (tableData) => {
    let yValLength = tableData.length > 0 ? tableData[0].length : 0;
    let tableRow = [];
    for (let i = 0; i < yValLength; i++) {
      let tableColumn = [];
      tableData.forEach((x) => {
        tableColumn.push(<td>{x[i]}</td>);
      });
      tableRow.push(<tr>{tableColumn}</tr>);
    }
    return tableRow;
  };

  return (
    <>
      <table className="tableGraph">{tableGraph(barChartData)}</table>
    </>
  );
}
