import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import "./dropdown.css";
import plusIcon from "../../assets/images/PlusVariantAdd.svg";
import actionPlus from "../../assets/images/actionPlus.svg";
import actionMinus from "../../assets/images/actionMinus.svg";

const CustomDropdown = (props) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);
  const { data, onChange, disabled, readOnly, popperClass } = props;
  const [variant, setVariant] = useState("dropdown");

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const handleChange = (event) => {
    if (
      event.target.value !== undefined &&
      event.target.value !== "Add another action"
    ) {
      setSelectedOptions(event.target.value);
      onChange(event.target.value, "dropdown");
    } else {
      setSelectedOptions("");
    }
  };

  const handleOpen = () => {
    if (!readOnly) {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDataChange = (event) => {
    if (event.target.value !== undefined) {
      setSelectedOptions(event.target.value);
      onChange(event.target.value, "textbox");
    }
  };

  const handleButtonClick = (event) => {
    setSelectedOptions("");
    props.onChange("", "textbox");
    setVariant("textbox");
  };

  const handleTextboxClick = (event) => {
    setSelectedOptions("");
    props.onChange("", "dropdown");
    setVariant("dropdown");
  };

  return variant === "dropdown" ? (
    <FormControl>
      <Select
        ref={selectRef}
        value={selectedOptions}
        onChange={handleChange}
        onClick={handleOpen}
        open={open}
        onClose={handleClose}
        className="custom-select"
        disabled={disabled}
        MenuProps={{
          className: "bwi-selectable-popper",
          style: { maxWidth: "1000px" },
        }}
        sx={{
          "& .MuiSelect-icon": {
            color: "#EDF1F3",
          },
          minWidth: props.width ? props.width : {},
          minHeight: props.height ? props.height : {},
        }}
      >
        {data.map((item, index) => (
          <MenuItem
            key={item.value}
            value={props.isFromScenarioPlanner ? item.value : item.label}
            className={`bwi-selectable-li-items ${
              index === 0 ? "bwi-selectable-li-items-first" : ""
            }`}
            sx={{
              maxWidth: "950px",
              whiteSpace: "normal",
            }}
          >
            {item.label}
          </MenuItem>
        ))}

        <MenuItem value="Add another action" className="bwi-selectable-add-btn">
          <img
            src={plusIcon}
            alt="plus-Icon-Image"
            className="dsf-selectable-add-plus-img"
            onClick={handleButtonClick}
          />
          <Button onClick={handleButtonClick} disableRipple>
            <span
              className="dsf-size12 dsf-font-weight-600"
              style={{ color: "#8FCAEB" }}
            >
              Add Another Action
            </span>
          </Button>
        </MenuItem>
      </Select>
    </FormControl>
  ) : (
    <div className="bwi-add-custom-textbox">
      <input
        type="text"
        className="bwi-add-review-action-add-input"
        onChange={(e) => {
          handleDataChange(e);
        }}
      />
      <img
        src={actionMinus}
        alt="Action-Minus-Image"
        className="bwi-action-add-minus-img"
        onClick={handleTextboxClick}
      />
    </div>
  );
};

export default CustomDropdown;
