import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import icon from "../../../../assets/images/Vector-cross.svg";
import { rndsignoff_text } from "../../../../globalConstants";
import "./modalRNDSignOff.css";


const ModalRNDSignOff = (props) => {
    const {showModal, setShowModal} = props;

    return (<>
        <Modal centered dialogClassName="dsf-dialog-rnd-signoff" contentClassName='dsf-modal-rnd-signoff' show={showModal}>
            <Modal.Header className='dsf-modal-header-rnd-signoff'>
                <Modal.Title className='dsf-font-weight-600 dsf-size16'>R&D Sign Off</Modal.Title>
                <img src={icon} alt="Close-Image" className='dsf-rnd-close-img' onClick={() => setShowModal(false)}/>
            </Modal.Header>
            <Modal.Body className='dsf-modal-body-rnd-signoff'>
                <div>
                    <p className="dsf-span-s">{rndsignoff_text.split('\n')[0]}</p><br />
                    <p className="dsf-span-s">{rndsignoff_text.split('\n')[1]}</p>
                </div>
            </Modal.Body>
            <Modal.Footer className='dsf-modal-footer-rnd-signoff justify-content-center bw-modal-footer'>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}

export default ModalRNDSignOff;