import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import CheckInnoflex from "../TaskflowScreens/CheckInnoflex/checkInnoflex";
import CheckDelists from "../TaskflowScreens/CheckDelists/checkDelists";
import DelistBOM from "../TaskflowScreens/DelistBOM/delistBOM";
import InventoryCM from "../TaskflowScreens/InventoryCM/inventoryCM";
import UniqueRPM from "../TaskflowScreens/UniqueRPM/uniqueRPM";
import EmailE2E from "../TaskflowScreens/EmailE2E/emailE2E";

const ComponentSelection = (props) => {
  const { activeStep, arrItem, isEdit, stepStatus } = props;

  const onWorkflowStageCompletion = (prevStepSelected, action) => {
    if (prevStepSelected) {
      props.onWorkflowStageCompletion(activeStep, action);
    } else {
      props.onWorkflowStageCompletion(false);
    }
  };

  const onRPMStageCompletion = (prevStepSelected, isFromRPMStatus) => {
    if (prevStepSelected) {
      props.onWorkflowStageCompletion(activeStep, isFromRPMStatus);
    }
    if (isFromRPMStatus) {
      props.onWorkflowStageCompletion(true);
    }
  };

  const renderCheckInnoflex = () => {
    return (
      <CheckInnoflex
        isShow={true}
        isEdit={isEdit}
        currentWorkflowStage={1}
        arrItem={arrItem}
        selectedProjectName={arrItem.projectName}
        stepStatus={stepStatus[1]}
        onComplete={onWorkflowStageCompletion}
      />
    );
  };

  const renderDelistBOM = () => {
    return (
      <DelistBOM
        isShow={true}
        isEdit={isEdit}
        currentWorkflowStage={2}
        arrItem={arrItem}
        selectedProjectName={arrItem.projectName}
        stepStatus={stepStatus[0]}
        onComplete={onWorkflowStageCompletion}
      />
    );
  };

  const renderCheckDelsists = () => {
    return (
      <CheckDelists
        isShow={true}
        isEdit={isEdit}
        currentWorkflowStage={3}
        arrItem={arrItem}
        selectedProjectName={arrItem.projectName}
        stepStatus={stepStatus[2]}
        onComplete={onWorkflowStageCompletion}
      />
    );
  };

  const renderUniqueRPM = () => {
    return (
      <UniqueRPM
        isShow={true}
        isEdit={isEdit}
        currentWorkflowStage={4}
        arrItem={arrItem}
        selectedProjectName={arrItem.projectName}
        stepStatus={stepStatus[3]}
        onComplete={onRPMStageCompletion}
      />
    );
  };

  const renderInventoryCM = () => {
    return (
      <InventoryCM
        isShow={true}
        isEdit={isEdit}
        currentWorkflowStage={5}
        arrItem={arrItem}
        selectedProjectName={arrItem.projectName}
        stepStatus={stepStatus[4]}
        onComplete={onWorkflowStageCompletion}
      />
    );
  };

  const renderEmailE2E = () => {
    return (
      <EmailE2E
        isShow={true}
        isEdit={isEdit}
        currentWorkflowStage={6}
        arrItem={arrItem}
        selectedProjectName={arrItem.projectName}
        stepStatus={stepStatus[5]}
        onComplete={onWorkflowStageCompletion}
      />
    );
  };

  switch (activeStep) {
    case 0:
      return renderDelistBOM();
    case 1:
      return renderCheckInnoflex();
    case 2:
      return renderCheckDelsists();
    case 3:
      return renderUniqueRPM();
    case 4:
      return renderInventoryCM();
    case 5:
      return renderEmailE2E();
    default:
      return null;
  }
};

export default ComponentSelection;
