import { ApiHelper, configByEnv } from "./APIHelper";

const config = configByEnv();

export const uploadScenarioFile = async (
  projectId,
  scenarioName,
  uploadedBy,
  payload
) => {
  try {
    const url =
      config.baseURL +
      `/Scenario/UploadScenarioPlannerFile?projectId=${projectId}&scenarioName=${scenarioName}&uploadedBy=${uploadedBy}`;
    const response = await ApiHelper.postFileType(url, payload);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveLockUnlockScenario = async (
  scenarioId,
  scenarioName,
  isLocked,
  projectId
) => {
  try {
    const url =
      config.baseURL +
      `/Scenario/UpdateScenarioLock?scenarioId=${scenarioId}&scenarioName=${scenarioName}&lockStatus=${isLocked}&projectId=${projectId}`;
    const response = await ApiHelper.put(url);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveScenarioAction = async (payload, projectId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/UpdateUserActions?projectId=${projectId}`;
    const response = await ApiHelper.post(url, payload);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const saveReviewAction = async (payload) => {
  try {
    const url = config.baseURL + `/ProjectDetails/ReviewAndSendAction`;
    const response = await ApiHelper.post(url, payload);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchRequiredActionList = async (projectId) => {
  try {
    const url =
      config.baseURL + `/ProjectDetails/GetOpenActions?projectId=${projectId}`;
    const response = await ApiHelper.get(url);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchScenarioTemplateDownload = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/ProjectDetails/DownloadScenarioTemplate?projectId=${projectId}`;
    const response = await ApiHelper.postFileType(url, {}, {}, "blob");
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};

export const fetchScenarioData = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/Scenario/GetScenarioLandingPage?projectId=${projectId}`;
    const response = await ApiHelper.get(url);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const fetchCountryMaterialDetails = async (projectId) => {
  try {
    const url =
      config.baseURL +
      `/Scenario/GetCountryMaterialList?projectId=${projectId}`;
    const response = await ApiHelper.get(url);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
export const fetchLineChartData = async (
  projectId,
  scenarioName,
  materialCode
) => {
  try {
    const url =
      config.baseURL +
      `/Scenario/GetScenarioDataForMaterial?projectId=${projectId}&scenarioName=${scenarioName}&materialNumber=${materialCode}`;
    const response = await ApiHelper.get(url);
    console.log(response);
    return {
      status: response.status,
      data: response.data,
    };
  } catch (e) {
    console.log("error", e);
    return {
      status: e.response.status,
      data: e.response.data,
    };
  }
};
