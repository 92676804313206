// Info Tooltip text Start
export const helpIconText_part1 = `
InnoWaste is meant to ensure there is a view early in an renovation project on the potential Business Waste, to ensure preventative actions are taken in time. `;

export const helpIconText_part2 = `This is done by enabling with this tool P&I to collect the information for E2E planning to create scenario’s on potential Business Waste, to align early on actions, follow up on actions to completion and track the success of the actions.`;

export const helpText_LaunchDate =
  "Launch date will be used as the transition date on shelf. When the old product will not be available anymore on shelf";

export const helpText_TradeWindow =
  "Trade window will be used as limitation in scenario planning to move launch date for soft transitions";

export const helpText_SkipValidation =
  "Skip validation excludes the validations of Missing value for Tradewindow Start and End date along with date limitation of Launch date";

export const errorText_checkInnoflex = `
Please check and complete missing values. Where needed contact the marketing project lead
`;

export const rndsignoff_text = `Message sent to R&D to validate RPM materials to be reused in new SKU.\nYou can proceed to the next step whilst waiting for sign off`;

export const rndcomplete_text =
  " from R&D has checked and signed off the Unique RPM list ";

export const uploadFileText =
  "Upload file containing Delist/BOM data. Download example template here";

export const noDataFoundText = "Data Not Found";

export const launchDateValidation =
  "please make sure launch date should lies between trade Window start Date and trade Window End Date";

export const cgAwaitingText =
  "Waiting for CG team to update the Unique RPMs in SAP ECC so that the data is available in RR for planning. Please request CG to update SAP ECC and confirm by clicking the button on the auto-email sent to them.";

// Info Tooltip text end

export const delistBOMActionTypeList = [
  {
    value: 1,
    label: "Delist",
    launchType: "Hard",
    toolTip:
      "FG is taken from the market. (Can be 1 in 1 out). There is not a direct replacement. The minute it is replaced, we follow Replacement - with Hard or Soft code change. BW is looking at the FG and unique RPM.",
  },
  {
    value: 2,
    label: "Replacement with a soft change",
    launchType: "Soft",
    toolTip:
      "FG old stock (recipe/packaging) phased out, NO new FG code is introduced, NO EAN change. Old and new Stocks (recipe/packaging) of FG can both be on the market at same time. So date of launch and delist date can differ. BW is looking at the unique RPM only.",
  },
  {
    value: 3,
    label: "Replacement with a hard change",
    launchType: "Hard",
    toolTip:
      "FG code is stopped, a new FG is introduced (EAN change), HARD cut linked to trade windows. Two FG's can NOT be on the market at the same time. So date of launch and delist date are the same. BW is looking at the FG and unique RPM.",
  },
  {
    value: 4,
    label: "Bill of material change",
    launchType: "Soft",
    toolTip:
      "The FG code is kept the same, but there is a ASA or AMA ongoing, so changes in BOM are made. BW is looking only at unique RPM. In unique RPM screen the materials need to be marked that are not part of this BOM change.",
  },
];

export const delistBOMLaunchTypeList = [
  { value: 1, label: "Soft" },
  { value: 2, label: "Hard" },
];

export const sortProjectEnum = {
  1: "Business Waste - high to low",
  2: "Business Waste - low to high",
  3: "Project Name A - Z",
  4: "Project Name Z - A",
};

export const sortNotificationEnum = {
  1: "Time - newest first",
  2: "Time - oldest first",
  3: "Group by - project",
};

export const innoflexStatusEnum_old = {
  1: "Validate",
  2: "Execute",
  "Post-Launch": "Post Launch",
};

export const innoflexStatusEnum = {
  Validate: "Validate",
  Execute: "Execute",
  Explore: "Explore",
  Exclude: "Exclude",
  "Post-Launch": "Post-Launch",
  Temporary: "Temporary",
};

export const innoflexStatusNamesEnum = {
  Validate: "Validate",
  Execute: "Execute",
  Explore: "Explore",
  Exclude: "Exclude",
  "Post-Launch": "PostLaunch",
  Temporary: "Temp",
};

export const innoflexStatusColorsEnum = {
  Validate: "#9C44C0",
  Execute: "#7C45AA",
  Explore: "#9C44C0",
  Exclude: "Exclude",
  "Post-Launch": "#684BDC",
  Temporary: "#846CE2",
};

export const workflowStatusEnum = {
  1: "stateNothing",
  2: "stateComplete",
  3: "stateIncomplete",
  4: "stateAttention",
  5: "stateWaiting",
  6: "stateSkipped",
  7: "stateAttention",
};

export const workflowStageEnum = [
  { id: "1", desc: "Check Innoflex" },
  { id: "2", desc: "Delists/BOM" },
  { id: "3", desc: "Check Delists" },
  { id: "4", desc: "Unique RPM" },
  { id: "5", desc: "R&D Sign Off" },
  { id: "6", desc: "Inventory@CM" },
  { id: "7", desc: "Send to MDM Ops" },
  { id: "8", desc: "Email E2E" },
  { id: "9", desc: "Scenario Planner" },
];

export const checkProjectStage = [
  { value: 1, label: "P&I knows FG to be delisted" },
  { value: 2, label: "BC review" },
  { value: 3, label: "R&D knows RPM to be reused in new FG" },
  { value: 4, label: "Lock launch date" },
  { value: 5, label: "Re-negotiate contracts on old FG/RPM" },
  { value: 6, label: "Discuss forecast changes for old FG" },
  { value: 7, label: "CM Ops knows RPM inventory" },
  { value: 8, label: "New FC in RR" },
  { value: 9, label: "Ensure stopping ordering old RPM" },
  { value: 10, label: "Ensure stopping Production old FG" },
];

export const uniqueRPMFilterEnum = {
  1: "country",
  2: "skuCode",
  3: "skuName",
  4: "plantCode",
  5: "plantName",
  6: "materialType",
  //7: "recipientEmail",
};

export const workflowStageSteps = [
  "INPUT FGs",
  "INPUT DATES",
  "REVIEW SUs & CMs",
  "REVIEW UNIQUE RPMs",
  "INPUT SUPPLIER STOCK",
  "E-MAIL E2E",
  "PLAN SCENARIO",
];

export const workflowStatusEnum_New = {
  1: "stateNothing",
  2: "stateComplete",
  3: "stateNothing",
  4: "stateProgress",
  5: "stateWaiting",
  6: "stateSkip",
  7: "statePartial",
};

export const delistingActionMenus = [
  {
    icon: "summary",
    label: "Project Summary",
  },
  {
    icon: "edit",
    label: "Edit Project",
  },
  {
    icon: "delete",
    label: "Delete Project",
  },
];

export const functionRoleType = [
  { label: "Marketing", value: "Marketing" },
  { label: "Procurement", value: "Procurement" },
  { label: "Material Planning", value: "Material Planning" },
  { label: "Demand Planning", value: "Demand Planning" },
  { label: "E2E Planning", value: "E2E Planning" },
  { label: "P&I", value: "P&I" },
];
