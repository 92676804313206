import React, { useState, useEffect } from "react";
import "../../styles/global.css";
import Spinner from "react-bootstrap/Spinner";

function ActivityIndicator(props) {
    return (
        <div className="loading-spinner" style={{display:props.display}}>
            <Spinner animation="border" role="status">
                {/* <span className="visually-hidden">Loading...</span> */}
            </Spinner>
        </div>
    );
}

export default ActivityIndicator;
