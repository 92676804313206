import { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Row, Col } from "react-bootstrap";
import ActivityIndicator from "../../../../components/Loader/loader";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import { configByEnv } from "../../../../service/APIHelper";
import { saveReviewAction } from "../../../../service/scenarioPlannerService";
import UserDetailsService from "../../../../service/userDetailsService";
import { dateTimeFormat } from "../../../../utils";

const ModalReviewSendMail = (props) => {
  const {
    showModal,
    setShowModal,
    currentItem,
    selectedProject,
    e2ePlannerName,
  } = props;
  const [isLoadingDatagrid, setIsLoadingDatagrid] = useState(true);
  const [actionData, setActionData] = useState([]);
  const [recipientName, setRecipientName] = useState("");
  const [validationMessage, setValidationMessage] = useState("");

  const userDetails = useSelector((state) => state.userDetails);

  useEffect(() => {
    setActionData(...actionData, currentItem);
    // setIsLoadingDatagrid(true);
    getUserDetails(currentItem.contact);
  }, []);

  const getUserDetails = async (recepientMailId) => {
    const userDetails = await UserDetailsService.fetchUsersByMailId(
      recepientMailId
    );
    if (userDetails.status === 200) {
      setRecipientName(
        `${userDetails.data.userFirstName} ${userDetails.data.userLastName}`
      );
      setIsLoadingDatagrid(false);
    } else {
      setRecipientName("");
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleOkClick = async () => {
    let doc = document.getElementById("review-action-preview-email");
    const { isEditable, isModified, ...rest } = currentItem;
    rest.mailSubject = `Action for ${currentItem.functionName}`;
    rest.mailBody = doc.innerHTML;
    rest.recepientMailId = currentItem.contact;
    rest.isMailSent = true;
    const response = await saveReviewAction(rest);
    if (response.status === 200) {
      props.onChange(response.data);
      closeModal();
    } else {
      setValidationMessage("Mail not sent. Please try again.");
    }
  };

  return (
    <>
      <Modal
        centered
        dialogClassName="dsf-scenario-review-mail-dialog dsf-dialog-messagebox"
        contentClassName="dsf-modal-messagebox dsf-delistingProjDel-okmodal"
        show={showModal}
        style={{ width: "100% !important" }}
      >
        <Modal.Header className="bw-modal-messagebox-header">
          <Modal.Title className="dsf-size16 bw-messagebox-header-title">
            Preview Email
            <br />
            <span className="dsf-size11 dsf-font-weight-400 errorMsg">
              {validationMessage}
            </span>
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close-Image"
            className="img.dsf-close-messagebox-img"
            onClick={() => closeModal()}
          />
        </Modal.Header>
        <Modal.Body className="bw-modal-messagebox-body">
          <Row className="scenario-action-div" style={{ marginTop: "5px" }}>
            {isLoadingDatagrid ? (
              <ActivityIndicator />
            ) : (
              <div
                className="input-attachments"
                id="review-action-preview-email"
              >
                <div className="E2E-preview-div">
                  <p className="E2E-preview-text1">Dear {recipientName},</p>{" "}
                  <br />
                  <p className="E2E-preview-text1">
                    As part of optimising the Business waste for project{" "}
                    {selectedProject.projectName}, we have agreed on the
                    following action. Please execute the action by the
                    respective deadline. Please confirm back when the action is
                    completed by using below buttons or going directly to the
                    <a target="_blank" href={configByEnv().appLink}>
                      {" "}
                      Application
                    </a>
                    .
                  </p>
                  <br />
                  <ul>
                    <li key={currentItem.actionId}>
                      <span>
                        {currentItem.actionDetails} adjusted before{" "}
                        {dateTimeFormat(currentItem.deadline, "DD/MM/YYYY")}{" "}
                      </span>
                    </li>
                  </ul>
                  <div>
                    Kind Regards,
                    <br />
                    {userDetails.userFirstName}&nbsp; {userDetails.userLastName}
                  </div>
                </div>
              </div>
            )}
          </Row>
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer">
          <Button
            className="bw-footer-cancle-btn "
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            className="dsf-footer-okbutton-PI"
            variant="secondary"
            onClick={handleOkClick}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalReviewSendMail;
