import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../styles/global.css";
import "./card.css";

function Card(props) {
  const { headerTitle, icon, unit, value } = props;
  const userDetails = useSelector((state) => state.userDetails);
  return (
    <>
      <div className={`dsf-card-main-div dsf-card-main-div-color-PI`}>
        <div className="dsf-card-header">
          <span className="dsf-card-title">{headerTitle}</span>
          <div className="dsf-card-icon">
            <img src={icon} alt="Icon" />
            
          </div>
          ;
        </div>
        <div className="dsf-card-value">
          <span className="dsf-card-value-span">
            {unit ?? ""}
            {value}
          </span>
        </div>
      </div>
    </>
  );
}

export default Card;
