import React, { useState, useRef, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Checkbox from "@mui/material/Checkbox";
import "./autocomplete.css";
import polygon from "../../assets/images/Polygon-enable.svg";
import polygonClose from "../../assets/images/PolygonClose.svg";
import polygonDisable from "../../assets/images/Polygon-disable.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: "#EDF1F3",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-root": {
    alignItems: "unset",
  },
  "& .MuiInputBase-input": {
    color: "#797775",
    fontWeight: "400",
    maxWidth: "unset",
    justifyContent: "center",
  },
}));

const CustomAutoCompleteDropdown = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isOpenFirstTime, setIsOpenFirstTime] = useState(true);
  const {
    options,
    maxLength,
    width,
    disable,
    maxDropdownHeight,
    onSelectionChange,
    listClassName,
    isOpen,
    setIsOpen,
    selectAllEnable = false,
  } = props;

  useEffect(() => {
    if (
      props.options.length > 0 &&
      isOpenFirstTime &&
      props.selectedValues &&
      props.selectedValues.length > 0
    ) {
      {
        props.selectedValues.length === 1 &&
        props.selectedValues[0].length === 0
          ? setSelectedValues([])
          : setSelectedValues(props.selectedValues);
      }
      setIsOpenFirstTime(false);
    } else {
      setSelectedValues([]);
    }
  }, [props.options]);

  useEffect(() => {
    if (!props.isOpen) {
      onSelectionChange(selectedValues);
    }
  }, [props.isOpen]);

  const filteredOptions = Array.isArray(options)
    ? options.filter((option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  const toggleDropdown = () => {
    {
      !disable && setIsOpen(!isOpen);
      setSearchTerm("");
    }
  };

  const toggleValue = (value) => {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((val) => val !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  return (
    <div className={`custom-dropdown ${listClassName}`}>
      <div
        className="input-container"
        onClick={() => {
          toggleDropdown();
          if (onSelectionChange) {
            onSelectionChange(selectedValues);
          }
        }}
        style={{ width: width }}
      >
        <input
          type="text"
          value={
            selectedValues && selectedValues.length > 0
              ? truncateText(selectedValues.join(", "), maxLength)
              : ""
          }
          disabled={disable}
          readOnly
        />
        <img
          src={disable ? polygonDisable : isOpen ? polygon : polygonClose}
          disabled={disable}
          alt="autocomplete-dropdown-icon"
          className={`autocomplete-dropdown-icon  ${
            disable
              ? "dropdownDisable"
              : isOpen
              ? "dropdownEnable"
              : "dropdownClose"
          }`}
        />
      </div>
      {isOpen && (
        <div className="options-container">
          <Search className="dsf-delistingProj-search">
            <SearchIconWrapper className="dsf-delistingProj-search-icon">
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={"Search…"}
              value={searchTerm}
              className="dsf-delistingProj-search-text"
              onChange={(e) => setSearchTerm(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          {selectAllEnable && (
            <div className="bwi-autocomplete-select-all">
              <Checkbox
                checked={
                  selectedValues.length > 0 &&
                  selectedValues.length ===
                    filteredOptions.filter((option) => !option.isDisable).length
                }
                onChange={() => {
                  if (
                    selectedValues.length ===
                    filteredOptions.filter((option) => !option.isDisable).length
                  ) {
                    setSelectedValues([]);
                  } else {
                    setSelectedValues(
                      filteredOptions
                        .filter((option) => !option.isDisable)
                        .map((option) => option.label)
                    );
                  }
                }}
                className="bwi-checkbox"
                disableRipple
              />
              <span>
                Select All (
                {options.filter((option) => !option.isDisable).length})
              </span>
            </div>
          )}
          <div
            className="options-list"
            style={{
              maxHeight: maxDropdownHeight,
              overflowX: "hidden",
              maxWidth: width,
            }}
          >
            {filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`option-row ${
                  selectedValues.includes(option.value) ? "selected" : ""
                }`}
                onClick={() => {
                  toggleValue(option.label);
                  if (onSelectionChange) {
                    onSelectionChange(selectedValues);
                  }
                }}
              >
                <Checkbox
                  checked={selectedValues.includes(option.label)}
                  onChange={() => toggleValue(option.label)}
                  color="primary" // Change this to customize the color
                />
                {option.label}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomAutoCompleteDropdown;
