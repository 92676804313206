import React, { useEffect, useState, useRef } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Header from "../../../../layouts/Header/header";
import "./modalDeepDiveView.css";
import DeepDiveAction from "./deepDiveAction";
import {
  fetchDeepDiveValue,
  fetchDeepDiveVolume,
  fetchDeepDiveValueDefault,
  fetchDeepDiveVolumeDefault,
  fetchDeepDiveDetailedTable,
  fetchDeepDiveDetailedTableVolume,
} from "../../../../service/projectDetails";
import ActivityIndicator from "../../../../components/Loader/loader";
import BackIcon from "../../../../assets/images/landingPage/backIcon.svg";
import DeepDiveValue from "./deepDiveValue";
import DeepDiveVolume from "./deepDiveVolume";

const DeepDiveView = (props) => {
  const [showModal, setShowModal] = useState(props.showModal);
  const [isLoading, setIsLoading] = useState(true);
  const [tabSelection, setTabSelection] = useState("");

  const [deepDiveDefaultValueData, setDeepDiveDefaultValueData] = useState({});
  const [deepDiveActualValueData, setDeepDiveActualValueData] = useState({});
  const [deepDiveValueTableData, setDeepDiveValueTableData] = useState({});

  const [isValueDefaultSuccess, setIsValueDefaultSuccess] = useState(0);
  const [isValueActualSuccess, setIsValueActualSuccess] = useState(0);
  const [isValueTableDataSuccess, setIsValueTableDataSuccess] = useState(0);
  const [isValueDataPresent, setIsValueDataPresent] = useState(false);

  const [deepDiveDefaultVolumeData, setDeepDiveDefaultVolumeData] = useState(
    {}
  );
  const [deepDiveActualVolumeData, setDeepDiveActualVolumeData] = useState({});
  const [deepDiveVolumeTableData, setDeepDiveVolumeTableData] = useState({});

  const [isVolumeDefaultSuccess, setIsVolumeDefaultSuccess] = useState(0);
  const [isVolumeActualSuccess, setIsVolumeActualSuccess] = useState(0);
  const [isVolumeTableDataSuccess, setIsVolumeTableDataSuccess] = useState(0);
  const [isVolumeDataPresent, setIsVolumeDataPresent] = useState(false);
  const [isDeepDiveEnabled, setIsDeepDiveEnabled] = useState(true);

  useEffect(() => {
    if (props.arrItem.systemBW === "0") {
      setIsDeepDiveEnabled(false);
      setIsLoading(false);
    } else {
      setTabSelection("Value");
    }
  }, []);

  useEffect(() => {
    if (tabSelection === "Value") {
      if (!isValueDataPresent) {
        setIsLoading(true);
        getDefaultDeepDiveData();
        getDeepDiveData();
        getDetaileViewTableData();
      }
    } else if (tabSelection === "Volume") {
      if (!isVolumeDataPresent) {
        setIsLoading(true);
        getDefaultDeepDiveDataVolume();
        getDeepDiveDataVolume();
        getDetaileViewTableDataVolume();
      }
    }
  }, [tabSelection]);

  useEffect(() => {
    if (
      isValueActualSuccess &&
      isValueDefaultSuccess &&
      isValueTableDataSuccess
    ) {
      setIsLoading(false);
      setIsValueActualSuccess(0);
      setIsValueDefaultSuccess(0);
      setIsValueTableDataSuccess(0);
      setIsValueDataPresent(true);
    }
  }, [isValueActualSuccess, isValueDefaultSuccess, isValueTableDataSuccess]);

  useEffect(() => {
    if (
      isVolumeActualSuccess &&
      isVolumeDefaultSuccess &&
      isVolumeTableDataSuccess
    ) {
      setIsLoading(false);
      setIsVolumeActualSuccess(0);
      setIsVolumeDefaultSuccess(0);
      setIsVolumeTableDataSuccess(0);
      setIsVolumeDataPresent(true);
    }
  }, [isVolumeActualSuccess, isVolumeDefaultSuccess, isVolumeTableDataSuccess]);

  const handleTabChange = (selectedTab) => {
    setTabSelection(selectedTab);
  };

  const getDetaileViewTableData = async () => {
    const res = await fetchDeepDiveDetailedTable(props.selectedProjectId);
    if (res.status === 200) {
      setDeepDiveValueTableData(Object.assign({}, res.data));
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsValueTableDataSuccess(1);
  };

  const getDefaultDeepDiveData = async () => {
    let res;
    res = await fetchDeepDiveValueDefault(props.selectedProjectId);
    if (res.status === 200) {
      setDeepDiveDefaultValueData(Object.assign({}, res.data));
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsValueDefaultSuccess(1);
  };

  const getDeepDiveData = async () => {
    let res;
    res = await fetchDeepDiveValue(props.selectedProjectId);
    if (res.status === 200) {
      setDeepDiveActualValueData(Object.assign({}, res.data));
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsValueActualSuccess(1);
  };

  const getDetaileViewTableDataVolume = async () => {
    const res = await fetchDeepDiveDetailedTableVolume(props.selectedProjectId);
    if (res.status === 200) {
      setDeepDiveVolumeTableData(Object.assign({}, res.data));
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsVolumeTableDataSuccess(1);
  };

  const getDefaultDeepDiveDataVolume = async () => {
    let res;
    res = await fetchDeepDiveVolumeDefault(props.selectedProjectId);
    if (res.status === 200) {
      setDeepDiveDefaultVolumeData(Object.assign({}, res.data));
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsVolumeDefaultSuccess(1);
  };

  const getDeepDiveDataVolume = async () => {
    let res;
    res = await fetchDeepDiveVolume(props.selectedProjectId);
    if (res.status === 200) {
      setDeepDiveActualVolumeData(Object.assign({}, res.data));
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsVolumeActualSuccess(1);
  };

  const handleBackButtonClicked = () => {
    setShowModal(false);
    props.onComplete(false);
  };

  return (
    <>
      <Modal
        id="fullScreenModalId"
        dialogClassName="deepdive-fullscreen-modal"
        contentClassName="deepdive-modal-content"
        show={showModal}
      >
        <div className="dsf-header-div">
          <Header />
          <div className="dsf-header-empty-div"></div>
        </div>
        <div className="dsf-dashboard-deepdive">
          <Row className="dsf-deepdive-parent-row1">
            <Col xs={9} className="dsf-deepdive-parent-row-col1">
              <Row className="dsf-deepdive-title-row">
                <Col xs={7} className="dsf-deepdive-title">
                  <p>
                    {props.selectedProjectName.length > 60
                      ? `${props.selectedProjectName.substring(0, 60)}...`
                      : props.selectedProjectName}{" "}
                    - Deep Dive
                  </p>
                </Col>
                <Col xs={3}></Col>
                <Col xs={2} className="dsf-deepdive-close">
                  <div
                    onClick={handleBackButtonClicked}
                    className="bwi-landing-cursor-pointer"
                  >
                    <img
                      src={BackIcon}
                      alt="back-icon"
                      className="bwi-landing-cursor-pointer bwi-padding-right-10"
                    />
                    <span className="bwi-landing-back-text">Back</span>
                  </div>
                </Col>
              </Row>

              {isLoading ? (
                <ActivityIndicator />
              ) : (
                <>
                  {!isDeepDiveEnabled ? (
                    <div className="dsf-deepdive-tab-parent-no-data">
                      <p className="dsf-deepdive-tab-no-data-text">
                        No deep dive data available for the selected project
                      </p>
                    </div>
                  ) : (
                    <>
                      {tabSelection === "Value" ? (
                        <DeepDiveValue
                          deepDiveDefaultValueData={deepDiveDefaultValueData}
                          deepDiveActualValueData={deepDiveActualValueData}
                          deepDiveValueTableData={deepDiveValueTableData}
                          onTabChange={handleTabChange}
                          selectedProjectId={props.selectedProjectId}
                        />
                      ) : (
                        <DeepDiveVolume
                          deepDiveDefaultVolumeData={deepDiveDefaultVolumeData}
                          deepDiveActualVolumeData={deepDiveActualVolumeData}
                          deepDiveVolumeTableData={deepDiveVolumeTableData}
                          onTabChange={handleTabChange}
                          selectedProjectId={props.selectedProjectId}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Col>
            <Col xs={3} className="dsf-deepdive-parent-row-col2">
              <Row className="dsf-deepdive-action-parent-row">
                <DeepDiveAction arrItem={props.arrItem} isEdit={props.isEdit} />
              </Row>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default DeepDiveView;
