import { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";

const textStyle = {
  color: "#EDF1F3",
  fontSize: 8,
  fontFamily: "Unilever Shilling",
  fontWeight: 400,
};
const LineChartDesign = (props) => {
  const [barChartData, setBarChartData] = useState([]);
  useEffect(() => {
    let newData = [];
    if (props.arrLinechart.length > 0) {
      props.arrLinechart.sort(
        (a, b) =>
          parseInt(a.weekName.substring(3, 7)) -
          parseInt(b.weekName.substring(3, 7))
      );
      const columnValues = Object.keys(props.arrLinechart[0]);
      newData.push(columnValues);
      for (var i = 0; i < props.arrLinechart.length; i++) {
        let annotation = null;
        let annotationText = null;
        const rowValues = Object.values(props.arrLinechart[i]);
        if (i === props.arrLinechart.length - 1) {
          rowValues.splice(1, 0, "");
        } else {
          rowValues.splice(1, 0, null);
        }
        newData.push(rowValues);
      }
    } else {
      newData.push([]);
    }
    setBarChartData(newData);
  }, [props.arrLinechart]);

  useEffect(() => {
    GoogleCharts.load(drawChart, {
      packages: ["corechart", "line"],
    });
  }, [barChartData]);

  // console.log("barChartData", barChartData)
  const drawChart = () => {
    const chartDiv = document.getElementById("line_chart_div");
    if (barChartData.length > 0) {
      let data_n = new GoogleCharts.api.visualization.DataTable();
      var numRows = barChartData.length;
      data_n.addColumn("string", barChartData[0][0]);
      data_n.addColumn({ type: "string", role: "annotation" });
      barChartData.map((item, i) => {
        if (i === 0) {
          item.map((ele, i) => {
            if (i > 0) return data_n.addColumn("number", ele);
          });
        }
      });

      for (var i = 1; i < numRows; i++) {
        data_n.addRow(barChartData[i]);
      }

      const options = {
        title: "",
        backgroundColor: "transparent",
        legend: { position: "top", textStyle: textStyle },
        chartArea: { width: "80%" },
        series: {
          0: { color: "#9C44C0" },
          1: { color: "#00D7C4" },
          2: { color: "#00B2FF" },
          3: { color: "#FF79C6" },
          4: { color: "#FCEDA4" },
        },
        hAxis: {
          format: "short",
          titleTextStyle: { color: "#EC6157" },
          textPosition: "none",
          gridlines: { count: 14, color: "#EC6157", opacity: 1 },
        },
        vAxis: {
          titleTextStyle: { color: "#FFF" },
          textStyle: textStyle,
          gridlines: { count: 0 },
        },
        annotations: {
          style: "line",
          stemColor: "#EC6157",

          // textStyle: {
          //   fontSize: 12,
          //   fontFamily: 'Unilever Shilling',
          //   fontWeight: 400,
          //   color: '#EC6157',
          //   opacity: 1,
          //   textPosition: "none",
          // },
        },
      };

      const chart = new GoogleCharts.api.visualization.LineChart(chartDiv);
      chart.draw(data_n, options);
    }
  };
};
export default LineChartDesign;
