import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import ActivityIndicator from "../Loader/loader";
import { Col, Row } from "react-bootstrap";

import "./transferlist.css";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: "#EDF1F3",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-root": {
    alignItems: "unset",
  },
  "& .MuiInputBase-input": {
    color: "#797775",
    fontWeight: "400",
    maxWidth: "unset",
    justifyContent: "center",
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const TransferList = (props) => {
  const [checked, setChecked] = React.useState([]);
  const [leftSearchText, setLeftSearchText] = React.useState("");
  const [rightSearchText, setRightSearchText] = React.useState("");
  const {
    leftData,
    rightData,
    handleSelectedData,
    rightDataReset,
    setRightDataReset,
    selectedCountry,
    selectedAction,
    selectCountryAction,
  } = props;

  let left = leftData.map((data) => data.skuCode + " - " + data.skuName);
  let right = rightData.map((data) => data.skuCode + " - " + data.skuName);

  React.useEffect(() => {
    return () => {
      setChecked([]);
    };
  }, [props.leftData, props.selectedCountry]);

  React.useEffect(() => {
    if (rightDataReset) {
      handleAllLeft();
      setRightDataReset(false);
    }
  }, [rightDataReset]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    if (props.isEdit) {
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    }
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleAllLeft = () => {
    const data = {
      [selectCountryAction]: {
        selectedData: [],
        unselectedData: left.concat(right),
      },
    };
    handleSelectedData(data);
  };

  const handleCheckedRight = () => {
    const data = {
      [selectCountryAction]: {
        selectedData: right.concat(leftChecked),
        unselectedData: not(left, leftChecked),
      },
    };
    handleSelectedData(data);
    // setRight(right.concat(leftChecked));
    // setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    const data = {
      [selectCountryAction]: {
        selectedData: not(right, rightChecked),
        unselectedData: left.concat(rightChecked),
      },
    };
    handleSelectedData(data);
    setChecked(not(checked, rightChecked));
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item className="dsf-transferlist-list-left">
        <Card>
          <CardHeader
            sx={{
              pb: 0,
            }}
          />
          <Search className="dsf-transferlist-list-search">
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              // disabled={
              //   props.ListClassName === "dsf-delist-form-disabled"
              //     ? true
              //     : false
              // }
              // placeholder={props.ListClassName !== "dsf-delist-form-disabled" && props.selectedCountry.length > 0 && props.selectedCountry.length > 0 && props.isLoading ? "Loading..." : 'Search…'}
              placeholder={"Search…"}
              onChange={(e) => setLeftSearchText(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <div className="list-parent">
            {props.isLoading ? (
              <ActivityIndicator />
            ) : (
              <>
                {left.length > 0 ? (
                  <List
                    className="dsf-delistbom-scroll"
                    // className={`${
                    //   props.ListClassName === "dsf-delist-form-disabled"
                    //     ? "dsf-delistbom-scroll-disabled"
                    //     : "dsf-delistbom-scroll"
                    // }`}
                    sx={{
                      width: 200,
                      height: 230,
                      bgcolor: "background.paper",
                      overflow: "auto",
                    }}
                    dense
                    component="div"
                    role="list"
                  >
                    {left ? (
                      left
                        .filter(
                          (value) =>
                            leftSearchText === "" ||
                            `${value}`
                              .toLowerCase()
                              .includes(leftSearchText.toLowerCase())
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((value) => {
                          const labelId = `transfer-list-all-item-${selectCountryAction}-${value}-label`;
                          const isPresent = props.modifiedData.some(
                            (item) =>
                              item.skuCode === value.split(" - ")[0] &&
                              item.isSelected
                          );
                          return (
                            <ListItem
                              disabled={
                                props.ListClassName ===
                                  "dsf-delist-form-disabled" ||
                                isPresent == true
                                  ? true
                                  : false
                              }
                              key={value}
                              role="listitem"
                              onClick={handleToggle(value)}
                            >
                              <ListItemIcon className="dsf-transferlist-chkbox">
                                <Checkbox
                                  checked={checked.indexOf(value) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  style={{
                                    color: "#EDF1F3",
                                    cursor: "pointer",
                                    pointerEvents: "unset",
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={`${value}`} />
                            </ListItem>
                          );
                        })
                    ) : (
                      <ActivityIndicator />
                    )}
                  </List>
                ) : props.selectedCountry.length > 0 &&
                  props.selectedAction.length > 0 &&
                  props.selectedCountryLaunchType.length > 0 &&
                  !props.isLoading &&
                  left.length <= 0 ? (
                  <span
                    className={`not-found ${
                      props.ListClassName === "dsf-delist-form-disabled"
                        ? "dsf-delistbom-listtxt-disabled"
                        : "dsf-delistbom-listtxt"
                    }`}
                  >
                    {" "}
                  </span>
                ) : (
                  (props.selectedAction.length > 0 &&
                    props.selectedCountryLaunchType.length === 0 && (
                      <span
                        className={`not-found ${
                          props.ListClassName === "dsf-delist-form-disabled"
                            ? "dsf-delistbom-listtxt-disabled"
                            : "dsf-delistbom-listtxt"
                        }`}
                      >
                        Please select any launch type !!
                      </span>
                    )) ||
                  (props.selectedCountryLaunchType.length > 0 &&
                    props.selectedCountry.length === 0 && (
                      <span
                        className={`not-found ${
                          props.ListClassName === "dsf-delist-form-disabled"
                            ? "dsf-delistbom-listtxt-disabled"
                            : "dsf-delistbom-listtxt"
                        }`}
                      >
                        Please select any country !!
                      </span>
                    ))
                )}
              </>
            )}
          </div>
        </Card>
      </Grid>

      <Grid item className="bw-transferlist-button">
        <Grid container direction="column" alignItems="center">
          <Button
            className="dsf-manual-entry-navigation-btn"
            onClick={handleCheckedRight}
            disabled={
              leftChecked.length === 0 ||
              props.ListClassName === "dsf-delist-form-disabled"
            }
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            className="dsf-manula-entry-navigation-btn"
            onClick={handleCheckedLeft}
            disabled={
              rightChecked.length === 0 ||
              props.ListClassName === "dsf-delist-form-disabled"
            }
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>

      <Grid item className="dsf-transferlist-list-right">
        <Card>
          <CardHeader
            sx={{
              pb: 0,
            }}
          />
          <Search className="dsf-transferlist-list-search">
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              // disabled={
              //   props.ListClassName === "dsf-delist-form-disabled"
              //     ? true
              //     : false
              // }
              // placeholder={props.ListClassName !== "dsf-delist-form-disabled" && props.isLoading ? "Loading..." : 'Search…'}
              placeholder={"Search…"}
              onChange={(e) => setRightSearchText(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <div className="list-parent">
            {props.isLoading ? (
              <ActivityIndicator />
            ) : (
              <>
                {right.length > 0 ? (
                  <List
                    className="dsf-delistbom-scroll"
                    // className={`${
                    //   props.ListClassName === "dsf-delist-form-disabled"
                    //     ? "dsf-delistbom-scroll-disabled"
                    //     : "dsf-delistbom-scroll"
                    // }`}
                    sx={{
                      width: 200,
                      height: 230,
                      bgcolor: "background.paper",
                      overflow: "auto",
                    }}
                    dense
                    component="div"
                    role="list"
                  >
                    {right ? (
                      right
                        .filter(
                          (value) =>
                            rightSearchText === "" ||
                            `${value}`
                              .toLowerCase()
                              .includes(rightSearchText.toLowerCase())
                        )
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((value) => {
                          const labelId = `transfer-list-all-item-${selectCountryAction}-${value}-label`;
                          return (
                            <ListItem
                              disabled={
                                props.ListClassName ===
                                "dsf-delist-form-disabled"
                                  ? true
                                  : false
                              }
                              key={value}
                              role="listitem"
                              onClick={handleToggle(value)}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  checked={checked.indexOf(value) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                  style={{
                                    color: "#EDF1F3",
                                    cursor: "pointer",
                                    pointerEvents: "unset",
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText id={labelId} primary={`${value}`} />
                            </ListItem>
                          );
                        })
                    ) : (
                      <ActivityIndicator />
                    )}
                  </List>
                ) : props.selectedCountry.length > 0 &&
                  props.selectedAction.length > 0 &&
                  props.selectedCountryLaunchType.length > 0 &&
                  !props.isLoading &&
                  right.length <= 0 ? (
                  <span
                    className={`not-found ${
                      props.ListClassName === "dsf-delist-form-disabled"
                        ? "dsf-delistbom-listtxt-disabled"
                        : "dsf-delistbom-listtxt"
                    }`}
                  >
                    {" "}
                  </span>
                ) : (
                  (props.selectedAction.length > 0 &&
                    props.selectedCountryLaunchType.length === 0 && (
                      <span
                        className={`not-found ${
                          props.ListClassName === "dsf-delist-form-disabled"
                            ? "dsf-delistbom-listtxt-disabled"
                            : "dsf-delistbom-listtxt"
                        }`}
                      >
                        Please select any launch type !!
                      </span>
                    )) ||
                  (props.selectedCountryLaunchType.length > 0 &&
                    props.selectedCountry.length === 0 && (
                      <span
                        className={`not-found ${
                          props.ListClassName === "dsf-delist-form-disabled"
                            ? "dsf-delistbom-listtxt-disabled"
                            : "dsf-delistbom-listtxt"
                        }`}
                      >
                        Please select any country !!
                      </span>
                    ))
                )}
              </>
            )}
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TransferList;
