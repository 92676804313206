import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Modal, Row } from "react-bootstrap";
import ActivityIndicator from "../../../../components/Loader/loader";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import downloadIcon from "../../../../assets/images/download.svg";
import addChart from "../../../../assets/images/Add chart.svg";
import lockIcon from "../../../../assets/images/lock_open.svg";
import ScenarioUpload from "./scenarioUpload";
import ScenarioAction from "./scenarioAction";
import Header from "../../../../layouts/Header/header";
import HorizontalBarChartDesign from "../../../../helpers/HorizontalBarChart";
import StackedHorizontalBarChartDesign from "../../../../helpers/StackedHorizontalBarChart";
import {
  fetchScenarioData,
  fetchCountryMaterialDetails,
  fetchRequiredActionList,
  fetchScenarioTemplateDownload,
} from "../../../../service/scenarioPlannerService";
import "./modalScenarioPlanner.css";
import template from "../../../../assets/Templates/InventoryAtCM-Template.xlsx";

const colorCode = ["#EF5891", "#846CE2", "#846CE2", "#846CE2"];

const ScenarioPlanner = (props) => {
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [showUploadModal, setShowUploadModal] = React.useState(false);
  const [isScenarioAdded, setIsScenarioAdded] = React.useState(false);
  const [selectedScenario, setSelectedScenario] = useState();
  const [activeTab, setActiveTab] = useState("Value");
  const [scenarioListData, setScenarioListData] = useState({});
  const [countryMaterialData, setCountryMaterialData] = useState([]);
  const [requiredActionData, setRequiredActionData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDiv, setSelectedDiv] = useState("");
  const [scenarioTemplate, setScenarioTemplate] = useState("");
  const [isWaiting, setIsWaiting] = useState(false);
  const [isSlectedGoodValue, setIsSlectedGoodValue] =
    useState("combinedGoodsValue");
  let lastSelectedContainer = "";

  const userDetails = useSelector((state) => state.userDetails);

  //Redux Start
  const dispatch = useDispatch();
  //Redux End

  useEffect(() => {
    setIsLoading(true);
    getScenarioDetails();
    getCountryMaterialData();
    getRequiredActionList();
    {
      userDetails.roleName === "BWInnovationE2EPlanner" &&
        getScenarioFileTemplate();
    }
  }, []);

  const getScenarioFileTemplate = async () => {
    setIsWaiting(false);
    const res = await fetchScenarioTemplateDownload(props.arrItem.projectId);
    if (res.status === 200) {
      const link = URL.createObjectURL(res.data);
      setScenarioTemplate(link);
    } else {
      console.log("failed template", res.data);
    }
    setIsWaiting(true);
  };

  const onPreviewExcelBtnClicked = () => {
    if (isWaiting) {
      const link = document.createElement("a");
      link.href = scenarioTemplate;
      link.download = "ScenarioTemplate";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getScenarioDetails = async () => {
    const res = await fetchScenarioData(props.arrItem.projectId);
    if (res.status === 200) {
      setScenarioListData(Object.assign({}, res.data));
    } else {
      console.log("failed to fetch data", res.data);
    }
    setIsLoading(false);
  };

  const getCountryMaterialData = async () => {
    const res = await fetchCountryMaterialDetails(props.arrItem.projectId);
    // console.log(res);
    if (res.status === 200) {
      let arr = res.data.map((item) => {
        return item;
      });
      setCountryMaterialData([...arr]);
      // console.log("scenarioList", countryMaterialData);
    } else {
      console.log("failed to fetch data", res.data);
    }
  };

  const getRequiredActionList = async () => {
    const res = await fetchRequiredActionList(props.arrItem.projectId);
    // console.log(res);
    if (res.status === 200) {
      const arr = res.data.map((item) => {
        return item;
      });
      setRequiredActionData([...arr]);
      // console.log("scenarioList", requiredActionData);
    } else {
      console.log("failed to fetch data", res.data);
    }
  };

  /* Success callback function start */
  const handleSuccessfulUpload = (isShow, response) => {
    setShowUploadModal(!showUploadModal);
    if (response.status === 200) {
      setIsScenarioAdded(true);
      let arrScenarioList = Object.assign({}, scenarioListData);
      arrScenarioList.arrScenario.push(response.data.arrScenario[0]);
      setScenarioListData(arrScenarioList);
      // console.log(scenarioListData);
    }
  };
  const onUpdateRequiredAction = (data) => {
    const arr = data.map((item) => {
      return item;
    });
    setRequiredActionData([...arr]);
  };
  const onSuccessfulReviewSend = (isSuccess) => {
    setShowModal(false);
    props.onComplete(false, true);
  };
  const handleLockUnlockScenario = (isScenarioLocked) => {
    let arrScenarioList = Object.assign({}, scenarioListData);
    arrScenarioList.arrScenario.map((item) => {
      if (
        item.scenarioId === selectedScenario.scenarioId &&
        item.scenarioName === selectedScenario.scenarioName
      ) {
        item.isScenarioLocked = isScenarioLocked ? 1 : 0;
      } else {
        item.isScenarioLocked = 0;
      }
    });
    setScenarioListData(arrScenarioList);
  };
  /* Success callback function end */

  const closeModal = () => {
    setShowModal(false);
    props.onComplete(false, false);
  };

  const onUploadModalOpen = () => {
    setShowUploadModal(true);
  };

  const onChangeTab = (selectedTab) => {
    selectedDiv.length >= 1 && toogleBackgrdColorDiv(selectedDiv);
    setActiveTab(selectedTab);
  };
  const onScenarioSelection = async (event, objScenario, parentDiv) => {
    if (
      !(
        typeof event.target.className === "string" &&
        event.target.className.includes("bwi-scenario-vol-goods-label-div")
      )
    ) {
      setSelectedScenario(objScenario);
      toogleBackgrdColorDiv(parentDiv);
      setSelectedDiv(parentDiv);
    }
  };
  const toogleBackgrdColorDiv = async (parentDiv) => {
    const container = document.getElementById(parentDiv);
    container.style.backgroundColor = "#0D1116";
    if (parentDiv.includes("current_pos")) {
      container.style.marginTop = "0px";
      container.style.borderTop = "none";
    } else {
      container.style.borderTop = "0.5px solid #797775";
      container.style.marginTop = "2.9px";
    }
    let svg = container.getElementsByTagName("svg")[0];
    let rect = svg.getElementsByTagName("rect")[1];
    if (rect.hasAttributes()) {
      rect.setAttribute("style", `fill:#0D1116;`);
    }
    if (selectedDiv.length >= 1 && parentDiv !== selectedDiv) {
      const prevContainer = document.getElementById(selectedDiv);
      prevContainer.style.backgroundColor = "#1E232F";
      let svg = prevContainer.getElementsByTagName("svg")[0];
      let rect = svg.getElementsByTagName("rect")[1];
      if (rect.hasAttributes()) {
        rect.setAttribute("style", `fill:#1E232F;`);
      }
    }
    lastSelectedContainer = parentDiv;
  };

  const renderAddedScenario = () => {
    let renderElement = [];
    scenarioListData &&
      scenarioListData.arrScenario &&
      scenarioListData.arrScenario.map((item, index) => {
        const chart_div_id = `chart_div${index}`;
        const chart_parent_div_id = `chart_div${index}${index}`;
        renderElement.push(
          <>
            <div
              id={chart_parent_div_id}
              key={chart_parent_div_id}
              className="scenario_list_parent_div"
              onClick={(e) => onScenarioSelection(e, item, chart_parent_div_id)}
            >
              <div className="scenario-list-row">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <span className="dsf-size16 dsf-font-weight-600">
                    {item.scenarioName}
                  </span>
                  {item.isScenarioLocked === 1 && (
                    <img
                      src={lockIcon}
                      style={{ width: "24px", height: "24px" }}
                    />
                  )}
                </div>
              </div>
              <span
                className="dsf-size10 dsf-font-weight-400 span-wrap bringViewInFront"
                style={{ color: "#CFCFCF" }}
              >
                Phase:{item.phase}
              </span>
              {activeTab === "Value" ? (
                <div
                  key={chart_div_id}
                  id={chart_div_id}
                  className="test_chart_1"
                >
                  {
                    <HorizontalBarChartDesign
                      key={chart_div_id}
                      container={chart_div_id}
                      colorCode={colorCode}
                      activeTab={activeTab}
                      chartAreaBackgroundColor={
                        selectedDiv === chart_parent_div_id
                          ? "#0D1116"
                          : "#1E232F"
                      }
                      chartData={item.value}
                    />
                  }
                </div>
              ) : (
                <div className="chart-wrapper">
                  <div
                    key={chart_div_id}
                    id={chart_div_id}
                    className="stacked_test_chart_1"
                  >
                    {
                      <StackedHorizontalBarChartDesign
                        key={chart_div_id}
                        container={chart_div_id}
                        colorCode={colorCode}
                        activeTab={activeTab}
                        chartAreaBackgroundColor={
                          selectedDiv === chart_parent_div_id
                            ? "#0D1116"
                            : "#1E232F"
                        }
                        chartData={item.volume}
                        selectedGoodValue={isSlectedGoodValue}
                      />
                    }
                  </div>
                </div>
              )}
            </div>
          </>
        );
      });
    return renderElement;
  };

  const handleGoodChange = (activeTab, goodValue) => {
    if (activeTab === "Volume") {
      if (goodValue === isSlectedGoodValue) {
        setIsSlectedGoodValue("combinedGoodsValue");
      } else {
        setIsSlectedGoodValue(goodValue);
      }
    }
  };

  const renderScenarioPlanner = () => {
    return (
      <>
        {showUploadModal && (
          <ScenarioUpload
            isShow={showUploadModal}
            onComplete={handleSuccessfulUpload}
            selectedProjectName={props.selectedProjectName}
            scenarioList={scenarioListData.arrScenario}
            projectId={props.arrItem.projectId}
          />
        )}
        <Modal
          id="fullScreenModalId"
          dialogClassName="fullscreen-modal"
          contentClassName=" scenario-modal-content"
          show={showModal}
          bsClass="my-modal"
        >
          <div className="dsf-header-div">
            <Header />
          </div>
          <Row className="scenario-upper-div">
            {isLoading ? <ActivityIndicator /> : <></>}
            {scenarioListData && scenarioListData.currentPos && (
              <Col className="col1 col-basis-30" id="current_pos_chart_div">
                <Row className="checkDelist-modal-header-div">
                  <Col>
                    <span
                      className="dsf-size16 dsf-font-weight-600"
                      style={{ height: "20px" }}
                    >
                      {scenarioListData.currentPos.scenarioName ??
                        "Current Position"}
                    </span>
                    <span
                      className="dsf-size12 dsf-font-weight-400"
                      style={{
                        marginBottom: "5px",
                        color: "#CFCFCF",
                        height: "20px",
                      }}
                    >
                      {scenarioListData.currentPos.scenarioDesc ??
                        "As is position of Goods"}
                    </span>
                    <ul className="nav">
                      <li
                        className={activeTab === "Value" ? "active" : ""}
                        onClick={() => onChangeTab("Value")}
                      >
                        Value (€)
                      </li>
                      <li
                        className={activeTab === "Volume" ? "active" : ""}
                        onClick={() => onChangeTab("Volume")}
                      >
                        Volume
                      </li>
                    </ul>
                  </Col>
                </Row>
                <Row
                  className="chart_area_div_row"
                  onClick={(e) =>
                    onScenarioSelection(
                      e,
                      scenarioListData.currentPos,
                      "current_pos_chart_div"
                    )
                  }
                >
                  <Col
                    className={`test_chart_axis_label ${
                      activeTab === "Volume" ? "bwi-landing-cursor-pointer" : ""
                    }`}
                  >
                    {activeTab === "Value" && (
                      <Row>
                        <span className="dsf-size12 dsf-font-weight-600 gap-div-bottom">
                          Total Business Waste Risk
                        </span>
                      </Row>
                    )}
                    <Row
                      className={`${
                        activeTab === "Volume" &&
                        "bwi-scenario-vol-goods-label-div"
                      }`}
                      onClick={() =>
                        handleGoodChange(activeTab, "finishedGoodsValue")
                      }
                    >
                      <span
                        className={`dsf-size12  gap-div-bottom ${
                          activeTab === "Volume" &&
                          isSlectedGoodValue === "finishedGoodsValue"
                            ? "bwi-scenario-goods-active-div"
                            : "bwi-scenario-goods-inactive-div"
                        }`}
                      >
                        {activeTab === "Volume" && (
                          <span
                            className="color-dot"
                            style={{ backgroundColor: "#EF5891" }}
                          ></span>
                        )}
                        Finished Goods
                      </span>
                    </Row>
                    <Row
                      className={`${
                        activeTab === "Volume" &&
                        "bwi-scenario-vol-goods-label-div"
                      }`}
                      onClick={() =>
                        handleGoodChange(activeTab, "rawMaterialsValue")
                      }
                    >
                      <span
                        className={`dsf-size12  gap-div-bottom ${
                          activeTab === "Volume" &&
                          isSlectedGoodValue === "rawMaterialsValue"
                            ? "bwi-scenario-goods-active-div"
                            : "bwi-scenario-goods-inactive-div"
                        }`}
                      >
                        {activeTab === "Volume" && (
                          <span
                            className="color-dot"
                            style={{ backgroundColor: "#846CE2" }}
                          ></span>
                        )}
                        Raw Materials
                      </span>
                    </Row>
                    <Row
                      className={`${
                        activeTab === "Volume" &&
                        "bwi-scenario-vol-goods-label-div"
                      }`}
                      onClick={() =>
                        handleGoodChange(activeTab, "packMaterialsValue")
                      }
                    >
                      <span
                        className={`dsf-size12 gap-div-bottom ${
                          activeTab === "Volume" &&
                          isSlectedGoodValue === "packMaterialsValue"
                            ? "bwi-scenario-goods-active-div"
                            : "bwi-scenario-goods-inactive-div"
                        }`}
                      >
                        {activeTab === "Volume" && (
                          <span
                            className="color-dot"
                            style={{ backgroundColor: "#0044AC" }}
                          ></span>
                        )}
                        Pack Materials
                      </span>
                    </Row>
                  </Col>
                  {activeTab === "Value" ? (
                    <Col className="cur_pos_graph">
                      <div id="chart_div" className="test_chart">
                        {
                          <HorizontalBarChartDesign
                            key={"chart_div"}
                            container={"chart_div"}
                            // vAxisTextReq={false}
                            colorCode={colorCode}
                            chartAreaBackgroundColor={
                              selectedDiv === "current_pos_chart_div"
                                ? "#0D1116"
                                : "#1E232F"
                            }
                            chartData={scenarioListData.currentPos?.value}
                          />
                        }
                      </div>
                    </Col>
                  ) : (
                    <Col className="cur_pos_stacked_graph">
                      <div id="chart_div" className="test_chart">
                        {
                          <StackedHorizontalBarChartDesign
                            key={"chart_div"}
                            container={"chart_div"}
                            // vAxisTextReq={false}
                            colorCode={colorCode}
                            chartAreaBackgroundColor={
                              selectedDiv === "current_pos_chart_div"
                                ? "#0D1116"
                                : "#1E232F"
                            }
                            chartData={scenarioListData.currentPos?.volume}
                            selectedGoodValue={isSlectedGoodValue}
                          />
                        }
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            )}

            {scenarioListData && scenarioListData.arrScenario && (
              <Col className="col2 scenario-list col-basis-60">
                {renderAddedScenario()}
              </Col>
            )}
            {userDetails.roleName === "BWInnovationE2EPlanner" &&
              scenarioListData.arrScenario &&
              scenarioListData.arrScenario.length <= 3 && (
                <Col
                  className="col-basis-5 upload-div"
                  style={{ height: "96%" }}
                >
                  <Row>
                    <img
                      src={addChart}
                      alt="addChart-Image"
                      className="add-Chart-Img"
                    />
                  </Row>
                  <Row>
                    <span
                      className="dsf-size16 dsf-font-weight-600 gap-div-top"
                      style={{ width: "140px" }}
                    >
                      Add a Scenario to Compare
                    </span>
                  </Row>
                  <Row>
                    <button
                      className="upload-button"
                      onClick={() => onUploadModalOpen()}
                    >
                      Upload Scenario
                    </button>
                  </Row>
                  {/* </div>
                  <div> */}
                  <Row>
                    <span
                      className="dsf-size10 dsf-font-weight-400 gap-div-top"
                      // style={{ width:'120px'}}
                    >
                      Don’t have template?
                    </span>
                  </Row>
                  <Row
                    onClick={() => onPreviewExcelBtnClicked()}
                    style={{ cursor: isWaiting ? "pointer" : "wait" }}
                  >
                    <img
                      src={downloadIcon}
                      alt="download-Image"
                      className=" scenario-upload-Img"
                    />
                    <span className="download-text">Download Template</span>
                  </Row>
                </Col>
              )}
            <Col className="col-basis-5">
              <img
                src={closeIcon}
                alt="Close-Image"
                className="dsf-close-scenarioPlanner-img"
                onClick={() => closeModal()}
              />
            </Col>
          </Row>
          <Row className="bw-planner-bottom-div">
            {selectedScenario ? (
              <ScenarioAction
                arrCoutnryMaterialList={countryMaterialData}
                selectedProject={props.arrItem}
                selectedScenario={selectedScenario}
                allScenario={scenarioListData.arrScenario}
                requiredActionData={requiredActionData}
                onLockUnlockScenario={handleLockUnlockScenario}
                onUpdateRequiredAction={onUpdateRequiredAction}
                onSuccessfulReviewSend={onSuccessfulReviewSend}
                parentTab={activeTab}
              />
            ) : (
              <div className="no-selection-div">
                <span
                  className="dsf-size16 dsf-font-weight-600"
                  style={{ position: "relative", top: "50%", color: "#FFFFFF" }}
                >
                  {" "}
                  Click on Scenario above to deep dive
                </span>
              </div>
            )}
          </Row>
        </Modal>
      </>
    );
  };

  return renderScenarioPlanner();
};
export default ScenarioPlanner;
