import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";
import "./delistingProject.css";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import ActivityIndicator from "../../../../components/Loader/loader";
import AutoCompleteNestedDropdown from "../../../../components/Autocomplete/nestedautocomplete";
import CustomDropdown from "../../../../components/Dropdown/dropdown";
import AutoCompleteDropdown from "../../../../components/Autocomplete/autocomplete";
import CustomDatePicker from "../../../../components/DatePicker/datePicker";
import { dateTimeFormat, validateUnileverMailId } from "../../../../utils";
import {
  fetchDelistingProjectDetails,
  saveDelistingProjectDetails,
  updateDelistingProjectDetails,
  fetchProjectInfo,
} from "../../../../service/projectDetails";

const DelistingProject = (props) => {
  const [delistingProjectInput, setDelistingProjectInput] = useState({});
  const [validationMessage, setValidationMessage] = useState("");
  const [isOkEnabled, setIsOkEnabled] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [delistProjectData, setDelistProjectData] = useState({});
  const [delistInfoData, setDelistInfoData] = useState({});
  const [transformedGroupData, setTransformedGroupData] = useState({});
  const [transformedCountryData, setTransformedCountryData] = useState({});
  const [transformedCategoryData, setTransformedCategoryData] = useState({});
  const [transformedBrandData, setTransformedBrandData] = useState({});
  const [transformedTypeData, setTransformedTypeData] = useState([]);
  const [inputSuccess, setInputSuccess] = useState(0);
  const [infoSuccess, setInfoSuccess] = useState(0);
  const [isCategoryLoadedFirstTime, setIsCategoryLoadedFirstTime] =
    useState(false);
  const [isBrandLoadedFirstTime, setIsBrandLoadedFirstTime] = useState(false);
  const [dropdownDataSet, setDropdownDataSet] = useState(false);
  const [countryIsOpen, setCountryIsOpen] = useState(false);
  const [categoryIsOpen, setCategoryIsOpen] = useState(false);
  const [channelIsOpen, setChannelIsOpen] = useState(false);
  const [brandIsOpen, setBrandIsOpen] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { showModal, setShowModal, isDataUpdate, projectId } = props;
  const userDetails = useSelector((state) => state.userDetails);

  const maxLength = 60;
  const maxDropdownHeight = "175px";
  const dropdownRefCountry = useRef(null);
  const dropdownRefBrand = useRef(null);
  const dropdownRefChannel = useRef(null);
  const dropdownRefCategory = useRef(null);

  const getProjectInputDetails = async () => {
    const res = await fetchDelistingProjectDetails();
    if (res.status === 200) {
      setDelistingProjectInput(Object.assign({}, res.data));
      setDelistProjectData({
        projectId: "",
        projectName: "",
        projectDescription: "",
        leadChannelName: "",
        categoryCode: "",
        categoryName: "",
        leadChannelName: "",
        projectLeadName: props.userName,
        classification: res.data.classification[0],
        projectStatus: res.data.projectStatus[0],
        projectPhaseType: res.data.projectPhaseType[0],
      });
      setInputSuccess(1);
    }
  };

  const getProjectInfo = async () => {
    const res = await fetchProjectInfo(projectId);
    if (res.status === 200) {
      res.data.categoryName = res.data.category;
      res.data.category = res.data.category
        .split(",")
        .map((item) => item.trim());
      res.data.brandName = res.data.brand;
      res.data.brand = res.data.brand.split(",").map((item) => item.trim());
      const countryCodes = [];
      const geographyNames = [];

      res.data.countryDetails.forEach((item) => {
        countryCodes.push(item.countryCode);
        geographyNames.push(item.geographyName);
      });
      res.data.countryCodes = countryCodes;
      res.data.geographyNames = geographyNames;
      res.data.leadChannelName = res.data.channel;
      res.data.projectLeadName = res.data.projectLeader;
      setInfoSuccess(1);
      setDelistInfoData(res.data);
    } else {
      console.log("error occurred!", res.data);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getProjectInputDetails();
    if (isDataUpdate) {
      getProjectInfo();
      setIsCategoryLoadedFirstTime(true);
      setIsBrandLoadedFirstTime(true);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (
        isDataUpdate &&
        infoSuccess === 1 &&
        inputSuccess === 1 &&
        transformedGroupData.length > 0
      ) {
        handleDropdownProjectTypeChange(delistInfoData.projectType);
        if (delistInfoData.groupName) {
          var dataCheck = await handleDropdownBusinessGroupChange(
            delistInfoData.groupName
          );
          dataCheck && setDropdownDataSet(true);
        }

        setDelistProjectData({
          ...delistProjectData,
          projectId: delistInfoData.projectId,
          projectName: delistInfoData.projectName,
          projectDescription: delistInfoData.projectDescription,
          leadChannelName: delistInfoData.leadChannelName,
          projectLeadName:
            delistInfoData.projectLeadName &&
            delistInfoData.projectLeadName.length > 0
              ? delistInfoData.projectLeadName
              : props.userName,
          groupCode: delistInfoData.groupCode,
          groupName: delistInfoData.groupName,
          classification: delistInfoData.classification,
          projectStatus: delistInfoData.projectStatus,
          projectPhaseType: delistInfoData.projectPhaseType,
          projectStartDate: delistInfoData.projectStartDate,
          targetLaunchDate: delistInfoData.targetLaunchDate,
          countryDetails: delistInfoData.countryDetails,
          projectType: delistInfoData.projectType,
          categoryCode: delistInfoData.categoryCode,
          categoryName: delistInfoData.categoryName,
          brandCode: delistInfoData.brandCode,
          brandName: delistInfoData.brandName,
        });
        setInfoSuccess(0);
      }
    };

    fetchData();
  }, [infoSuccess, inputSuccess, transformedGroupData]);

  useEffect(() => {
    if (dropdownDataSet) {
      setDelistProjectData({
        ...delistProjectData,
        categoryCode: delistInfoData.categoryCode,
        categoryName: delistInfoData.categoryName,
        brandCode: delistInfoData.brandCode,
        brandName: delistInfoData.brandName,
      });
      setDropdownDataSet(false);
      setIsLoading(false);
    }
  }, [dropdownDataSet]);

  useEffect(() => {
    if (inputSuccess === 1) {
      const optionsObject = {};
      for (const fieldName in delistingProjectInput) {
        if (delistingProjectInput.hasOwnProperty(fieldName)) {
          const fieldValue = delistingProjectInput[fieldName];
          if (Array.isArray(fieldValue)) {
            if (fieldName === "groupDetails") {
              optionsObject[fieldName] = fieldValue.map((field) => ({
                value: field.groupCode,
                label: field.groupName,
                categoryDetails: field.categoryDetails.map((category) => ({
                  value: category.categoryCode,
                  label: category.categoryName,
                  brandDetails: category.brandDetails
                    .slice()
                    .sort((a, b) => a.brandName.localeCompare(b.brandName))
                    .map((brand) => ({
                      value: brand.brandCode,
                      label: brand.brandName,
                    })),
                })),
              }));
            } else if (fieldName === "countryDetails_geoAPI") {
              optionsObject[fieldName] = fieldValue.map((field) => ({
                value: `region-${field.regionCode}`,
                label: field.regionName,
                children: field.clusterDetails.map((cluster) => ({
                  value: cluster.clusterCode,
                  label: cluster.clusterName,
                  children: cluster.geographyDetails
                    .slice()
                    .map((geography) => ({
                      value: geography.countryCode,
                      label: geography.geographyName,
                    })),
                })),
              }));
            } else if (fieldName === "projectTypeDetails") {
              optionsObject[fieldName] = fieldValue.map((field) => ({
                value: field.projectTypeId,
                label: field.projectType,
              }));
            } else {
              optionsObject[fieldName] = fieldValue.map((field) => ({
                value: field,
                label: field,
              }));
            }
          }
        }
      }

      setTransformedGroupData(optionsObject.groupDetails);
      setTransformedCountryData(optionsObject.countryDetails_geoAPI);
      setTransformedTypeData(optionsObject.projectTypeDetails);
      !isDataUpdate && setIsLoading(false);
    }
  }, [inputSuccess]);

  useEffect(() => {
    let errCount = 0;
    let elementCount = 0;
    for (const key in delistProjectData) {
      if (delistProjectData.hasOwnProperty(key)) {
        elementCount++;
        if (
          key !== "projectDescription" &&
          key !== "leadChannelName" &&
          key !== "categoryName" &&
          key !== "categoryCode" &&
          key !== "projectId" &&
          key !== "projectLeadName"
        ) {
          const element = delistProjectData[key];
          if (isNullvalue(element)) {
            errCount++;
          }
        }
      }
    }
    if (errCount === 0 && elementCount === 18 && validationMessage === "") {
      setIsOkEnabled(false);
    } else {
      setIsOkEnabled(true);
    }
  }, [delistProjectData, validationMessage]);

  const isNullvalue = (value) => {
    if (value === null || value === "") {
      return true;
    } else {
      return false;
    }
  };

  const handleSendtoValueOnEnter = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
    }
    if (event.type === "blur") {
      if (validateUnileverMailId(event.target.value)) {
        setValidationMessage("");
      } else {
        setValidationMessage("Please provide valid Unilever Mail id");
      }
    }
  };

  const handleProjectNameChange = (event) => {
    setDelistProjectData({
      ...delistProjectData,
      projectName: event.target.value,
    });
    if (validationMessage === "Project already exists.") {
      setValidationMessage("");
    }
  };

  const handleProjectDescChange = (event) => {
    setDelistProjectData({
      ...delistProjectData,
      projectDescription: event.target.value,
    });
  };

  const handleProjectLeadChange = (event) => {
    setDelistProjectData({
      ...delistProjectData,
      projectLeadName: event.target.value,
    });
  };

  const handleDropdownChannelChange = (value) => {
    if (value.length > 0) {
      const channelName = value.join("; ");
      setDelistProjectData((prevState) => ({
        ...prevState,
        leadChannelName: channelName,
      }));
    }
  };

  const handleDropdownBusinessGroupChange = async (item) => {
    if (
      item &&
      item.length > 0 &&
      transformedGroupData &&
      transformedGroupData.length > 0
    ) {
      let selectedGroup = transformedGroupData.filter(
        (group) => group.label === item
      );
      let brandData = [];
      if (isCategoryLoadedFirstTime && isDataUpdate) {
        let selectedCategories = [];

        if (delistInfoData?.categoryName?.length === 0) {
          selectedCategories = selectedGroup[0].categoryDetails;
        } else {
          selectedCategories = selectedGroup[0].categoryDetails.filter(
            (category) => delistInfoData.categoryName.includes(category.label)
          );
        }

        selectedCategories.forEach((category) => {
          category.brandDetails.forEach((brand) => {
            brandData
              .sort((a, b) => a.label.localeCompare(b.label))
              .push({
                value: brand.value,
                label: brand.label,
              });
          });
        });
        brandData = brandData.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.value === thing.value)
        );
        setTransformedBrandData(brandData);
        setTransformedCategoryData(
          selectedGroup[0].categoryDetails.sort((a, b) =>
            a.label.localeCompare(b.label)
          )
        );
      } else {
        selectedGroup[0].categoryDetails.forEach((category) => {
          category.brandDetails.forEach((brand) => {
            brandData
              .sort((a, b) => a.label.localeCompare(b.label))
              .push({
                value: brand.value,
                label: brand.label,
              });
          });
        });

        brandData = brandData.filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.value === thing.value)
        );

        setTransformedBrandData(brandData);
        setTransformedCategoryData(
          selectedGroup[0].categoryDetails.sort((a, b) =>
            a.label.localeCompare(b.label)
          )
        );
        const { categoryName, categoryCode, brandCode, brandName, ...rest } =
          delistProjectData;
        setDelistProjectData({
          ...rest,
          categoryName: "",
          categoryCode: "",
          groupCode: selectedGroup[0].value,
          groupName: item,
        });
      }
      return true;
    }
    return false;
  };

  const handleDropdownCategoryChange = (items) => {
    let isDataUpdated = false;
    const CategorySplit = delistProjectData.categoryName.split(", ");
    if (CategorySplit.includes("")) {
      CategorySplit.splice(CategorySplit.indexOf(""), 1);
    }
    if (items.length === CategorySplit.length) {
      let count = 0;
      for (let i = 0; i < items.length; i++) {
        if (CategorySplit.includes(items[i])) {
          count++;
        }
      }
      if (count === items.length) {
        isDataUpdated = true;
        return;
      }
    }

    if (items.length === 0 && !isCategoryLoadedFirstTime) {
      handleDropdownBusinessGroupChange(delistProjectData.groupName);
    } else if (
      delistProjectData.groupName &&
      transformedCategoryData &&
      transformedCategoryData.length > 0 &&
      !isCategoryLoadedFirstTime &&
      !isDataUpdated
    ) {
      const categoryName = items.join("; ");
      const selectedCategories = transformedCategoryData.filter((category) =>
        items.includes(category.label)
      );

      let brandData = [];
      selectedCategories.forEach((category) => {
        category.brandDetails.forEach((brand) => {
          brandData
            .sort((a, b) => a.label.localeCompare(b.label))
            .push({
              value: brand.value,
              label: brand.label,
            });
        });
      });

      brandData = brandData.filter(
        (thing, index, self) =>
          index === self.findIndex((t) => t.value === thing.value)
      );

      setTransformedBrandData(brandData);

      const { brandCode, brandName, ...rest } = delistProjectData;
      setDelistProjectData({
        ...rest,
        categoryCode: selectedCategories
          .map((category) => category.value)
          .join("; "),
        categoryName: selectedCategories
          .map((category) => category.label)
          .join("; "),
      });
    }
    if (isCategoryLoadedFirstTime) {
      setIsCategoryLoadedFirstTime(false);
    }
  };

  const handleDropdownGeographyChange = (value, key) => {
    const { selectedValues, selectedLabels } = value;

    if (
      selectedValues.length > 0 &&
      transformedCountryData &&
      transformedCountryData.length > 0
    ) {
      const combinedSelected = selectedValues.map((value, index) => ({
        label: selectedLabels[index],
        value: value,
      }));

      const children = [];
      selectedValues.forEach((value) => {
        transformedCountryData.forEach((parent) => {
          if (parent.children) {
            parent.children.forEach((child) => {
              if (child.children) {
                child.children.forEach((grandchild) => {
                  if (grandchild.value === value) {
                    const grandchildObj = {
                      countryCode: grandchild.value,
                      geographyName: grandchild.label,
                    };
                    children.push(grandchildObj);
                  }
                });
              }
            });
          }
        });
      });

      setDelistProjectData((prevState) => ({
        ...prevState,
        countryDetails: children,
      }));
    } else {
      const { countryDetails, ...rest } = delistProjectData;
      setDelistProjectData(rest);
    }
  };

  const handleDropdownBrandChange = (items) => {
    if (
      delistProjectData.groupName &&
      transformedBrandData &&
      transformedBrandData.length > 0 &&
      !isBrandLoadedFirstTime
    ) {
      const brandsName = items.join("; ");
      const selectedBrands = transformedBrandData.filter((brand) =>
        items.includes(brand.label)
      );

      const { brandCode, brandName, ...rest } = delistProjectData;
      setDelistProjectData({
        ...rest,
        brandCode: selectedBrands.map((brand) => brand.value).join("; "),
        brandName: selectedBrands.map((brand) => brand.label).join("; "),
      });
    } else if (isBrandLoadedFirstTime) {
      setIsBrandLoadedFirstTime(false);
    }
  };

  const handleDropdownClassificationChange = (value) => {
    setDelistProjectData((prevState) => ({
      ...prevState,
      classification: value,
    }));
  };

  const handleDropdownStatusChange = (value) => {
    setDelistProjectData((prevState) => ({
      ...prevState,
      projectStatus: value,
    }));
  };

  const handleDropdownPhaseChange = (value) => {
    setDelistProjectData((prevState) => ({
      ...prevState,
      projectPhaseType: value,
    }));
  };

  const handleStartDatePickerChange = (value, isNull, date) => {
    setDelistProjectData((prevState) => ({
      ...prevState,
      projectStartDate: dateTimeFormat(date, "YYYY/MM/DD"),
    }));
  };

  const handleLaunchDatePickerChange = (value, isNull, date) => {
    setDelistProjectData((prevState) => ({
      ...prevState,
      targetLaunchDate: dateTimeFormat(date, "YYYY/MM/DD"),
    }));
  };

  const handleDropdownProjectTypeChange = (item) => {
    if (
      item.length > 0 &&
      transformedTypeData &&
      transformedTypeData.length > 0
    ) {
      const projectTypeSelected = transformedTypeData.filter(
        (type) => type.label === item
      );
      setDelistProjectData((prevState) => ({
        ...prevState,
        projectType: projectTypeSelected[0].value,
      }));
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const saveProjectData = async () => {
    const updatedData = { ...delistProjectData };
    const res = await saveDelistingProjectDetails(updatedData);
    if (res.status === 200) {
      setModalMessage("Project creation successful.");
      setShowMessageModal(true);
    } else {
      console.log("res", res.data);
      if (res.data === "Project already exists.") {
        setValidationMessage("Project already exists.");
      } else {
        setModalMessage("Project creation failed.");
        setShowMessageModal(true);
      }
    }
  };

  const updateProjectData = async () => {
    let isDataUpdated = false;
    const updatedData = { ...delistProjectData };
    for (const key in delistProjectData) {
      if (delistProjectData.hasOwnProperty(key)) {
        if (key === "countryDetails") {
          const countryDetails = delistProjectData[key];
          const countryDetailsInfo = delistInfoData[key];
          if (countryDetails.length !== countryDetailsInfo.length) {
            updatedData[key] = countryDetails;
            isDataUpdated = true;
          } else {
            let changed = false;
            for (let i = 0; i < countryDetails.length; i++) {
              if (
                countryDetails[i].countryCode !==
                  countryDetailsInfo[i].countryCode ||
                countryDetails[i].geographyName !==
                  countryDetailsInfo[i].geographyName
              ) {
                changed = true;
                break;
              }
            }
            if (changed) {
              updatedData[key] = countryDetails;
              isDataUpdated = true;
            } else {
              updatedData[key] = [];
            }
          }
        } else if (
          delistInfoData[key] !== delistProjectData[key] ||
          key === "projectId"
        ) {
          updatedData[key] = delistProjectData[key];
          key !== "projectId" && (isDataUpdated = true);
        } else if (key === "projectType") {
          updatedData[key] = 0;
        } else {
          updatedData[key] = "";
        }
      }
    }
    if (isDataUpdated) {
      const res = await updateDelistingProjectDetails(updatedData);
      if (res.status === 200) {
        setModalMessage("Project update successful.");
        setShowMessageModal(true);
      } else {
        console.log("res", res.data);
        setModalMessage("Project update failed.");
        setShowMessageModal(true);
      }
    } else {
      setModalMessage("No data to modify.");
      setShowMessageModal(true);
    }
  };

  const onOkBtnClicked = async () => {
    if (isDataUpdate) {
      updateProjectData();
    } else {
      saveProjectData();
    }
  };

  const closeMessageModal = () => {
    setShowMessageModal(false);
    setShowModal(false);
  };

  const onOkMessageBtnClicked = async () => {
    setShowMessageModal(false);
    setShowModal(false);
    if (
      modalMessage === "Project creation successful." ||
      modalMessage === "Project update successful."
    ) {
      props.updateNewProjectList();
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRefCountry.current &&
        !dropdownRefCountry.current.contains(event.target)
      ) {
        setCountryIsOpen(false);
      }
      if (
        dropdownRefBrand.current &&
        !dropdownRefBrand.current.contains(event.target)
      ) {
        setBrandIsOpen(false);
      }
      if (
        dropdownRefChannel.current &&
        !dropdownRefChannel.current.contains(event.target)
      ) {
        setChannelIsOpen(false);
      }
      if (
        dropdownRefCategory.current &&
        !dropdownRefCategory.current.contains(event.target)
      ) {
        setCategoryIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    dropdownRefCountry,
    dropdownRefBrand,
    dropdownRefChannel,
    dropdownRefCategory,
  ]);

  return (
    <>
      <Modal
        centered
        dialogClassName="dsf-dialog-delistingProjModal"
        contentClassName="dsf-modal-workflowModal"
        show={showModal}
        //onHide={() => closeModal()}
      >
        <Modal.Header className="bw-modal-delistingProj-header">
          <Modal.Title className="dsf-size16 bw-delistBOM-header-title">
            {!isDataUpdate ? (
              <div>
                <p className="dsf-size16 bw-messagebox-header-title dsf-title-color">
                  Add Project
                </p>
                <p className="dsf-modal-sub-title dsf-span-s">
                  Complete this form to add a new project that is not available
                  in the Innoflex system
                </p>
              </div>
            ) : (
              <div>
                <span className="dsf-title-color">Update Project</span>
                <p className="dsf-modal-sub-title dsf-span-s">
                  Complete this form to update the details of the project
                </p>
              </div>
            )}
            <p className="dsf-delistProj-error-msg">
              {validationMessage.length > 0 &&
                validationMessage === "Project already exists." && (
                  <span className="dsf-font-weight-400 errorMsg dsf-size11 pl-2">
                    {validationMessage}
                  </span>
                )}
            </p>
          </Modal.Title>
          <img
            src={closeIcon}
            alt="Close-Image"
            className="dsf-close-img"
            onClick={() => closeModal()}
            style={{ left: "-5px" }}
          />
        </Modal.Header>
        {isLoading ? (
          <ActivityIndicator />
        ) : (
          <Modal.Body className="bw-modal-delistProj-body bw-modal-padding">
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-name dsf-span-s">
                  Project Name{" "}
                  <span className="dsf-label-mandatory-field">*</span>
                </p>
              </Col>
              <Col xs={9} className="dsf-col-textBox-div2">
                <input
                  type="text"
                  name="projName"
                  maxLength={150}
                  value={
                    delistProjectData.projectName
                      ? delistProjectData.projectName
                      : ""
                  }
                  className="dsf-delistProj-name-field dsf-delistProj-input"
                  onChange={(e) => handleProjectNameChange(e)}
                  style={{ width: "487px" }}
                />
              </Col>
            </Row>
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-desc dsf-span-s">
                  Project Description
                </p>
              </Col>
              <Col xs={9} className="dsf-col-textBox-div2">
                <input
                  type="text"
                  name="projDesc"
                  maxLength={500}
                  value={
                    delistProjectData.projectDescription
                      ? delistProjectData.projectDescription
                      : ""
                  }
                  className="dsf-delistProj-desc-field dsf-delistProj-input"
                  onChange={(e) => handleProjectDescChange(e)}
                  style={{ width: "487px" }}
                />
              </Col>
            </Row>
            {isDataUpdate && (
              <Row className="dsf-delistingProj-row">
                <Col xs={3} className="dsf-text-left">
                  <p className="dsf-delistProj-lead dsf-span-s">
                    Project Lead{" "}
                    <span className="dsf-label-mandatory-field">*</span>
                  </p>
                </Col>
                <Col xs={9} className="dsf-col-textBox-div2">
                  <input
                    type="text"
                    name="projLead"
                    value={
                      delistProjectData.projectLeadName
                        ? delistProjectData.projectLeadName
                        : ""
                    }
                    className={`dsf-delistProj-lead-field dsf-delistProj-input ${
                      validationMessage.length > 0
                        ? "errorBorder"
                        : "successBorder"
                    }`}
                    onChange={(e) => handleProjectLeadChange(e)}
                    onBlur={(e) => handleSendtoValueOnEnter(e)}
                    onKeyDown={(e) => handleSendtoValueOnEnter(e)}
                    style={{ width: "487px" }}
                  />
                </Col>
              </Row>
            )}
            {/* <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-channel dsf-span-s">
                  Lead Channel
                </p>
              </Col>
              <Col
                xs={9}
                className="dsf-delistProj-autocomplete dsf-col-textBox-div2 delistProj-dropdown"
                ref={dropdownRefChannel}
              >
                <AutoCompleteDropdown
                  options={
                    transformedProjectData.leadChannels
                      ? transformedProjectData.leadChannels
                      : []
                  }
                  maxLength={maxLength}
                  maxDropdownHeight="140px"
                  width="487px"
                  isOpen={channelIsOpen}
                  setIsOpen={setChannelIsOpen}
                  disable={false}
                  listClassName="dsf-delistingProj-channel-list"
                  onSelectionChange={(value) =>
                    handleDropdownChannelChange(value)
                  }
                />
              </Col>
            </Row> */}
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-business dsf-span-s">
                  Business Group{" "}
                  <span className="dsf-label-mandatory-field">*</span>
                </p>
              </Col>
              <Col xs={9} className="dsf-col-textBox-div2 delistProj-dropdown">
                <CustomDropdown
                  modal="BusinessGroupDropdown"
                  data={transformedGroupData ? transformedGroupData : []}
                  styleClass="dsf-delistProj-channel-dropdown dsf-delistProj-input"
                  value={
                    delistProjectData.groupName
                      ? delistProjectData.groupName
                      : ""
                  }
                  onChange={(value) => handleDropdownBusinessGroupChange(value)}
                  width="487px"
                  disabled={isDataUpdate ? true : false}
                />
              </Col>
            </Row>
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-category dsf-span-s">Category</p>
              </Col>
              <Col
                xs={9}
                className="dsf-delistProj-autocomplete dsf-col-textBox-div2 delistProj-dropdown"
                ref={dropdownRefCategory}
              >
                <AutoCompleteDropdown
                  options={
                    delistProjectData.groupName && transformedCategoryData
                      ? transformedCategoryData
                      : []
                  }
                  selectedValues={
                    delistInfoData.category &&
                    delistInfoData.category.length > 0
                      ? delistInfoData.category
                      : ""
                  }
                  maxLength={maxLength}
                  maxDropdownHeight="140px"
                  width="487px"
                  isOpen={categoryIsOpen}
                  setIsOpen={setCategoryIsOpen}
                  disable={delistProjectData.groupName ? false : true}
                  listClassName="dsf-delistingProj-category-list"
                  onSelectionChange={(value) =>
                    handleDropdownCategoryChange(value)
                  }
                />
              </Col>
            </Row>
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-geography dsf-span-s">
                  Geography <span className="dsf-label-mandatory-field">*</span>
                </p>
              </Col>
              <Col
                xs={9}
                className="dsf-delistProj-autocomplete dsf-col-textBox-div2 delistProj-dropdown"
                ref={dropdownRefCountry}
              >
                <AutoCompleteNestedDropdown
                  options={transformedCountryData ? transformedCountryData : []}
                  selectedCountries={
                    delistInfoData.countryDetails &&
                    delistInfoData.countryDetails.length > 0
                      ? delistInfoData.countryDetails
                      : ""
                  }
                  maxLength={maxLength}
                  maxDropdownHeight={maxDropdownHeight}
                  width="487px"
                  disable={false}
                  isOpen={countryIsOpen}
                  setIsOpen={setCountryIsOpen}
                  listClassName="dsf-delistingProj-geography-list"
                  onSelectionChange={(value, key) =>
                    handleDropdownGeographyChange(value, key)
                  }
                />
              </Col>
            </Row>
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-brand dsf-span-s">
                  Brand <span className="dsf-label-mandatory-field">*</span>
                </p>
              </Col>
              <Col
                xs={9}
                className="dsf-delistProj-autocomplete dsf-col-textBox-div2 delistProj-dropdown"
                ref={dropdownRefBrand}
              >
                <AutoCompleteDropdown
                  options={
                    delistProjectData.groupName ? transformedBrandData : []
                  }
                  selectedValues={
                    delistInfoData.brand && delistInfoData.brand.length > 0
                      ? delistInfoData.brand
                      : ""
                  }
                  maxLength={maxLength}
                  maxDropdownHeight="140px"
                  width="487px"
                  isOpen={brandIsOpen}
                  setIsOpen={setBrandIsOpen}
                  disable={delistProjectData.groupName ? false : true}
                  listClassName="dsf-delistingProj-brand-list"
                  onSelectionChange={(value) =>
                    handleDropdownBrandChange(value)
                  }
                  selectAllEnable={true}
                />
              </Col>
            </Row>
            {/* <Row className="dsf-delistingProj-row" style={{ display: "none" }}>
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-classification dsf-span-s">
                  Classification
                </p>
              </Col>
              <Col xs={3} className="dsf-col-textBox-div2 delistProj-dropdown">
                <CustomDropdown
                  modal="ClassificationDropdown"
                  data={
                    transformedProjectData.classification
                      ? transformedProjectData.classification
                      : []
                  }
                  styleClass="dsf-delistProj-classification-dropdown dsf-delistProj-input errorBorder"
                  popperClass="dsf-delistProj-classification-dropdown-popper"
                  value={
                    delistProjectData.classification
                      ? delistProjectData.classification
                      : ""
                  }
                  onChange={(value) =>
                    handleDropdownClassificationChange(value)
                  }
                  width="158px"
                />
              </Col>
              <Col
                xs={3}
                className="dsf-text-left dsf-delistingProj-right-label"
              >
                <p className="dsf-delistProj-status dsf-span-s">
                  Project Status
                </p>
              </Col>
              <Col xs={3} className="dsf-col-textBox-div2 delistProj-dropdown">
                <CustomDropdown
                  modal="StatusDropdown"
                  data={
                    transformedProjectData.projectStatus
                      ? transformedProjectData.projectStatus
                      : []
                  }
                  styleClass="dsf-delistProj-status-dropdown dsf-delistProj-input"
                  popperClass="dsf-delistProj-status-dropdown-popper"
                  value={
                    delistProjectData.projectStatus
                      ? delistProjectData.projectStatus
                      : ""
                  }
                  onChange={(value) => handleDropdownStatusChange(value)}
                  width="158px"
                />
              </Col>
            </Row>
            <Row className="dsf-delistingProj-row" style={{ display: "none" }}>
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-phase dsf-span-s">
                  Project Phase Type
                </p>
              </Col>
              <Col xs={9} className="dsf-col-textBox-div2 delistProj-dropdown">
                <CustomDropdown
                  modal="PhaseTypeDropdown"
                  data={
                    transformedProjectData.projectPhaseType
                      ? transformedProjectData.projectPhaseType
                      : []
                  }
                  styleClass="dsf-delistProj-phase-dropdown dsf-delistProj-input"
                  popperClass="dsf-delistProj-phase-dropdown-popper"
                  value={
                    delistProjectData.projectPhaseType
                      ? delistProjectData.projectPhaseType
                      : ""
                  }
                  onChange={(value) => handleDropdownPhaseChange(value)}
                  width="487px"
                />
              </Col>
            </Row> */}
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-startdate dsf-span-s">
                  Project Start Date{" "}
                  <span className="dsf-label-mandatory-field">*</span>
                </p>
              </Col>
              <Col
                xs={3}
                className="dsf-delistingProj-datepicker dsf-delistingProj-startdatepicker dsf-delistProj-input"
              >
                <CustomDatePicker
                  launchDate={
                    delistProjectData.projectStartDate
                      ? delistProjectData.projectStartDate
                      : ""
                  }
                  isValueNull="true"
                  disablePast={false}
                  disableFuture={false}
                  onDatePickerChange={(value, isNull, date) =>
                    handleStartDatePickerChange(value, isNull, date)
                  }
                />
              </Col>
              <Col
                xs={3}
                className="dsf-text-left dsf-delistingProj-right-label"
              >
                <p className="dsf-delistProj-enddate dsf-span-s">
                  Target Launch Date{" "}
                  <span className="dsf-label-mandatory-field">*</span>
                </p>
              </Col>
              <Col
                xs={3}
                className="dsf-delistingProj-datepicker dsf-delistingProj-enddatepicker dsf-delistProj-input"
              >
                <CustomDatePicker
                  launchDate={
                    delistProjectData.targetLaunchDate
                      ? delistProjectData.targetLaunchDate
                      : ""
                  }
                  isValueNull="true"
                  onDatePickerChange={(value, isNull, date) =>
                    handleLaunchDatePickerChange(value, isNull, date)
                  }
                />
              </Col>
            </Row>
            <Row className="dsf-delistingProj-row">
              <Col xs={3} className="dsf-text-left">
                <p className="dsf-delistProj-business dsf-span-s">
                  Project Type{" "}
                  <span className="dsf-label-mandatory-field">*</span>
                </p>
              </Col>
              <Col xs={9} className="dsf-col-textBox-div2 delistProj-dropdown">
                <CustomDropdown
                  modal="ProjectTypeDropdown"
                  data={transformedTypeData ? transformedTypeData : []}
                  styleClass="dsf-delistProj-channel-dropdown dsf-delistProj-input"
                  value={
                    delistInfoData.projectType ? delistInfoData.projectType : ""
                  }
                  onChange={(value) => handleDropdownProjectTypeChange(value)}
                  width="487px"
                />
              </Col>
            </Row>
          </Modal.Body>
        )}
        {!isLoading && (
          <Modal.Footer className="bw-modal-footer dsf-delistingProj-footer-div dsf-rndsignoff-footer">
            <div className="dsf-delistingProj-footer-label-div">
              {/* <p className="dsf-span-s">
                <span className="dsf-label-mandatory-field">*</span> - Mandatory
                field
              </p> */}
            </div>
            <div className="dsf-delistingProj-footer-btn-div">
              <Button
                className="bw-footer-cancle-btn"
                variant="secondary"
                hidden={props.currentWorkflowStage === "5" ? true : false}
                onClick={() => closeModal()}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                className={`${
                  userDetails.roleName === "BWInnovationP&I"
                    ? "dsf-footer-okbutton-PI"
                    : ""
                }`}
                disabled={isOkEnabled}
                onClick={() => onOkBtnClicked()}
              >
                OK
              </Button>
            </div>
          </Modal.Footer>
        )}
      </Modal>

      <Modal
        centered
        dialogClassName="dsf-dialog-messagebox dsf-delistingProj-okdialog"
        contentClassName="dsf-modal-messagebox dsf-delistingProj-okmodal"
        show={showMessageModal}
      >
        <Modal.Header className="bw-modal-messagebox-header">
          {!isDataUpdate ? (
            <Modal.Title className="dsf-size16 bw-messagebox-header-title dsf-title-color">
              Add Project
            </Modal.Title>
          ) : (
            <Modal.Title className="dsf-size16 bw-messagebox-header-title">
              Update Project
            </Modal.Title>
          )}
          <img
            src={closeIcon}
            alt="Close-Image"
            className="img.dsf-close-messagebox-img"
            onClick={() => onOkMessageBtnClicked()}
          />
        </Modal.Header>
        <Modal.Body className="bw-modal-messagebox-body">
          <p className="bw-modal-messagebox-msg">{modalMessage}</p>
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer">
          <Button
            className="dsf-footer-okbutton-PI"
            variant="secondary"
            onClick={() => onOkMessageBtnClicked()}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DelistingProject;
