import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import "./stepper.css";

import {
  workflowStageSteps,
  workflowStatusEnum_New,
} from "../../globalConstants";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 12,
    left: "calc(-50% + 9px)",
    right: "calc(50% + 9px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#61D4C4",
    },
    // [`& .${stepConnectorClasses.img}`]: {
    //   height: "40px",
    // },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#61D4C4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 1,
    backgroundColor: "#4B4B4B",
    borderRadius: 3,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme }) => ({
  //backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: "#fff",
  width: 24,
  height: 24,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  transition: "transform 1s ease-in-out",
}));

const ColorlibStepIcon = (props) => {
  const { className, workflowData } = props;
// console.log("props",workflowData)
  return (
    <ColorlibStepIconRoot className={className}>
      <img
        className="bwi-stepper-icon-img"
        src={require(`../../assets/images/landingPage/${workflowStatusEnum_New[workflowData]}.svg`)}
        alt={`Step ${props.icon}`}
      />
    </ColorlibStepIconRoot>
  );
};

ColorlibStepIcon.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node,
  workflowData: PropTypes.string.isRequired,
};

export default function CustomizedSteppers(props) {
  const { listClassName, arrItem } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [stepData, setStepData] = useState([]);


  useEffect(() => {
    let workflowData = arrItem.workflow;
    let finalworkflowStatusData;
    if (workflowData) {
      let workflowStatusData = workflowData
        .map((item, index) => {
          if (index === 3) {
            if (
              workflowData[4].status === "5" ||
              workflowData[4].status === "7"
            ) {
              return workflowData[4].status;
            } else {
              return item.status;
            }
          } else {
            return item.status;
          }
        })
        .filter((item, index) => index !== 4 && index !== 6);

      let activeStep = workflowStatusData
        .map((item, index) => {
          if (item !== "1" && item !== "3") {
            return index;
          }
        })
        .filter((item) => item !== undefined)
        .pop();


      finalworkflowStatusData = workflowStatusData.slice(props.startValue, props.EndValue)
      setActiveStep(activeStep);
      setStepData(finalworkflowStatusData);
    }
  }, [arrItem]);

  return (
    <>
    {stepData.length > 0 && (
    <Stack
      sx={{ width: "100%" }}
      spacing={1}
      className={`bwi-stepper ${listClassName}`}
    >
      <Stepper
        alternativeLabel
        activeStep={props.startValue === 5 ? activeStep-5 : activeStep}
        connector={<ColorlibConnector />}
      >
        {workflowStageSteps.slice(props.startValue, props.EndValue).map((label, index) => (
          <Step
            key={label}
            className={`bwi-stepper-step ${
              index === 4 &&
              stepData[4] === "1" &&
              arrItem.workflow[6].status === "4"
                ? "bwi-stepper-invcm-disable"
                : ""
            }
          ${index === 5 && arrItem.workflow[6].status === "4" ? "bwi-stepper-cg-waiting" : ""}`}
          >
            <StepButton
              onClick={() => {
                props.onStepSelection( props.startValue === 5 ? (index+5): index);
              }}
              disabled={stepData[index] === "1" ? true : false}
              disableRipple
            >
              <StepLabel
                className={`bwi-stepper-label`}
                StepIconComponent={(props) => (
                  <ColorlibStepIcon
                    {...props}
                    iconIndex={index}
                    workflowData={stepData[index]}
                    // className={`bwi-stepper-icon ${index === activeStep ? "bwi-stepper-icon-active" : ""}`}
                  />
                )}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: "#EDF1F3 !important",
                  },
                }}
              >
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Stack>
    )}
    </>
  );
}
