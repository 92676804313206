import { useEffect, useState } from "react";
import { GoogleCharts } from "./GoogleCharts";

const textStyle = {
  color: "#edf1f3",
  fontSize: 8,
  fontFamily: "Unilever Shilling",
  fontWeight: 400,
};
const LineChartDesign = (props) => {
  const [barChartData, setBarChartData] = useState([]);

  useEffect(() => {
    let arrBarChartData = [];
    if (props.chartData) {
      const sortedData = props.chartData.map((item) => ({
        ...item,
        year: parseInt(item.weekname.substring(3, 7)),
      }));
      sortedData
        .sort((a, b) => a.year - b.year)
        .map((item, index) => {
          let annotation = null;
          let annotationText = null;
          if (index === props.chartData.length - 1) {
            annotation = "";
          }
          arrBarChartData.push([
            item.weekname,
            annotation,
            parseFloat(item.inventory),
          ]);
        });
    } else {
      arrBarChartData.push([]);
    }
    setBarChartData(arrBarChartData);
  }, [props.chartData]);

  useEffect(() => {
    GoogleCharts.load(drawChart, {
      packages: ["corechart", "line"],
    });
  }, [barChartData]);

  const drawChart = () => {
    const chartDiv = document.getElementById("line_chart_div");
    let data_n = new GoogleCharts.api.visualization.DataTable();
    data_n.addColumn("string", "Week");
    data_n.addColumn({ type: "string", role: "annotation" });
    data_n.addColumn("number", "Inventory");
    data_n.addRows(barChartData);

    const options = {
      title: "",
      backgroundColor: "transparent",
      legend: "none",
      chartArea: { top: "15%", width: "90%", height: "55%" },
      series: {
        0: { color: "#F1A33A" },
      },
      hAxis: {
        format: "short",
        titleTextStyle: { color: "#EC6157" },
        textStyle: textStyle,
        gridlines: { count: 1, color: "#797775" },
      },
      vAxis: {
        titleTextStyle: { color: "#FFF" },
        textStyle: textStyle,
        gridlines: { count: 0 },
      },
      annotations: {
        style: "line",
        stemColor: "#00D7C4",
        textStyle: {
          fontSize: 12,
          fontFamily: "Unilever Shilling",
          fontWeight: 400,
          color: "#EC6157",
          opacity: 1,
        },
      },
    };

    const chart = new GoogleCharts.api.visualization.LineChart(chartDiv);
    chart.draw(data_n, options);
  };
};
export default LineChartDesign;
