import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserDetailsService from "../service/userDetailsService";

const initialState = [];

export const retrieveUserDetails = createAsyncThunk(
  "user-details/retrieve",
  async (data) => {
    const res = await UserDetailsService.fetchUsers(data);
    if (res.status === 200) {
      return res.data;
    } else {
      return {};
    }
  }
);

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  extraReducers: {
    [retrieveUserDetails.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

const { reducer } = userDetailsSlice;
export default reducer;
