import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import SKUCodeAndNameService from "../service/skuCodeAndNameService";

const initialState = [];

export const createOrUpdateUploadSKUFile = createAsyncThunk(
  "projectDetails/createOrUpdateUploadSKU",
  async ({loggedInUser, projectName, data}) => {
    const res = await SKUCodeAndNameService.uploadSKUFile(loggedInUser, projectName, data);
    return res.data;
  }
);

export const retrieveSKUCodeAndNames = createAsyncThunk(
  "projectDetails/retrieveSKUCodeAndNames",
  async ({projectName, searchText}) => {
    const res = await SKUCodeAndNameService.fetchSKUCodeAndNames(projectName, searchText);
    return res.data;
  }
);

export const updateSKUCodeAndNames = createAsyncThunk(
  "projectDetails/updateSKUCodeAndNames",
  async ({ projectName, loggedInUser, data }) => {
    const res = await SKUCodeAndNameService.updateSKUCodeAndNames(projectName, loggedInUser, data);
    return res.data;
  }
);

const skuCodeAndNameSlice = createSlice({
  name: "skuCodeAndName",
  initialState,
  reducers: {
  },
  extraReducers: {
  [createOrUpdateUploadSKUFile.fulfilled]: (state, action) => {
      return action.payload;
  },  
  [retrieveSKUCodeAndNames.fulfilled]: (state, action) => {
    return action.payload;
  },
  [updateSKUCodeAndNames.fulfilled]: (state, action) => {
    return action.payload;
  }  
},
});

const { reducer } = skuCodeAndNameSlice;
export default reducer;