import React, { useState, useEffect } from "react";
import "../../styles/global.css";
import Spinner from "react-bootstrap/Spinner";

function ActivityIndicator(props) {
    return (
        <div>
            <Spinner animation="border" role="status" style={{height:"1rem", width:"1rem"}}>
                {/* <span className="visually-hidden">Loading...</span> */}
            </Spinner>
        </div>
    );
}

export default ActivityIndicator;
