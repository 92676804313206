import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store';
import reportWebVitals from './reportWebVitals';

// MSAL imports
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./config/msalConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

// // Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  //   // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

// // Optional - This will update account state if a user signs in from another tab or window
// msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalInstance.setActiveAccount(account);
  }
});

// handle auth redired/do all initial setup for msal
msalInstance.handleRedirectPromise().then(authResult => {
  // Check if user signed in 
  const account = msalInstance.getActiveAccount();
  if (!account) {
    // redirect anonymous user to login page 
    msalInstance.loginRedirect();
  }
}).catch(err => {
  // TODO: Handle errors
  console.log(err);
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App pca={msalInstance} />
  </Provider>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// // ReactDOM.render(<App />, document.getElementById('root'));
