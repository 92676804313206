import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import uploadIcon from "../../../../assets/images/upload.svg";
import FileUploader from "../../../../components/FileUploder/fileuploader";
import FileUploaderNew from "../../../../components/FileUploder/fileUploaderNew";
import { uploadScenarioFile } from "../../../../service/scenarioPlannerService";
import { getFormContentLength, matchString } from "../../../../utils";
import ActivityIndicator from "../../../../components/Loader/loader";
// import { fetchMDMOpsData, saveMDMOpsData ,fetchProjectWorkFlowStatus,saveRNDSignoffData} from "../../../../service/projectDetails";
// import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
// import "./modalScenarioPlanner.css";

const ScenarioUpload = (props) => {
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [isOkBtnDisabled, setIsOkBtnDisabled] = React.useState(true);
  const [formData, setFormData] = useState(new FormData());
  const [scenarioName, setScenarioName] = useState("");
  const [validationMessage, setValidationMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const userDetails = useSelector((state) => state.userDetails);
  const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;

  useEffect(() => {
    if (getFormContentLength(formData) >= 1 && scenarioName.length >= 1) {
      setIsOkBtnDisabled(false);
    }
  }, [formData, scenarioName]);

  const closeModal = () => {
    setShowModal(false);
    props.onComplete(false, "fail");
  };

  const handleUploadOnClick = async () => {
    setIsLoading(true);
    const uploadedBy = `${userDetails.userFirstName} ${userDetails.userLastName}`;
    const res = await uploadScenarioFile(
      props.projectId,
      scenarioName,
      uploadedBy,
      formData
    );
    if (res.status === 200) {
      if (res.data?.arrScenario[0] !== null) {
        setShowModal(false);
        props.onComplete(false, res);
        setValidationMessage("");
      } else {
        setIsLoading(false);
        setValidationMessage("Invalid data provided. Please upload the valid data");
      }
    } else {
      setIsLoading(false);
      setValidationMessage(res.data);  
    }
  };

  const handleScenarioNameChange = async (event) => {
    const isPresent = matchString(
      props.scenarioList,
      "scenarioName",
      event.target.value
    );
    if (isPresent) {
      setScenarioName("");
      setValidationMessage("Duplicate Name is not allowed!!!");
    } else {
      setScenarioName(event.target.value);
      setValidationMessage("");
    }
  };

  const onHandleBrowseFileCallback = async (formData) => {
    setFormData(formData);
  };

  return (
    <>
      <Modal
        centered
        dialogClassName="dsf-dialog-UniqueRPMModal"
        contentClassName="dsf-modal-workflowModal scenario-upload-modal"
        show={showModal}
      >
        <Modal.Header className="bw-modal-checkDelist-header">
          <Modal.Title className=" bw-checkDeslist-header-title-div">
            <div className="checkDelist-modal-header-div">
              <span className="dsf-size12 dsf-font-weight-700 dsf-title-color">
                {" "}
                Scenario Upload
              </span>
              <span
                className="dsf-size12 dsf-font-weight-400"
                style={{ color: "#CFCFCF" }}
              >
                Upload Scenario details from Kinaxis output
              </span>

              {/* <span className={`dsf-size12 dsf-font-weight-400`} style={{ color: "#FF544F", marginBottom: '-15px' }}>
                {isUploadUnsuccessful ? 'Upload failed' : ''}
              </span> */}
              <span
                className="dsf-size11 dsf-font-weight-400 errorMsg"
                style={{ color: "#FF544F", marginBottom: "-15px" }}
              >
                {validationMessage}
              </span>
            </div>
            <img
              src={closeIcon}
              alt="Close-Image"
              onClick={() => closeModal()}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="scenario-upload-body">
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <>
              <Row className="scenario-upload-div">
                <span className="dsf-size12 dsf-font-weight-400">
                  Scenario Name
                </span>
              </Row>
              <Row className="scenario-upload-div ">
                <input
                  type="text"
                  name="recipient"
                  // value={props.currentWorkflowStage === '5' ? props.rdApproverName : recipient}
                  className={`dsf-RPM-textField input-sendTo ${
                    validationMessage && validationMessage.length > 0 &&
                    validationMessage.includes("Duplicate")
                      ? "errorBorder"
                      : "successBorder"
                  }`}
                  // disabled={!isReceipientEnable}
                  // onChange={(e) => handleScenarioNameChange(e)}
                  onBlur={(e) => handleScenarioNameChange(e)}
                  // onKeyDown={(e) => handleRecipientValueOnEnter(e)}
                />
              </Row>
              <Row
                className="scenario-upload-div"
                style={{ marginTop: "15px" }}
              >
                <span className="dsf-size12 dsf-font-weight-400">
                  Upload Scenario File
                </span>
              </Row>
              <Row
                className="scenario-upload-div fileUpload-div"
                style={{ marginTop: "11px", marginBottom: "0px" }}
              >
                <FileUploaderNew
                  selectedProjectName={props.selectedProjectName}
                  handleBrowseFile={onHandleBrowseFileCallback}
                />
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer scenario-upload-footer-div">
          <Button
            className="dsf-footer-okbutton-PI"
            variant="secondary"
            onClick={() => handleUploadOnClick()}
            disabled={isOkBtnDisabled}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ScenarioUpload;
