import { msalInstance } from '../index';
import { loginRequest } from "../config/msalConfig";

export async function onSuccessfulAuthentication(activeAccount) {
    if (activeAccount) {

        const tokenResponse = await msalInstance.acquireTokenSilent(loginRequest);
        if(tokenResponse){
            localStorage.setItem('userDetails', JSON.stringify(tokenResponse.account))
            localStorage.setItem('IDToken', tokenResponse.idToken)
            localStorage.setItem('AccessToken', tokenResponse.accessToken);
            localStorage.setItem('Token_Expires_in', tokenResponse.idTokenClaims.exp);
        }
        // return JSON.parse(localStorage.getItem('userDetails'));
    }
}