import React, { useState, useEffect } from "react";
import "./carousel.css";
import PendingTask from "../../pages/Dashboard/TaskflowScreens/PendingTaskBar/pendingTask";
import Prev from "../../assets/images/landingPage/prevArrow.svg";
import Next from "../../assets/images/landingPage/nextArrow.svg";

const Carousel = ({
  items,
  role,
  onComplete,
  visibleItems = 2,
  autoScroll = true,
  interval = 3000,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (autoScroll) {
      const autoScrollInterval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, interval);

      return () => clearInterval(autoScrollInterval);
    }
  }, [autoScroll, interval, items.length]);

  const goToPrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === items.length - visibleItems ? prevIndex : prevIndex + 1
    );
  };

  const getVisibleItems = () => {
    let visibleItemsArray = [];
    for (let i = 0; i < visibleItems; i++) {
      visibleItemsArray.push(items[(currentIndex + i) % items.length]);
    }
    return visibleItemsArray;
  };

  return (
    <div className="bwi-carousel">
      {currentIndex !== 0 && (
        <div
          className="bwi-carousel-button bwi-carousel-button-left"
          onClick={goToPrevious}
          disabled={currentIndex === 0}
        >
          <img src={Prev} alt="prev" />
        </div>
      )}
      <div
        className={`bwi-carousel-item-container ${
          currentIndex !== 0 ? "bwi-carousel-item-container-extend-left" : ""
        } ${
          currentIndex < items.length - visibleItems
            ? "bwi-carousel-item-container-extend-right"
            : ""
        }`}
      >
        {getVisibleItems().map((item, i) => (
          <PendingTask
            task={item}
            key={i}
            role={role}
            onComplete={onComplete}
          />
        ))}
      </div>
      {currentIndex < items.length - visibleItems && (
        <div
          className="bwi-carousel-button bwi-carousel-button-right"
          onClick={goToNext}
          disabled={currentIndex >= items.length - visibleItems}
        >
          <img src={Next} alt="next" />
        </div>
      )}
    </div>
  );
};

export default Carousel;
