// import React from "react";
// export default function Login (props){
//     return(<>
//     <p>test page</p>
//     </>)
// }

import React, { Component } from 'react';

class Login extends Component {
  render() {
    return (<>
        <div style = {{backgroundcolor:'red'}}>
          <h2>Home</h2>
        </div>
        <div>
        <h2>Home1</h2>
      </div>
      <div>
      <h2>Home2</h2>
    </div>
    </>
    );
  }
}

export default Login;