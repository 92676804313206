import axios from "axios";
import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createOrUpdateUploadSKUFile } from "../../slices/skuCodeAndNameSlice";
import AppSnackbar from "../Snackbar/snackbar";

const FileUploaderNew = (props) => {
  const dispatch = useDispatch();
  const hiddenFileInput = React.useRef(null);

  const arrProjectDetails = useSelector((state) => state.projectDetails);

  const [fileName, setFileName] = useState();

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const formData = new FormData();

    // const userEmailAddr = JSON.parse(localStorage.getItem('userDetails')).username;
    // const prjName = props.selectedProjectName['currentProjectName'];

    setFileName(event.target.files[0].name);
    formData.append("file", event.target.files[0]);
    props.handleBrowseFile(formData);

    // dispatch(createOrUpdateUploadSKUFile({ loggedInUser: userEmailAddr, projectName: prjName, data: formData }))
    //   .unwrap()
    //   .then(res => {
    //     console.log(res);
    //     return (<AppSnackbar
    //       open={true}
    //       vertical={'top'}
    //       horizontal={'right'}
    //       message={'File uploaded successfuly'}
    //     />);
    //   })
    //   .catch(e => {
    //     return (<AppSnackbar
    //       open={true}
    //       vertical={'top'}
    //       horizontal={'right'}
    //       message={'File upload failed'}
    //     />);
    //     console.log(e);
    //   });

    // try {
    //   const res = await axios.post("https://bieno-da12-d-930072-webapi-02.azurewebsites.net/ProjectDetails/UploadFile?loggedInUser=Varun.Sheshagowda2@unilever.com&projectName='Test'", formData);
    // }
    // catch (ex) {
    //   console.log(ex);
    // }
    //const fileUploaded = event.target.files[0];
    //props.handleFile(fileUploaded);
  };
  return (
    <>
      <InputGroup className="mb-3 dsf-delist-form-upload-form-ctrl">
        <Form.Control
          name="upload-delist_bom"
          aria-describedby="browse-button"
          className="dsf-delist-form-upload-type"
          value={fileName}
        />
        <Button
          variant="outline-secondary"
          id="browse-button"
          onClick={handleClick}
          className="dsf-delist-form-upload-button"
        >
          Browse
        </Button>
      </InputGroup>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
      {/* <AppSnackbar
          open={{showStackbar}}
          vertical={'top'}
          horizontal={'right'}
          message={{showNotificationMsg}}
        />       */}
    </>
  );
};
export default FileUploaderNew;
