import { ApiHelper, configByEnv } from "./APIHelper";

const config = configByEnv();

export const getNotification = async(userEmail) =>{
    try {
      // https://bieno-da12-d-930072-webapi-02.azurewebsites.net/api/Authorization/GetUserNotifications?userEmail=harish.babu@unilever.com
      const url = config.baseURL + '/User/GetUserNotifications';
      const response = await ApiHelper.get(url)
      return {
        "status":response.status,
        "data":response.data
      };
    } catch (e) {
      console.log("error", e);
      return {
        "status":e.response.status,
        "data":e.response.data
      };
    }
  }


  export const updateNotification = async(notificationID) =>{
    try {
      //https://bieno-da12-d-930072-webapi-02.azurewebsites.net//api/Authorization/UpdateUserNotifications?notificationID=2
     // /Authorization/GetUserNotifications?userEmail=harish.babu@unilever.com
      const url = config.baseURL + '/User/UpdateUserNotifications?notificationID='+notificationID;
      const response = await ApiHelper.put(url);
      return {
        "status":response.status,
        "data":response.data
      };
    } catch (e) {
      console.log("error", e);
      return {
        "status":e.response.status,
        "data":e.response.data
      };
    }
  }