import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";

const modalRedundantDetails = (props) => {
  const {
    showModal,
    setShowModal,
    redundantMaterial,
    material,
    FgCode,
    FgDescription,
  } = props;
  return (
    <Modal
      centered
      contentClassName="bwi-modal-material-redundant"
      show={showModal}
    >
      <Modal.Header className="bw-modal-header">
        <Modal.Title className="dsf-size16 dsf-title-color">
          Consult the below project
        </Modal.Title>
        <img
          src={closeIcon}
          alt="Close-Image"
          className="dsf-close-img"
          onClick={() => setShowModal(false)}
        />
      </Modal.Header>
      <Modal.Body className="bw-modal-body">
        <>
          <p className="bwi-body-desc">
            Please be informed that the following project(s) is also having this
            material as Unique RPM. So consult the Project owner and agree the
            way forward.
          </p>
          {redundantMaterial.map((item) => {
            return (
              <div className="bwi-project-details-div">
                <Row className="bwi-project-details-row">
                  <Col xs={5} className="dsf-text-left">
                    <p className="bw-proj-value dsf-span-s">project Name</p>
                  </Col>
                  <Col xs={7} className="dsf-text-left dsf-pleft0">
                    <p className="bw-proj-value dsf-span-s">
                      {item.projectName}
                    </p>
                  </Col>
                </Row>
                {/* <Row className="bwi-project-details-row">
                  <Col xs={5} className="dsf-text-left">
                    <p className="bw-proj-value dsf-span-s">Material</p>
                  </Col>
                  <Col xs={7} className="dsf-text-left dsf-pleft0">
                    <p className="bw-proj-value dsf-span-s">{material}</p>
                  </Col>
                </Row> */}
                <Row className="bwi-project-details-row">
                  <Col xs={5} className="dsf-text-left">
                    <p className="bw-proj-value dsf-span-s">FG code</p>
                  </Col>
                  <Col xs={7} className="dsf-text-left dsf-pleft0">
                    <p className="bw-proj-value dsf-span-s">{FgCode}</p>
                  </Col>
                </Row>
                <Row className="bwi-project-details-row">
                  <Col xs={5} className="dsf-text-left">
                    <p className="bw-proj-value dsf-span-s">FG Description</p>
                  </Col>
                  <Col xs={7} className="dsf-text-left dsf-pleft0">
                    <p className="bw-proj-value dsf-span-s">{FgDescription}</p>
                  </Col>
                </Row>
                <Row className="bwi-project-details-row">
                  <Col xs={5} className="dsf-text-left">
                    <p className="bw-proj-value dsf-span-s">Project Owner</p>
                  </Col>
                  <Col xs={7} className="dsf-text-left dsf-pleft0">
                    <p className="bw-proj-value dsf-span-s">
                      {item.projectOwner}
                    </p>
                  </Col>
                </Row>
                <Row className="bwi-project-details-row">
                  <Col xs={5} className="dsf-text-left">
                    <p className="bw-proj-value dsf-span-s">Launch Date</p>
                  </Col>
                  <Col xs={7} className="dsf-text-left dsf-pleft0">
                    <p className="bw-proj-value dsf-span-s">
                      {item.launchDate}
                    </p>
                  </Col>
                </Row>
              </div>
            );
          })}
        </>
      </Modal.Body>
    </Modal>
  );
};

export default modalRedundantDetails;
