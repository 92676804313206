import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import ActivityIndicator from "../../../components/Loader/loader";
import closeIcon from "../../../assets/images/Vector-cross.svg";
import { fetchProjectInfo } from "../../../service/projectDetails";

const ModalProjectInfo = (props) => {
  const { showModal, setShowModal } = props;
  const [projInfo, setProjInfo] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  //Redux Start
  let arrProjectDetails = useSelector((state) => state.projectDetails);
  const userDetails = useSelector((state) => state.userDetails);
  //Redux End

  const getProjectInfo = async () => {
    const res = await fetchProjectInfo(
      arrProjectDetails.filter(
        (item) => item.projectName === props.selectedProjectName
      )[0].projectId
    );
    if (res.status === 200) {
      setProjInfo(res.data);
    } else {
      console.log("error occurred", res.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getProjectInfo();
  }, []);

  return (
    <>
      <Modal
        centered
        contentClassName="dsf-modal-project-info dsf-modal-bgcolor-PI"
        show={showModal}
      >
        <Modal.Header className="bw-modal-header">
          <Modal.Title className="dsf-size16">Project Summary</Modal.Title>
          <img
            src={closeIcon}
            alt="Close-Image"
            className="dsf-close-img"
            onClick={() => setShowModal(false)}
          />
        </Modal.Header>
        <Modal.Body className="bw-modal-body">
          {isLoading ? (
            <ActivityIndicator />
          ) : (
            <>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">Project Name:</p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s">
                    {projInfo.projectName}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">
                    Project Description:
                  </p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s">
                    {projInfo.projectDescription}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">Project Leader:</p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s">
                    {projInfo.projectLeader}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">Brand:</p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s bw-brand-value">
                    {projInfo.brand}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">
                    Regions/ Countries:
                  </p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s bw-country-value">
                    {projInfo.countries}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">Channel:</p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s">{projInfo.channel}</p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">Project Status:</p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s">
                    {projInfo.projectStatus}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">Category:</p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s">
                    {projInfo.category}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={5} className="dsf-text-left">
                  <p className="bw-proj-label dsf-span-s">Project Type:</p>
                </Col>
                <Col className="dsf-text-left dsf-pleft0">
                  <p className="bw-proj-value dsf-span-s">
                    {projInfo.projectType}
                  </p>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer">
          <Button
            variant="secondary"
            className={"dsf-footer-okbutton-PI"}
            onClick={() => setShowModal(false)}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalProjectInfo;
