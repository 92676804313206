import React, { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Checkbox from "@mui/material/Checkbox";
import "./autocomplete.css";
import polygon from "../../assets/images/Polygon-enable.svg";
import polygonClose from "../../assets/images/PolygonClose.svg";
import polygonDisable from "../../assets/images/Polygon-disable.svg";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  display: "flex",
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  color: "#EDF1F3",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-root": {
    alignItems: "unset",
  },
  "& .MuiInputBase-input": {
    color: "#797775",
    fontWeight: "400",
    maxWidth: "unset",
    justifyContent: "center",
  },
}));

const CustomNestedAutoCompleteDropdown = (props) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const {
    options,
    maxLength,
    width,
    disable,
    maxDropdownHeight,
    onSelectionChange,
    listClassName,
    isOpen,
    setIsOpen,
  } = props;

  const [filteredOptions, setFilteredOptions] = useState([]);
  const [expandedParents, setExpandedParents] = useState({});

  const toggleParentExpansion = (value) => {
    setExpandedParents((prevState) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };

  useEffect(() => {
    if (props.selectedCountries.length > 0) {
      const countryCodes = [];
      const countryLabels = [];
      props.selectedCountries.forEach((item) => {
        countryCodes.push(item.countryCode);
        countryLabels.push(item.geographyName);
      });
      setSelectedValues(countryCodes);
      setSelectedLabels(countryLabels);
    }
  }, [props.selectedCountries]);

  useEffect(() => {
    if (filteredOptions.length === 0) {
      setFilteredOptions(flattenOptions(options));
    } else {
      setFilteredOptions(flattenOptions(filteredOptions));
    }
  }, [props.options, expandedParents]);

  useEffect(() => {
    if (!props.isOpen) {
      onSelectionChange({ selectedValues, selectedLabels });
    }
  }, [props.isOpen, selectedValues, selectedLabels]);

  const flattenOptions = (options) => {
    const flattened = [];
    options.forEach((option) => {
      option.expanded = true;
      flattened.push(option);
      if (
        option.children &&
        expandedParents[option.value] &&
        !option.expanded
      ) {
        flattened.push(...option.children);
      }
    });
    return flattened;
  };

  const toggleDropdown = () => {
    if (!disable) {
      setIsOpen(!isOpen);
      setFilteredOptions(flattenOptions(options));
      setExpandedParents({});
      setSearchTerm("");
    }
  };

  const toggleValue = (value, label) => {
    const superParentOption = options.find(
      (option) => option.value === value && option.children
    );

    const parent = options.find(
      (option) =>
        option.children &&
        option.children.some((child) => child.value === value)
    );

    const parentOption = parent?.children
      .map((child) => {
        if (child.value === value) {
          return child;
        }
      })
      .filter(Boolean);

    if (superParentOption) {
      const childValues = superParentOption.children.reduce((acc, child) => {
        if (child.children) {
          acc.push(...child.children.map((grandchild) => grandchild.value));
        }
        return acc;
      }, []);
      const childLabels = superParentOption.children.reduce((acc, child) => {
        if (child.children) {
          acc.push(...child.children.map((grandchild) => grandchild.label));
        }
        return acc;
      }, []);
      const children = [];
      superParentOption.children.forEach((child) => {
        if (child.children) {
          child.children.forEach((grandchild) => {
            children.push(grandchild);
          });
        }
      });

      const areAllChildrenSelected = childValues.every((childValue) =>
        selectedValues.includes(childValue)
      );

      if (areAllChildrenSelected) {
        setSelectedValues(
          selectedValues.filter((val) => !childValues.includes(val))
        );
        setSelectedLabels(
          selectedLabels.filter((label) => !childLabels.includes(label))
        );
      } else {
        setSelectedValues([
          ...selectedValues.filter((val) => !childValues.includes(val)),
          ...childValues,
        ]);
        setSelectedLabels([
          ...selectedLabels.filter((label) => !childLabels.includes(label)),
          ...childLabels,
        ]);
      }
    } else {
      if (parentOption && parentOption[0].children) {
        const childValues = parentOption[0].children.map(
          (child) => child.value
        );
        const childLabels = parentOption[0].children.map(
          (child) => child.label
        );
        const children = parentOption[0].children.map((child) => child);

        const areAllChildrenSelected = childValues.every((childValue) =>
          selectedValues.includes(childValue)
        );

        if (areAllChildrenSelected) {
          setSelectedValues(
            selectedValues.filter((val) => !childValues.includes(val))
          );
          setSelectedLabels(
            selectedLabels.filter((label) => !childLabels.includes(label))
          );
        } else {
          setSelectedValues([
            ...selectedValues.filter((val) => !childValues.includes(val)),
            ...childValues,
          ]);
          setSelectedLabels([
            ...selectedLabels.filter((label) => !childLabels.includes(label)),
            ...childLabels,
          ]);
        }
      } else {
        const isValueSelected = selectedValues.includes(value);
        const children = { label, value };

        if (isValueSelected) {
          setSelectedValues(selectedValues.filter((val) => val !== value));
          setSelectedLabels(
            selectedLabels.filter((labelItem) => labelItem !== label)
          );
        } else {
          setSelectedValues([...selectedValues, value]);
          setSelectedLabels([...selectedLabels, label]);
        }
      }
    }
  };

  const deepSearch = (options, searchTerm) => {
    const filteredOptions = [];
    const addedOptions = new Set();

    options.forEach((option) => {
      if (option.label.toLowerCase().includes(searchTerm.toLowerCase())) {
        if (!addedOptions.has(option.label)) {
          filteredOptions.push(option);
          addedOptions.add(option.label);
          setExpandedParents((prevState) => ({
            ...prevState,
            [option.value]: true,
          }));
        }
      }

      if (option.children) {
        const childResults = deepSearch(option.children, searchTerm);
        if (childResults.length > 0) {
          const optionWithChildren = {
            ...option,
            children: childResults,
          };
          if (!addedOptions.has(optionWithChildren.label)) {
            setExpandedParents((prevState) => ({
              ...prevState,
              [optionWithChildren.value]: true,
            }));

            filteredOptions.push(optionWithChildren);
            addedOptions.add(optionWithChildren.label);
          }
        }
      }
    });
    return filteredOptions;
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    if (value.trim() === "") {
      setFilteredOptions(flattenOptions(options));
      setExpandedParents({});
    } else {
      const filtered = deepSearch(options, value);
      setFilteredOptions(filtered);
    }
  };

  const isParentIndeterminate = (data) => {
    const superParentOption = options.find(
      (option) => option.value === data.value && option.children
    );

    const parentOption = [];
    if (superParentOption) {
      const childValues = superParentOption.children.reduce((acc, child) => {
        if (child.children) {
          acc.push(...child.children.map((grandchild) => grandchild.value));
        }
        return acc;
      }, []);
      const selectedChildCount = childValues.filter((childValue) =>
        selectedValues.includes(childValue)
      ).length;

      return selectedChildCount > 0 && selectedChildCount < childValues.length;
    }

    if (parentOption) {
      data.children.forEach((childData) => {
        options.forEach((option) => {
          if (option.children) {
            option.children.forEach((child) => {
              if (child.children) {
                child.children.forEach((grandchild) => {
                  if (grandchild.value === childData.value) {
                    parentOption.push(child);
                  }
                });
              }
            });
          }
        });
      });
      const childValues = parentOption.reduce((acc, child) => {
        if (child.children) {
          acc.push(...child.children.map((grandchild) => grandchild.value));
        }
        return acc;
      }, []);

      const selectedChildCount = childValues.filter((childValue) =>
        selectedValues.includes(childValue)
      ).length;

      return selectedChildCount > 0 && selectedChildCount < childValues.length;
    }
  };

  const isParentChecked = (data) => {
    const superParentOption = options.find(
      (option) => option.value === data.value && option.children
    );

    const parent = options.find(
      (option) =>
        option.children &&
        option.children.some((child) => child.value === data.value)
    );

    const parentOption = parent?.children
      .map((child) => {
        if (child.value === data.value) {
          return child;
        }
      })
      .filter(Boolean);

    if (superParentOption) {
      const childValues = superParentOption.children.reduce((acc, child) => {
        if (child.children) {
          acc.push(...child.children.map((grandchild) => grandchild.value));
        }
        return acc;
      }, []);

      if (childValues.length === 0) {
      }
      return childValues.every((childValue) =>
        selectedValues.includes(childValue)
      );
    }

    if (parentOption) {
      const childValues = data.children.map((child) => child.value);

      if (childValues.length === 0) {
        return false;
      }
      return childValues.every((childValue) =>
        selectedValues.includes(childValue)
      );
    }
  };

  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
  };

  const renderOption = (option) => {
    const hasChildren = option.children && option.children.length > 0;
    const isExpanded = expandedParents[option.value] || false;

    return (
      <div key={option.value} className={`region-${option.value}`}>
        <div className="dropdown-parent-container">
          {hasChildren && (
            <div className="expand-icon-container">
              <div
                className="expand-icon"
                onClick={() => toggleParentExpansion(option.value)}
              >
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </div>
            </div>
          )}
          <div
            className={`option-row ${
              selectedValues.includes(option.value) ? "selected" : ""
            } ${hasChildren ? "nested-parent-list" : "nested-children-list"}`}
            onClick={() => {
              toggleValue(option.value, option.label);
              if (onSelectionChange) {
                onSelectionChange({ selectedValues, selectedLabels });
              }
            }}
          >
            {hasChildren ? (
              <Checkbox
                checked={isParentChecked(option)}
                color="primary"
                indeterminate={isParentIndeterminate(option)}
                onChange={() => toggleValue(option.value, option.label)}
              />
            ) : (
              <Checkbox
                checked={selectedValues.includes(option.value)}
                onChange={() => toggleValue(option.value, option.label)}
                color="primary"
              />
            )}
            {option.label}
          </div>
        </div>
        {hasChildren && isExpanded && (
          <div className={hasChildren ? "nested-parent" : "nested-children"}>
            {option.children.map((childOption) => renderOption(childOption))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={`custom-dropdown ${listClassName}`}>
      <div
        className="input-container"
        onChange={() => {
          if (onSelectionChange) {
            onSelectionChange({ selectedValues, selectedLabels });
          }
        }}
        onClick={() => {
          toggleDropdown();
          if (onSelectionChange) {
            onSelectionChange({ selectedValues, selectedLabels });
          }
        }}
        style={{ width: width }}
      >
        <input
          type="text"
          value={truncateText(selectedLabels.join(", "), maxLength)}
          disabled={disable}
          readOnly
        />
        <img
          src={disable ? polygonDisable : isOpen ? polygon : polygonClose}
          disabled={disable}
          alt="autocomplete-dropdown-icon"
          className={`autocomplete-dropdown-icon  ${
            disable
              ? "dropdownDisable"
              : isOpen
              ? "dropdownEnable"
              : "dropdownClose"
          }`}
        />
      </div>
      {isOpen && (
        <div className="options-container">
          <Search className="dsf-delistingProj-search">
            <SearchIconWrapper className="dsf-delistingProj-search-icon">
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder={"Search…"}
              value={searchTerm}
              className="dsf-delistingProj-search-text"
              onChange={(e) => handleSearch(e.target.value)}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
          <div
            className="options-list"
            style={{
              maxHeight: maxDropdownHeight,
              overflowX: "hidden",
              maxWidth: width,
            }}
          >
            {filteredOptions.map((option) => renderOption(option))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomNestedAutoCompleteDropdown;
