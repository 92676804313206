import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Header from "../../../layouts/Header/header";
import CustomizedSteppers from "../../../components/Stepper/stepper";
import ComponentSelection from "../TaskflowScreens/workflowStepSelection";
import ScenarioPlanner from "../ui-components/ScenarioPlanner/modalScenarioPlanner";
import DelistingProject from "../ui-components/DelistingProject/delistingProject";
import {
  deleteDelistingProject,
  postUpdatedWorkFlowStatus,
} from "../../../slices/projectDetailSlice";
import { fetchProjectWorkFlowStatus } from "../../../service/projectDetails";

import BackIcon from "../../../assets/images/landingPage/backIcon.svg";
import closeIcon from "../../../assets/images/Vector-cross.svg";
import ModalProjectInfo from "../../Dashboard/TaskflowScreens/ProjectInfo/projectInfo";
import CustomMenus from "../../../components/Menu/customMenu";
import { ProjectInfoTooltip } from "../../../components/Tooltip/tooltip";
import { configByEnv } from "../../../service/APIHelper";

import {
  innoflexStatusEnum,
  delistingActionMenus,
} from "../../../globalConstants";
import "./projectLanding.css";

const ModalProjectLanding = (props) => {
  const {
    showModal,
    setShowModal,
    arrItem,
    selectedProjectName,
    isEdit,
    selectedWorkflow,
  } = props;
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [updatedStepData, setUpdatedStepData] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [stepSelected, setStepSelected] = useState(props.selectedWorkflow);
  const [dataReady, setDataReady] = useState(false);
  const [isProjectModal, setIsProjectModal] = useState(false);
  const [scenarioModalOpen, setScenarioModalOpen] = useState(false);
  const [scenarioCompleted, setScenarioCompleted] = useState(false);
  const [isLoadedFirstTime, setIsLoadedFirstTime] = useState(true);
  const [showDelistingProjectModal, setShowDelistingProjectModal] =
    useState(false);

  const imgSrc = innoflexStatusEnum[arrItem.innoflexStatus];

  //Redux Start
  const dispatch = useDispatch();
  //Redux End

  useEffect(() => {
    let workflowData = arrItem.workflow;
    if (workflowData) {
      let workflowStatusData = workflowData
        .map((item, index) => {
          if (index === 3) {
            if (
              workflowData[4].status === "5" ||
              workflowData[4].status === "7"
            ) {
              return workflowData[4].status;
            } else {
              return item.status;
            }
          } else {
            return item.status;
          }
        })
        .filter((item, index) => index !== 4 && index !== 6);

      let activeStep = workflowStatusData
        .map((item, index) => {
          if (item !== "1" && item !== "3") {
            return index;
          }
        })
        .filter((item) => item !== undefined)
        .pop();

      setActiveStep(activeStep);
      if (
        isLoadedFirstTime &&
        selectedWorkflow >= 0 &&
        selectedWorkflow <= 6 &&
        selectedWorkflow
      ) {
        setStepSelected(selectedWorkflow);
        setIsLoadedFirstTime(false);
      } else if (isLoadedFirstTime && selectedWorkflow === 0) {
        setStepSelected(selectedWorkflow);
        setIsLoadedFirstTime(false);
      } else if (
        !isLoadedFirstTime &&
        stepSelected === 4 &&
        workflowStatusData[4] === "7" &&
        workflowStatusData[5] !== "1"
      ) {
        setStepSelected(4);
      } else {
        setStepSelected(activeStep);
      }
      setUpdatedStepData(workflowStatusData);
      setDataReady(true);
    }
  }, [arrItem]);

  //Redux Start , innoflexStatus
  let arrProjectDetails = useSelector((state) => state.projectDetails);
  //Redux End

  const updateNewProjectList = async () => {
    const res1 = await fetchProjectWorkFlowStatus(arrItem.projectId);
    if (res1.status === 200) {
      const res = {
        projectName: props.selectedProjectName,
        workflow: res1.data,
      };
      dispatch(postUpdatedWorkFlowStatus(res));
    }
    // props.updateNewProjectList();
  };

  const onOkDeleteBtnClicked = async () => {
    setIsDeleteModalOpen(false);
    const res = await dispatch(
      deleteDelistingProject(arrItem.projectId)
    ).unwrap();
    props.updateNewProjectList(true);
    setShowModal(false);
  };

  const handleBackButtonClicked = () => {
    setShowModal(false);
    {
      scenarioCompleted && props.updateProjectList(true);
    }
  };

  const handleStepSelection = (activeStep) => {
    setStepSelected(activeStep);
  };

  const onScenarioPlannerCompletion = (status, isReviewActionSuccess) => {
    setStepSelected(6);
    setScenarioModalOpen(false);
    {
      isReviewActionSuccess && setScenarioCompleted(true);
    }
  };

  const handleScenarioButtonClicked = () => {
    setScenarioModalOpen(!scenarioModalOpen);
  };

  const onWorkflowStageCompletion = (prevStateSelection, action) => {
    if (
      prevStateSelection >= 0 &&
      prevStateSelection < 6 &&
      prevStateSelection !== true
    ) {
      if (action === "Next") {
        if (prevStateSelection === 3 && arrItem.workflow[5].status === "1") {
          arrItem.workflow[7].status !== "1" &&
            setStepSelected(prevStateSelection + 2);
        } else {
          setStepSelected(prevStateSelection + 1);
        }
      } else {
        if (prevStateSelection === 5 && arrItem.workflow[5].status === "1") {
          setStepSelected(prevStateSelection - 2);
        } else {
          setStepSelected(prevStateSelection - 1);
        }
      }
    }
  };

  const handleMenuSelection = (menu) => {
    if (menu === "Project Summary") {
      setIsProjectModal(!isProjectModal);
    } else if (menu === "Edit Project") {
      setShowDelistingProjectModal(true);
    } else if (menu === "Delete Project") {
      setIsDeleteModalOpen(true);
    }
  };

  return (
    <>
      {showDelistingProjectModal && (
        <DelistingProject
          showModal={true}
          setShowModal={(isModalOpen) =>
            setShowDelistingProjectModal(isModalOpen)
          }
          updateNewProjectList={updateNewProjectList}
          isDataUpdate={true}
          projectId={arrItem.projectId}
        />
      )}
      <Modal
        id="projectLandingModalId"
        contentClassName="bwi-proj-landing-content"
        show={showModal}
      >
        <div className="dsf-header-div">
          <Header />
        </div>
        <div className="bwi-proj-landing-body">
          <Row className="bwi-landing-row-header">
            <Col xs={4} className="bwi-landing-header-info bwi-display-flex">
              <div
                onClick={handleBackButtonClicked}
                className="bwi-landing-cursor-pointer"
              >
                <img
                  src={BackIcon}
                  alt="back-icon"
                  className="bwi-landing-cursor-pointer bwi-padding-right-10"
                />
                <span className="bwi-landing-back-text">Back</span>
              </div>
            </Col>
            <Col xs={4} className="bwi-landing-status-info">
              <div className="bwi-proj-status-div">
                <span className="bwi-padding-right-10 bwi-proj-status-label">
                  Innoflex Status :
                </span>
                <img
                  src={require(`../../../assets/images/${imgSrc}Phase.svg`)}
                  alt="project-phase-icon"
                  className="bwi-padding-right-10"
                />
                <span className="dsf-span-s bwi-proj-status-data">
                  {innoflexStatusEnum[arrItem.innoflexStatus]}
                </span>
              </div>
            </Col>
            <Col xs={4} className="bwi-landing-delete-info">
              {/* {arrItem.innoflexStatus === "Temporary" && (
                <div
                  className="bwi-landing-cursor-pointer"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                  }}
                >
                  <img
                    src={DeleteIcon}
                    alt="delete-icon"
                    className="bwi-padding-right-10 bwi-landing-delete-icon"
                  />
                  <span className="bwi-landing-delete-text dsf-span-s bwi-modal-title">
                    Delete Project
                  </span>
                </div>
              )} */}
            </Col>
          </Row>
          <Row className="bwi-landing-row-info">
            <Col xs={4} className="bwi-landing-proj-info">
              <div className="bwi-proj-div">
                <Col className="bwi-proj-name-col flex-fill">
                  <span className="bwi-proj-name-header dsf-span-xl dsf-desc-length-txt">
                    {arrItem.projectName}
                  </span>
                </Col>
                <Col
                  className="bwi-proj-info-icon-col flex-fill bwi-landing-cursor-pointer"
                  style={{ width: "10%" }}
                  onClick={() => {
                    if (arrItem.innoflexStatus !== "Temporary") {
                      setIsProjectModal(!isProjectModal);
                    }
                  }}
                >
                  {arrItem.innoflexStatus !== "Temporary" ? (
                    <img
                      src={
                        require(`../../../assets/images/Tooltip-new.svg`)
                          .default
                      }
                      alt="sort"
                      className="dsf-workflow-icon-new bwi-proj-info-icon-img"
                    />
                  ) : (
                    <CustomMenus
                      listClassName="bwi-proj-info-more-icon"
                      variant="MoreButton"
                      menuItems={
                        arrItem.innoflexStatus !== "Temporary" ||
                        !props.isEdit ||
                        configByEnv().sampleProjectNameSK === arrItem.projectId
                          ? [delistingActionMenus[0]]
                          : delistingActionMenus
                      }
                      onMenuItemClick={handleMenuSelection}
                    />
                  )}
                </Col>
              </div>
              {arrItem.projectDesc.length > 32 ? (
                <div className="bwi-proj-info-tooltip">
                  <ProjectInfoTooltip
                    item={arrItem}
                    isShowDesc={false}
                    projNameLength={22}
                    projDescLength={64}
                  />
                </div>
              ) : (
                <span className={`bwi-proj-desc-text dsf-span-s`}>
                  {arrItem.projectDesc}
                </span>
              )}
            </Col>
            <Col xs={8} className="bwi-landing-status-info">
              {dataReady && (
                <CustomizedSteppers
                  stepData={updatedStepData}
                  arrItem={arrItem}
                  activeStep={activeStep}
                  iconSelected={stepSelected}
                  onStepSelection={handleStepSelection}
                  listClassName="bwi-landing-stepper"
                />
              )}
            </Col>
          </Row>

          {stepSelected !== 6 && (
            <Row
              className={`${
                stepSelected === 5
                  ? "bwi-landing-row-workflow-E2E"
                  : "bwi-landing-row-workflow-long"
              }`}
            >
              {dataReady && (
                <ComponentSelection
                  stepStatus={updatedStepData}
                  arrItem={arrItem}
                  activeStep={stepSelected}
                  onWorkflowStageCompletion={onWorkflowStageCompletion}
                  isEdit={isEdit}
                />
              )}
            </Row>
          )}
        </div>
        <div className="bwi-proj-landing-background bwi-display-flex-center">
          {stepSelected === 6 && (
            <Button
              className="bwi-landing-scenario-planner-btn"
              variant="secondary"
              onClick={handleScenarioButtonClicked}
            >
              Open Scenario Planner
            </Button>
          )}
        </div>
      </Modal>

      {scenarioModalOpen && (
        <ScenarioPlanner
          isShow={true}
          isEdit={isEdit}
          currentWorkflowStage={6}
          arrItem={arrItem}
          selectedProjectName={arrItem.projectName}
          onComplete={onScenarioPlannerCompletion}
        />
      )}

      {isDeleteModalOpen && (
        <Modal
          centered
          dialogClassName="dsf-delistingProjDel-okdialog dsf-dialog-messagebox"
          contentClassName="dsf-modal-messagebox dsf-delistingProjDel-okmodal"
          show={isDeleteModalOpen}
        >
          <Modal.Header className="bw-modal-messagebox-header">
            <Modal.Title className="dsf-size16 bw-messagebox-header-title">
              Delete Project
            </Modal.Title>
            <img
              src={closeIcon}
              alt="Close-Image"
              className="img.dsf-close-messagebox-img"
              onClick={() => setIsDeleteModalOpen(false)}
            />
          </Modal.Header>
          <Modal.Body className="bw-modal-messagebox-body">
            <p className="bw-modal-messagebox-msg">{`Are you sure you want to delete '${arrItem.projectName}' project?`}</p>
          </Modal.Body>
          <Modal.Footer className="bw-modal-footer">
            <Button
              className="bw-footer-cancle-btn"
              variant="secondary"
              onClick={() => setIsDeleteModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className="dsf-footer-okbutton-PI"
              variant="secondary"
              onClick={() => onOkDeleteBtnClicked()}
            >
              OK
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isProjectModal && (
        <ModalProjectInfo
          showModal={isProjectModal}
          setShowModal={setIsProjectModal}
          selectedProjectName={selectedProjectName}
        />
      )}
    </>
  );
};

export default ModalProjectLanding;
