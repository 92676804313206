import React, { useEffect, useState, useRef } from "react";
import { Col, Row } from "react-bootstrap";
import "./modalDeepDiveView.css";
import StackedbarChart from "../../../../helpers/StackedBarChartDD";
import ComboChart from "../../../../helpers/comboChart";
import LineChart from "../../../../helpers/lineChartDD";

import { formatNumber } from "../../../../utils";

import TableArrowPointer from "../../../../assets/images/TableArrowPointer.svg";
import ModalDetailedTable from "./modalDetailedTable";

const DeepDiveVolume = (props) => {
  const {
    deepDiveDefaultVolumeData,
    deepDiveActualVolumeData,
    deepDiveVolumeTableData,
    selectedProjectId,
  } = props;
  const [activeTab, setActiveTab] = useState("Volume");
  const [deepDiveViewData, setDeepDiveViewData] = useState({});
  const [defaultDeepDiveViewData, setDefaultDeepDiveViewData] = useState({});
  const [fgChartData, setFgChartData] = useState([]);
  const [rmChartData, setRMChartData] = useState([]);
  const [pmChartData, setPMChartData] = useState([]);
  const [CountryChartData, setCountryChartData] = useState([]);
  const [countryValue, setCountryValue] = useState(0);
  const [fgBwValue, setFgBwValue] = useState(0);
  const [rmBwValue, setRmBwValue] = useState(0);
  const [pmBwValue, setPmBwValue] = useState(0);
  const [isModalTableOpen, setIsModalTableOpen] = useState(false);
  const [countrySelected, setCountrySelected] = useState("");
  const [functionSelected, setFuntionSelected] = useState("");
  const [funcVolumeSelected, setFuncVolumeSelected] = useState("");
  const [selectedUomValue, setSelectedUomValue] = useState("");
  // const [detailedTableData, setDetailedTableData] = useState({});
  const [LineChartData, setLineChartData] = useState();
  const [totalBwValue, setTotalBwValue] = useState(0);
  const [filteredTableData, setFilteredTableData] = useState({});
  const [selectedUomIndex, setSelectedUomIndex] = useState("");

  useEffect(() => {
    setDefaultDeepDiveViewData(Object.assign({}, deepDiveDefaultVolumeData));
    setDeepDiveViewData(Object.assign({}, deepDiveActualVolumeData));
    // setDetailedTableData(Object.assign({}, deepDiveVolumeTableData));
  }, []);

  useEffect(() => {
    if (LineChartData?.length > 0) {
      let filteredData = {};
      if (fgChartData?.length > 0) {
        if (functionSelected === "FG") {
          filteredData["fgList"] = deepDiveVolumeTableData.fgList.filter(
            (item) => {
              return (
                item.fgCode === funcVolumeSelected &&
                item.uom === selectedUomValue
              );
            }
          );
        } else {
          filteredData["fgList"] = deepDiveVolumeTableData.fgList
            .filter((item) => {
              return fgChartData.some((ele) => ele.code === item.fgCode);
            })
            .filter((item) => {
              return item.uom === selectedUomValue;
            });
        }
      }
      if (rmChartData?.length > 0) {
        if (functionSelected === "RM" || functionSelected === "SFG") {
          filteredData["rmList"] = deepDiveVolumeTableData.rmList.filter(
            (item) => {
              return (
                item.rmCode === funcVolumeSelected &&
                item.uom === selectedUomValue
              );
            }
          );
        } else {
          filteredData["rmList"] = deepDiveVolumeTableData.rmList
            .filter((item) => {
              return rmChartData.some((ele) => ele.code === item.rmCode);
            })
            .filter((item) => {
              return item.uom === selectedUomValue;
            });
        }
      }
      if (pmChartData?.length > 0) {
        if (functionSelected === "PM") {
          filteredData["pmList"] = deepDiveVolumeTableData.pmList.filter(
            (item) => {
              return (
                item.pmCode === funcVolumeSelected &&
                item.uom === selectedUomValue
              );
            }
          );
        } else {
          filteredData["pmList"] = deepDiveVolumeTableData.pmList
            .filter((item) => {
              return pmChartData.some((ele) => ele.code === item.pmCode);
            })
            .filter((item) => {
              return item.uom === selectedUomValue;
            });
        }
      }
      setFilteredTableData(Object.assign({}, filteredData));
    } else {
      setFilteredTableData({});
    }
  }, [LineChartData]);

  const handleStackebarSelection = (value) => {
    let selectedUom,
      objCountry = [],
      objFG = [],
      objRM = [],
      objPM = [];
    if (value === undefined) {
      setSelectedUomValue("");
      setTotalBwValue("0");
    } else {
      setFuntionSelected("");
      setFuncVolumeSelected("");
      setSelectedUomIndex(value);
      defaultDeepDiveViewData?.volume?.overview.map((item, index) => {
        if (value === index) {
          selectedUom = item.uom;
          setSelectedUomValue(item.uom);
          setTotalBwValue(
            formatNumber(
              parseFloat(item.fgbw) +
                parseFloat(item.rmbw) +
                parseFloat(item.pmbw)
            )
          );
        }
      });
      let selectedUomData =
        deepDiveActualVolumeData?.volume?.countryViewUOM?.filter(
          (item) => item.uom === selectedUom
        );
      let countrySum = 0,
        fgSum = 0,
        rmSum = 0,
        pmSum = 0;

      selectedUomData?.map((item) => {
        item.countryView.map((ele) => {
          countrySum += parseFloat(ele.countryBW);
          fgSum += parseFloat(ele.countryFGBW);
          rmSum += parseFloat(ele.countryRMBW);
          pmSum += parseFloat(ele.countryPMBW);
          const weekIndex = objCountry.findIndex(
            (ele1) => ele1.code === ele.country
          );
          if (weekIndex === -1) {
            if (ele.countryBW !== "0") {
              objCountry.push({
                code: ele.country,
                value: Math.round(parseFloat(ele.countryBW)),
                color: "#61D4C4",
                uom: ele.uom,
              });
            }
          } else {
            let existingValue = objCountry[weekIndex].value;
            objCountry[weekIndex].value =
              existingValue + Math.round(parseFloat(ele.countryBW));
          }

          ele.fgView.map((data) => {
            const weekIndexFG = objFG.findIndex(
              (ele1) => ele1.code === data.fgCode
            );
            if (weekIndexFG === -1) {
              if (data.fgbw !== "0") {
                objFG.push({
                  code: data.fgCode,
                  value: Math.round(parseFloat(data.fgbw)),
                  color: "#8FCAEB",
                  uom: data.uom,
                  country: ele.country,
                });
              }
            } else {
              let existingValue = objFG[weekIndexFG].value;
              objFG[weekIndexFG].value =
                existingValue + Math.round(parseFloat(data.fgbw));
            }

            data?.pmView?.map((chunck) => {
              const weekIndexPM = objPM.findIndex(
                (ele1) => ele1.code === chunck.pmCode
              );
              if (weekIndexPM === -1) {
                if (chunck.pmbw !== "0") {
                  objPM.push({
                    code: chunck.pmCode,
                    value: Math.round(parseFloat(chunck.pmbw)),
                    color: "#C79AD5",
                    fgcode: chunck.fgCode,
                    uom: chunck.uom,
                    country: ele.country,
                  });
                }
              } else {
                if (chunck.fgcode !== objPM[weekIndexPM].fgCode) {
                  let existingValue = objPM[weekIndexPM].value;
                  objPM[weekIndexPM].value =
                    existingValue + Math.round(parseFloat(chunck.pmbw));
                }
              }
            });

            data?.rmView?.map((chunck) => {
              const weekIndexRM = objRM.findIndex(
                (ele1) => ele1.code === chunck.rmCode
              );
              if (weekIndexRM === -1) {
                if (chunck.rmbw !== "0") {
                  objRM.push({
                    code: chunck.rmCode,
                    value: Math.round(parseFloat(chunck.rmbw)),
                    color: "#FCEDA4",
                    fgcode: chunck.fgCode,
                    uom: chunck.uom,
                    country: ele.country,
                    materialType: chunck.materialType,
                  });
                }
              } else {
                if (chunck.fgcode !== objRM[weekIndexRM].fgCode) {
                  let existingValue = objRM[weekIndexRM].value;
                  objRM[weekIndexRM].value =
                    existingValue + Math.round(parseFloat(chunck.rmbw));
                }
              }
            });
          });
        });
      });
      objCountry.sort((a, b) => b.value - a.value);
      objFG.sort((a, b) => b.value - a.value);
      objRM.sort((a, b) => b.value - a.value);
      objPM.sort((a, b) => b.value - a.value);
      setCountryChartData(objCountry);
      setFgChartData(objFG);
      setPMChartData(objPM);
      setRMChartData(objRM);

      setCountryValue(formatNumber(countrySum));
      setFgBwValue(formatNumber(fgSum));
      setRmBwValue(formatNumber(rmSum));
      setPmBwValue(formatNumber(pmSum));
    }
  };

  const onChangeTab = (selectedTab) => {
    setActiveTab(selectedTab);
    props.onTabChange(selectedTab);
  };

  const handleCountryBarSelect = (value) => {
    let countryName,
      objCountry = [],
      objFG = [],
      objRM = [],
      objPM = [],
      fgArray = [],
      pmArray = [],
      rmArray = [];
    if (value === undefined) {
      setLineChartData([]);
      handleStackebarSelection(selectedUomIndex);
      setFuntionSelected("");
      setFuncVolumeSelected("");
    } else {
      // setCountrySelected(value);
      setFuntionSelected("Country");
      CountryChartData?.map((item, index) => {
        if (value === index) countryName = item.code;
      });
      setFuncVolumeSelected(countryName);
      let countrybwsum = 0,
        FGbwsum = 0,
        RMbwsum = 0,
        PMbwsum = 0;
      deepDiveViewData?.volume?.countryViewUOM?.map((data) => {
        if (data.uom === selectedUomValue) {
          data?.countryView?.map((ele) => {
            if (ele.country === countryName && ele.countryBW !== "0") {
              countrybwsum += parseFloat(ele.countryBW);
              FGbwsum += parseFloat(ele.countryFGBW);
              RMbwsum += parseFloat(ele.countryRMBW);
              PMbwsum += parseFloat(ele.countryPMBW);
              objCountry.push({
                code: ele.country,
                value: Math.round(parseFloat(ele.countryBW)),
                color: "#61D4C4",
                uom: ele.uom,
              });
              ele?.fgView?.map((data) => {
                const weekIndexFG = objFG.findIndex(
                  (ele1) => ele1.code === data.fgCode
                );
                if (weekIndexFG === -1) {
                  if (data.fgbw !== "0") {
                    objFG.push({
                      code: data.fgCode,
                      value: Math.round(parseFloat(data.fgbw)),
                      color: "#8FCAEB",
                      uom: data.uom,
                      country: ele.country,
                    });
                    fgArray.push(data.fgCode);
                  }
                } else {
                  let existingValue = objFG[weekIndexFG].value;
                  objFG[weekIndexFG].value =
                    existingValue + Math.round(parseFloat(data.fgbw));
                }

                data?.pmView?.map((chunck) => {
                  const weekIndexPM = objPM.findIndex(
                    (ele1) => ele1.code === chunck.pmCode
                  );
                  if (weekIndexPM === -1) {
                    if (chunck.pmbw !== "0") {
                      objPM.push({
                        code: chunck.pmCode,
                        value: Math.round(parseFloat(chunck.pmbw)),
                        color: "#C79AD5",
                        fgcode: chunck.fgCode,
                        uom: chunck.uom,
                        country: ele.country,
                      });
                      pmArray.push(chunck.pmCode);
                    }
                  } else {
                    if (chunck.fgcode !== objPM[weekIndexPM].fgCode) {
                      let existingValue = objPM[weekIndexPM].value;
                      objPM[weekIndexPM].value =
                        existingValue + Math.round(parseFloat(chunck.pmbw));
                    }
                  }
                });
                data?.rmView?.map((chunck) => {
                  const weekIndexRM = objRM.findIndex(
                    (ele1) => ele1.code === chunck.rmCode
                  );
                  if (weekIndexRM === -1) {
                    if (chunck.rmbw !== "0") {
                      objRM.push({
                        code: chunck.rmCode,
                        value: Math.round(parseFloat(chunck.rmbw)),
                        color: "#FCEDA4",
                        fgcode: chunck.fgCode,
                        uom: chunck.uom,
                        country: ele.country,
                        materialType: chunck.materialType,
                      });
                      rmArray.push(chunck.rmCode);
                    }
                  } else {
                    if (chunck.fgcode !== objRM[weekIndexRM].fgCode) {
                      let existingValue = objRM[weekIndexRM].value;
                      objRM[weekIndexRM].value =
                        existingValue + Math.round(parseFloat(chunck.rmbw));
                    }
                  }
                });
              });
            }
          });
        }
      });
      objCountry.sort((a, b) => b.value - a.value);
      objFG.sort((a, b) => b.value - a.value);
      objRM.sort((a, b) => b.value - a.value);
      objPM.sort((a, b) => b.value - a.value);
      // setCountryChartData(objCountry);
      setFgChartData(objFG);
      setPMChartData(objPM);
      setRMChartData(objRM);
      setCountryValue(formatNumber(countrybwsum));
      setFgBwValue(formatNumber(FGbwsum));
      setRmBwValue(formatNumber(RMbwsum));
      setPmBwValue(formatNumber(PMbwsum));
      let FGlinechartdata = [];
      if (fgArray.length > 0) {
        deepDiveVolumeTableData?.fgList?.map((item) => {
          if (
            fgArray.includes(item.fgCode) &&
            item.plantCountry === countryName &&
            item.uom === selectedUomValue
          ) {
            item.weekDetails.map((ele) => {
              const weekIndex = FGlinechartdata.findIndex(
                (ele1) => ele1.weekname === ele.weekNumber
              );
              if (weekIndex !== -1) {
                let inventory = parseFloat(
                  FGlinechartdata[weekIndex].inventory
                );
                inventory += parseFloat(ele.inventory);
                FGlinechartdata[weekIndex].inventory = inventory;
              } else {
                FGlinechartdata.push({
                  weekname: ele.weekNumber,
                  inventory: ele.inventory,
                });
              }
            });
          }
        });
      }
      if (pmArray.length > 0) {
        deepDiveVolumeTableData?.pmList?.map((item) => {
          if (
            pmArray.includes(item.pmCode) &&
            item.plantCountry === countryName &&
            item.uom === selectedUomValue
          ) {
            item.weekDetails.map((ele) => {
              const weekIndex = FGlinechartdata.findIndex(
                (ele1) => ele1.weekname === ele.weekNumber
              );
              if (weekIndex !== -1) {
                let inventory = parseFloat(
                  FGlinechartdata[weekIndex].inventory
                );
                inventory += parseFloat(ele.inventory);
                FGlinechartdata[weekIndex].inventory = inventory;
              } else {
                FGlinechartdata.push({
                  weekname: ele.weekNumber,
                  inventory: ele.inventory,
                });
              }
            });
          }
        });
      }
      if (rmArray.length > 0) {
        deepDiveVolumeTableData?.rmList?.map((item) => {
          if (
            rmArray.includes(item.rmCode) &&
            item.plantCountry === countryName &&
            item.uom === selectedUomValue
          ) {
            item.weekDetails.map((ele) => {
              const weekIndex = FGlinechartdata.findIndex(
                (ele1) => ele1.weekname === ele.weekNumber
              );
              if (weekIndex !== -1) {
                let inventory = parseFloat(
                  FGlinechartdata[weekIndex].inventory
                );
                inventory += parseFloat(ele.inventory);
                FGlinechartdata[weekIndex].inventory = inventory;
              } else {
                FGlinechartdata.push({
                  weekname: ele.weekNumber,
                  inventory: ele.inventory,
                });
              }
            });
          }
        });
      }
      setLineChartData(FGlinechartdata);
    }
  };

  const handleFGBarSelect = (value) => {
    let uomName,
      countryName,
      fgcode,
      objCountry = [],
      objFG = [],
      objRM = [],
      objPM = [];
    if (value === undefined) {
      setLineChartData([]);
      setFuntionSelected("");
      handleStackebarSelection(selectedUomIndex);
      setFuncVolumeSelected("");
    } else {
      setFuntionSelected("FG");
      fgChartData?.map((item, index) => {
        if (index === value) {
          uomName = item.uom;
          countryName = item.country;
          fgcode = item.code;
        }
      });
      setFuncVolumeSelected(fgcode);
      deepDiveViewData?.volume?.countryViewUOM?.map((data) => {
        if (data.uom === uomName) {
          data?.countryView?.map((ele) => {
            ele?.fgView?.map((data) => {
              if (data.fgCode === fgcode) {
                const fgCode = objFG.every((ele) => ele.code !== data.fgCode);
                const cntry = objCountry.every(
                  (ele1) => ele1.code !== ele.country
                );
                if (cntry) {
                  objCountry.push({
                    code: ele.country,
                    value: Math.round(parseFloat(data.fgbw)),
                    color: "#61D4C4",
                    uom: ele.uom,
                  });
                }
                if (fgCode && data.fgbw !== "0") {
                  objFG.push({
                    code: data.fgCode,
                    value: Math.round(parseFloat(data.fgbw)),
                    color: "#8FCAEB",
                    uom: data.uom,
                    country: ele.country,
                  });
                }
                data?.pmView?.map((chunck) => {
                  const pmCode = objPM.every(
                    (ele) => ele.code !== chunck.pmCode
                  );
                  if (pmCode && chunck.pmbw !== "0") {
                    objPM.push({
                      code: chunck.pmCode,
                      value: Math.round(parseFloat(chunck.pmbw)),
                      color: "#C79AD5",
                      fgcode: chunck.fgCode,
                      uom: chunck.uom,
                      country: ele.country,
                    });
                  }
                });
                data?.rmView?.map((chunck) => {
                  const rmCode = objRM.every(
                    (ele) => ele.code !== chunck.rmCode
                  );
                  if (rmCode && chunck.rmbw !== "0") {
                    objRM.push({
                      code: chunck.rmCode,
                      value: Math.round(parseFloat(chunck.rmbw)),
                      color: "#FCEDA4",
                      fgcode: chunck.fgCode,
                      uom: chunck.uom,
                      country: ele.country,
                      materialType: chunck.materialType,
                    });
                  }
                });
              }
            });
          });
        }
      });
      objRM.sort((a, b) => b.value - a.value);
      objPM.sort((a, b) => b.value - a.value);
      objCountry.sort((a, b) => b.value - a.value);
      setCountryChartData(objCountry);
      // setFgChartData(objFG);
      setPMChartData(objPM);
      setRMChartData(objRM);

      const countrybwsum = objCountry.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const FGbwsum = objFG.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const RMbwsum = objRM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const PMbwsum = objPM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      setCountryValue(formatNumber(fgChartData[value].value));
      setFgBwValue(formatNumber(fgChartData[value].value));
      setRmBwValue(formatNumber(RMbwsum));
      setPmBwValue(formatNumber(PMbwsum));
      let FGlinechartdata = [];
      deepDiveVolumeTableData.fgList.map((item) => {
        if (fgcode === item.fgCode) {
          item.weekDetails.map((ele) => {
            const weekIndex = FGlinechartdata.findIndex(
              (ele1) => ele1.weekname === ele.weekNumber
            );
            if (weekIndex !== -1) {
              let inventory = parseFloat(FGlinechartdata[weekIndex].inventory);
              inventory += parseFloat(ele.inventory);
              FGlinechartdata[weekIndex].inventory = inventory;
            } else {
              FGlinechartdata.push({
                weekname: ele.weekNumber,
                inventory: ele.inventory,
              });
            }
          });
        }
      });
      setLineChartData(FGlinechartdata);
    }
  };

  const handlePMBarSelect = (value) => {
    let uomName,
      countryName,
      fgcode,
      pmcode,
      objCountry = [],
      objFG = [],
      objRM = [],
      objPM = [];
    if (value === undefined) {
      setLineChartData([]);
      setFuntionSelected("");
      handleStackebarSelection(selectedUomIndex);
      setFuncVolumeSelected("");
    } else {
      setFuntionSelected("PM");
      pmChartData?.map((item, index) => {
        if (index === value) {
          uomName = item.uom;
          countryName = item.country;
          fgcode = item.fgcode;
          pmcode = item.code;
        }
      });
      setFuncVolumeSelected(pmcode);
      deepDiveViewData?.volume?.countryViewUOM?.map((data) => {
        if (data.uom === uomName) {
          data?.countryView?.map((ele) => {
            ele?.fgView?.map((data) => {
              if (data.fgCode === fgcode) {
                const fgCode = objFG.every((ele) => ele.code !== data.fgCode);
                if (fgCode && data.fgbw !== "0") {
                  objFG.push({
                    code: data.fgCode,
                    value: Math.round(parseFloat(data.fgbw)),
                    color: "#8FCAEB",
                    uom: data.uom,
                    country: ele.country,
                  });
                }
                data?.pmView?.map((chunck) => {
                  if (pmcode === chunck.pmCode) {
                    const pmCode = objPM.every(
                      (ele) => ele.code !== chunck.pmCode
                    );
                    if (pmCode && chunck.pmbw !== "0") {
                      objPM.push({
                        code: chunck.pmCode,
                        value: Math.round(parseFloat(chunck.pmbw)),
                        color: "#C79AD5",
                        fgcode: chunck.fgCode,
                        uom: chunck.uom,
                        country: ele.country,
                      });
                    }
                    const weekIndex = objCountry.findIndex(
                      (ele1) => ele1.fgcode === data.fgCode
                    );
                    if (weekIndex === -1) {
                      objCountry.push({
                        code: ele.country,
                        value: Math.round(parseFloat(chunck.pmbw)),
                        color: "#61D4C4",
                        uom: ele.uom,
                        fgcode: data.fgCode,
                      });
                    } else {
                      let existingValue = objCountry[weekIndex].value;
                      objCountry[weekIndex].value =
                        existingValue + Math.round(parseFloat(chunck.pmbw));
                    }
                  }
                });
                data?.rmView?.map((chunck) => {
                  const rmCode = objRM.every(
                    (ele) => ele.code !== chunck.rmCode
                  );
                  if (rmCode && chunck.rmbw !== "0") {
                    objRM.push({
                      code: chunck.rmCode,
                      value: Math.round(parseFloat(chunck.rmbw)),
                      color: "#FCEDA4",
                      fgcode: chunck.fgCode,
                      uom: chunck.uom,
                      country: ele.country,
                      materialType: chunck.materialType,
                    });
                  }
                });
              }
            });
          });
        }
      });

      objRM.sort((a, b) => b.value - a.value);
      objFG.sort((a, b) => b.value - a.value);
      objCountry.sort((a, b) => b.value - a.value);
      setCountryChartData(objCountry);
      setFgChartData(objFG);
      // setPMChartData(objPM);
      setRMChartData(objRM);

      const countrybwsum = objCountry.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const FGbwsum = objFG.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const RMbwsum = objRM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const PMbwsum = objPM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      setCountryValue(formatNumber(pmChartData[value].value));
      setFgBwValue(formatNumber(FGbwsum));
      setRmBwValue(formatNumber(RMbwsum));
      setPmBwValue(formatNumber(pmChartData[value].value));
      let PMlinechartdata = [];
      deepDiveVolumeTableData?.pmList?.map((item) => {
        if (pmcode === item.pmCode) {
          item?.weekDetails?.map((ele) => {
            const weekIndex = PMlinechartdata.findIndex(
              (ele1) => ele1.weekname === ele.weekNumber
            );
            if (weekIndex !== -1) {
              let inventory = parseFloat(PMlinechartdata[weekIndex].inventory);
              inventory += parseFloat(ele.inventory);
              PMlinechartdata[weekIndex].inventory = inventory;
            } else {
              PMlinechartdata.push({
                weekname: ele.weekNumber,
                inventory: ele.inventory,
              });
            }
          });
        }
      });
      setLineChartData(PMlinechartdata);
    }
  };

  const handleRMBarSelect = (value) => {
    let uomName,
      countryName,
      fgcode,
      rmcode,
      objCountry = [],
      objFG = [],
      objRM = [],
      objPM = [];
    if (value === undefined) {
      setLineChartData([]);
      setFuntionSelected("");
      handleStackebarSelection(selectedUomIndex);
      setFuncVolumeSelected("");
    } else {
      setFuntionSelected("RM");
      rmChartData?.map((item, index) => {
        if (index === value) {
          uomName = item.uom;
          countryName = item.country;
          fgcode = item.fgcode;
          rmcode = item.code;
        }
      });
      setFuncVolumeSelected(rmcode);
      deepDiveViewData?.volume?.countryViewUOM?.map((data) => {
        if (data.uom === uomName) {
          data?.countryView?.map((ele) => {
            ele?.fgView?.map((data) => {
              if (data.fgCode === fgcode) {
                const fgCode = objFG.every((ele) => ele.code !== data.fgCode);
                if (fgCode && data.fgbw !== "0") {
                  objFG.push({
                    code: data.fgCode,
                    value: Math.round(parseFloat(data.fgbw)),
                    color: "#8FCAEB",
                    uom: data.uom,
                    country: ele.country,
                  });
                }
                data?.pmView?.map((chunck) => {
                  const pmCode = objPM.every(
                    (ele) => ele.code !== chunck.pmCode
                  );
                  if (pmCode && chunck.pmbw !== "0") {
                    objPM.push({
                      code: chunck.pmCode,
                      value: Math.round(parseFloat(chunck.pmbw)),
                      color: "#C79AD5",
                      fgcode: chunck.fgCode,
                      uom: chunck.uom,
                      country: ele.country,
                    });
                  }
                });
                data?.rmView?.map((chunck) => {
                  if (rmcode === chunck.rmCode) {
                    const rmCode = objRM.every(
                      (ele) => ele.code !== chunck.rmCode
                    );
                    if (rmCode && chunck.rmbw !== "0") {
                      objRM.push({
                        code: chunck.rmCode,
                        value: Math.round(parseFloat(chunck.rmbw)),
                        color: "#FCEDA4",
                        fgcode: chunck.fgCode,
                        uom: chunck.uom,
                        country: ele.country,
                        materialType: chunck.materialType,
                      });
                    }
                    const weekIndex = objCountry.findIndex(
                      (ele1) => ele1.fgcode === data.fgCode
                    );
                    if (weekIndex === -1) {
                      objCountry.push({
                        code: ele.country,
                        value: Math.round(parseFloat(chunck.rmbw)),
                        color: "#61D4C4",
                        uom: ele.uom,
                        fgcode: data.fgCode,
                      });
                    } else {
                      let existingValue = objCountry[weekIndex].value;
                      objCountry[weekIndex].value =
                        existingValue + Math.round(parseFloat(chunck.rmbw));
                    }
                  }
                });
              }
            });
          });
        }
      });
      objPM.sort((a, b) => b.value - a.value);
      objFG.sort((a, b) => b.value - a.value);
      objCountry.sort((a, b) => b.value - a.value);
      setCountryChartData(objCountry);
      setFgChartData(objFG);
      setPMChartData(objPM);
      // setRMChartData(objRM);

      const countrybwsum = objCountry.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const FGbwsum = objFG.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const RMbwsum = objRM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      const PMbwsum = objPM.reduce((acc, item) => {
        return acc + item.value;
      }, 0);
      setCountryValue(formatNumber(rmChartData[value].value));
      setFgBwValue(formatNumber(FGbwsum));
      setRmBwValue(formatNumber(rmChartData[value].value));
      setPmBwValue(formatNumber(PMbwsum));
      let RMlinechartdata = [];
      deepDiveVolumeTableData.rmList.map((item) => {
        if (rmcode === item.rmCode) {
          item.weekDetails.map((ele) => {
            const weekIndex = RMlinechartdata.findIndex(
              (ele1) => ele1.weekname === ele.weekNumber
            );
            if (weekIndex !== -1) {
              let inventory = parseFloat(RMlinechartdata[weekIndex].inventory);
              inventory += parseFloat(ele.inventory);
              RMlinechartdata[weekIndex].inventory = inventory;
            } else {
              RMlinechartdata.push({
                weekname: ele.weekNumber,
                inventory: ele.inventory,
              });
            }
          });
        }
      });
      setLineChartData(RMlinechartdata);
    }
  };

  return (
    <>
      {isModalTableOpen && (
        <ModalDetailedTable
          tableData={deepDiveVolumeTableData}
          filteredData={filteredTableData}
          showModal={true}
          setShowModal={() => setIsModalTableOpen(false)}
          selectedProjectId={selectedProjectId}
          selectedUom={selectedUomValue}
          tabselected={"Volume"}
          countrySelected={
            functionSelected === "Country" ? funcVolumeSelected : ""
          }
        />
      )}
      <div className="dsf-deepdive-parent-div">
        <Row className="dsf-deepdive-child-row1">
          <Col xs={4} className="dsf-deepdive-child-row1-col1">
            <div className="dsf-deepdive-child-row1-col1-div">
              <ul className="nav dsp-deepdive-toggle-ul">
                <li
                  className={`dsp-deepdive-toggle-li 
                      ${activeTab === "Value" ? "active" : ""}`}
                  onClick={() => onChangeTab("Value")}
                >
                  Value (€)
                </li>
                <li
                  className={`dsp-deepdive-toggle-li ${
                    activeTab === "Volume" ? "active" : ""
                  }`}
                  onClick={() => onChangeTab("Volume")}
                >
                  Volume
                </li>
              </ul>
              <Row className="dsf-deepdive-donut-chart-row">
                <Col xs={5} className="dsf-deepdive-donut-chart-row-col1">
                  <div className="dsf-deepdive-totalBw-value-div">
                    <p className="dsf-bw-title">Total BW Risk</p>
                    <p className="dsf-bw-value">
                      {totalBwValue} {selectedUomValue}
                    </p>
                  </div>
                  <div className="donut-volume-legends-main-div">
                    <div className="donut-legends-div">
                      <span className="dsf-legen-style span-gap">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#8FCAEB" }}
                        ></span>
                        FG
                      </span>
                      <span className="dsf-legen-style">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#FCEDA4" }}
                        ></span>
                        RM
                      </span>
                    </div>
                    <div className="donut-legends-div">
                      <span className="dsf-legen-style span-gap">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#C79AD5" }}
                        ></span>
                        PM
                      </span>
                      <span className="dsf-legen-style">
                        <span
                          className="color-dot"
                          style={{ backgroundColor: "#CFCFCF" }}
                        ></span>
                        SFG
                      </span>
                    </div>
                  </div>
                </Col>
                <Col xs={7} className="dsf-deepdive-stackedBar-chart-row-col2">
                  <div id="volume-Uom-chart" className="stackedBar-chart-div">
                    <StackedbarChart
                      chartData={deepDiveDefaultVolumeData?.volume?.overview}
                      container={"volume-Uom-chart"}
                      onChange={handleStackebarSelection}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
          <Col
            xs={8}
            className={`dsf-deepdive-child-row1-col2`}
            key={CountryChartData}
          >
            {selectedUomValue.length > 0 ? (
              CountryChartData?.length > 0 ? (
                <div className="dsf-deepdive-child-row1-col2-div">
                  <div className="dsf-deepdive-barchart-info">
                    <p className="dsf-deepdive-barchart-name">DC-country view</p>
                    <p className="dsf-deepdive-barchart-value">
                      Total BW:
                      <span className="dsf-deepdive-barchart-value-FG">
                        {" "}
                        {countryValue} {selectedUomValue}
                      </span>
                    </p>
                  </div>
                  {CountryChartData && CountryChartData.length > 0 && (
                    <div className="fg-combo-chart-parent-div">
                      <div
                        id="country_view_chart"
                        className={`country_combo_chart_div ${
                          functionSelected.length > 0
                            ? functionSelected !== "Country"
                              ? "dsf-deepdive-col-disable"
                              : ""
                            : ""
                        }`}
                      >
                        <ComboChart
                          container={"country_view_chart"}
                          chartData={CountryChartData}
                          onChange={handleCountryBarSelect}
                          chartAreaLeft={"10%"}
                          chartAreaBottom={"20%"}
                          chartAreaWidth={"80%"}
                          chartAreaHeight={"70%"}
                          chartWidth={"70%"}
                          chartHeight={"140"}
                          barColor={"#61D4C4"}
                          barCount={"14"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="dsf-deepdive-child-row-col-div-text">
                  <span>No country data available</span>
                </div>
              )
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>Select a UoM to load the countries</span>
              </div>
            )}
          </Col>
        </Row>
        <Row className="dsf-deepdive-child-row2">
          <Col xs={4} className={`dsf-deepdive-child-row2-col1`}>
            {selectedUomValue.length > 0 ? (
              fgChartData?.length > 0 ? (
                <div
                  className="dsf-deepdive-child-row2-col1-div"
                  key={fgChartData}
                >
                  <div className="dsf-deepdive-barchart-info">
                    <p className="dsf-deepdive-barchart-name">Finished Goods</p>
                    <p className="dsf-deepdive-barchart-value">
                      Total BW:
                      <span className="dsf-deepdive-barchart-value-FG">
                        {" "}
                        {fgBwValue} {selectedUomValue}
                      </span>
                    </p>
                  </div>
                  {fgChartData && fgChartData.length > 0 && (
                    <div className="fg-combo-chart-parent-div">
                      <div
                        id="fg_combo_chart"
                        className={`fg_combo_chart_div ${
                          functionSelected.length > 0
                            ? functionSelected !== "FG"
                              ? "dsf-deepdive-col-disable"
                              : ""
                            : ""
                        }`}
                      >
                        <ComboChart
                          container={"fg_combo_chart"}
                          chartData={fgChartData}
                          onChange={handleFGBarSelect}
                          chartAreaLeft={"20%"}
                          chartAreaBottom={"20%"}
                          chartAreaWidth={"70%"}
                          chartAreaHeight={"70%"}
                          chartWidth={"70%"}
                          chartHeight={"140"}
                          barColor={"#8FCAEB"}
                          barCount={"8"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="dsf-deepdive-child-row-col-div-text">
                  <span>No FG data available</span>
                </div>
              )
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>Select a UoM to load the FGs</span>
              </div>
            )}
          </Col>
          <Col xs={4} className={`dsf-deepdive-child-row2-col2`}>
            {selectedUomValue.length > 0 ? (
              rmChartData?.length > 0 ? (
                <div
                  className="dsf-deepdive-child-row2-col2-div"
                  key={rmChartData}
                >
                  <div className="dsf-deepdive-barchart-info">
                    <p className="dsf-deepdive-barchart-name">Raw Materials</p>
                    <p className="dsf-deepdive-barchart-value">
                      Total BW:
                      <span className="dsf-deepdive-barchart-value-RM">
                        {" "}
                        {rmBwValue} {selectedUomValue}
                      </span>
                    </p>
                  </div>
                  {rmChartData && rmChartData.length > 0 && (
                    <div className="fg-combo-chart-parent-div">
                      <div
                        id="rm_combo_chart"
                        className={`rm_combo_chart_div ${
                          functionSelected.length > 0
                            ? functionSelected !== "RM"
                              ? "dsf-deepdive-col-disable"
                              : ""
                            : ""
                        }`}
                      >
                        <ComboChart
                          container={"rm_combo_chart"}
                          chartData={rmChartData}
                          onChange={handleRMBarSelect}
                          chartAreaLeft={"20%"}
                          chartAreaBottom={"20%"}
                          chartAreaWidth={"70%"}
                          chartAreaHeight={"70%"}
                          chartWidth={"70%"}
                          chartHeight={"140"}
                          barColor={"#FCEDA4"}
                          barCount={"8"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="dsf-deepdive-child-row-col-div-text">
                  <span>No RM data available</span>
                </div>
              )
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>Select a UoM to load the RMs</span>
              </div>
            )}
          </Col>
          <Col xs={4} className={`dsf-deepdive-child-row2-col3`}>
            {selectedUomValue.length > 0 ? (
              pmChartData?.length > 0 ? (
                <div
                  className="dsf-deepdive-child-row2-col3-div"
                  key={pmChartData}
                >
                  <div className="dsf-deepdive-barchart-info">
                    <p className="dsf-deepdive-barchart-name">Pack Materials</p>
                    <p className="dsf-deepdive-barchart-value">
                      Total BW:
                      <span className="dsf-deepdive-barchart-value-PM">
                        {" "}
                        {pmBwValue} {selectedUomValue}
                      </span>
                    </p>
                  </div>
                  {pmChartData && pmChartData.length > 0 && (
                    <div className="fg-combo-chart-parent-div">
                      <div
                        id="pm_combo_chart"
                        className={`pm_combo_chart_div ${
                          functionSelected.length > 0
                            ? functionSelected !== "PM"
                              ? "dsf-deepdive-col-disable"
                              : ""
                            : ""
                        }`}
                      >
                        <ComboChart
                          container={"pm_combo_chart"}
                          chartData={pmChartData}
                          onChange={handlePMBarSelect}
                          chartAreaLeft={"20%"}
                          chartAreaBottom={"20%"}
                          chartAreaWidth={"70%"}
                          chartAreaHeight={"70%"}
                          chartWidth={"70%"}
                          chartHeight={"140"}
                          barColor={"#C79AD5"}
                          barCount={"8"}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="dsf-deepdive-child-row-col-div-text">
                  <span>No PM data available</span>
                </div>
              )
            ) : (
              <div className="dsf-deepdive-child-row-col-div-text">
                <span>Select a UoM to load the PMs</span>
              </div>
            )}
          </Col>
        </Row>
        <Row className="dsf-deepdive-child-row3">
          <Col xs={12} className="dsf-deepdive-child-row3-col1">
            <div className="dsf-deepdive-child-row3-col1-div">
              <div className="dsf-deepdive-barchart-info">
                <p className="dsf-deepdive-barchart-name">
                  Inventory Development
                </p>
                <div
                  className="dsf-deepdive-detaile-table"
                  onClick={() => setIsModalTableOpen(true)}
                >
                  Detailed Table
                  <img src={TableArrowPointer} alt="arrowpointer" />
                </div>
              </div>
              <div className="donut-legends-div align-data-div">
                <span className="span-gap dsf-legen-style">
                  <span
                    className="color-dot"
                    style={{ backgroundColor: "#F1A33A" }}
                  ></span>
                  Business Waste
                </span>
                <span className="dsf-legen-style">
                  <span
                    className="color-dot"
                    style={{ backgroundColor: "#00D7C4" }}
                  ></span>
                  Delist Date
                </span>
              </div>
              {LineChartData && (
                <div id="line_chart_div">
                  {LineChartData.length > 0 ? (
                    <LineChart chartData={LineChartData} />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DeepDiveVolume;
