import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal, Row, FormCheck } from "react-bootstrap";
import closeIcon from "../../../../assets/images/Vector-cross.svg";
import { Checkbox } from "@mui/material";
import {
  fetchMDMOpsData,
  saveMDMOpsData,
  fetchProjectWorkFlowStatus,
  saveRNDSignoffData,
} from "../../../../service/projectDetails";
import { postUpdatedWorkFlowStatus } from "../../../../slices/projectDetailSlice";
import "./modalMDMOps.css";
import UniqueRPMGrid from "../UniqueRPM/uniqueRPMGrid";

const MDMOps = (props) => {
  const [showModal, setShowModal] = React.useState(props.isShow);
  const [objMDMOps, setObjMDMOps] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSelectedAll, setSelectedAll] = useState(false);
  const [isDataNotAvailable, setIsDataNotAvailable] = useState(false);
  const { isEdit } = props;

  const userDetails = useSelector((state) => state.userDetails);
  const isReadOnly =
    !isEdit &&
    (userDetails.roleName !== "BWInnovationR&D" ||
      userDetails.roleName !== "BWInnovationp&I");
  //Redux Start
  const dispatch = useDispatch();
  //Redux End

  useEffect(() => {
    setIsLoading(true);
    getMDMOpsData();
  }, []);

  useEffect(() => {
    if (props.isFromRnDUser) {
      if (objMDMOps) {
        const updateObjMDMOps = Object.assign({}, objMDMOps);
        if (isSelectedAll) {
          updateObjMDMOps?.uniqueRMPMDetail.map(
            (item, i) => (item.isSelected = true)
          );
        } else {
          if (
            !updateObjMDMOps?.uniqueRMPMDetail.filter(
              (item) => !item.isSelected
            ).length
          ) {
            updateObjMDMOps?.uniqueRMPMDetail.map(
              (item, i) => (item.isSelected = false)
            );
          }
        }
        setObjMDMOps(updateObjMDMOps);
      }
    }
  }, [isSelectedAll]);

  const getMDMOpsData = async () => {
    const response = await fetchMDMOpsData(
      props.selectedProjectId,
      props.isFromRnDUser
    );
    let MDMOpsData = {};
    if (response.status === 200) {
      MDMOpsData.appLink = response.data.appLink;
      MDMOpsData.approvalLink = response.data.approvalLink;
      MDMOpsData.deadline = response.data.deadline;
      MDMOpsData.piUser = response.data.piUser;
      MDMOpsData.projectDescription = response.data.projectDescription;
      MDMOpsData.projectFamily = response.data.projectFamily;
      MDMOpsData.projectId = response.data.projectId;
      MDMOpsData.projectName = response.data.projectName;
      MDMOpsData.userName = response.data.userName;
      MDMOpsData.userMailId = response.data.userMailId;
      MDMOpsData.mailMessage = response.data.mailMessage;
      MDMOpsData.projectIdDisplay = response.data.projectIdDisplay;
      if (props.isFromRnDUser) {
        MDMOpsData.uniqueRMPMDetail = [...response.data.uniqueRMPMDetail];
      } else {
        MDMOpsData.uniqueRMPMDetail = [
          ...response.data.uniqueRMPMDetail,
        ].filter((item) => item.isSelected === false);
      }
      setObjMDMOps(MDMOpsData);
      MDMOpsData.uniqueRMPMDetail.filter((item) => item.isSelected).length <
      MDMOpsData.uniqueRMPMDetail.length
        ? setSelectedAll(false)
        : setSelectedAll(true);
    } else {
      setIsDataNotAvailable(true);
      console.log("error occurred", response.status);
    }
    setIsLoading(false);
  };

  const onOkBtnClicked = async () => {
    const usermailId = JSON.parse(localStorage.getItem("userDetails")).username;
    if (userDetails.roleName !== "BWInnovationR&D") {
      let objMDMOpsToSave = Object.assign({}, objMDMOps);
      if (objMDMOps) {
        objMDMOpsToSave.piUser = `${userDetails.userFirstName}  ${userDetails.userLastName}`;
        objMDMOpsToSave.validateProjectName = 1;
      } else {
        objMDMOpsToSave.projectName = props.selectedProjectName;
        objMDMOpsToSave.projectId = props.selectedProjectId;
        objMDMOpsToSave.projectIdDisplay = "";
        objMDMOpsToSave.validateProjectName = 0;
      }
      const res = await saveMDMOpsData(objMDMOpsToSave);
      if (res.status === 200) {
        const res1 = await fetchProjectWorkFlowStatus(props.selectedProjectId);
        if (res1.status === 200) {
          const res = {
            projectName: props.selectedProjectName,
            workflow: res1.data,
          };
          dispatch(postUpdatedWorkFlowStatus(res));
        }
        setShowModal(false);
        props.onComplete(false);
      } else {
        console.log(res.status);
      }
    } else {
      const res = await saveRNDSignoffData(props.selectedProjectId, objMDMOps);
      if (res.status === 200) {
        const res1 = await fetchProjectWorkFlowStatus(props.selectedProjectId);
        if (res1.status === 200) {
          const res = {
            projectName: props.selectedProjectName,
            workflow: res1.data,
          };
          dispatch(postUpdatedWorkFlowStatus(res));
        }
        setShowModal(false);
        props.onComplete(false);
      } else {
        console.log(res.status);
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    props.onComplete(false);
  };

  const onCheckBoxClicked = async (e, index) => {
    const updateObjMDMOps = Object.assign({}, objMDMOps);
    updateObjMDMOps.uniqueRMPMDetail = [...e.uniqueRMPMDetail];
    // setObjMDMOps(updateObjMDMOps);
  };

  return (
    <>
      <Modal
        centered
        dialogClassName="dsf-dialog-UniqueRPMModal dsf-dialog-mdmOps"
        contentClassName="dsf-modal-workflowModal"
        show={showModal}
      >
        <Modal.Header className="bw-modal-checkDelist-header">
          <Modal.Title className=" bw-checkDeslist-header-title-div">
            <div className="checkDelist-modal-header-div uniquerpm-modal-header-div">
              <span className="dsf-size16 dsf-font-weight-900">
                {" "}
                Unique Raw Pack Materials
              </span>
              <span className="dsf-span-s" style={{ color: "#CFCFCF" }}>
                The following materials have been identified as unique. Please
                select the materials that will be reused / kept for this
                project.
                <br /> They will be considered as NOT delisted.{" "}
                <span className="dsf-span-sl">
                  So your selection will NOT be taken into the BW calculation.
                </span>
              </span>
            </div>
            <img
              src={closeIcon}
              alt="Close-Image"
              className="dsf-close-img"
              onClick={() => closeModal()}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bw-modal-uniqueRPM-body dsf-modal-uniqueRPM-body">
          <div className="dsf-uniqueRPM-table">
            {props.isFromRnDUser && (
              <Row className="dsf-uniqueRPM-row checkbox-info-div">
                <Col className="header-checkbox" xs={1}>
                  <Checkbox
                    disabled={true}
                    className="dsf-uniqueRPM-checkbox dsf-uniqueRPM-legend-checkbox"
                    checked={false}
                    disableRipple
                  />
                  <span className="dsf-size11 dsf-font-weight-400 bw-uniqueRPM-legend-span">
                    Delisted
                  </span>
                </Col>
                <Col
                  className="header-checkbox"
                  xs={3}
                  style={{ display: "flex" }}
                >
                  <Checkbox
                    disabled={true}
                    // onChange={(e) => enableMessageFacility(e)}
                    className="dsf-uniqueRPM-checkbox dsf-uniqueRPM-legend-checkbox"
                    checked={true}
                  />
                  <span className="dsf-size11 dsf-font-weight-400 bw-uniqueRPM-legend-span">
                    Not Delisted - Materials to be kept/reused
                  </span>
                </Col>
              </Row>
            )}
            <UniqueRPMGrid
              objUniqueRPM={objMDMOps}
              isLoading={isLoading}
              isEdit={isEdit}
              isDataNotAvailable={isDataNotAvailable}
              isReceipientEnable={false}
              isRNDPIUser={false}
              isCheckboxDisabled={props.isFromRnDUser ? false : true}
              selectionValueChange={onCheckBoxClicked}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="bw-modal-footer dsf-delist-footer-div">
          <Button
            className="bw-mdmOps-footer-btn bwi-reset-button"
            variant="secondary"
            onClick={() => closeModal()}
          >
            Cancel
          </Button>
          <Button
            className="bw-mdmOps-footer-btn bwi-submit-button"
            variant="secondary"
            onClick={(e) => onOkBtnClicked(e)}
            disabled={isReadOnly}
          >
            {props.isFromRnDUser ? "Sign Off" : "Send to CG"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default MDMOps;
