import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import {
  UploadAdminLWDDetails,
  fetchLWDFileDownload,
  ResetLWDFile,
} from "../../../../service/projectDetails";
import ActivityIndicator from "../../../../components/Loader/buttonLoader";
import ModalWarning from "./modalDeleteWarning";

const FileUploaderNew = (props) => {
  const hiddenFileInput = React.useRef(null);

  const [fileName, setFileName] = useState(props.fileName);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const { departmentName, setIsLoading } = props;
  const [error, setError] = useState("");
  const [fileData, setFileData] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [lwdFile, setLwdFile] = useState("");
  const [isResetLoading, setIsResetLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isWarnWindowOpen, setIsWarnWindowOpen] = useState(false);

  useEffect(() => {
    if (fileName && fileName.length > 0) {
      setFileName(fileName + ".xlsx");
      setIsFileUploaded(true);
    } else {
      setFileName("Choose a file to upload");
      setIsFileUploaded(false);
    }
  }, []);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleDeleteClick = async () => {
    setIsResetLoading(true);
    const res = await ResetLWDFile(departmentName);
    if (res.status === 200) {
      setFileName("Choose a file to upload");
      setIsFileUploaded(false);
      setError("");
    } else {
      setError(res.data);
    }
    setIsResetLoading(false);
  };

  const handleChange = async (event) => {
    const formData = new FormData();
    setError("");
    setFileName(event?.target?.files[0]?.name);
    formData.append("file", event.target.files[0]);
    setFileData(formData);
  };

  const onUploadBtnClicked = async () => {
    setIsActionLoading(true);
    const res = await UploadAdminLWDDetails(fileData, departmentName);
    if (res.status === 200) {
      setIsFileUploaded(true);
      setError("");
    } else {
      setError(res.data);
    }
    setIsActionLoading(false);
  };

  const onDownloadBtnClicked = async () => {
    setIsActionLoading(true);
    const res = await fetchLWDFileDownload(departmentName);
    if (res.status === 200) {
      const link = URL.createObjectURL(res.data);
      setLwdFile(link);
      setError("");
      setIsWaiting(true);
    } else {
      setError(res.data);
    }
    setIsActionLoading(false);
  };

  useEffect(() => {
    if (isWaiting) {
      const link = document.createElement("a");
      link.href = lwdFile;
      link.download = fileName;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsWaiting(false);
    }
  }, [isWaiting]);

  const onWarnModalOpen = (modalOpen) => {
    setIsWarnWindowOpen(modalOpen);
  };

  return (
    <>
      {isWarnWindowOpen && (
        <ModalWarning
          show={true}
          onOk={handleDeleteClick}
          onWarnModal={onWarnModalOpen}
          displayText={`"${fileName}" will be deleted. You cannot undo this action.`}
        />
      )}
      <div>
        <InputGroup
          className={`bwi-admin-department-browse-div bwi-modal-admin-task-title ${
            error.length > 0 ? "errorBorder" : "successBorder"
          }`}
        >
          <Form.Control
            name="admin_lwd_file"
            aria-describedby="browse-button"
            className={`bwi-admin-lwd-file-browse ${
              isFileUploaded ? "bwi-admin-lwd-file-browse-uploaded" : ""
            }`}
            value={fileName}
          />
          {isFileUploaded ? (
            <Button
              variant="outline-secondary"
              id="delete-button"
              className="bwi-admin-lwd-file-delete-button"
              onClick={() => onWarnModalOpen(true)}
              disableRipple
            >
              {isResetLoading ? <ActivityIndicator /> : "Delete"}
            </Button>
          ) : (
            <Button
              variant="outline-secondary"
              id="browse-button"
              onClick={handleClick}
              className="bwi-admin-lwd-file-browse-button"
              disableRipple
            >
              Browse
            </Button>
          )}
        </InputGroup>
        {error && error.length > 0 && (
          <p className="dsf-size10 dsf-font-weight-400 errorMsg bwi-admin-owner-err-msg">
            {" "}
            {error}
          </p>
        )}
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />

      {isFileUploaded ? (
        <button
          className="bwi-footer-admin-task-button"
          onClick={() => onDownloadBtnClicked()}
        >
          {isActionLoading ? <ActivityIndicator /> : "Download"}
        </button>
      ) : (
        <button
          className="bwi-footer-admin-task-button"
          onClick={() => onUploadBtnClicked()}
          disabled={fileName === "Choose a file to upload"}
        >
          {isActionLoading ? <ActivityIndicator /> : "Upload"}
        </button>
      )}
    </>
  );
};
export default FileUploaderNew;
